import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Field, FieldArray, useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import FormikPhoneField from '../common/FormikPhoneField';
import FormikSelect from '../common/FormikSelect';
import { PhoneTypes, MobileProviders } from '../../actions/types';

const useStyles = makeStyles((theme) => ({
  addPhoneButton: {
    paddingLeft: '0px',
  },
}));

const PhoneComponent = ({ prefix = '', contactInfo, allowOptional = false }) => {
  const { values, setFieldValue } = useFormikContext();
  const [selectedPhoneTypes, setSelectedPhoneTypes] = useState([]);

  const [showPreferredContactMethod, setShowPreferredContactMethod] =
    useState(true);

  useEffect(() => {
    //This monitors the contact object for changes
    const selectedPhoneTypes = contactInfo.phone.map((item) => item.type);
    setSelectedPhoneTypes([...selectedPhoneTypes]);

    if (contactInfo.phone.length === 1) {
      setFieldValue(`${prefix}.primaryPhoneType`, contactInfo.phone[0].type);
      setShowPreferredContactMethod(false);
    }
    if (!contactInfo.primaryPhoneType || contactInfo.phone.length > 1) {
      setShowPreferredContactMethod(true);
    }
  }, [contactInfo.phone]);

  const getPhoneTypes = () => {
    const typeArr = [];
    for (const property in PhoneTypes) {
      typeArr.push(PhoneTypes[property]);
    }
    return typeArr;
  };

  const getAvailPhoneTypes = () => {
    return getPhoneTypes()
      .map((val) => {
        if (!selectedPhoneTypes.includes(val)) {
          return val;
        }
      })
      .filter((e) => e !== undefined);
  };

  const updateSelectedPhoneType = (oldVal, newVal) => {
    const i = selectedPhoneTypes.findIndex((item) => item === oldVal);
    if (i !== -1) {
      const temp = [...selectedPhoneTypes];
      temp[i] = newVal;
      setSelectedPhoneTypes(temp);
    }
  };

  const getAvailPhoneTypeMenuItems = (selectedValue) => {
    return [
      <MenuItem key={selectedValue} value={selectedValue}>
        {selectedValue}
      </MenuItem>,
      ...getAvailPhoneTypes().map((phoneType) => (
        <MenuItem key={phoneType} value={phoneType}>
          {phoneType}
        </MenuItem>
      )),
    ];
  };

  const classes = useStyles();

  return (
    <Grid container item xs={12} spacing={2}>
      <FieldArray
        name={`${prefix}.phone`}
        render={(arrayHelpers) =>
          contactInfo.phone.map((item, index) => (
            <Grid key={`fai-${index}`} container item spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormikPhoneField
                  name={`${prefix}.phone.${index}.number`}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={4}>
                <FormikSelect
                  id={'type'}
                  name={`${prefix}.phone.${index}.type`}
                  label='Type'
                  onChange={(event) => {
                    const oldVal = item.type;
                    const newVal = event.target.value;

                    updateSelectedPhoneType(oldVal, newVal);

                    setFieldValue(
                      `${prefix}.phone.${index}.type`,
                      event.target.value
                    );
                  }}>
                  {getAvailPhoneTypeMenuItems(contactInfo.phone[index].type)}
                </FormikSelect>
              </Grid>
              {item.type === PhoneTypes.MOBILE && (
                <Grid item xs>
                  <FormikSelect
                    id={'mobileProvider'}
                    label='Mobile Provider'
                    name={`${prefix}.phone.${index}.mobileProvider`}
                    onChange={(event) => {
                      const val = event.target.value;
                      if (
                        val !== 'Other' &&
                        contactInfo.phone[index].mobileProviderOther !== ''
                      ) {
                        setFieldValue(
                          `${prefix}.phone.${index}.mobileProviderOther`,
                          ''
                        );
                      }
                      setFieldValue(
                        `${prefix}.phone.${index}.mobileProvider`,
                        event.target.value
                      );
                    }}>
                    {MobileProviders.map((val, index) => (
                      <MenuItem key={`mp-${index}`} value={val}>
                        {val === 'ATT' ? 'AT&T' : val}
                      </MenuItem>
                    ))}
                  </FormikSelect>
                </Grid>
              )}
              {(index > 0 || allowOptional) && (
                <Grid item style={{ margin: 'auto 0px' }}>
                  <IconButton
                    size={'small'}
                    onClick={() => {
                      arrayHelpers.remove(index);
                      const i = selectedPhoneTypes.findIndex(
                        (e) => e === item.type
                      );
                      if (i !== -1) {
                        const updatedSelectedItems = [
                          ...selectedPhoneTypes.slice(i, 0),
                        ];

                        setSelectedPhoneTypes(updatedSelectedItems);
                      }
                    }}>
                    <ClearIcon fontSize={'small'} color={'secondary'} />
                  </IconButton>
                </Grid>
              )}
              {item.type === PhoneTypes.MOBILE &&
                item.mobileProvider === 'Other' && (
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name={`${prefix}.phone.${index}.mobileProviderOther`}
                      label='Mobile Provider'
                      placeholder='Enter the name of your mobile provider'
                      fullWidth
                    />
                  </Grid>
                )}
            </Grid>
          ))
        }
      />
      {contactInfo.phone.length < 3 && (
        <Grid item xs={12}>
          <Button
            className={classes.addPhoneButton}
            color={'primary'}
            size={'small'}
            onClick={() =>
              setFieldValue(`${prefix}.phone`, [
                ...contactInfo.phone,
                {
                  type: getAvailPhoneTypes()[0],
                  number: '',
                  mobileProvider: '',
                  mobileProviderOther: '',
                },
              ])
            }>
            <AddCircleOutlineIcon fontSize={'small'} /> &nbsp; Add Phone Number
          </Button>
        </Grid>
      )}
      {contactInfo.phone.length > 1 && (
        <Grid item xs={12} sm={6}>
          <FormikSelect
            label='Preferred Contact Method'
            name={`${prefix}.primaryPhoneType`}>
            {selectedPhoneTypes.map((val, index) => (
              <MenuItem key={`pcm-${index}`} value={val}>
                {val}
              </MenuItem>
            ))}
          </FormikSelect>
        </Grid>
      )}
    </Grid>
  );
};

export default PhoneComponent;
