// React
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
// Material UI
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Container,
  Box,
  Grid,
  Button,
  Fab,
  Typography,
  Stepper,
  Step,
  LinearProgress,
  StepButton,
  StepLabel,
  StepConnector,
  Drawer,
  IconButton,
} from '@material-ui/core';
// Common
import { Caused } from './Common/Caused';
import { Reporter } from './Common/Reporter';
import PolicyComponent from '../PolicyComponent';
import PolicyInfoComponent from '../PolicyInfoComponent';
// Recreational
import { DamagedRecreational } from './Recreational/Damaged';
import { LastSteptRecreational } from './Recreational/LastStept';
import { LossLocationRecreational } from './Recreational/LossLocation';
import { LossLocationNotPolicy } from './Common/LossLocationNotPolicy';
// Residential
import { DamagedResidential } from './Residential/Damaged';
import { LastSteptResidential } from './Residential/LastStept';
import { LossLocationResidential } from './Residential/LossLocation';
// Renter
import { Damaged } from './Renter/Damaged';
import { LastStept } from './Renter/LastStept';
// Actions
import { summaryActions } from '../../../actions/summary';
import { updateClaim } from '../../../actions/claimActions';
import { sendEventLogRequest } from '../../../actions/eventActions';
import {
  updateCompletedSteps,
  updateStepContentIndex,
} from '../../../actions/stepperActions';
import {
  NotValidatedRecreationalSteps,
  NotValidatedResidentialSteps,
  NotValidatedRenterSteps,
  PolicyTypes,
  RecreationalSteps,
  StepTypes,
  ResidentialSteps,
  RenterSteps,
} from '../../../actions/types';
import { AMIGHelp } from './Utilities/AMIGHelp';
import { DamagedVessel } from './Recreational/DamagedVessel';

const { updateLastStept } = summaryActions;

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  container: {
    maxWidth: '1095px',
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  panel: {
    background: '#ededed',
  },
  fab: {
    float: 'right',
    bottom: '1.5rem',
  },
  fabPolicy: {
    float: 'left',
    bottom: '1.5rem',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SimpleConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: '-50%',
    right: '50%',
  },
  active: {
    '& $line': {
      borderColor: '#0094ca',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#0094ca',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 10,
    borderRadius: 1,
  },
})(StepConnector);

const simpleStepIconStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '10px',
    width: '100%',
    marginTop: '10px',
    zIndex: '9',
    background: '#eaeaf0',
  },
  active: {
    background: '#0094ca',
  },
  completed: {
    background: '#0094ca',
  },
  circle: {
    display: 'none',
  },
});

function simpleStepIcon(props) {
  const classes = simpleStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {completed ? (
        <div className={classes.circle} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const PolicyPage = ({
  claim,
  policy,
  stepper,
  updateCompletedSteps,
  updateStepContentIndex,
  updateLastStept,
  updateClaim,
}) => {
  const { deviceType } = getDeviceTypeInfo();
  const { logId, type } = claim;

  useEffect(() => {
    var event = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Start Claim'}",
    };

    sendEventLogRequest(event);
    async function updatePolicyType() {
      const newClaim = { ...claim };
      !newClaim.type && (newClaim.type = localStorage.getItem('policyType'));
      if (localStorage.getItem('continueClaimId')) {
        newClaim.logId = localStorage.getItem('continueClaimId');
        localStorage.removeItem('continueClaimId');
      }
      await updateClaim({ ...newClaim });
    }
    updatePolicyType();
  }, []);

  const { completed, stepContentIndex } = stepper;
  const activeStep = Object.keys(completed).length;
  const [policyDrawerState, setPolicyDrawerState] = useState(false);

  const getResponsiveStepper = (deviceType) => {
    let stepperContent = null;
    if (deviceType === 'Mobile') {
      return (
        <>
          <Container align={'center'}>
            <Box p={2}>
              <LinearProgress
                style={{ width: '80%' }}
                variant='determinate'
                value={((activeStep + 1) / getSteps().length) * 100}
              />
            </Box>
            <Typography color={'primary'}>
              {stepContentIndex + 1} / {getSteps().length}
            </Typography>
          </Container>
        </>
      );
    } else {
      return (
        <>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            //nonLinear
            style={{
              width: '100%',
              margin: '0 auto',
              padding: '0 0 4em',
            }}
            connector={<SimpleConnector />}>
            {steps.map((label, index) => (
              <Step
                key={`step-${index}`}
                style={{
                  padding: '0',
                }}>
                <StepButton
                  key={`step-bStepButtonndex}`}
                  onClick={handleStep(index)}
                  completed={completed[index]}>
                  <StepLabel
                    key={`step-label-${index}`}
                    active={true}
                    StepIconComponent={simpleStepIcon}>
                    <span style={{ fontSize: '0.75rem' }}>{label}</span>
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </>
      );
    }
    return null;
  };

  const togglePolicyDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setPolicyDrawerState(open);
  };

  function getSteps() {
    switch (claim.type) {
      case 'recreational':
        //Task#1475551 - Prod Support - Digital FNOL 2.0 - REC header should say “Choose vehicle” instead of "verify loss location"
        return [
          '1. Enter policy number',
          '2. Choose vehicle',
          '3. Loss location',
          '4. Select claim type',
          '5. Who’s reporting the claim?',
          '6. Finalize and submit',
        ];
      case 'renters':
        return [
          '1. Enter policy number',
          '2. Select claim type',
          '3. Enter claim details',
          '4. Who’s reporting the claim?',
          '5. Finalize and submit',
        ];
      case 'notvalidatedrecreational':
        //Task#1475551 - Prod Support - Digital FNOL 2.0 - REC header should say “Choose vehicle” instead of "verify loss location"
        return [
          '1. Enter policy number',
          '2. Choose vehicle',
          '3. Select claim type',
          '4. Who’s reporting the claim?',
          '5. Finalize and submit',
        ];
      default:
        return [
          '1. Enter policy number',
          '2. Verify loss location',
          '3. Select claim type',
          '4. Enter claim details',
          '5. Who’s reporting the claim?',
          '6. Finalize and submit',
        ];
    }
  }

  function getStepContent(stepIndex) {
    if (stepIndex === 0) {
      return (
        <PolicyComponent
          activeStep={activeStep}
          handleNext={handleNext}
          handleBack={handleBack}
        />
      );
    } else if (type === PolicyTypes.RECREATIONAL.toLowerCase()) {
      switch (stepIndex) {
        case RecreationalSteps.LOSS_LOCATION:
          return (
            <LossLocationRecreational
              stepType={StepTypes.LOSS_LOCATION_REC}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case RecreationalSteps.LOSS_LOCATION_NOT_POLICY:
          return (
            <LossLocationNotPolicy
              stepType={StepTypes.LOSS_LOCATION_NOT_POLICY}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case RecreationalSteps.DAMAGED:
          if (policy.isVisselType) {
            return (
              <DamagedVessel
                stepType={StepTypes.DAMAGED_REC}
                activeStep={activeStep}
                handleNext={handleNext}
                handleBack={handleBack}
              />
            );
          }

          return (
            <DamagedRecreational
              stepType={StepTypes.DAMAGED_REC}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case RecreationalSteps.REPORTER:
          return (
            <Reporter
              stepType={StepTypes.REPORTER}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case RecreationalSteps.SUMMARY:
          return (
            <LastSteptRecreational
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        default:
          return 'Unknown stepIndex';
      }
    } else if (type === PolicyTypes.RESIDENTIAL.toLowerCase()) {
      switch (stepIndex) {
        case ResidentialSteps.LOSS_LOCATION:
          return (
            <LossLocationResidential
              stepType={StepTypes.LOSS_LOCATION_RES}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case ResidentialSteps.DAMAGED:
          return (
            <DamagedResidential
              stepType={StepTypes.DAMAGED_RES}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case ResidentialSteps.CAUSED:
          return (
            <Caused
              stepType={StepTypes.CAUSED}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case ResidentialSteps.REPORTER:
          return (
            <Reporter
              stepType={StepTypes.REPORTER}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case ResidentialSteps.SUMMARY:
          return (
            <LastSteptResidential
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        default:
          return 'Unknown stepIndex';
      }
    } else if (type === PolicyTypes.RENTERS.toLowerCase()) {
      switch (stepIndex) {
        case RenterSteps.DAMAGED:
          return (
            <Damaged
              stepType={StepTypes.DAMAGED_REN}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case RenterSteps.CAUSED:
          return (
            <Caused
              stepType={StepTypes.CAUSED}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case RenterSteps.REPORTER:
          return (
            <Reporter
              stepType={StepTypes.REPORTER}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case RenterSteps.SUMMARY:
          return (
            <LastStept
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        default:
          return 'Unknown stepIndex';
      }
    } else if (type === PolicyTypes.NOT_AUTHENTICATED_RENTERS.toLowerCase()) {
      switch (stepIndex) {
        case NotValidatedRenterSteps.LOSS_LOCATION_NOT_POLICY:
          return (
            <LossLocationNotPolicy
              stepType={StepTypes.LOSS_LOCATION_NOT_POLICY}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case NotValidatedRenterSteps.DAMAGED:
          return (
            <Damaged
              stepType={StepTypes.DAMAGED_REN}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case NotValidatedRenterSteps.CAUSED:
          return (
            <Caused
              stepType={StepTypes.CAUSED}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case NotValidatedRenterSteps.REPORTER:
          return (
            <Reporter
              stepType={StepTypes.REPORTER}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case NotValidatedRenterSteps.SUMMARY:
          return (
            <LastStept
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        default:
          return 'Unknown stepIndex';
      }
    } else if (
      type === PolicyTypes.NOT_AUTHENTICATED_RECREATIONAL.toLowerCase()
    ) {
      switch (stepIndex) {
        case NotValidatedRecreationalSteps.LOSS_LOCATION_NOT_POLICY:
          return (
            <LossLocationNotPolicy
              stepType={StepTypes.LOSS_LOCATION_NOT_POLICY}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case NotValidatedRecreationalSteps.DAMAGED:
          return (
            <DamagedRecreational
              stepType={StepTypes.DAMAGED_REC}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case NotValidatedRecreationalSteps.REPORTER:
          return (
            <Reporter
              stepType={StepTypes.REPORTER}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case NotValidatedRecreationalSteps.SUMMARY:
          return (
            <LastSteptRecreational
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        default:
          return 'Unknown stepIndex';
      }
    } else if (
      type === PolicyTypes.NOT_AUTHENTICATED_RESIDENTIAL.toLowerCase()
    ) {
      switch (stepIndex) {
        case NotValidatedResidentialSteps.LOSS_LOCATION_NOT_POLICY:
          return (
            <LossLocationNotPolicy
              stepType={StepTypes.LOSS_LOCATION_NOT_POLICY}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case NotValidatedResidentialSteps.DAMAGED:
          return (
            <DamagedResidential
              stepType={StepTypes.DAMAGED_RES}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case NotValidatedResidentialSteps.CAUSED:
          return (
            <Caused
              stepType={StepTypes.CAUSED}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case NotValidatedResidentialSteps.REPORTER:
          return (
            <Reporter
              stepType={StepTypes.REPORTER}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        case NotValidatedResidentialSteps.SUMMARY:
          return (
            <LastSteptResidential
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        default:
          return 'Unknown stepIndex';
      }
    } else {
      return 'Unknown stepIndex';
    }
  }

  const steps = getSteps();

  const isStepComplete = (step) => {
    const res = completed.hasOwnProperty(step);
    return res;
  };

  const handleNext = () => {
    if (!isStepComplete(stepContentIndex)) {
      const newCompleted = completed;
      newCompleted[stepContentIndex] = true;
      updateCompletedSteps(newCompleted);
    }
    if (stepContentIndex + 1 < getSteps().length) {
      updateStepContentIndex(stepContentIndex + 1);
    }
  };

  const handleStep = (step) => () => {
    updateLastStept(false);
    updateStepContentIndex(step);
  };

  const handleBack = () => {
    if (stepContentIndex - 1 > 0) {
      updateStepContentIndex(stepContentIndex - 1);
    } else {
      updateStepContentIndex(0);
    }
  };

  const handleReset = () => {
    updateCompletedSteps([]);
  };

  const classes = useStyles();

  return (
    <Box
      p={4}
      bgcolor='text.disabled'
      style={{ background: '#ededed', padding: '1rem 0' }}>
      <Container className={classes.container}>
        <Box p={4} boxShadow={3} borderRadius={5} bgcolor='background.paper'>
          <Grid container justify='center'>
            <Grid item xs={12}>
              {claim.showStepper && getResponsiveStepper(deviceType)}
            </Grid>
            <Grid item xs={12}>
              <div>
                {activeStep === steps.length ? (
                  <>
                    <Typography className={classes.instructions}>
                      All steps completed
                    </Typography>
                    <Button onClick={handleReset}>Reset</Button>
                  </>
                ) : (
                  getStepContent(stepContentIndex)
                )}
              </div>
            </Grid>
          </Grid>
          <AMIGHelp classFab={classes.fab} />
          {claim.namedInsured.firstName && (
            <>
              <Box style={{ padding: 0 }}>
                <Fab
                  className={classes.fabPolicy}
                  onClick={togglePolicyDrawer(true)}
                  color='primary'
                  aria-label='My Policy'
                  size='small'
                  variant='extended'>
                  <AssignmentIcon />
                  <Box>My Policy</Box>
                </Fab>
              </Box>
              <Drawer
                anchor={'top'}
                open={policyDrawerState}
                onClose={togglePolicyDrawer(false)}
                onOpen={togglePolicyDrawer(true)}>
                <Box className={classes.panel} height={'100%'} p={2} pb={5}>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      <Box align={'left'}>
                        <Typography>Policy Information:</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs>
                      <Box align={'right'}>
                        <IconButton onClick={togglePolicyDrawer(false)}>
                          <CloseIcon fontSize={'small'} />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                  <PolicyInfoComponent policy={policy} />
                </Box>
              </Drawer>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
  policy: state.policy,
  stepper: state.stepper,
});

PolicyPage.propTypes = {
  claim: PropTypes.object.isRequired,
  stepper: PropTypes.object.isRequired,
  updateCompletedSteps: PropTypes.func.isRequired,
  updateStepContentIndex: PropTypes.func.isRequired,
  updateClaim: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  updateCompletedSteps,
  updateStepContentIndex,
  updateClaim,
  updateLastStept,
})(PolicyPage);
