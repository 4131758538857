import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import { sendEventLogRequest } from '../../actions/eventActions';
import { updateClaim } from '../../actions/claimActions';
import { getContainerOffset } from '../../actions/layoutActions';
import PropTypes from 'prop-types';
import OffsetGrid from '../layout/OffsetGrid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import Paper from '@material-ui/core/Paper';

import {
  weatherDamage,
  waterDamage,
  fireDamage,
  theftVandalism,
  other,
  ClaimTypes,
} from '../../actions/types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    'position': 'relative',
    'width': '100%',
    'height': 150,
    [theme.breakpoints.down('xs')]: {
      width: '100% !important', // Overrides inline-style
      height: 150,
    },
    '&:hover, &$focusVisible': {
      'zIndex': 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.0,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    // position: 'relative',
    texgAlign: 'center',
    marginTop: '12rem',
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ClaimTypeComponent = ({
  claim,
  activeStep,
  handleBack,
  handleNext,
  updateClaim,
}) => {
  const { logId } = claim;
  const { deviceType } = getDeviceTypeInfo();

  const incidentTypes = [
    {
      id: 'Theft-Vandalism',
      type: ClaimTypes.THEFT_VANDALISM,
      title: 'Theft/Vandalism',
      imgSrc: theftVandalism,
    },
    {
      id: 'Weather/Property Damage',
      type: ClaimTypes.PROPERTY_DAMAGE,
      title: 'Weather/Property Damage',
      imgSrc: weatherDamage,
    },
    {
      id: 'Fire Damage',
      type: ClaimTypes.FIRE_DAMAGE,
      title: 'Fire/Smoke Damage',
      imgSrc: fireDamage,
    },
    {
      id: 'Water Damage',
      type: ClaimTypes.WATER_DAMAGE,
      title: 'Water Damage',
      imgSrc: waterDamage,
    },
    {
      id: 'Other',
      type: ClaimTypes.OTHER,
      title: 'Other',
      imgSrc: other,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    var event = {
      logId: logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Incident Type Selection'}",
    };

    sendEventLogRequest(event);
  }, []);

  const classes = useStyles();

  const createNewIncident = (id) => {
    const newClaim = { ...claim };
    if (id === ClaimTypes.THEFT_VANDALISM) {
      newClaim.lossDetails.incidents = [
        {
          type: ClaimTypes.THEFT_VANDALISM,
          incidentDesc: '',
          numOfClaimItems: '',
          totalLoss: null,
          source: null,
        },
      ];
    } else if (id === ClaimTypes.PROPERTY_DAMAGE) {
      newClaim.lossDetails.incidents = [
        {
          type: ClaimTypes.PROPERTY_DAMAGE,
          incidentDesc: '',
          source: '',
          isRepaired: null,
        },
      ];
    } else if (id === ClaimTypes.WATER_DAMAGE) {
      newClaim.lossDetails.incidents = [
        {
          type: ClaimTypes.WATER_DAMAGE,
          isMitigated: null,
          mitigationDesc: '',
        },
      ];
    } else if (id === ClaimTypes.FIRE_DAMAGE) {
      newClaim.lossDetails.incidents = [
        {
          type: ClaimTypes.FIRE_DAMAGE,
          q1: {
            selected: null,
            fireDept: '',
            fireAddress: '',
            policeDept: '',
            policeAddress: '',
          },
          q2: {
            selected: null,
            name: '',
            severity: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: '',
          },
          q3: {
            selected: null,
          },
          q4: {
            selected: null,
          },
          q5: {
            selected: null
          }
        },
      ];
    } else if (id === ClaimTypes.OTHER) {
      newClaim.lossDetails.incidents = [
        {
          type: ClaimTypes.OTHER,
          incidentDesc: '',
          injuredPerson: {
            isHospitalized: null,
            isPolicyholder: null,
            name: '',
            phone: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postalCode: '',
          }
        },
      ];
    }
    updateClaim({ ...newClaim });
  };

  const [showIncidentWarningDialog, setShowIncidentWarningDialog] = useState({
    id: null,
    flag: false,
  });

  const handleCloseIncidentWarningDialog = () => {
    setShowIncidentWarningDialog({ id: null, flag: false });
  };

  const handleIncidentWarningDialogSubmit = () => {
    createNewIncident(showIncidentWarningDialog['id']);
    setShowIncidentWarningDialog({ id: null, flag: false });
    handleNext();
  };

  const handleClaimTypeClick = (id) => {
    if (claim.lossDetails.incidents.length > 0) {
      if (claim.lossDetails.incidents[0].type === id) {
        handleNext();
      } else {
        // set an alert indicating you will overwrite the existing incident
        setShowIncidentWarningDialog({ id: id, flag: true });
      }
    } else {
      createNewIncident(id);
      handleNext();
    }
  };

  return (
    <>
      <OffsetGrid offset={`${getContainerOffset(deviceType)}`}>
        <Typography variant='h1' align='center' color='primary' gutterBottom>
          What kind of loss occurred?
        </Typography>
        <Typography gutterBottom variant='subtitle1' align='center'>
          Select an incident type below to enter the details of the loss.
        </Typography>
        <Box pt={3}>
          <Grid container spacing={3} justify='center'>
            {incidentTypes.map((incident, index) => (
              <Grid item key={index} xs={12} sm={4}>
                <Grid container item spacing={1} direction='column'>
                  <Grid item>
                    <ButtonBase
                      focusRipple
                      key={incident.id}
                      className={classes.image}
                      focusVisibleClassName={classes.focusVisible}
                      onClick={() => handleClaimTypeClick(incident.type)}>
                      <span
                        className={classes.imageSrc}
                        style={{
                          backgroundImage: `url(${incident.imgSrc})`,
                        }}
                      />
                      <span className={classes.imageBackdrop} />
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12} align='center'>
                    <Typography
                      component='span'
                      variant='subtitle1'
                      color='inherit'
                      className={classes.imageTitle}>
                      {incident.title}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
      </OffsetGrid>

      <Box p={5} style={{ textAlign: 'center' }}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.backButton}>
          Back
        </Button>
      </Box>
      <Dialog
        maxWidth='xs'
        open={showIncidentWarningDialog['flag']}
        onClose={handleCloseIncidentWarningDialog}>
        <DialogTitle id='form-dialog-title'>
          Incident Overwrite Warning
        </DialogTitle>
        <DialogContent>
          <Box pb={3}>
            <Grid container spacing={2} direction='column'>
              <Grid item xs>
                <Box pb={3}>
                  <Typography gutterBottom>
                    Are you sure you wish to change the incident type? If you
                    choose to continue, you will delete the existing incident
                    you've already started.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Box p={1}>
          <DialogActions>
            <Button onClick={handleCloseIncidentWarningDialog}>CANCEL</Button>
            <Button
              onClick={handleIncidentWarningDialogSubmit}
              variant='contained'
              color='primary'>
              Continue
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
});

ClaimTypeComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { updateClaim })(ClaimTypeComponent);
