import { StepTypes } from '../actions/types';
import { reporter } from '../components/pages/claim/ScreenObjects/Common/reporter';
import { lossLocationNotPolicy } from '../components/pages/claim/ScreenObjects/Common/lossLocationNotPolicy';
import { caused } from '../components/pages/claim/ScreenObjects/Common/caused';
import { injured } from '../components/pages/claim/ScreenObjects/Common/injured';
import { damage as damagedRen } from '../components/pages/claim/ScreenObjects/Renter/damaged';
import { damaged as damagedRec } from '../components/pages/claim/ScreenObjects/Recreational/damaged';
import { damaged as damagedRes } from '../components/pages/claim/ScreenObjects/Residential/damaged';
import { lossLocation as locationRec } from '../components/pages/claim/ScreenObjects/Recreational/lossLocation';
import { lossLocation as locationRes } from '../components/pages/claim/ScreenObjects/Residential/lossLocation';
import { getInicialValues } from '../components/pages/claim/Utilities/AMIGHandleForm';

const initialState = {
  reporter: getInicialValues(reporter),
  notPolicy: getInicialValues(lossLocationNotPolicy),
  caused: getInicialValues(caused),
  injured: getInicialValues(injured),
  damagedRen: getInicialValues(damagedRen),
  damagedRec: getInicialValues(damagedRec),
  damagedRes: getInicialValues(damagedRes),
  locationRec: getInicialValues(locationRec),
  locationRes: getInicialValues(locationRes),
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case StepTypes.REPORTER: 
      return {...state, reporter: payload};
    case StepTypes.LOSS_LOCATION_NOT_POLICY: 
      return {...state, notPolicy: payload};
    case StepTypes.CAUSED: 
      return {...state, caused: payload};
    case StepTypes.INJURED: 
      return {...state, injured: payload};
    case StepTypes.DAMAGED_REN: 
      return {...state, damagedRen: payload};
    case StepTypes.DAMAGED_REC: 
      return {...state, damagedRec: payload};
    case StepTypes.DAMAGED_RES: 
      return {...state, damagedRes: payload};
    case StepTypes.LOSS_LOCATION_REC: 
      return {...state, locationRec: payload};
    case StepTypes.LOSS_LOCATION_RES: 
      return {...state, locationRes: payload};
    default:
      return state;
  }
};
