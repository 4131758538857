import { IS_LAST_STEPT, DISABLED, SHOW_TITLE } from '../actions/types';

const initialState = {
    isLastStept: false,
    showTitle: true,
    disable: [],
    isStepDisabled: (disableList, stepType) => disableList.some(step => step.name === stepType && step.value),
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case IS_LAST_STEPT:
      return {...state, isLastStept: payload};
    case DISABLED: 
      const step = state.disable;
      const stepIndex = step.findIndex( step => step.name === payload.name); 
      const newEnabled =  stepIndex >= 0 ? state.disable.map(step => step.name === payload.name ? payload : step): [...state.disable, payload];
      
      return {...state, disable: newEnabled};
    case SHOW_TITLE:
      return {...state, showTitle: payload};
    default:
      return state;
  }
};
