import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import { HttpMethods } from '../../actions/types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import store from '../../store';
import { NETWORK_CALL_SUCCEEDED } from '../../actions/types';
import { showLoading } from '../../actions/loading';

const ErrorDialog = ({ networkMonitor, stepper, showLoading, ...props }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const { stepContentIndex } = stepper;
    const { failedNetworkCalls } = networkMonitor;
    if (
      (stepContentIndex < 5 && failedNetworkCalls > 2) ||
      (stepContentIndex === 5 && failedNetworkCalls > 0)
    ) {
      setOpen(true);
    }
  }, [networkMonitor, stepper]);

  const handleTryAgain = async () => {
    try {

      const {
        method,
        url,
        body,
        options,
      } = networkMonitor.lastAttemptedRequest;
      setOpen(false);
      showLoading(true);

      switch (method) {
        case HttpMethods.GET:
          await axios.get(url);
          break;
        case HttpMethods.DELETE:
          await axios.delete(url);
          break;
        case HttpMethods.POST:
          await axios.post(url, body, options);
          break;
        case HttpMethods.PUT:
          await axios.put(url, body, options);
          break;
        default:
          break;
      }

      store.dispatch({
        type: NETWORK_CALL_SUCCEEDED,
      });
    } catch (error) {
      setOpen(true);
    } finally {
      showLoading(false);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleTryAgain}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Sorry ...'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            We are having trouble saving your claim. Please try again or come
            back a little later to pick up where you left off.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTryAgain} color='primary' autoFocus>
            Try Again
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  networkMonitor: state.networkMonitor,
  stepper: state.stepper,
});

ErrorDialog.propTypes = {
  networkMonitor: PropTypes.object.isRequired,
  stepper: PropTypes.object.isRequired,
  showLoading: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { showLoading })(ErrorDialog);
