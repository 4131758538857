import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { FormHelperText, Grid } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

export const AMIGTogglePosNeg = ({ 
  name, 
  value, 
  positiveLabel, 
  negativeLabel, 
  isDisable,
  handleChange, 
  helperText, 
  ...rest 
}) => {

  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [name, value]);

  const { palette } = useTheme();

  const useStyles = makeStyles((theme) => ({
    root: {
      padding: theme.spacing(2, 0, 1, 0),
    },
    toggleButon:{
      width: 'auto', 
      display: 'inline-flex',
      paddingLeft: '36px',
    },
    error: {
      color: palette.error.main,
    }
  }));

  const handleButtonChange = (event, selected) => {

    handleChange(event, selected);
    setSelected(selected);
  };

  const classes = useStyles();

  return (
    <Grid  container className={classes.toggleButon} {...rest} >
      <Grid  rows={1} >
        <ToggleButtonGroup name={name} value={selected} exclusive onChange={handleButtonChange}  >
          <ToggleButton size={'small'} key={1} value={true} disabled={isDisable} >
            {positiveLabel}
          </ToggleButton>,
          <ToggleButton size={'small'} key={2} value={false} disabled={isDisable} >
            {negativeLabel}
          </ToggleButton>
        </ToggleButtonGroup>
        <FormHelperText className={classes.error} >
          {helperText}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};

AMIGTogglePosNeg.defaultProps = {
  name: '',
  value: false,
  positiveLabel: 'Yes',
  negativeLabel: 'No',
  isDisable: false,
  handleChange: (event, value) => {},
  helperText: '',
};
