import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { theftVandalismSchema } from '../../validation/claimsSchemas';
import { TextField, Select } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import { sendEventLogRequest } from '../../actions/eventActions';
import { updateClaim } from '../../actions/claimActions';
import { getContainerOffset } from '../../actions/layoutActions';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ClaimTypes } from '../../actions/types';
import OffsetGrid from '../layout/OffsetGrid';
import CurrencyField from '../common/CurrencyField';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import ErrorFocus from '../common/ErrorFocus';
import FormikSelect from '../common/FormikSelect';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const TheftVandalismComponent = ({
  claim,
  activeStep,
  handleNext,
  handleBack,
  updateClaim,
}) => {
  const select = ['1 - 5', '6 - 15', '16 or More'];
  const { logId } = claim;
  const { deviceType } = getDeviceTypeInfo();

  const initialValues = {
    type: ClaimTypes.THEFT_VANDALISM,
    incidentDesc: claim.lossDetails.incidents[0].incidentDesc,
    numOfClaimItems: claim.lossDetails.incidents[0].numOfClaimItems,
    totalLoss: claim.lossDetails.incidents[0].totalLoss,
    source: claim.lossDetails.incidents[0].source,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    var event = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Theft Vandalism Incident'}",
    };

    sendEventLogRequest(event);
  }, []);

  const classes = useStyles();

  return (
    <>
      <OffsetGrid offset={`${getContainerOffset(deviceType)}`}>
        <Typography variant='h1' align='center' color='primary' gutterBottom>
          Tell us what happened
        </Typography>
        <Typography gutterBottom variant='subtitle1' align='center'>
          Describe what was stolen, how your items were damaged, or the extent
          of the vandalism. How do you think it happened?
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={theftVandalismSchema}
          onSubmit={(values) => {
            if (values) {
              const newClaim = { ...claim };
              newClaim.lossDetails.incidents[0] = values;
              updateClaim({ ...newClaim });
            }
            handleNext();
          }}>
          {({ errors, touched, values }) => (
            <>
              <Form id='theft-damage-form'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box my={2}>
                      <Field
                        className={classes.textField}
                        name='incidentDesc'
                        component={TextField}
                        placeholder='Please provide a description of the what happened'
                        variant='outlined'
                        multiline
                        rows={4}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <FormikSelect name='source' label='Type'>
                          <MenuItem value='theft'>Theft</MenuItem>
                          <MenuItem value='vandalism'>Vandalism</MenuItem>
                        </FormikSelect>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      error={
                        errors &&
                        errors.numOfClaimItems &&
                        touched &&
                        touched.numOfClaimItems
                      }>
                      <InputLabel htmlFor='item-count-select'>
                        Number of items to claim
                      </InputLabel>
                      <Field
                        component={Select}
                        name='numOfClaimItems'
                        inputProps={{
                          id: 'item-count-select',
                        }}>
                        <MenuItem value={'none'}>Choose...</MenuItem>
                        {select.map((value, i) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Field>
                      <ErrorMessage name='numOfClaimItems'>
                        {(msg) => (
                          <FormHelperText style={{ color: '#f44336' }}>
                            {msg}
                          </FormHelperText>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CurrencyField
                      fullWidth
                      name='totalLoss'
                      label='Total estimated item(s) value'
                    />
                  </Grid>
                </Grid>
                <ErrorFocus />
              </Form>
            </>
          )}
        </Formik>
      </OffsetGrid>

      <Box p={5} style={{ textAlign: 'center' }}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.backButton}>
          Back
        </Button>
        <Button
          type='submit'
          form='theft-damage-form'
          variant='contained'
          color='primary'>
          Next
        </Button>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
});

TheftVandalismComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { updateClaim })(
  TheftVandalismComponent
);
