
import { Layout } from './types';

export const getContainerOffset = (deviceType) => {
    let padding = null;
    deviceType === 'Mobile' ? (padding = Layout.MOBILE_GRID_OFFSET) : (padding = Layout.DESKTOP_GRID_OFFSET);
    return padding;
  };

export const getContainerPadding = (deviceType) => {
    let padding = null;
    deviceType === 'Mobile' ? (padding = Layout.MOBILE_PADDING) : (padding = Layout.DESKTOP_PADDING);
    return padding;
  };

  export const getInteriorContainerPadding = (deviceType) => {
    let padding = null;
    deviceType === 'Mobile' ? (padding = Layout.MOBILE_INTERIOR_PADDING) : (padding = Layout.DESKTOP_INTERIOR_PADDING);
    return padding;
  };