import { combineReducers } from 'redux';
import claimReducer from './claimReducer';
import ipReducer from './ipReducer';
import policyReducer from './policyReducer';
import loading from './loading';
import alert from './alert';
import stepper from './stepper';
import networkMonitor from './networkMonitor';
import newClaim from './newClaim';
import summary from './summary';

export default combineReducers({
    loading,
    stepper,
    alerts: alert,
    networkMonitor,
    policy: policyReducer,
    claim: claimReducer,
    newClaim,
    summary,
    ip: ipReducer,
});