import React, { useEffect } from 'react';
import { useField, ErrorMessage, setIn } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const FormikAutocomplete = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);
  const { label } = props;


  return (
    <>
      <Autocomplete
        {...field}
        {...props}
        onChange={(event, newValue) => {
          helpers.setValue(newValue);
        }}
        blurOnSelect='touch'
        renderInput={(params) => (
          <>
          <TextField
            error={meta.touched && meta.error != undefined}
            {...params}
            {...props}
            label={label}
            inputProps={{
              ...params.inputProps
            }}
          />
          </>
        )}
      />
      {meta.touched && meta.error ? (
        <ErrorMessage name={field.name}>
          {(msg) => (
            <FormHelperText style={{ color: '#f44336' }}>{msg}</FormHelperText>
          )}
        </ErrorMessage>
      ) : null}
    </>
  );
};

export default FormikAutocomplete;
