import {
  EMPTY_VALUE,
  PLACEHOLDER_SELECT_VALUE,
  phoneRegExp,
  driving,
  DRIVING_OPTIONS,
  FIELD_TYPE,
} from '../../../../../actions/types';
import * as Yup from 'yup';

export const driver = [
  {
    name: 'driving',
    initialValue: PLACEHOLDER_SELECT_VALUE,
    placeholder: 'Please pick the best that applies',
    type: FIELD_TYPE.SELECT,
    options: driving,
    isOneColumn: true,
    keyPathToSaveValue:
      'claim.eventDetails.driverVehicleDetails.vehicleLossParty',
    yup: Yup.string().when('driverRule', {
      is: true,
      then: (schema) =>
        schema.test(
          'driving',
          'Provide a value',
          (value) => value !== PLACEHOLDER_SELECT_VALUE
        ),
    }),
  },
  {
    name: 'firstName',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'First Name',
    type: FIELD_TYPE.TEXT,
    col: 4,
    toHide: true,
    dependencyFieldToShow: {
      name: 'driving',
      value: DRIVING_OPTIONS.DRIVINGOTHER,
    },
    keyPathToSaveValue:
      'claim.eventDetails.driverVehicleDetails.driver.firstName',
    yup: Yup.string().when('driving', {
      is: (value) => value === DRIVING_OPTIONS.DRIVINGOTHER,
      then: (schema) =>
        schema
          .required('Provide a first name')
          .min(2, 'Too Short!')
          .max(50, 'Too Long!'),
    }),
  },
  {
    name: 'lastName',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Last Name',
    type: FIELD_TYPE.TEXT,
    col: 4,
    toHide: true,
    dependencyFieldToShow: {
      name: 'driving',
      value: DRIVING_OPTIONS.DRIVINGOTHER,
    },
    keyPathToSaveValue:
      'claim.eventDetails.driverVehicleDetails.driver.lastName',
    yup: Yup.string().when('driving', {
      is: (value) => value === DRIVING_OPTIONS.DRIVINGOTHER,
      then: (schema) =>
        schema
          .required('Provide a last name')
          .min(2, 'Too Short!')
          .max(50, 'Too Long!'),
    }),
  },
  {
    name: 'phoneNumber',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Phone Number',
    type: FIELD_TYPE.TEL,
    col: 4,
    toHide: true,
    inputProps: { maxLength: 12 },
    dependencyFieldToShow: {
      name: 'driving',
      value: DRIVING_OPTIONS.DRIVINGOTHER,
    },
    keyPathToSaveValue:
      'claim.eventDetails.driverVehicleDetails.driver.cellNumber',
    yup: Yup.string().when('driving', {
      is: (value) => value === DRIVING_OPTIONS.DRIVINGOTHER,
      then: (schema) =>
        schema
          .required('Provide a phone number')
          .matches(
            phoneRegExp,
            'Phone number is not valid, Format: 123-456-7890.'
          ),
    }),
  },
  {
    name: 'driverRule',
    initialValue: false,
    type: FIELD_TYPE.EMPTY,
  },
];
