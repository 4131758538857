import { SHOW_LOADING, HIDE_LOADING } from '../actions/types';

const initialState = {
  isLoading: false,
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SHOW_LOADING:
      return {...state, isLoading: true };
    case HIDE_LOADING:
      return {...state, isLoading: false };
    default:
      return state;
  }
};
