import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { TextField } from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import { policyLossTsDialogSchema } from '../../validation/claimsSchemas';
import LinearProgress from '@material-ui/core/LinearProgress';
import { globalClasses } from '../ui/customClasses';

const PolicySearchDialog = ({
  showDialog,
  handleClose,
  handleOnSubmit,
  showLoading = false,
  showError = false,
  title,
  contentText,
  errorText,
}) => {
  const globalCSS = globalClasses();

  return (
    <Dialog maxWidth='xs' open={showDialog} onClose={handleClose}>
      <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
      {showLoading && <LinearProgress color='primary' />}
      <Formik
        initialValues={{ firstName: '', lastName: '', zipcode: '' }}
        validationSchema={policyLossTsDialogSchema}
        onSubmit={handleOnSubmit}>
        <Form>
          <DialogContent>
            <DialogContentText>{contentText}</DialogContentText>
            <Box pb={3}>
              <Grid container spacing={2} direction='column'>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name='firstName'
                    placeholder='First Name'
                    fullWidth
                    color="secondary"
                  />
                </Grid>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name='lastName'
                    placeholder='Last Name'
                    fullWidth
                    color="secondary"
                  />
                </Grid>
                <Grid item xs>
                  <Field
                    component={TextField}
                    name='zipcode'
                    placeholder='Zip Code'
                    fullWidth
                    color="secondary"
                  />
                </Grid>
                {showError && (
                  <Grid item xs>
                    <Typography align='center' color='error'>
                      {errorText}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </DialogContent>
          <Box p={1}>
            <DialogActions>
              <Button className={globalCSS.AMIGBigButton} onClick={handleClose}>Cancel</Button>
              <Button className={globalCSS.AMIGBigButton} type='submit' variant='contained' color='primary'>
                Submit
              </Button>
            </DialogActions>
          </Box>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default PolicySearchDialog;
