import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { SubFormType } from '../Utilities/SubFormType';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { AMIGForm } from '../Utilities/AMIGForm';
import { injured } from '../ScreenObjects/Recreational/injured';
import { setReduxValues } from '../Utilities/AMIGHandleForm';
import {
  StepTypes,
  ComponentTitle,
  EMPTY_VALUE,
  phoneRegExp,
  PLACEHOLDER_SELECT_VALUE,
  severeInjuries,
  states,
  zipCodeExp,
  FIELD_TYPE,
  InjuredTemplate,
} from '../../../../actions/types';
import { useLoading } from '../../../../hooks/useLoading';
import { LoadingPlaceholder } from '../Utilities/LoadingPlaceholder';
import * as Yup from 'yup';

export const InjuredWithRedux = ({
  summary,
  stepType,
  parentRedux,
  isInitValuesRule,
  formik,
  allFields,
  setAllFields,
}) => {
  const { palette } = useTheme();
  const useStyles = makeStyles((theme) => ({
    text: {
      color: palette.text.primary,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
    },
  }));

  const classes = useStyles();

  const [fields, setFields] = useState();
  const [injuryFields, setInjuryFields] = useState([]);
  const [isInitValues, setIsInitValues] = useState(false);

  const getInjuryFields = (injurySection, manyInjured) => {
    const newList = [];
    for (let index = 0; index < manyInjured; index++) {
      injurySection.forEach((field, indexField) => {
        if (manyInjured > 1 && !indexField) {
          newList.push({
            name: `injured${index}`,
            label: `Injured ${index + 1}`,
            type: FIELD_TYPE.LABEL,
            isOneColumn: true,
            toHide: true,
            dependencyFieldToShow: [
              {
                name: 'parties',
                value: true,
              },
              {
                name: 'manyInjured',
                min: 0,
              },
              {
                name: 'anyOneInjured',
                value: 'yes',
              },
            ],
          });
        }
        newList.push({ ...field, name: `${field.name}_${index}` });
      });
    }

    return newList;
  };

  const buildFields = (manyInjured) => {
    if (manyInjured) {
      const partiesIndex = injured.findIndex(
        (field) => field.name === 'parties'
      );
      const injurySection = [
        {
          name: 'firstName',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'First Name',
          type: FIELD_TYPE.TEXT,
          col: 4,
          toHide: true,
          dependencyFieldToShow: [
            {
              name: 'parties',
              value: true,
            },
            {
              name: 'manyInjured',
              min: 0,
            },
            {
              name: 'anyOneInjured',
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.firstName',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: (schema) =>
              schema
                .required('Provide a value')
                .min(2, 'Too Short!')
                .max(50, 'Too Long!'),
          }),
        },
        {
          name: 'lastName',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Last Name',
          type: FIELD_TYPE.TEXT,
          col: 4,
          toHide: true,
          dependencyFieldToShow: [
            {
              name: 'parties',
              value: true,
            },
            {
              name: 'manyInjured',
              min: 0,
            },
            {
              name: 'anyOneInjured',
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.lastName',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: (schema) =>
              schema
                .required('Provide a value')
                .min(2, 'Too Short!')
                .max(50, 'Too Long!'),
          }),
        },
        {
          name: 'phone',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Phone Number',
          type: FIELD_TYPE.TEL,
          col: 4,
          toHide: true,
          inputProps: { maxLength: 12 },
          dependencyFieldToShow: [
            {
              name: 'parties',
              value: true,
            },
            {
              name: 'manyInjured',
              min: 0,
            },
            {
              name: 'anyOneInjured',
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.cellNumber',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: (schema) =>
              schema
                .required('Provide a phone number')
                .matches(
                  phoneRegExp,
                  'Phone number is not valid, Format: 123-456-7890.'
                ),
          }),
        },
        {
          name: 'addressLine1',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Address 1',
          type: FIELD_TYPE.TEXT,
          toHide: true,
          col: 6,
          dependencyFieldToShow: [
            {
              name: 'parties',
              value: true,
            },
            {
              name: 'manyInjured',
              min: 0,
            },
            {
              name: 'anyOneInjured',
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.primaryAddress.addressLine1',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: (schema) => schema.required('Provide an address'),
          }),
        },
        {
          name: 'addressLine2',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Address 2',
          type: FIELD_TYPE.TEXT,
          toHide: true,
          col: 6,
          dependencyFieldToShow: [
            {
              name: 'parties',
              value: true,
            },
            {
              name: 'manyInjured',
              min: 0,
            },
            {
              name: 'anyOneInjured',
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.primaryAddress.addressLine2',
          },
        },
        {
          name: 'city',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'City',
          type: FIELD_TYPE.TEXT,
          toHide: true,
          col: 4,
          dependencyFieldToShow: [
            {
              name: 'parties',
              value: true,
            },
            {
              name: 'manyInjured',
              min: 0,
            },
            {
              name: 'anyOneInjured',
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.primaryAddress.city',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: (schema) => schema.required('Provide a value'),
          }),
        },
        {
          name: 'state',
          initialValue: EMPTY_VALUE.SELECT,
          label: 'State',
          type: FIELD_TYPE.SELECT,
          options: states.map((option) => {
            return {
              name: option.title,
              value: option.abbreviation.toUpperCase(),
            };
          }),
          toHide: true,
          col: 4,
          dependencyFieldToShow: [
            {
              name: 'parties',
              value: true,
            },
            {
              name: 'manyInjured',
              min: 0,
            },
            {
              name: 'anyOneInjured',
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.primaryAddress.state',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: (schema) => schema.required('Provide a value'),
          }),
        },
        {
          name: 'postalCode',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Zip',
          type: FIELD_TYPE.TEXT,
          toHide: true,
          col: 4,
          dependencyFieldToShow: [
            {
              name: 'parties',
              value: true,
            },
            {
              name: 'manyInjured',
              min: 0,
            },
            {
              name: 'anyOneInjured',
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.primaryAddress.postalCode',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: (schema) =>
              schema
                .required('Provide a value')
                .matches(zipCodeExp, 'Provide a valid Zip Code'),
          }),
        },
        {
          name: 'resideHousehold',
          initialValue: EMPTY_VALUE.POS_NEG,
          label: 'Did this person reside in your household?',
          type: FIELD_TYPE.YES_NO,
          isOneColumn: true,
          toHide: true,
          dependencyFieldToShow: [
            {
              name: 'parties',
              value: true,
            },
            {
              name: 'manyInjured',
              min: 0,
            },
            {
              name: 'anyOneInjured',
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.resideHousehold',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: (schema) => schema.required('Select one').nullable(),
          }),
        },
        {
          name: 'severeInjuries',
          initialValue: PLACEHOLDER_SELECT_VALUE,
          label: 'How severe are the injuries?',
          type: FIELD_TYPE.SELECT,
          options: severeInjuries,
          isOneColumn: true,
          toHide: true,
          dependencyFieldToShow: [
            {
              name: 'parties',
              value: true,
            },
            {
              name: 'manyInjured',
              min: 0,
            },
            {
              name: 'anyOneInjured',
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'severity',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: Yup.string().test(
              'severeInjuriesTest',
              'Provide a value',
              (value) => value !== PLACEHOLDER_SELECT_VALUE
            ),
          }),
        },
      ];
      const newInjuryFields = getInjuryFields(injurySection, manyInjured);
      setInjuryFields(newInjuryFields);
      return [
        ...injured.slice(0, partiesIndex + 1),
        ...newInjuryFields,
        ...injured.slice(partiesIndex + 1),
      ];
    }

    return injured;
  };

  const { isLoading, setIsLoading } = useLoading();

  const initValues = (list) =>
    list.forEach((field) => {
      const initialValue =
        field.name === 'injuredRule' ? true : field.initialValue;
      formik.setFieldValue(field.name, initialValue, false);
    });

  const handleInjuryRule = (event) => {
    const fieldName = 'parties';
    if (event.target.name === fieldName) {
      if (event.target.value) {
        const manyInjured = formik.values['manyInjured'];
        const newFields = buildFields(manyInjured);
        setAllFields({ ...allFields, injured: newFields });
        setFields(newFields);
        setIsInitValues(true);
      }
    }
  };

  useEffect(() => {
    try {
      initValues(injured);
    } catch (error) {
      console.error('Error', error);
    }
  }, [isInitValuesRule]);

  useEffect(() => {
    try {
      const newFields = buildFields(parentRedux['manyInjured']);
      setAllFields({ ...allFields, injured: newFields });
      setFields(newFields);
      setReduxValues(formik, parentRedux, newFields);
      setIsLoading(false);
    } catch (error) {
      console.error('Error', error);
    }
  }, []);

  useEffect(() => {
    try {
      if (isInitValues) {
        initValues(injuryFields);
        setIsInitValues(false);
      }
    } catch (error) {
      console.error('Error', error);
    }
  }, [injuryFields]);

  if (isLoading) return <LoadingPlaceholder />;

  return (
    <SubFormType title={ComponentTitle.INJURED}>
      <AMIGForm
        stepType={stepType}
        fields={fields}
        formik={formik}
        handleChange={(event) => handleInjuryRule(event)}
      />
    </SubFormType>
  );
};

InjuredWithRedux.defaultProps = {
  stepType: StepTypes.INJURED,
};

const mapStateToProps = (state) => ({
  summary: state.summary,
});

export const Injured = connect(mapStateToProps)(InjuredWithRedux);
