import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getClaimsTrackingSubmission } from '../../actions/claimActions';
import { retrieveAttachments } from '../../actions/attachments';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { ClaimTypes, RELATION_TO_POLICYHOLDER } from '../../actions/types';
import Thumbnails from '../common/Thumbnails';
import Link from '@material-ui/core/Link';
import AttachmentViewDialog from '../dialogs/AttachmentViewDialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import { formatPhoneForDisplay } from '../../utils/PhoneNumberUtils';
import { useIsAuthenticated } from "@azure/msal-react";

const SubmissionTableDetail = ({
  submissionData,
  getClaimsTrackingSubmission,
  handleUpdateSummary
}) => {
  const isAuthenticated = useIsAuthenticated();
  const [claimData, setClaimData] = useState({ ...submissionData });
  const [selectedImage] = useState({ name: '' });
  const [showImageView, setShowImageView] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleCloseAttachmentViewDialog = () => {
    URL.revokeObjectURL(selectedImage.preview);
    setShowImageView(false);
  };

  // useEffect(() => {
  //   async function getClaimSubmission (submissionId, type) {
  //     if (submissionId) {
  //       const res = await getClaimsTrackingSubmission(submissionId, type);
  //       setClaimData((prev) => ({ ...prev, ...res.data }));
  //       result = res.data;
  //     }
  //     setIsLoading(false);
  //   };

  //   let result;
  //   if (!submissionData.hasOwnProperty('lossLocation')) {
  //     getClaimSubmission(submissionData.logId, submissionData.type);
  //   } else {
  //     setIsLoading(false);
  //   }

  //   return () => {
  //     if (result) {
  //       const {confirmationId, lossDetails, logId, policyNumber, namedInsured, claimReporter, reportedByType, status, type } = result;
  //       const { location: { addressLine1, addressLine2, city, state, postalCode} } = lossDetails;
        
  //       let addr = addressLine1;
  //       if (addressLine2 !== '') {
  //         addr += `, ${addressLine2},`;
  //       }
  //       addr += `, ${city}, ${state} ${postalCode}`;
        
  //       handleUpdateSummary({
  //         logId,
  //         confirmationId,
  //         policyNumber,
  //         status,
  //         type,
  //         reporter: `${claimReporter.firstName} ${claimReporter.lastName}`,
  //         lossDateTime: lossDetails.lossDateTime,
  //         location: addr,
  //         lossDetails,
  //         namedInsured,
  //         reportedByType
  //       });
  //     }
  //   };
  // }, []);

  const useStyles = makeStyles({
    tileText: {
      color: 'textSecondary',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    headerText: {
      color: '#0094ca',
      fontSize: '1.25rem',
      paddingBottom: '0.5rem',
      fontWeight: '400',
    },
    bottomCell: {
      borderBottom: 'none',
    },
    tableFont: {
      color: 'rgba(0, 0, 0, 0.54) !important',
    },
    iconCell: {
      width: '5%',
    },
    titleCell: {
      width: '30%',
    },
  });

  const classes = useStyles();

  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const ContactInfoComponent = () => {
    // The policy is nonvalidated
    if (!claimData.isVerifiedPolicy) {
      return (
        <>
          <Typography className={classes.headerText} gutterBottom>
            Claim Contact
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={6}>
              {/* {claimData.policyNumber && (
                <>
                  <Typography className={classes.title} color='textSecondary'>
                    Policy Number
                  </Typography>
                  <Typography gutterBottom>{claimData.policyNumber}</Typography>
                </>
              )} */}
              <Typography className={classes.title} color='textSecondary'>
                Reporting Party
              </Typography>
              {claimData.reportedByType !== '' ? (
                <>
                  <Typography gutterBottom>
                  {claimData.claimReporter.firstName}{' '}
                  {claimData.claimReporter.lastName}
                </Typography>
                <Typography className={classes.title} color='textSecondary'>
                  Relation to Insured
                </Typography>
                <Typography gutterBottom>
                  {RELATION_TO_POLICYHOLDER[`${claimData.reportedByType}`] ?? 'Unknown'}
                </Typography>
                <Grid item xs={12} sm={6}>
              <Typography className={classes.title} color='textSecondary'>
                Contact
              </Typography>
              <Typography gutterBottom>
                {claimData.claimReporter.emailAddress1}
              </Typography>
              {/* {claimData.reportingParty.contactInfo.phone.map((item, idx) => {
                return (
                  <>
                    {idx ===
                    claimData.reportingParty.contactInfo.phone.length - 1 ? (
                      <Typography gutterBottom>
                        {item.type}
                        {' : '}
                        {formatPhoneForDisplay(item)}
                      </Typography>
                    ) : (
                      <Typography>
                        {item.type}
                        {' : '}
                        {formatPhoneForDisplay(item)}
                      </Typography>
                    )}
                  </>
                );
              })} */}
              <Typography>
                        {claimData.claimReporter.primaryPhoneType}
                        {' : '}
                        {formatPhoneForDisplay(claimData.claimReporter.cellNumber)}
                      </Typography>
              <Typography className={classes.title} color='textSecondary'>
                Mailing Address
              </Typography>
              <Typography gutterBottom>
                {claimData.claimReporter.primaryAddress.addressLine1}{' '}
                {claimData.claimReporter.primaryAddress.addressLine2}
                {', '}
                {claimData.claimReporter.primaryAddress.city}
                {', '}
                {claimData.claimReporter.primaryAddress.state}{' '}
                {claimData.claimReporter.primaryAddress.postalCode}
              </Typography>
              <Typography className={classes.title} color='textSecondary'>
                Text/Email Opt-In
              </Typography>
              <Typography gutterBottom>
                {claimData.claimReporter.allowEmailText === true ? 'Yes' : 'No'}
              </Typography>
              <Typography className={classes.title} color='textSecondary'>
                EFT Payment Opt-In
              </Typography>
              <Typography gutterBottom>
                {claimData.claimReporter.allowEFTPayment === true ? 'Yes' : 'No'}
              </Typography>
            </Grid>
                </>
              ) : (<>
                   <Typography className={classes.title} color='textSecondary'>
                  Unknown
                </Typography>
              </>) }
            </Grid>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Typography className={classes.headerText} gutterBottom>
            Claim Contact
          </Typography>
          
          {claimData.isVerifiedPolicy && (
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.title} color='textSecondary'>
                  Reporting Party
                </Typography>
                {claimData.reportedByType !== '' ? (
                  <>
                    <Typography gutterBottom>
                  ${claimData.claimReporter.firstName} ${claimData.claimReporter.lastName}
                </Typography>
                <Typography gutterBottom>
                  {RELATION_TO_POLICYHOLDER[`${claimData.reportedByType}`]}
                </Typography>
                  </>
                ) : (
                  <>
                    <Typography className={classes.title} color='textSecondary'>
                  Relation to Insured
                </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.title} color='textSecondary'>
                  Contact
                </Typography>
                
                <Typography gutterBottom>
                  {claimData.claimReporter.emailAddress1}
                </Typography>
                {/* {claimData.reportingParty.contactInfo.phone.map((item, idx) => {
                  return (
                    <>
                      {idx ===
                      claimData.reportingParty.contactInfo.phone.length - 1 ? (
                        <Typography gutterBottom>
                          {item.type}
                          {' : '}
                          {formatPhoneForDisplay(item)}
                        </Typography>
                      ) : (
                        <Typography>
                          {item.type}
                          {' : '}
                          {formatPhoneForDisplay(item)}
                        </Typography>
                      )}
                    </>
                  );
                })} */}
                <Typography>
                          {claimData.claimReporter.primaryPhoneType}
                          {' : '}
                          {formatPhoneForDisplay(claimData.claimReporter.cellNumber)}
                        </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box pb={2}>
                  <Divider />
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              {/* {claimData.reportingParty.reporter !== 'Other' && (
                <>
                  <Box style={{ textAlign: 'left' }}>
                    <Typography className={classes.title} color='textSecondary'>
                      Reporting Party
                    </Typography>
                    <Typography gutterBottom>
                      {claimData.claimReporter.firstName} {claimData.claimReporter.lastName}
                    </Typography>
                  </Box>
                </>
              )} */}
              <Typography className={classes.title} color='textSecondary'>
                Policy Number
              </Typography>
              <Typography gutterBottom>{claimData.policyNumber}</Typography>
              <Typography className={classes.title} color='textSecondary'>
                Named Insured
              </Typography>
              <Typography gutterBottom>
                {claimData.namedInsured.firstName}{' '}
                {claimData.namedInsured.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} alignContent={'left'}>
              <Typography className={classes.title} color='textSecondary'>
                Contact
              </Typography>
              <Typography gutterBottom>
                {claimData.namedInsured.contactInfo.emailAddress1}
              </Typography>
              {claimData.namedInsured.contactInfo.phone.map((item, idx) => {
                return (
                  <>
                    {idx ===
                    claimData.namedInsured.contactInfo.phone.length - 1 ? (
                      <Typography gutterBottom>
                        {item.type}
                        {' : '}
                        {formatPhoneForDisplay(item)}
                      </Typography>
                    ) : (
                      <Typography>
                        {item.type}
                        {' : '}
                        {formatPhoneForDisplay(item)}
                      </Typography>
                    )}
                  </>
                );
              })}
              <Typography className={classes.title} color='textSecondary'>
                Mailing Address
              </Typography>
              <Typography gutterBottom>
                {claimData.namedInsured.address.addressLine1}{' '}
                {claimData.namedInsured.address.addressLine2}
                {', '}
                {claimData.namedInsured.address.city}
                {', '}
                {claimData.namedInsured.address.state}{' '}
                {claimData.namedInsured.address.postalCode}
              </Typography>
              {/* {claimData.reportingParty.reporter ===
                `${claimData.namedInsured.firstName} ${claimData.namedInsured.lastName}` && (
                <>
                  <Typography className={classes.title} color='textSecondary'>
                    Text/Email Opt-In
                  </Typography>
                  <Typography gutterBottom>
                    {claimData.reportingParty.isOptedIn === true ? 'Yes' : 'No'}
                  </Typography>
                </>
              )} */}
                <Typography className={classes.title} color='textSecondary'>
                    Text/Email Opt-In
                  </Typography>
                  <Typography gutterBottom>
                    {claimData.claimReporter.allowEmailText === true ? 'Yes' : 'No'}
                  </Typography>
                  <Typography className={classes.title} color='textSecondary'>
                    EFT Payment Opt-In
                  </Typography>
                  <Typography gutterBottom>
                    {claimData.claimReporter.allowEFTPayment === true ? 'Yes' : 'No'}
                  </Typography>
            </Grid>
          </Grid>
        </>
      );
    }
  };
  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          <Box p={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <ContactInfoComponent />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.headerText} gutterBottom>
                  Loss Details
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Grid container direction={'column'}>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.title}
                          color='textSecondary'>
                          Loss Date/Time
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Typography>
                            {typeof claimData.lossDate ===
                            'string'
                              ? claimData.lossDate
                              : null}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container direction={'column'}>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.title}
                          color='textSecondary'>
                          Loss Location
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <Typography>
                            {claimData.lossLocation.addressLine1}{' '}
                            {claimData.lossLocation.addressLine2}{' '}
                            {claimData.lossLocation.city},{' '}
                            {claimData.lossLocation.state}{' '}
                            {claimData.lossLocation.postalcode}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.headerText}>
                  Incidents
                </Typography>
              </Grid>

              {claimData.lossDetails.incidents.map((value, index) => {
                if (value.type === ClaimTypes.THEFT_VANDALISM) {
                  return (
                    <>
                      <Grid item xs={12}>
                        <Typography>Theft/Vandalism</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction={'column'}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.title}
                              color='textSecondary'>
                              Description:
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography align={'justify'}>
                              {value.incidentDesc}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction={'column'}>
                          {value.hasOwnProperty('source') && (
                            <>
                              <Grid item xs={12} sm={6}>
                                <Typography
                                  className={classes.title}
                                  color='textSecondary'>
                                  Incident:
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography>
                                  {capitalize(value.source)}
                                </Typography>
                              </Grid>
                            </>
                          )}
                          <Grid item xs={12} sm={6}>
                            <Typography
                              className={classes.title}
                              color='textSecondary'>
                              Number of Items Stolen/Damaged:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>{value.numOfClaimItems}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              className={classes.title}
                              color='textSecondary'>
                              Estimated loss amount?
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>${value.totalLoss}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                } else if (value.type === ClaimTypes.PROPERTY_DAMAGE) {
                  return (
                    <>
                      <Grid item xs={12}>
                        <Typography>Property/Weather Damage</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction={'column'}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.title}
                              color={'textSecondary'}>
                              Description:
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>{value.incidentDesc}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction={'column'}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.title}
                              color={'textSecondary'}>
                              Source of Damage:
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>{value.source}</Typography>
                          </Grid>
                          {value.source === 'Other' && (
                            <>
                              <Grid item xs={12}>
                                <Typography
                                  className={classes.title}
                                  color={'textSecondary'}>
                                  Source Description:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>{value.sourceOtherDesc}</Typography>
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <Grid container direction={'column'}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.title}
                              color={'textSecondary'}>
                              Has the damage been repaired?
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              {value.isRepaired === true ? 'Yes' : 'No'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                } else if (value.type === ClaimTypes.FIRE_DAMAGE) {
                  return (
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.heading}>
                          Fire Damage
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction={'column'}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.title}
                              color={'textSecondary'}>
                              Fire Source Description:
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography align={'justify'}>
                              {value.fireSourceDesc}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.title}
                              color={'textSecondary'}>
                              Did the fire department respond?
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              {value.q1.selected === true ? 'Yes' : 'No'}
                            </Typography>
                          </Grid>
                          {value.q1.selected === true && (
                            <>
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6}>
                                    <Grid
                                      container
                                      spacing={1}
                                      direction={'column'}>
                                      <Grid item xs={12}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Fire Department:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography>
                                          {value.q1.fireDept}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Fire Department Address:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography>
                                          {value.q1.fireAddress}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Grid
                                      container
                                      spacing={1}
                                      direction={'column'}>
                                      <Grid item xs={12}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Police Department:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography>
                                          {value.q1.policeDept}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Police Department Address:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography>
                                          {value.q1.policeAddress}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container>
                          <Grid item xs={12} sm={6}>
                            <Grid container spacing={1} direction={'column'}>
                              <Grid item xs={12}>
                                <Typography
                                  className={classes.title}
                                  color={'textSecondary'}>
                                  Was anyone injured in the fire?
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  {value.q2.selected === true ? 'Yes' : 'No'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          {value.q2.selected === true && (
                            <>
                              <Grid item xs={12} sm={6}>
                                <Grid container direction={'column'}>
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Was the injured person hospitalized?
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography>
                                      {value.q2.isHospitalized === true
                                        ? 'Yes'
                                        : 'No'}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Grid container direction={'column'}>
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      First Name of Injured Party:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.q2.firstName}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Grid container direction={'column'}>
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Last Name of Injured Party:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>{value.q2.lastName}</Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Grid container direction={'column'}>
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Injured Party's Address:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography>
                                      {value.q2.addressLine1} {value.q2.addressLine2}
                                      {', '}
                                      {value.q2.city}
                                      {', '}
                                      {value.q2.state} {value.q2.zipCode}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <Grid container direction={'column'}>
                              <Grid item xs={12}>
                                <Typography
                                  className={classes.title}
                                  color={'textSecondary'}>
                                  Did the fire cause smoke damage only?
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  {value.q3.selected === true ? 'Yes' : 'No'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction={'column'}>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              className={classes.title}
                              color={'textSecondary'}>
                              Is the home currently habitable?
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>
                              {value.q4.selected === true ? 'Yes' : 'No'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction={'column'}>
                          <Grid item xs={12} sm={6}>
                            <Typography
                              className={classes.title}
                              color={'textSecondary'}>
                              Is the home currently secure?
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography>
                              {value.q5.selected === true ? 'Yes' : 'No'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                } else if (value.type === ClaimTypes.WATER_DAMAGE) {
                  return (
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.heading}>
                          Water Damage
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                        <Grid item xs={12} sm={3}>
                            <Grid container direction={'column'}>
                              <Grid item xs={12}>
                                <Typography
                                  className={classes.title}
                                  color={'textSecondary'}>
                                  Description:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>{value.incidentDesc}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Grid container direction={'column'}>
                              <Grid item xs={12}>
                                <Typography
                                  className={classes.title}
                                  color={'textSecondary'}>
                                  Source of Damage:
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>{value.waterSource}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Grid container direction={'column'}>
                              <Grid item xs={12}>
                                <Typography
                                  className={classes.title}
                                  color={'textSecondary'}>
                                  Has the damage been repaired?
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>
                                  {value.isMitigated === true ? 'Yes' : 'No'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          {value.isMitigated === true && (
                            <>
                              <Grid item xs={12} sm={6}>
                                <Grid container direction={'column'}>
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Mitigation Description:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography align={'justify'}>
                                      {value.mitigationDesc}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  );
                } else if (value.type === ClaimTypes.OTHER) {
                  return (
                    <>
                      <Grid item xs={12}>
                        <Typography className={classes.heading}>
                          Other/Injury
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container direction={'column'}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.title}
                              color={'textSecondary'}>
                              Description:
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>{value.incidentDesc}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Grid container direction={'column'}>
                          <Grid item xs={12}>
                            <Typography
                              className={classes.title}
                              color={'textSecondary'}>
                              Was anyone injured?
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>
                              {value.injuredPerson.isHospitalized === true
                                ? 'Yes'
                                : 'No'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {value.injuredPerson.isHospitalized === true && (
                        <>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12} sm={3}>
                                <Grid container direction={'column'}>
                                  <Grid item xs={12}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Was the injured person the policyholder?
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography>
                                      {value.injuredPerson.isPolicyholder ===
                                      true
                                        ? 'Yes'
                                        : 'No'}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {value.injuredPerson.isPolicyholder === false && (
                                <>
                                  <Grid item xs={12} sm={3}>
                                    <Grid
                                      container
                                      spacing={0}
                                      direction={'column'}>
                                      <Grid item xs={12}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Injured Person's name:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography>
                                          {value.injuredPerson.name}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <Grid container direction={'column'}>
                                      <Grid item xs={12}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Injured Person's phone:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography>
                                          {value.injuredPerson.phone}
                                        </Typography>
                                      </Grid>
                                      <Box pt={1}>
                                        <Grid item xs={12}>
                                          <Typography
                                            className={classes.title}
                                            color={'textSecondary'}>
                                            Injured Person's Address:
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <Typography>
                                            {value.injuredPerson.addressLine1}{' '}
                                            {value.injuredPerson.addressLine2}{' '}
                                            {value.injuredPerson.city}
                                            {', '}
                                            {value.injuredPerson.state}{' '}
                                            {value.injuredPerson.postalCode}
                                          </Typography>
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <Grid container direction={'column'}>
                                      <Grid item xs={12}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Injured Person's email:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography>
                                          {value.injuredPerson.email}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  );
                }
              })}

              <Grid item xs={12}>
                <Typography className={classes.headerText} gutterBottom>
                  Attachments
                </Typography>

                <Grid
                  container
                  spacing={1}
                  style={{ display: '-webkit-inline-box' }}>
                  {claimData.attachments.map((value) => {
                    return (
                      <>
                        <Grid item xs={12} sm={3}>
                          <Grid container spacing={2} direction={'column'}>
                            <Grid item xs={12} sm={3}>
                              <Thumbnails
                                files={[value]}
                                removeCloseIcon={true}
                              />
                            </Grid>
                            {isAuthenticated && (
                              <Grid xs={12} sm={3} gutterBottom>
                                <Box p={2}>
                                  <Typography style={{ textAlign: 'center' }}>
                                    <Link
                                      // component='button'
                                      href={value.url}
                                      download>
                                      Download
                                    </Link>
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <AttachmentViewDialog
            file={selectedImage}
            show={showImageView}
            handleClose={handleCloseAttachmentViewDialog}
          />
        </>
      )}
    </>
  );
};

SubmissionTableDetail.propTypes = {
  getClaimsTrackingSubmission: PropTypes.func.isRequired,
  retrieveAttachments: PropTypes.func.isRequired,
}

export default connect(null, {
  getClaimsTrackingSubmission,
  retrieveAttachments,
})(SubmissionTableDetail);
