import axios from 'axios';
import appSettings from '../appSettings';
import { NETWORK_CALL_FAILED } from '../actions/types';

export const sendEventLogRequest = (data) => {
  try {
    const url = appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL + '/logs/event';
    axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    dispatchEvent({
      type: NETWORK_CALL_FAILED
    });
  }
};
