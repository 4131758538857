import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Typography, Link } from "@material-ui/core";
import { FormType } from "../Utilities/FormType";
import { AMIGForm } from "../Utilities/AMIGForm";
import { reporter } from "../ScreenObjects/Common/reporter";
import { StepTypes, ComponentTitle } from "../../../../actions/types";
import { setReduxValues, useAMIGFormik, setPathValue } from "../Utilities/AMIGHandleForm";
import { summaryActions } from "../../../../actions/summary";
import { updateClaim } from '../../../../actions/claimActions';
import { newClaimActions } from "../../../../actions/newClaim";
import { useLoading } from "../../../../hooks/useLoading";
import { LoadingPlaceholder } from "../Utilities/LoadingPlaceholder";
import appSettings from "../../../../appSettings";

const { updateDisabled } = summaryActions;
const { updateReporter } = newClaimActions;

const TERMS_OF_SERVICE_URL = `${appSettings.STORAGE_ACCT_URL}/pdf/messaging_program_terms.pdf`;

const ReporterWithRedux = ({  
  summary, 
  newClaim, 
  claim,
  updateClaim,
  updateDisabled, 
  updateReporter,
  stepType, 
  handleNext 
}) => {

  const fields = reporter;
  const {isLoading, setIsLoading} = useLoading();
  const [dependencyPathFields, setDedependencyPathFields] = useState([]);
  const [currentValues, setCurrentValues] = useState({});
  const [fieldsObject, setFieldsObject] = useState({});

  const handleSubmit = () => {
    updateReporter(formik.values);
    const copyClaim = setPathValue(formik, fields, claim);
    
    const primaryPhoneType = copyClaim.claimReporter.primaryPhoneType.toLowerCase();
    const isWorkPhoneProvided = primaryPhoneType === 'work';
    copyClaim.claimReporter.isWorkPhoneProvided = isWorkPhoneProvided;
    if(!isWorkPhoneProvided){
      copyClaim.claimReporter.cellNumber = copyClaim.claimReporter.workNumber;
      copyClaim.claimReporter.workNumber = '';
    } else {
      copyClaim.claimReporter.cellNumber = '';
    }
    updateClaim(copyClaim);
    if(!summary.isLastStept) handleNext();
    else updateDisabled(stepType, true);
  };
  
  const formik = useAMIGFormik(fields, handleSubmit);

  useEffect(() => {
    try {
      setReduxValues(formik, newClaim.reporter, fields, claim);
      setDedependencyPathFields(fields.reduce( (acc, cur) => {
        if(cur.keyPath && typeof cur.keyPath !== 'string') return [...new Set([...acc, cur.keyPath.dependencyFieldName])];
        else return acc;
      },[]));
      setFieldsObject(fields.reduce((acc, cur) => ({...acc, [cur.name]:cur}), {}));
      setIsLoading(false);
    } catch(error) {
      console.error("Error",error);
    }
  },[]);

  useEffect(() => {
    try {
      const changedValues = Object.keys(formik.values).filter( field => formik.values[field] !== currentValues[field] ); 
      if(changedValues.some( elemement => dependencyPathFields.find( field => elemement === field && fieldsObject[field].initialValue !== formik.values[field])))
        setReduxValues(formik, newClaim.reporter, fields, claim, true);

      setCurrentValues({...formik.values})
    } catch(error) {
      console.error("Error",error);
    }
  },[formik.values]);

  if(isLoading) return <LoadingPlaceholder />;
  
  return (
    // Task # 1500920 - Change the text from View our Privacy Policy and Terms of Use to By clicking Continue, I agree to the Privacy Policy and Terms of Use. 
    <FormType
      title={ComponentTitle.REPORTER}
      subtitle="File a claim"
      buttonLabel={summary.isLastStept ? "Save" :"Continue"}
      isDisable={summary.isStepDisabled(summary.disable, stepType)}
      handleSubmit={formik.handleSubmit}> 
      <AMIGForm stepType={stepType} fields={fields} formik={formik} />
      {!summary.isLastStept && <Typography variant="subtitle2" >
      By clicking Continue, I agree to the <Link href='https://amig.com/company/internet-privacy-policy/' target="_blank" rel="noopener">Privacy Policy</Link> and <Link href={TERMS_OF_SERVICE_URL} target="_blank" rel="noopener">Terms of Use</Link>
      </Typography>} 
    </FormType>
  );
};

ReporterWithRedux.defaultProps = {
  stepType: StepTypes.REPORTER,
  handleNext: () => {},
};

const mapStateToProps = (state) => ({
  summary: state.summary,
  newClaim: state.newClaim,
  claim: state.claim,
});

export const Reporter = connect(mapStateToProps, { updateDisabled, updateReporter, updateClaim })(ReporterWithRedux);
