import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormType } from '../Utilities/FormType';
import { Typography } from '@material-ui/core';
import { AMIGEnabledFields } from '../Utilities/AMIGEnabledFields';
import { shallowCopy } from '../Utilities/AMIGHandleForm';
import { summaryActions } from '../../../../actions/summary';
import { useState } from 'react';
import ClaimSubmitConfirmation from '../../ClaimSubmitConfirmation';
import {
  submitClaim,
  updateClaim,
  getIP,
} from '../../../../actions/claimActions';
import { sendClaimSummaryEmail } from '../../../../actions/emailActions';
import { showLoading } from '../../../../actions/loading';
import { ClaimStatus, ClaimType } from '../../../../actions/types';
import moment from 'moment';

import { formatDateForDisplay } from '../../../../utils/DateUtils';

const { updateDisabled, updateShowTitle, updateLastStept } = summaryActions;

export const SummaryWithRedux = ({
  claim,
  handleSubmit,
  summary,
  updateDisabled,
  updateShowTitle,
  updateLastStept,
  updateClaim,
  submitClaim,
  showLoading,
  children,
  getIP,
}) => {
  const notValidated = sessionStorage.getItem('notValidated');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmitSummary = async () => {
    showLoading(true);
    updateLastStept(false);
    children.forEach(
      (child) => child && updateDisabled(child.props.stepType, false)
    );

    const copyClaim = shallowCopy(claim);
    copyClaim.showStepper = false;
    copyClaim.submittedOn = moment(new Date()).format();
    //BUG#1508295 - Digital FNOL 2.0 - Unverified Claim email PDF displays report time in GMT
    copyClaim.submittedTimeZone = moment.tz.guess(true);

    try {
      if (notValidated === 'true') {
        throw new Error(
          'Claim Submission Error. User policy information could not be verified'
        );
      }
      handleSubmit();
      const response = await submitClaim(copyClaim);
      copyClaim.status = ClaimStatus.API_SUBMISSION;
      copyClaim.claimNumber = response.data.claimNumber;

      const { ip, network } = await getIP();
      const networkInfo = { ip, network };
      copyClaim['networkInfo'] = networkInfo;
    } catch (error) {
      copyClaim.status = ClaimStatus.EMAIL_SUBMISSION;
      let emailArr = [];
      if (copyClaim.claimReporter.emailAddress1) {
        emailArr.push(`${copyClaim.claimReporter.emailAddress1}`);
      }
      if (
        copyClaim.namedInsured.contactInfo.emailAddress1 !== '' &&
        copyClaim.namedInsured.contactInfo.emailAddress1 !==
          copyClaim.claimReporter.emailAddress1
      ) {
        emailArr.push(`${copyClaim.namedInsured.contactInfo.emailAddress1}`);
      }
      const toEmail = emailArr;

      const isNamedInsured = copyClaim.namedInsured.firstName !== '';
      const { firstName } = isNamedInsured
        ? copyClaim.namedInsured
        : copyClaim.claimReporter;

      let reporter = `${copyClaim.claimReporter.firstName} ${copyClaim.claimReporter.lastName}`;

      const {
        logId,
        confirmationId,
        policyNumber,
        lossDate,
        lossLocation: { addressLine1, addressLine2, city, state, postalCode },
        type,
        
      } = copyClaim;

      sendClaimSummaryEmail(
        logId,
        confirmationId,
        {
          policyNumber: policyNumber,
          firstName: firstName,
          reporter: reporter,
          dateOfLoss: formatDateForDisplay(lossDate),
          lossLocation: `${addressLine1} ${addressLine2} ${city} ${state} ${postalCode}`,
          email: toEmail,
        },
        type
      );
    } finally {
      updateClaim(copyClaim);
      showLoading(false);
      setIsSubmitted(true);
    }
  };

  useEffect(() => {
    try {
      children.forEach(
        (child) => child && updateDisabled(child.props.stepType, true)
      );
    } catch (error) {
      console.error('Error', error);
    }
  }, []);

  return isSubmitted ? (
    <ClaimSubmitConfirmation claim={claim} />
  ) : (
    <FormType
      title='Last step. click Submit after you review your claim information'
      subtitle='After you click the Submit button, we will assign a claims adjuster to work with you on all of the details of your loss.'
      buttonLabel='Submit'
      isDisable={!summary.disable.every((step) => step.value)}
      isSummary={true}
      handleSubmit={handleSubmitSummary}>
      {summary.showTitle && updateShowTitle(false)}
      {!summary.isLastStept && updateLastStept(true)}
      {children.map(
        (child, index) =>
          child && (
            <AMIGEnabledFields
              key={index}
              title={child.props.title}
              stepType={child.props.stepType}
              handleClick={() => updateDisabled(child.props.stepType, false)}>
              {child}
            </AMIGEnabledFields>
          )
      )}
      <Typography variant='subtitle2'>
        This is to inform you that it is the insured's responsibility to do what
        you can to protect the property from further damage. If you buy supplies
        to protect your property from further damage, please save all receipts
        and do not discard of any of the damaged items as the adjuster will
        collect the receipts and look at the damaged items upon inspection of
        the claim. This is not a confirmation of coverage or of reimbursement as
        that will be done by the adjuster. Please take pictures of all the
        damage before anything is done. <br />
        <br /> We also ask that no permanent repairs be done and in an effort to
        ensure your safety, if you attempt to mitigate any damage for your loss
        or are asked to document your loss, we request that you do not put
        yourself or others in a potentially hazardous situation.
      </Typography>
    </FormType>
  );
};

SummaryWithRedux.defaultProps = {
  title: '',
  subtitle: '',
  buttonLabel: '',
  handleSubmit: () => {},
};

const mapStateToProps = (state) => ({
  claim: state.claim,
  summary: state.summary,
});

export const Summary = connect(mapStateToProps, {
  updateDisabled,
  updateShowTitle,
  updateLastStept,
  updateClaim,
  showLoading,
  submitClaim,
  getIP,
})(SummaryWithRedux);
