import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Button, FormControl, Grid, Typography } from "@material-ui/core";

export const FormTypeWithRedux = ({
  title,
  subtitle,
  isSummary,
  buttonLabel,
  isDisable,
  handleSubmit,
  summary,
  children
}) => {

  const { palette, breakpoints } = useTheme();
  const tabletBreakpoint = breakpoints.up(600);
  const desktopBreakpoint = breakpoints.up(960);

  const useStyles = makeStyles((theme) => ({
    fieldset: {
      margin: '0',
      width: '100%',
      [desktopBreakpoint]: {
        margin: '0 100px',
        width: 'clamp(1px, 100%, calc(100% - 200px))',
      },
    },
    formTitle: {
      color: palette.text.secondary,
      fontSize: '32px',
      fontWeight: 'bold',
    },
    formSubtitle: {
      color: palette.text.light,
      fontSize: '16px',
      fontWeight: 'bold',
    },
    summayTitle: {
      color: palette.primary.main,
      textAlign: 'center',
      },
    summarySubtitle: {
      textAlign: 'center',
      fontWeight: 400,
      fontFamily: ['Raleway', 'sans-serif'].join(','),
    },
    alignButton: {
      marginBottom: '30px',
      textAlign: 'center',
      margin: '38px 0',
      [tabletBreakpoint]: {
        textAlign: 'right',
      }
    },
    align: {
      '&.MuiGrid-container > *': {
        width: '100%',
      },
    },
  }));

  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.fieldset} >
      <Grid container className={classes.align}>
        <Grid item xs={12} style={{marginBottom: '30px'}} >
          {summary.showTitle && !isSummary && <Typography variant="subtitle1" className={ classes.formSubtitle } >{subtitle}</Typography>}
          {summary.showTitle && !isSummary && <Typography variant="h2" className={classes.formTitle} >{title}</Typography>}
          {isSummary && <Typography variant="h1" className={classes.summayTitle} >{title}</Typography>}
          {isSummary && <Typography variant="h6" className={ classes.summarySubtitle } >{subtitle}</Typography>}
        </Grid>
        {children} 
        <Grid item xs={12} className={classes.alignButton} >
          <Button 
            id='continue'
            variant="contained" 
            color="primary" 
            style={{width: 'clamp(1px, 100%, 300px)'}} 
            disabled={isDisable}
            onClick={handleSubmit}>
              {buttonLabel}
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
}

FormTypeWithRedux.defaultProps = {
  title: '',
  subtitle: '',
  isSummary: false,
  buttonLabel: '',
  isDisable: false,
  handleSubmit: () => {},
};

const mapStateToProps = (state) => ({
  summary: state.summary,
});

export const FormType = connect(mapStateToProps)(FormTypeWithRedux);
