import * as yup from 'yup';
import { states, PhoneTypes, MobileProviders } from '../actions/types';

export const policyLossTsSchema = yup.object({
  policyNumber: yup
    .string()
    .matches(/^[A-Za-z0-9_-]*$/, 'Only alphanumeric characters are allowed')
    .min(9, 'Policy number must be at least 9 characters')
    .max(13, 'Policy number must not be more than 13 characters long'),
  //.test('len', 'Must not be exactly 11 characters', val => val.length === 11),
  lossDate: yup.mixed().required('Provide a date and time of loss'),
});

export const policyLossTsDialogSchema = yup.object({
  firstName: yup
    .string()
    .max(30, 'value must be less than 30 characters'),
  lastName: yup
    .string()
    .max(30, 'value must be less than 30 characters')
    .required('Provide policyholder last name'),
  zipcode: yup
    .string()
    .required('Provide policyholder Zip Code')
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Provide a valid Zip Code'),
});

export const vehicleInfoDialogSchema = yup.object({
  make: yup.string().required('Provide a make'),
  model: yup.string().required('Provide a model'),
  year: yup.mixed().required('Provide a year'),
  owner: yup.object({
    isInsureds: yup.boolean().nullable().required('Required'),
    firstName: yup.string().when('isInsureds', {
      is: false,
      then: yup.string().required('Provide a first name'),
    }),
    lastName: yup.string().when('isInsureds', {
      is: false,
      then: yup.string().required('Provide a last name'),
    }),
  }),
});

export const lossLocationSchema = yup.object({
  addressLine1: yup
    .string()
    .max(60, 'value must be less than 60 characters')
    .required('Provide a street address'),
  addressLine2: yup.string().max(60, 'value must be less than 60 characters'),
  city: yup
    .string()
    .max(60, 'value must be less than 60 characters')
    .required('Provide a city'),
  state: yup.string().max(2).nullable().required('Select a state'),
  zipCode: yup
    .string()
    .required('Provide a Zip Code')
    .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Provide a valid Zip Code'),
});

export const fireDamageSchema = yup.object({
  fireSourceDesc: yup
    .string()
    .required('Provide fire source and description details'),
  q1: yup.object({
    selected: yup.boolean().nullable().required('Required'),
    isHospitalized: yup
      .boolean()
      .nullable()
      .when('selected', {
        is: true,
        then: yup.boolean().required('Required'),
      }),
    firstName: yup.string().when('selected', {
      is: true,
      then: yup.string().required("Provide injured person's first name"),
    }),
    lastName: yup.string().when('selected', {
      is: true,
      then: yup.string().required("Provide injured person's last name"),
    }),
    addressLine1: yup.string().when('selected', {
      is: true,
      then: yup.string().required('Provide street address'),
    }),
    city: yup.string().when('selected', {
      is: true,
      then: yup.string().required('Provide a city'),
    }),
    state: yup.string().when('selected', {
      is: true,
      then: yup
        .string()
        .required('Select a state')
        .oneOf(
          states.map((state) => state.abbreviation),
          'Select a state'
        ),
    }),
    zipCode: yup.string().when('selected', {
      is: true,
      then: yup
        .string()
        .required('Provide a zip code')
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Provide a valid zip code'),
    }),
  }),
  q2: yup.object({
    selected: yup.boolean().nullable().required('Required'),
  }),
  q3: yup.object({
    selected: yup.boolean().nullable().required('Required'),
  }),
  q4: yup.object({
    selected: yup.boolean().nullable().required('Required'),
  }),
  officials: yup.array().of(
    yup.object({
      firstName: yup.string().required('Provide a name'),
      lastName: yup.string().required('Provide a last name'),
    })
  ),
});

export const waterDamageSchema = yup.object({
  incidentDesc: yup
    .string()
    .required('Provide a description of how the water damage was discovered'),
  waterSource: yup.string().required('Select a water source'),
  isMitigated: yup.boolean().nullable().required('Required'),
  mitigationDesc: yup.string().when('isMitigated', {
    is: true,
    then: yup
      .string()
      .required('A description of how the damage was stopped is required'),
  }),
});

export const theftVandalismSchema = yup.object({
  incidentDesc: yup.string().required('Describe what happened'),
  source: yup
    .string()
    .nullable()
    .oneOf(['theft', 'vandalism'], 'Select a valid option'),
  numOfClaimItems: yup
    .string()
    .oneOf(['1 - 5', '6 - 15', '16 or More'], 'Select a valid option'),
  // .required('Select the number of items to claim'),
  totalLoss: yup.string().nullable(),
});

export const propertyDamageSchema = yup.object({
  incidentDesc: yup
    .string()
    .max(1000)
    .required('Describe how the property damage occurred'),
  source: yup.string().required('Select the source of the damage'),
  sourceOtherDesc: yup.string().when('source', {
    is: 'Other',
    then: yup.string().required('Describe the source of the damage'),
  }),
  isRepaired: yup.boolean().nullable().required('Required'),
});

export const recreationalDamageSchema = yup.object({
  incidentDesc: yup.string().required('Describe how the incident occurred'),
  source: yup.string().nullable().required('Describe the source of the damage'),
  vehicles: yup.array().of(
    yup.object({
      estimateReceived: yup.boolean().nullable().required('Required'),
      estimatedRepairCost: yup.string().when('estimateReceived', {
        is: true,
        then: yup
          .string()
          .required('Provide the amount of the recieved estimate'),
      }),
    })
  ),
  witnesses: yup.array().of(
    yup.object({
      firstName: yup.string().required('Provide a name'),
      lastName: yup.string().required('Provide a last name'),
    })
  ),
});

export const otherSchema = yup.object({
  incidentDesc: yup.string().required('Describe the extent of the injuries'),
  injuredPerson: yup.object({
    isHospitalized: yup.boolean().nullable().required('Required'),
    isPolicyholder: yup
      .boolean()
      .nullable()
      .when('isHospitalized', {
        is: true,
        then: yup.boolean().required('Required'),
      }),
    name: yup.string().when('isPolicyholder', {
      is: false,
      then: yup.string().required("Provide injured person's name"),
    }),
    phone: yup.string().when('isPolicyholder', {
      is: false,
      then: yup
        .string()
        .matches(/^[0-9]{10}$/, 'Provide a valid phone number')
        .required("Provide injured person's phone number"),
    }),
    emailAddress1: yup.string().when('isPolicyholder', {
      is: false,
      then: yup
        .string()
        .email('Provide a valid email address')
        .required("Provide injured person's email"),
    }),
    addressLine1: yup.string().when('isPolicyholder', {
      is: false,
      then: yup
        .string()
        .max(60, 'value must be less than 60 characters')
        .required('Provide a street address'),
    }),
    addressLine2: yup.string().max(60, 'value must be less than 60 characters'),
    city: yup.string().when('isPolicyholder', {
      is: false,
      then: yup
        .string()
        .max(60, 'value must be less than 60 characters')
        .required('Provide a city'),
    }),
    state: yup.string().when('isPolicyholder', {
      is: false,
      then: yup.string().max(2).required('Select a state'),
    }),
    zipCode: yup.string().when('isPolicyholder', {
      is: false,
      then: yup
        .string()
        .required('Provide a Zip Code')
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Provide a valid Zip Code'),
    }),
  }),
});

export const claimReporterSchema = yup.object({
  //validateOptIn: yup.boolean(),
  isAuth: yup.boolean(),
  reporter: yup.string().required('Select a reporting party'),
  firstName: yup
    .string()
    .max(30, 'value must be less than 30 characters')
    .when('reporter', {
      is: 'Other',
      then: yup.string().required('Provide your first name'),
    }),
  lastName: yup
    .string()
    .max(30, 'value must be less than 30 characters')
    .when('reporter', {
      is: 'Other',
      then: yup.string().required('Provide your last name'),
    }),
  relationToInsured: yup.string().when('reporter', {
    is: 'Other',
    then: yup
      .string()
      .notOneOf(['none'], 'Provide your relationship to the insured')
      .required('Provide your relationship to the insured'),
  }),
  address: yup.object({
    isRequired: yup.boolean(),
    addressLine1: yup
      .string()
      .when('isRequired', {
        is: true,
        then: yup
          .string()
          .max(60, 'value must be less than 60 characters')
          .required('Required'),
      }),
    addressLine2: yup.string().max(60, 'value must be less than 60 characters'),
    city: yup
      .string()
      .when('isRequired', {
        is: true,
        then: yup
          .string()
          .max(60, 'value must be less than 60 characters')
          .required(),
      }),
    state: yup.string().when('isRequired', {
      is: true,
      then: yup
        .string()
        .max(2)
        .oneOf(states.map((state) => state.abbreviation))
        .required('Required'),
    }),
    zipCode: yup.string().when('isRequired', {
      is: true,
      then: yup
        .string()
        .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Provide a valid Zip Code')
        .required('Required'),
    }),
  }),
  contactInfo: yup.object({
    primaryPhoneType: yup
      .string()
      .oneOf(
        [PhoneTypes.HOME, PhoneTypes.MOBILE, PhoneTypes.WORK],
        'Provide a valid contact method'
      )
      .required('Provide a preferred contact method'),
    emailAddress1: yup
      .string()
      .email('Provide a valid email address')
      .required('Provide an email address'),
    confirmEmail: yup
      .string()
      .email('Provide a valid email address')
      .required('Confirmation email required')
      .oneOf([yup.ref('email')], 'Emails must match'),
    phone: yup
      .array()
      .of(
        yup.object().shape({
          number: yup
            .string()
            .nullable()
            .matches(/^[0-9]{10}$/, 'Provide a valid phone number')
            .required('Provide a phone number'),
          type: yup.string().nullable().required('Provide a phone type'),
          mobileProvider: yup
            .string()
            .nullable()
            .when('type', {
              is: 'Mobile',
              then: yup
                .string()
                .oneOf(MobileProviders, 'Select a valid mobile provider')
                .required('Select a mobile provider'),
            }),
          mobileProviderOther: yup
            .string()
            .when('mobileProvider', {
              is: 'Other',
              then: yup
                .string()
                .required('Enter the name of your mobile provider'),
            })
            .max(100, 'Value exceeds 100 character limit'),
        })
      )
      .min(1, 'Provide at least one phone number'),
  }),
  // namedInsured: yup.object().when('reporter', {
  //   is: reporter => reporter !== 'Other',
  //   then: yup.object({
  //     address: yup.object().shape({
  //       addressLine1: yup.string().max(60, 'value must be less than 60 characters'),
  //       addressLine2: yup.string().max(60, 'value must be less than 60 characters'),
  //       city: yup.string().max(60, 'value must be less than 60 characters'),
  //       state: yup.string().max(2),
  //       postalCode: yup
  //         .string()
  //         .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Provide a valid Zip Code'),
  //     }),
  //     contactInfo: yup.object().shape({
  //       emailAddress1: yup
  //         .string()
  //         .email('Provide a valid email address')
  //         .required('Provide an email address'),
  //       confirmEmail: yup
  //         .string()
  //         .email('Provide a valid email address')
  //         .required('Confirmation email required')
  //         .oneOf([yup.ref('email')], 'Emails must match'),
  //         primaryPhoneType: yup
  //         .string()
  //         .oneOf(
  //           [
  //             PhoneTypes.HOME,
  //             PhoneTypes.MOBILE,
  //             PhoneTypes.WORK
  //           ],
  //           'Provide a valid contact method'
  //         )
  //         .required('Provide a preferred contact method'),
  //       phone: yup
  //         .array()
  //         .of(
  //           yup.object().shape({
  //             number: yup
  //               .string()
  //               .nullable()
  //               .matches(/^[0-9]{10}$/, 'Provide a valid phone number')
  //               .required('Provide a phone number'),
  //             type: yup.string().nullable().required('Provide a phone type'),
  //             mobileProvider: yup
  //               .string()
  //               .nullable()
  //               .when('type', {
  //                 is: 'Mobile',
  //                 then: yup
  //                   .string()
  //                   .oneOf(MobileProviders, 'Select a valid mobile provider')
  //                   .required('Select a mobile provider'),
  //               }),
  //             mobileProviderOther: yup
  //               .string()
  //               .when('mobileProvider', {
  //                 is: 'Other',
  //                 then: yup
  //                   .string()
  //                   .required('Enter the name of your mobile provider'),
  //               })
  //               .max(100, 'Value exceeds 100 character limit'),
  //           })
  //         )
  //         .min(1, 'Provide at least one phone number'),
  //     }),
  //   }),
  // }),
  optInRequired: yup.boolean(),
  isOptedIn: yup.boolean().nullable().when('optInRequired', {
    is: true,
    then: yup.boolean().required('Required'),
  }),
});
