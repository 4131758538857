import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import 'react-vertical-timeline-component/style.min.css';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { sendEventLogRequest } from '../../actions/eventActions';
import PropTypes from 'prop-types';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import { PolicyTypes } from '../../actions/types';
import { updateClaim } from '../../actions/claimActions';
import { getContainerPadding, getInteriorContainerPadding, getListPadding } from '../../actions/layoutActions';

const useStyles = makeStyles({
  card: {
    height: '100%',
    // padding: "1rem",
  },
  cardTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardMedia: {
    width: '13%',
    alignSelf: 'center',
  },
  btn: {
    'color': '#fff',
    'backgroundColor': '#007bff',
    'borderColor': '#007bff',
    '&:hover': {
      color: '#fff',
      background: '#0056b3',
    },
  },
});

const { deviceType } = getDeviceTypeInfo();

const WelcomePage = ({ claim, updateClaim }) => {
  let { logId, type } = claim;
  let displayLogo = null;
  const homeLogo = require('../../img/2-Story2_Circle_Purple.png');
  const autoLogo = require('../../img/Collector_Car_Circle_Green.png');
  const rentersLogo = require('../../img/Apartment_Circle_Blue.png');

  !type && (type = localStorage.getItem('policyType'));

  switch (type) {
    case PolicyTypes.RENTERS.toLowerCase():
      displayLogo = rentersLogo;
      break;
    case PolicyTypes.RESIDENTIAL.toLowerCase():
      displayLogo = homeLogo;
      break;
    case PolicyTypes.RECREATIONAL.toLowerCase():
      displayLogo = autoLogo;
      break;
    default:
      displayLogo = null;
      break;
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    var event = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Welcome'}",
    };

    sendEventLogRequest(event);
  }, []);

  const classes = useStyles();

  return (
    <>
      <Container
        style={{
          background: '#ededed',
          padding: `${getContainerPadding(deviceType)}`,
        }}
        align='center'>
        <Box p={getInteriorContainerPadding(deviceType)}>
          <Grid container spacing={2}>
            <Grid item xs>
              <Card className={classes.card}>
                <Box p={2} mt={3}>
                  <CardMedia
                    className={classes.cardMedia}
                    component='img'
                    image={displayLogo}
                    alt='Renters Banner'
                  />
                  <CardContent className={classes.cardContent}>
                    <Paper style={{ background: '#ededed' }} elevation={0}>
                      <Box p={3}>
                        <Typography
                          className={classes.cardTitle}
                          gutterBottom
                          variant='h4'
                          align='center'>
                          We are sorry for your loss, but we’re ready to help.
                        </Typography>
                        <Typography
                          variant='body2'
                          style={{ fontSize: '0.85rem' }}
                          component='p'
                          align='left'>
                          Before starting your claim, here are some good things
                          to have available:
                        </Typography>
                        <ul style={{padding: '1rem'}}>
                          <li key={'policy-number'}>
                            <Typography
                              variant='body2'
                              style={{ fontSize: '0.85rem' }}
                              component='p'
                              align='left'>
                              Your Policy Number{' '}
                              <i>
                                <strong>
                                  (you can still file the claim even if you
                                  don't have it)
                                </strong>
                              </i>
                            </Typography>
                          </li>
                          <li key={'date-time'}>
                            <Typography
                              variant='body2'
                              style={{ fontSize: '0.85rem' }}
                              component='p'
                              align='left'>
                              Date and time of your loss
                            </Typography>
                          </li>
                          <li key={'loss-cause'}>
                            <Typography
                              variant='body2'
                              style={{ fontSize: '0.85rem' }}
                              component='p'
                              align='left'>
                              Cause of damages, if you are aware of it
                            </Typography>
                          </li>
                          <li key={'loss-desc'}>
                            <Typography
                              variant='body2'
                              style={{ fontSize: '0.85rem' }}
                              component='p'
                              align='left'>
                              A description of what was damaged/lost
                            </Typography>
                          </li>
                          <li key={'police-fire-info'}>
                            <Typography
                              variant='body2'
                              style={{ fontSize: '0.85rem' }}
                              component='p'
                              align='left'>
                              Info from the police or fire department report
                            </Typography>
                          </li>
                          <li key={'temp-repairs-living'}>
                            <Typography
                              variant='body2'
                              style={{ fontSize: '0.85rem' }}
                              component='p'
                              align='left'>
                              Whether you believe you need to make temporary
                              repairs or require temporary living arrangements
                            </Typography>
                          </li>
                        </ul>
                        <Typography
                          variant='body2'
                          style={{ fontSize: '0.85rem' }}
                          component='p'
                          align='left'>
                          Don’t worry if you don’t have all of this now. We’ll
                          start with what you have.
                        </Typography>
                      </Box>
                    </Paper>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button
                      data-test-id='welcome-page-continue'
                      component={Link}
                      variant='contained'
                      color='primary'
                      className={classes.btn}
                      to='/claim'>
                      Continue
                    </Button>
                  </CardActions>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
});

WelcomePage.propTypes = {
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { updateClaim })(WelcomePage);
