import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PersonIcon from '@material-ui/icons/Person';
import IconButton from '@material-ui/core/IconButton';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useMsal } from '@azure/msal-react';
import logo from '../../img/AMIG-Logo_White_Horizonal.svg';

const AppMenu = ({ user }) => {
  const { instance } = useMsal();
  const searchInput = React.createRef();
  const location = useLocation().pathname.replace('/', '').replace(/-/g, ' ');
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    logo: {
      padding: '1em',
      width: '150px',
    },
    menu: {
      marginRight: theme.spacing(2),
      display: 'flex',
      color: '#FFF',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  }));

  const classes = useStyles();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const menuId = 'primary-search-account-menu';

  return (
    <div className={classes.grow}>
      <AppBar>
        <Toolbar disableGutters>
          <img className={classes.logo} src={logo} alt='American Modern logo' />
          <div className={classes.grow} />
          <Tooltip title={'Submission Search'} aria-label={'submission-search'}>
            <IconButton
              component={Link}
              to='/claims-fnol-search'
              style={{ color: '#FFF' }}
              aria-label='submissions-search'>
              <ListAltIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={'Account'} aria-label={'account'}>
            <IconButton color='inherit' aria-label='account'>
              <PersonIcon />
            </IconButton>
          </Tooltip>

          <Typography>{user}</Typography>
          <Tooltip title={'Logout'} aria-label={'logout-button'}>
            <IconButton
              style={{ color: '#FFF' }}
              aria-label='logout-button'
              onClick={() => instance.logout()}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AppMenu;
