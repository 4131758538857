import { EMPTY_VALUE, phoneRegExp, zipCodeExp, relationshipPolicyHolder, PhoneTypes, states, FIELD_TYPE, POLICY_HOLDER } from "../../../../../actions/types";
import * as Yup from 'yup'; 

export const reporter = [
    {
      name: 'relationship',
      initialValue: EMPTY_VALUE.SELECT,
      label: 'What is your relationship to the policyholder?',
      type: FIELD_TYPE.SELECT,
      options: relationshipPolicyHolder,
      keyPathToSaveValue: 'claim.reportedByType',
      yup: Yup.string().required('Provide a value'),
    },
    {
      name: 'config',
      type: FIELD_TYPE.EMPTY,
    },
    {
      name: 'firstName',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'First Name',
      type:  FIELD_TYPE.TEXT,
      keyPath: {
        dependencyFieldName:'relationship', 
        dependencies: [
          {
            fieldValue: POLICY_HOLDER, 
            keyPath: 'claim.namedInsured.firstName',
          },
        ],
      },
      keyPathToSaveValue: 'claim.claimReporter.firstName',
      yup: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Provide a value'),
    },
    {
      name: 'lastName',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'Last Name',
      type:  FIELD_TYPE.TEXT,
      keyPath: {
        dependencyFieldName:'relationship', 
        dependencies:[
          {
            fieldValue: POLICY_HOLDER, 
            keyPath: 'claim.namedInsured.lastName',
          },
        ],
      },
      keyPathToSaveValue: 'claim.claimReporter.lastName',
      yup: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Provide a value'),
    },
    {
      name: 'email',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'Email',
      type: FIELD_TYPE.EMAIL,
      keyPath: {
        dependencyFieldName:'relationship', 
        dependencies:[
          {
            fieldValue: POLICY_HOLDER, 
            keyPath: 'claim.namedInsured.contactInfo.emailAddress1',
          },
        ],
      },
      inputProps: {style: {textTransform: 'lowercase'}},
      yup: Yup.string().email('Invalid email').required('Provide an email'),
    },
    {
      name: 'confirmEmail',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'Confirm Email',
      type:  FIELD_TYPE.EMAIL,
      inputProps: {style: {textTransform: 'lowercase'}},
      keyPathToSaveValue: 'claim.claimReporter.emailAddress1',
      yup: Yup.string().email('Invalid email').required('Provide an email').oneOf([Yup.ref('email')], 'Emails must match'),
    },
    {
      name: 'phone',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'Phone',
      type: FIELD_TYPE.TEL,
      inputProps: { maxLength: 12 },
      keyPathToSaveValue: 'claim.claimReporter.workNumber',
      yup: Yup.string().matches(phoneRegExp, 'Phone number is not valid, Format: 123-456-7890.').required('Provide a phone'),
    },
    {
      name: 'phoneType',
      initialValue: EMPTY_VALUE.SELECT,
      label: 'Phone Type',
      type: FIELD_TYPE.SELECT,
      options: Object.values(PhoneTypes).map( option => {
        return { name: option, value: option };
      } ),
      keyPathToSaveValue: 'claim.claimReporter.primaryPhoneType',
      yup: Yup.string().required('Provide a value'),
    },
    {
      name: 'mailingAddress',
      label: 'Mailing Address',
      type: FIELD_TYPE.LABEL,
      isOneColumn: true,
    },
    {
      name: 'addressLine1',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'Address 1',
      type:  FIELD_TYPE.TEXT,
      keyPath: {
        dependencyFieldName:'relationship', 
        dependencies:[
          {
            fieldValue: POLICY_HOLDER, 
            keyPath: 'claim.namedInsured.address.addressLine1',
          },
        ],
      },
      keyPathToSaveValue: 'claim.claimReporter.primaryAddress.addressLine1',
      yup: Yup.string().required('Provide a value'),
    },
    {
      name: 'addressLine2',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'Address 2',
      type:  FIELD_TYPE.TEXT,
      keyPath: {
        dependencyFieldName:'relationship', 
        dependencies:[
          {
            fieldValue: POLICY_HOLDER, 
            keyPath: 'claim.namedInsured.address.addressLine2',
          },
        ],
      },
      keyPathToSaveValue: 'claim.claimReporter.primaryAddress.addressLine2',
    },
    {
      name: 'city',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'City',
      type:  FIELD_TYPE.TEXT,
      keyPath: {
        dependencyFieldName:'relationship', 
        dependencies:[
          {
            fieldValue: POLICY_HOLDER, 
            keyPath: 'claim.namedInsured.address.city',
          },
        ],
      },
      keyPathToSaveValue: 'claim.claimReporter.primaryAddress.city',
      yup: Yup.string().required('Provide a value'),
      col: 4,
    },
    {
      name: 'state',
      initialValue: EMPTY_VALUE.SELECT,
      label: 'State',
      type: FIELD_TYPE.SELECT,
      keyPath: {
        dependencyFieldName:'relationship', 
        dependencies:
        [
          {
            fieldValue: POLICY_HOLDER, 
            keyPath: 'claim.namedInsured.address.state',
          },
        ],
      },
      options: states.map( option => {
        return { name: option.title , value: option.abbreviation.toUpperCase() };
      }),
      keyPathToSaveValue: 'claim.claimReporter.primaryAddress.state',
      yup: Yup.string().required('Provide a value'),
      col: 4,
    },
    {
      name: 'postalCode',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'Zip',
      type:  FIELD_TYPE.TEXT,
      keyPath: {
        dependencyFieldName:'relationship', 
        dependencies:
        [
          {
            fieldValue: POLICY_HOLDER, 
            keyPath: 'claim.namedInsured.address.postalCode',
          },
        ],
      },
      keyPathToSaveValue: 'claim.claimReporter.primaryAddress.postalCode',
      yup: Yup.string().matches(zipCodeExp, 'Provide a valid Zip Code').required('Provide a value'),
      col: 4,
    },
    {
      name: 'permissionQuestion',
      initialValue: EMPTY_VALUE.POS_NEG,
      label: 'Do we have your permission to send text messages to the telephone number you have provided?',
      type:  FIELD_TYPE.YES_NO,
      keyPathToSaveValue: 'claim.claimReporter.allowEmailText',
      yup: Yup.boolean().required('Select one').nullable(),
    },
    {
      name: 'paymentQuestion',
      initialValue: EMPTY_VALUE.POS_NEG,
      label: 'Should it be determined that your loss is covered, you have the option to receive your payment via EFT. Do we have your permission to Opt-In?',
      type:  FIELD_TYPE.YES_NO,
      keyPathToSaveValue: 'claim.claimReporter.allowEFTPayment',
      yup: Yup.boolean().required('Select one').nullable(),
    },
  ];
