import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormType } from '../Utilities/FormType';
import { AMIGForm, getShowedFields } from '../Utilities/AMIGForm';
import { damaged } from '../ScreenObjects/Residential/damaged';
import { setPathValue, setReduxValues, useAMIGFormik } from '../Utilities/AMIGHandleForm';
import { StepTypes, ComponentTitle, FIELD_TYPE, IncidentOwnerTemplate } from '../../../../actions/types';
import { summaryActions } from "../../../../actions/summary";
import { newClaimActions } from "../../../../actions/newClaim";
import { useLoading } from '../../../../hooks/useLoading';
import { LoadingPlaceholder } from '../Utilities/LoadingPlaceholder';
import { Injured } from '../Common/Injured';
import CustomDialog from '../../../dialogs/CustomDialog';
import { updateClaim } from '../../../../actions/claimActions';

const { updateDisabled } = summaryActions;
const { updateDamagedRes } = newClaimActions;

export const DamagedWithRedux = ({
  summary, 
  claim,
  newClaim,
  updateDisabled, 
  updateDamagedRes,
  updateClaim,
  stepType, 
  handleNext
}) => {

  const fields = damaged;
  
  const [showInjured, setShowInjured] = useState(false);
  const {isLoading, setIsLoading} = useLoading();


  const handleSubmit = () => {
    
    const selectedDamageFields = fields.filter(field => field.type === FIELD_TYPE.CHECKBOX && formik.values[field.name] === true);
    
    const damageValues = selectedDamageFields.map(i => {
      return {name: i.name, value: formik.values[i.name]}
    });

    const ruleDamaged = damageValues.every( field => {
      
      if((field.name === 'NO_DAMAGE') && field.value) {
        return field.value;
      }
      return !field.value;
      
      });
      
      const newValues = {...formik.values, ruleDamaged}

      
      updateDamagedRes(newValues);
    
    const showedFields = getShowedFields(formik, fields)
    const copyClaim = setPathValue(formik, showedFields, claim);
    copyClaim.damageType = fields.reduce((acc,cur) =>{
      if(cur.type === FIELD_TYPE.CHECKBOX && formik.values[cur.name]){ 
        return [...acc,cur.name];
      }
      return acc;
    },[]);
    if (formik.values['NO_DAMAGE']) copyClaim.lossCause = 'powerOutageEvacuation_ext';
    if (formik.values['ANOTHER_PERSONS_PROPERTY'] && !formik.values['information']) {
      copyClaim.contacts.push(IncidentOwnerTemplate)
    }
    updateClaim(copyClaim);
    
    if(formik.values["SOMEONE_INJURED"] === true){
      setShowInjured(true)
    }
    else if(!summary.isLastStept) handleNext();
    else updateDisabled(stepType, true);
  };
  
  const formik = useAMIGFormik(fields, handleSubmit);

  useEffect(() => {
    try {
        setReduxValues(formik, newClaim.damagedRes, fields);
        setIsLoading(false);
    } catch(error) {
      console.error("Error",error);
    }
  },[]);

  if(isLoading) return <LoadingPlaceholder />;

  return (
    <FormType
      title={ComponentTitle.DAMAGED}
      subtitle='File a claim'
      buttonLabel={summary.isLastStept ? "Save" : "Continue"}
      isDisable={summary.isStepDisabled(summary.disable, stepType)}
      handleSubmit={formik.handleSubmit}>
      <AMIGForm stepType={stepType} fields={fields} formik={formik} />
      <CustomDialog
        title=''
        maxWidth='xl'
        open={showInjured}
        handleClose={() => setShowInjured(false)}>
        {<Injured stepType={StepTypes.INJURED} handleNext={handleNext}/>}
      </CustomDialog>
    </FormType>
  );
};

DamagedWithRedux.defaultProps = {
  stepType: StepTypes.DAMAGED_RES,
  handleNext: () => {},
};

const mapStateToProps = (state) => ({
  summary: state.summary,
  claim: state.claim,
  newClaim: state.newClaim,
});

export const DamagedResidential = connect(mapStateToProps, { updateDisabled, updateDamagedRes, updateClaim })(DamagedWithRedux);
