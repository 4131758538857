import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = ({ loading }) => {
  
  const { isLoading } = loading;
  
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  }));

  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

Loading.propTypes = {
    loading: PropTypes.object.isRequired
  };
  
  const mapStateToProps = state => ({
    loading: state.loading
  });

export default connect(mapStateToProps, {})(Loading);
