import React from 'react';
import { useField, ErrorMessage } from 'formik';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const FormikPhoneField = (props) => {
  const [field, meta, helpers] = useField(props);
  return (
    <>
      <FormControl fullWidth>
        <InputMask
          {...field}
          {...props}
          mask='999-999-9999'
          maskChar=''
          onChange={(event) => {
            const newStr = event.target.value.replace(/-/g, '');
            helpers.setValue(newStr);
          }}
          disabled={false}>
          {(props) => (
            <TextField
              label='Phone'
              {...props}
              error={meta.touched && meta.error}
            />
          )}
        </InputMask>
      </FormControl>
      <ErrorMessage {...field} {...props}>
        {(msg) => (
          <FormHelperText
            style={{
              color: '#f44336',
            }}>
            {msg}
          </FormHelperText>
        )}
      </ErrorMessage>
    </>
  );
};

export default FormikPhoneField;
