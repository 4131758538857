import { IS_LAST_STEPT, DISABLED, SHOW_TITLE } from './types';

export const summaryActions = {
  updateLastStept:value => dispatch => {
    dispatch({
      type: IS_LAST_STEPT,
      payload: value
    });
  },
  updateDisabled: (name, value) => dispatch => {
    dispatch({
      type: DISABLED,
      payload: {name, value}
    });
  },
  updateShowTitle: value => dispatch => {
    dispatch({
      type: SHOW_TITLE,
      payload: value
    });
  }
};
