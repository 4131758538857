import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormType } from '../Utilities/FormType';
import { AMIGForm, getShowedFields } from '../Utilities/AMIGForm';
import { damaged } from '../ScreenObjects/Recreational/damaged';
import {
  setPathValue,
  setReduxValues,
  useAMIGFormik,
} from '../Utilities/AMIGHandleForm';
import {
  StepTypes,
  ComponentTitle,
  CAUSE_OPTIONS,
  SOURCE_OPTIONS,
  EMPTY_VALUE,
  phoneRegExp,
  FIELD_TYPE,
  VehicleTemplate,
  DriverTemplate,
  DRIVING_OPTIONS,
} from '../../../../actions/types';
import { summaryActions } from '../../../../actions/summary';
import { newClaimActions } from '../../../../actions/newClaim';
import { useLoading } from '../../../../hooks/useLoading';
import { LoadingPlaceholder } from '../Utilities/LoadingPlaceholder';
import * as Yup from 'yup';
import { Driver } from './Driver';
import { driver } from '../ScreenObjects/Recreational/driver';
import { Injured } from './Injured';
import { injured } from '../ScreenObjects/Recreational/injured';
import { updateClaim } from '../../../../actions/claimActions';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { isEmptyOrSpaces } from '../../../../utils/StringUtils';

const { updateDisabled } = summaryActions;
const { updateDamagedRec } = newClaimActions;

export const DamagedWithRedux = ({
  summary,
  claim,
  newClaim,
  updateDisabled,
  updateDamagedRec,
  updateClaim,
  stepType,
  handleNext,
}) => {
  const [fields, setFields] = useState([]);
  const [accidentInfoFields, setAccidentInfoFields] = useState([]);
  const [driverRule, setDriverRule] = useState(false);
  const [isDriverFinished, setIsDriverFinished] = useState(false);
  const [isRuleByCause, setIsRuleByCause] = useState(false);
  const [injuredRule, setInjuredRule] = useState(false);
  const [isInitValues, setIsInitValues] = useState(false);
  const [allFields, setAllFields] = useState({
    damage: [],
    driver: [],
    injured: [],
  });

  const getAccidentInfoFields = (accidentInfoSection, vehicleNumInvol) => {
    const newList = [];
    for (let index = 0; index < vehicleNumInvol; index++) {
      accidentInfoSection.forEach((field, indexField) => {
        if (vehicleNumInvol > 1 && !indexField) {
          newList.push({
            name: `vehicle${index}`,
            label: `Vehicle ${index + 1}`,
            type: FIELD_TYPE.LABEL,
            isOneColumn: true,
            toHide: true,
            dependencyFieldToShow: [
              { name: 'cause', value: CAUSE_OPTIONS.VEHICLEACCIDENT },
              {
                name: 'source',
                value: [
                  SOURCE_OPTIONS.ANOTHERVEHICLE,
                  SOURCE_OPTIONS.FIXED_OBJECT,
                  SOURCE_OPTIONS.AN_ANIMAL,
                  SOURCE_OPTIONS.ROCK,
                ],
              },
              { name: 'accidentInformation', value: true },
              { name: 'accidentDamaged', value: true },
              { name: 'vehicleNumInvol', min: 0 },
            ],
          });
        }

        newList.push({ ...field, name: `${field.name}_${index}` });
      });
    }

    return newList;
  };
  const buildFields = (vehicleNumInvol) => {
    if (vehicleNumInvol) {
      const accidentInfoIndex = damaged.findIndex(
        (field) => field.name === 'accidentInformation'
      );
      const accidentInfoSection = [
        {
          name: 'accidentMake',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Vehicle Make',
          type: FIELD_TYPE.TEXT,
          col: 6,
          toHide: true,
          dependencyFieldToShow: [
            { name: 'cause', value: CAUSE_OPTIONS.VEHICLEACCIDENT },
            {
              name: 'source',
              value: [SOURCE_OPTIONS.ANOTHERVEHICLE],
            },
            { name: 'accidentInformation', value: true },
            { name: 'accidentDamaged', value: true },
            { name: 'vehicleNumInvol', min: 0 },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'vehicleNumInvol',
            templateObj: VehicleTemplate,
            arrayPathName: 'claim.eventDetails.vehicleIncidents',
            elementPathName: 'vehicle.make',
          },
          yup: Yup.string().when('accidentInformation', {
            is: true,
            then: (schema) => schema.required('Provide a value'),
          }),
        },
        {
          name: 'accidentModel',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Vehicle Model',
          type: FIELD_TYPE.TEXT,
          col: 6,
          toHide: true,
          dependencyFieldToShow: [
            { name: 'cause', value: CAUSE_OPTIONS.VEHICLEACCIDENT },
            {
              name: 'source',
              value: [SOURCE_OPTIONS.ANOTHERVEHICLE],
            },
            { name: 'accidentInformation', value: true },
            { name: 'accidentDamaged', value: true },
            { name: 'vehicleNumInvol', min: 0 },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'vehicleNumInvol',
            templateObj: VehicleTemplate,
            arrayPathName: 'claim.eventDetails.vehicleIncidents',
            elementPathName: 'vehicle.model',
          },
          yup: Yup.string().when('accidentInformation', {
            is: true,
            then: (schema) => schema.required('Provide a value'),
          }),
        },
        {
          name: 'accidentYear',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Vehicle Year',
          type: FIELD_TYPE.NUMBER,
          col: 6,
          toHide: true,
          dependencyFieldToShow: [
            { name: 'cause', value: CAUSE_OPTIONS.VEHICLEACCIDENT },
            {
              name: 'source',
              value: [SOURCE_OPTIONS.ANOTHERVEHICLE],
            },
            { name: 'accidentInformation', value: true },
            { name: 'accidentDamaged', value: true },
            { name: 'vehicleNumInvol', min: 0 },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'vehicleNumInvol',
            templateObj: VehicleTemplate,
            arrayPathName: 'claim.eventDetails.vehicleIncidents',
            elementPathName: 'vehicle.year',
          },
          yup: Yup.number().when('accidentInformation', {
            is: true,
            then: (schema) =>
              schema.min(1901).nullable().required('Provide a value'),
          }),
        },
        {
          name: 'accidentIdentificationNumber',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Vehicle Identification Number',
          type: FIELD_TYPE.TEXT,
          col: 6,
          toHide: true,
          dependencyFieldToShow: [
            { name: 'cause', value: CAUSE_OPTIONS.VEHICLEACCIDENT },
            {
              name: 'source',
              value: [SOURCE_OPTIONS.ANOTHERVEHICLE],
            },
            { name: 'accidentInformation', value: true },
            { name: 'accidentDamaged', value: true },
            { name: 'vehicleNumInvol', min: 0 },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'vehicleNumInvol',
            templateObj: VehicleTemplate,
            arrayPathName: 'claim.eventDetails.vehicleIncidents',
            elementPathName: 'vehicle.VIN',
          },
          yup: Yup.string().when('accidentInformation', {
            is: true,
            then: (schema) => schema.required('Provide a value'),
          }),
        },
        {
          name: 'accidentDriverName',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Driver Name',
          type: FIELD_TYPE.TEXT,
          col: 6,
          toHide: true,
          dependencyFieldToShow: [
            { name: 'cause', value: CAUSE_OPTIONS.VEHICLEACCIDENT },
            {
              name: 'source',
              value: [SOURCE_OPTIONS.ANOTHERVEHICLE],
            },
            { name: 'accidentInformation', value: true },
            { name: 'accidentDamaged', value: true },
            { name: 'vehicleNumInvol', min: 0 },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'vehicleNumInvol',
            templateObj: VehicleTemplate,
            arrayPathName: 'claim.eventDetails.vehicleIncidents',
            elementPathName: 'driver.firstName',
          },
          yup: Yup.string().when('accidentInformation', {
            is: true,
            then: (schema) => schema.required('Provide a value'),
          }),
        },
        {
          name: 'accidentDriverPhone',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Driver Phone Number',
          type: FIELD_TYPE.TEL,
          col: 6,
          toHide: true,
          inputProps: { maxLength: 12 },
          dependencyFieldToShow: [
            { name: 'cause', value: CAUSE_OPTIONS.VEHICLEACCIDENT },
            {
              name: 'source',
              value: [SOURCE_OPTIONS.ANOTHERVEHICLE],
            },
            { name: 'accidentInformation', value: true },
            { name: 'accidentDamaged', value: true },
            { name: 'vehicleNumInvol', min: 0 },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'vehicleNumInvol',
            templateObj: VehicleTemplate,
            arrayPathName: 'claim.eventDetails.vehicleIncidents',
            elementPathName: 'driver.cellNumber',
          },
          yup: Yup.string().when('accidentInformation', {
            is: true,
            then: (schema) =>
              schema
                .required('Provide a phone')
                .matches(
                  phoneRegExp,
                  'Phone number is not valid, Format: 123-456-7890.'
                ),
          }),
        },
      ];

      const newAccidentInfoFields = getAccidentInfoFields(
        accidentInfoSection,
        vehicleNumInvol
      );
      setAccidentInfoFields(newAccidentInfoFields);

      return [
        ...damaged.slice(0, accidentInfoIndex + 1),
        ...newAccidentInfoFields,
        ...damaged.slice(accidentInfoIndex + 1),
      ];
    }

    return damaged;
  };

  const { isLoading, setIsLoading } = useLoading();

  const handleSubmit = () => {
    updateDamagedRec(formik.values);

    const listFields = [
      ...allFields.damage,
      ...allFields.driver,
      ...allFields.injured,
    ];

    const showedFields = getShowedFields(formik, listFields);

    const { source, cause } = formik.values;

    let copyClaim = setPathValue(formik, showedFields, claim, true);
    updateClaim(copyClaim);

    copyClaim = setPathValue(formik, showedFields, claim);
    copyClaim.lossCause = !isEmptyOrSpaces(source) ? source : cause;
    copyClaim.lossSource = !isEmptyOrSpaces(source) ? cause : null;
    copyClaim.eventDetails.driverVehicleDetails.driverRule = driverRule;
    
    if (driverRule && formik.values['driving'] === DRIVING_OPTIONS.DRIVINGINSURED) {
      const driver = listFields.find((field) => field.name === 'populateDriver');
      copyClaim.eventDetails.driverVehicleDetails.driver = driver['driver'];
      copyClaim.eventDetails.driverVehicleDetails.vehicleLossParty = DRIVING_OPTIONS.DRIVINGINSURED
    }
    if (driverRule && formik.values['driving'] === DRIVING_OPTIONS.DRIVINGOTHER) {
      copyClaim.eventDetails.driverVehicleDetails.vehicleLossParty = DRIVING_OPTIONS.DRIVINGOTHER
    }

    updateClaim(copyClaim);

    if (!summary.isLastStept) handleNext();
    else updateDisabled(stepType, true);
  };

  const initValues = (list) =>
    list.forEach((field) =>
      formik.setFieldValue(field.name, field.initialValue, false)
    );

  const isDriverRule = (value) => {
    return (
      value === SOURCE_OPTIONS.FIXED_OBJECT ||
      value === SOURCE_OPTIONS.ANOTHERVEHICLE ||
      value === SOURCE_OPTIONS.AN_ANIMAL ||
      value === SOURCE_OPTIONS.ROCK
    );
  };

  const isCauseRule = (value) => value === CAUSE_OPTIONS.VEHICLEROADSIDE;

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'accidentInformation' && value) {
      const vehicleNumInvol = formik.values['vehicleNumInvol'];
      const newFields = buildFields(vehicleNumInvol);
      setFields(newFields);
      setAllFields({ ...allFields, damage: newFields });
      setIsInitValues(true);
    }

    if (name === 'cause' || name === 'source') {
      if (
        (name === 'cause' && isCauseRule(value)) ||
        (name === 'source' && isDriverRule(value))
      ) {
        if (name === 'cause' && isCauseRule(value)) {
          setIsRuleByCause(true);
          if (injuredRule) setInjuredRule(false);
        }

        setDriverRule(true);
      } else if (driverRule && injuredRule) {
        setDriverRule(false);
        initValues(driver);
        setInjuredRule(false);
        initValues(injured);
        setIsDriverFinished(false);
      } else if (driverRule) {
        if (isRuleByCause) setIsRuleByCause(false);
        setDriverRule(false);
        initValues(driver);
        setIsDriverFinished(false);
      }
    }
  };

  const formik = useAMIGFormik(
    [...allFields.damage, ...allFields.driver, ...allFields.injured],
    handleSubmit
  );

  const { palette } = useTheme();
  const useStyles = makeStyles((theme) => ({
    text: {
      color: palette.text.primary,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    try {
      const newFields = buildFields(newClaim.damagedRec['vehicleNumInvol']);
      setFields(newFields);
      setAllFields({ ...allFields, damage: newFields });
      setReduxValues(formik, newClaim.damagedRec, newFields);

      if (isCauseRule(newClaim.damagedRec['cause'])) {
        setIsRuleByCause(true);
        setDriverRule(true);
      } else if (isDriverRule(newClaim.damagedRec['source'])) {
        setDriverRule(true);
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error', error);
    }
  }, []);

  useEffect(() => {
    try {
      if (isInitValues) {
        initValues(accidentInfoFields);
        setIsInitValues(false);
      }
    } catch (error) {
      console.error('Error', error);
    }
  }, [accidentInfoFields]);

  useEffect(() => {
    try {
      if (!isRuleByCause && driverRule && isDriverFinished) {
        setInjuredRule(true);
        setIsDriverFinished(false);
      }
    } catch (error) {
      console.error('Error', error);
    }
  }, [isDriverFinished]);

  if (isLoading) return <LoadingPlaceholder />;

  return (
      <FormType
        title={ComponentTitle.DAMAGEDR}
        subtitle='File a claim'
        buttonLabel={summary.isLastStept ? 'Save' : 'Continue'}
        isDisable={summary.isStepDisabled(summary.disable, stepType)}
        handleSubmit={formik.handleSubmit}>
        <AMIGForm
          stepType={stepType}
          fields={fields}
          formik={formik}
          handleChange={(event) => handleChange(event)}
        />
        {driverRule && (
          <Driver
            formik={formik}
            stepType={stepType}
            parentRedux={newClaim.damagedRec}
            isInitValues={driverRule}
            allFields={allFields}
            setAllFields={setAllFields}
            setIsDriverFinished={setIsDriverFinished}
          />
        )}
        {injuredRule && (
          <Injured
            formik={formik}
            stepType={stepType}
            parentRedux={newClaim.damagedRec}
            isInitValues={injuredRule}
            allFields={allFields}
            setAllFields={setAllFields}
          />
        )}
        {formik.values.cause !== ' ' && (
          <Typography variant='subtitle2' className={classes.text}>
            You will have the ability to attach any documents at the end.
          </Typography>
        )}
      </FormType>
  );
};

DamagedWithRedux.defaultProps = {
  stepType: StepTypes.DAMAGED_REC,
  handleNext: () => {},
};

const mapStateToProps = (state) => ({
  summary: state.summary,
  claim: state.claim,
  newClaim: state.newClaim,
});

export const DamagedRecreational = connect(mapStateToProps, {
  updateDisabled,
  updateDamagedRec,
  updateClaim,
})(DamagedWithRedux);
