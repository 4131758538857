import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import { sendEventLogRequest } from '../../actions/eventActions';
import { updateClaim } from '../../actions/claimActions';
import { getContainerOffset } from '../../actions/layoutActions';
import OffsetGrid from '../layout/OffsetGrid';
import { ClaimTypes } from '../../actions/types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Alert from '@material-ui/lab/Alert';

import moment from 'moment';
import VehicleInfoDialog from '../dialogs/VehicleInfoDialog';

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  selectedCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  cardIcon: {
    color: 'rgba(0,0,0,0.54)',
  },
  selectedCardIcon: {
    color: 'white',
  },
  cardButton: {
    display: 'block',
    textAlign: 'initial',
  },
  cardContent: {
    flexGrow: 1,
    paddingBottom: '0px',
  },
  cardActions: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const RecreationalClaimTypeComponent = ({
  policy,
  claim,
  activeStep,
  handleBack,
  handleNext,
  updateClaim,
}) => {
  const { logId, lossDetails } = claim;
  const { deviceType } = getDeviceTypeInfo();
  const { riskUnits } = policy;
  const [vehicles, setVehicles] = useState([...lossDetails.additionalRiskUnits]);
  const [openVehicleInfoDialog, setOpenVehicleInfoDialog] = React.useState(
    false
  );
  const [showVehicleSelectError, setShowVehicleSelectError] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [vehicleToEdit, setVehicleToEdit] = useState();
  const [cardJustify, setCardJustify] = useState('center');

  useEffect(() => {
    window.scrollTo(0, 0);
    var event = {
      logId: logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Incident Type Selection'}",
    };

    sendEventLogRequest(event);
  }, []);

  useEffect(() => {
    // We need to add vehicles from riskUnits and add them as selected if they match an existing incident
    const recDamageIndex = lossDetails.incidents.findIndex(
      (incident) => incident.type === 'vehicleDamage'
    );
    if (recDamageIndex !== -1) {
      const recDamageIncident = lossDetails.incidents[recDamageIndex];
      const { vehicles } = recDamageIncident; // vehicles on the incident

      //If a policy vehicle matching the make, model, year and vin exists as an
      //incident, then we dont need to add the risk unit to the array of vehicles

      const policyRiskUnits = riskUnits.map((riskUnit) => {
        const foundIndex = vehicles.findIndex(
          (vehicle) =>
            vehicle.make === riskUnit.make &&
            vehicle.model === riskUnit?.model &&
            vehicle.year === riskUnit.year &&
            vehicle.vin === riskUnit.vin
        );
        if (foundIndex !== -1) {
          return vehicles[foundIndex];
        } else {
          return riskUnit;
        }
      });
      

      setVehicles((prev) => [...prev, ...policyRiskUnits]);
    } else {
      setVehicles((prev) => [...prev, ...riskUnits]);
    }
  }, [riskUnits, lossDetails.additionalRiskUnits]);

  useEffect(() => {
    const selectedVehicles = vehicles.filter((vehicle) => vehicle.isSelected);
    if (selectedVehicles.length > 0) {
      setShowVehicleSelectError(false);
    }
    if (vehicles.length < 3) {
      setCardJustify('center');
    } else {
      setCardJustify('flex-start');
    }
  }, [vehicles]);

  const toggleSelectedVehicle = (vehicle) => {
    const vehiclesCopy = [...vehicles];
    const index = vehiclesCopy.findIndex(
      (element) => element.id === vehicle.id
    );
    if (index !== -1) {
      const selectedState = vehiclesCopy[index].isSelected;
      vehiclesCopy[index].isSelected = !selectedState;
    }
    setVehicles(vehiclesCopy);
  };

  const handleOpenVehicleInfoDialog = () => {
    setOpenVehicleInfoDialog(true);
  };

  const handleOnCloseVehicleInfoDialog = () => {
    setOpenVehicleInfoDialog(false);
  };

  const handleAddVehicleInfo = (values) => {
    const vehiclesCopy = [...vehicles];
    const { make, model, year, vin, owner } = values;
    const vehicleToAdd = {
      id: uuidv4(),
      make,
      model: model ?? 'Unknown',
      year,
      vin,
      isOnPolicy: false,
      isSelected: true,
      estimateReceived: null,
      estimatedRepairCost: '',
      owner,
      driver: null,
      hasDriverDetails: null,
      isEditable: true,
    };
    vehiclesCopy.push(vehicleToAdd);
    setVehicles(vehiclesCopy);
    setOpenVehicleInfoDialog(false);
  };

  const handleVehicleToEdit = (e, vehicle) => {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) e.stopPropagation();
    
    setIsEditing(true);
    setVehicleToEdit(vehicle);
    setOpenVehicleInfoDialog(true);
  };

  const handleOnVehicleEdited = (vehicle) => {
    const updatedVehicles = vehicles.map((v) => {
      if (v.id === vehicle.id) {
        return vehicle;
      }
      return v;
    });
    setIsEditing(false);
    setVehicleToEdit(null);
    setVehicles(updatedVehicles);
    setOpenVehicleInfoDialog(false);
  };

  const handleDeleteVehicle = (id) => {
    const index = vehicles.findIndex((vehicle) => vehicle.id === id);
    if (index !== -1) {
      const vehiclesCopy = [...vehicles];
      vehiclesCopy.splice(index, 1);
      setVehicles(vehiclesCopy);
    }
  };

  const handleOnSubmit = () => {
    const selectedVehicles = vehicles.filter((vehicle) => vehicle.isSelected);

    if (selectedVehicles.length === 0) {
      setShowVehicleSelectError(true);
    } else {
      const recIncidentFoundIndex = lossDetails.incidents.findIndex(
        (incident) => incident.type === ClaimTypes.RECREATIONAL_DAMAGE
      );
      if (recIncidentFoundIndex !== -1) {
        const lossDetailsCopy = { ...lossDetails };
        lossDetailsCopy.additionalRiskUnits = vehicles.filter(
          (vehicle) => vehicle.isOnPolicy === false
        );
        lossDetailsCopy.incidents[
          recIncidentFoundIndex
        ].vehicles = selectedVehicles;
        updateClaim({ ...claim, lossDetails: lossDetailsCopy });
      } else {
        const newClaim = { ...claim };
        newClaim.lossDetails.additionalRiskUnits = vehicles.filter(
          (vehicle) => vehicle.isOnPolicy === false
        );
        newClaim.lossDetails.incidents.push({
          type: ClaimTypes.RECREATIONAL_DAMAGE,
          incidentDesc: '',
          source: null,
          vehicles: selectedVehicles,
          witnesses: [],
          officials: [],
        });
        updateClaim({ ...newClaim });
      }
      handleNext();
    }
  };

  const classes = useStyles();

  return (
    <>
      <>
        <OffsetGrid offset={getContainerOffset(deviceType)}>
          <Typography variant='h1' align='center' color='primary' gutterBottom>
            Select all damaged vehicles
          </Typography>
          <Grid container spacing={2}>
            {showVehicleSelectError && (
              <>
                <Grid item xs={12}>
                  <Alert severity='error'>
                    Error - Select or add a vehicle to continue.
                  </Alert>
                </Grid>
              </>
            )}

            <Grid
              container
              spacing={2}
              justify={cardJustify}
              className={classes.cardGrid}>
              {vehicles.map((vehicle, index) => (
                
                <Grid key={`vehicle-${index}`} item xs={12} sm={6}>
                  <Card
                    id='vehicle-card'
                    className={
                      vehicle.isSelected ? classes.selectedCard : classes.card
                    }
                    variant='outlined'
                    onClick={() => toggleSelectedVehicle(vehicle)}>
                    <CardContent className={classes.cardContent}>
                      <Typography
                        gutterBottom
                        variant='h5'
                        component='h2'>{`${moment(vehicle.year).format(
                        'YYYY'
                      )} ${vehicle.make} ${vehicle.model}`}</Typography>
                      <Typography>VIN: {vehicle.vin ?? 'N/A'}</Typography>
                    </CardContent>
                    {vehicle.hasOwnProperty('isEditable') &&
                      vehicle.isEditable && (
                        <Box display='flex' justifyContent='flex-end'>
                          <Box p={1}>
                            <IconButton
                              size='small'
                              className={classes.editIcon}
                              onClick={(e) => handleVehicleToEdit(e, vehicle)}>
                              <EditIcon
                                fontSize='small'
                                className={
                                  vehicle.isSelected
                                    ? classes.selectedCardIcon
                                    : classes.cardIcon
                                }
                              />
                            </IconButton>
                          </Box>
                          <Box p={1}>
                            <IconButton
                              size='small'
                              onClick={(e) => {
                                if (!e) var e = window.event;
                                e.cancelBubble = true;
                                if (e.stopPropagation) e.stopPropagation();
                                handleDeleteVehicle(vehicle.id);
                              }}>
                              <DeleteIcon
                                fontSize='small'
                                className={
                                  vehicle.isSelected
                                    ? classes.selectedCardIcon
                                    : classes.cardIcon
                                }
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      )}
                  </Card>
                </Grid>
              ))}
            </Grid>

            <Grid item xs={12} align='center'>
              <Button
                color={'primary'}
                size={'large'}
                onClick={handleOpenVehicleInfoDialog}>
                <AddCircleOutlineIcon fontSize={'large'} /> &nbsp; Add Vehicle
              </Button>
            </Grid>
          </Grid>

          <Box p={5} style={{ textAlign: 'center' }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.backButton}>
              Back
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={handleOnSubmit}>
              Next
            </Button>
          </Box>
        </OffsetGrid>
        <VehicleInfoDialog
          open={openVehicleInfoDialog}
          onClose={handleOnCloseVehicleInfoDialog}
          onSubmit={(values) =>
            isEditing
              ? handleOnVehicleEdited(values)
              : handleAddVehicleInfo(values)
          }
          isEditing={isEditing}
          data={vehicleToEdit}
        />
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
  claim: state.claim,
});

RecreationalClaimTypeComponent.propTypes = {
  policy: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updateClaim })(
  RecreationalClaimTypeComponent
);
