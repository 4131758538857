import React from "react";
import NumberFormat from 'react-number-format';

const NumberFormatCustom = ({ inputRef, onChange, name, ...other }) => {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString={true}
        />
    )
}
export default NumberFormatCustom
