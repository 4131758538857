import React, { useState } from "react";
import { Box, Container, Drawer, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Fab, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import { FaqList } from "../../../../actions/types";

const useStyles = makeStyles((theme) => ({
  helpButtonContainer: {
    padding: 0,
  },
  panel: {
    background: '#ededed',
  },
  fab: {
    float: 'right',
    bottom: '1.5rem',
  },
}));

export const AMIGHelp = ({classFab}) => {
 const [state, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const classes = useStyles();

  return (
    <Container className={classes.helpButtonContainer}>
      <Box>
        <Fab
          className={classFab}
          onClick={toggleDrawer(true)}
          color='primary'
          aria-label='Help'
          size='small'
          variant='extended'>
          <HelpOutlineIcon />
          <Box pl={1}>Help</Box>
        </Fab>
      </Box>
      <Drawer
        anchor={'right'}
        open={state}
        onClose={toggleDrawer(false)}>
        <Box className={classes.panel} width={375} height={'100%'} p={2}>
          <Grid container spacing={0}>
            <Grid item xs>
              <Box align={'left'}>
                <Typography variant={'h5'} component={'h5'}>
                  Help
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box align={'right'} p={0}>
                <IconButton
                  style={{ padding: '1px' }}
                  onClick={toggleDrawer(false)}>
                  <CloseIcon fontSize={'small'} />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Box pt={3}>
            {FaqList.map((value, index) => (
              <ExpansionPanel key={`ep-${index}`}>
                <ExpansionPanelSummary
                  key={`ep-summary-${index}`}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={index}
                  id={index}>
                  <Typography>
                    {value.q}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails key={`ep-details-${index}`}>
                  <Typography color={'textSecondary'}>{value.a}</Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </Box>
        </Box>
      </Drawer>
    </Container>
  );
};
