import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import appSettings from '../appSettings';
import { blobToBase64 } from '../utils/BlobUtils';
import {
  RETRIEVE_ATTACHMENTS,
  UPLOAD_ATTACHMENT,
  DELETE_ATTACHMENT,
  NETWORK_CALL_FAILED,
} from '../actions/types';
import { setAlert } from './alert';
import { updateClaim } from './claimActions';
import store from '../store';
import { FileUploadStatus } from './types';

export const uploadAttachment = async (claimNumber, attachment) => {
  const { id, file } = attachment;
  try {

    const base64Str = await blobToBase64(file);
    const base64StrComponents = base64Str.split(',');
    let base64Data;
    if (base64StrComponents.length < 1 || base64StrComponents.length > 2) {
      throw 'Invalid base64 data'
    }
    if (base64StrComponents.length === 1) {
      base64Data = base64StrComponents[0];
    }
    if (base64StrComponents.length === 2) {
      base64Data = base64StrComponents[1];
    }
    const baseUrl = appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL;
    const path = `/claims/${claimNumber}/attachment`;
    const body = JSON.stringify({
      name: file.name.replace(' ', '-'),
      mimeType: file.type,
      data: base64Data
    });
    
    const res = await axios.post(`${baseUrl}${path}`, body, {
      headers: {
        'Content-Type': 'application/json'
      },
    });

    if (res.status === 200) {
      return { id, status: FileUploadStatus.SUCCESS};
    } else {
      return { id, status: FileUploadStatus.FAILED};
    }
    
    // await dispatch({
    //   type: UPLOAD_ATTACHMENT,
    //   payload: res.data,
    // });

    
  } catch (error) {

    return { id, status: FileUploadStatus.FAILED};
  }
};

export const retrieveAttachments = (id) => async (dispatch) => {
  try {
    const baseUrl = appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL;
    const path = `/claims/${id}/attachments`;
    const res = await axios.get(`${baseUrl}${path}`);

    dispatch({
      type: RETRIEVE_ATTACHMENTS,
      payload: res.data,
    });
  } catch (error) {
    setAlert(
      'An error occured while attempting to retrieve previously uploaded attachments. Please try again.',
      'danger'
    );
  }
};

export const deleteAttachment = (id, name) => async (dispatch) => {
  try {
    const baseUrl = appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL;
    const path = `/claims/${id}/attachments?name=${name}`;
    await axios.delete(`${baseUrl}${path}`);

    dispatch({
      type: DELETE_ATTACHMENT,
      payload: name,
    });
  } catch (error) {
    setAlert(
      'An error occured while attempting to retrieve previously uploaded attachments. Please try again.',
      'danger'
    );
  }
};
