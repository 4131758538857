import { SHOW_LOADING, HIDE_LOADING } from './types';

export const showLoading = (value) => (dispatch) => {
  
  if (value === true) {
    dispatch({
      type: SHOW_LOADING
    })
  } else {
    dispatch({
      type: HIDE_LOADING 
    })
  }
};
