import React from 'react';
import { connect } from 'react-redux';
import { Typography, Card, CardMedia, CardContent } from "@material-ui/core";
import useStyles from './TrackClaimComponent.styles';

const SelectAccountTypeComponent = ({
  handleSelectAccountType
}) => {
  const classes = useStyles();
  const arrowIcon = require('../../../img/arrow_blue.png');
  const individualIcon = require('../../../img/individual_icon.png');
  const businessIcon = require('../../../img/business_icon.png');

  return (
    <div className={classes.statusCard}>
      <input type="hidden" id="createAccountType" />
      <Typography variant="subtitle1" className={classes.accountTypeSubText}>
        Select the policyholder type.
      </Typography>
      <Card className={classes.accountTypeCard} onClick={() => handleSelectAccountType('individual')}>
        <div className={classes.cardContent}>
          <img className={classes.individualIcon} src={individualIcon} alt="Individual Icon" />
          <CardContent>
            <Typography variant="h6" className={classes.accountType}>Individual</Typography>
          </CardContent>
        </div>
        <div className={classes.arrowContainer}>
          <img src={arrowIcon} alt="Arrow Icon" className={classes.arrowIcon} />
        </div>
      </Card>
      <Card className={classes.accountTypeCard} onClick={() => handleSelectAccountType('business')}>
        <div className={classes.cardContent}>
          <img className={classes.businessIcon} src={businessIcon} alt="Business Icon" />
          <CardContent>
            <Typography variant="h6" className={classes.accountType}>Business, LLC, Trust or Estate</Typography>
          </CardContent>
        </div>
        <div className={classes.arrowContainer}>
          <img src={arrowIcon} alt="Arrow Icon" className={classes.arrowIcon} />
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({
});

SelectAccountTypeComponent.propTypes = {
};

export default connect(mapStateToProps, { })(
  SelectAccountTypeComponent
);
