import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { states } from '../../actions/types';
import FormikAutocomplete from './FormikAutocomplete';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from 'formik-material-ui';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

const AddressComponent = ({ prefix, validateAddress = false, readOnly = false }) => {
  const { values } = useFormikContext();
  
  const useStyles = makeStyles((theme) => ({
    label: {
      color: '#535353', 
      marginBottom: '12px',
      fontSize: '1rem', 
      fontWeight: '700'
    },
  }));

  const classes = useStyles();

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12} sm={6}>
        <InputLabel shrink className={classes.label}>Loss Address 1</InputLabel>
        <Field
          name={prefix ? `${prefix}.addressLine1` : 'addressLine1'}
          component={TextField}
          fullWidth
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputLabel shrink className={classes.label}>Loss Address 2</InputLabel>
        <Field
          name={prefix ? `${prefix}.addressLine2` : 'addressLine2'}
          component={TextField}
          fullWidth
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputLabel shrink className={classes.label}>City</InputLabel>
        <Field
          name={prefix ? `${prefix}.city` : 'city'}
          component={TextField}
          fullWidth
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputLabel shrink className={classes.label}>State</InputLabel>
            <FormControl fullWidth>
              <FormikAutocomplete
                name={prefix ? `${prefix}.state` : 'state'}
                options={states.map((state) => state.abbreviation)}
                disabled={readOnly}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <InputLabel shrink className={classes.label}>Zip Code</InputLabel>
            <Field
              name={prefix ? `${prefix}.zipCode` : 'zipCode'}
              component={TextField}
              fullWidth
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddressComponent;
