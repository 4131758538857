import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Grid, Container, ButtonBase } from '@material-ui/core';

const Navigation = () => {
  const searchInput = React.createRef();
  const location = useLocation().pathname.replace('/', '').replace(/-/g, ' ');

  const useStyles = makeStyles((theme) => ({
    navigation: {
      maxWidth: '1050px',
      margin: '0 auto',
      marginTop: '13px',
      marginBottom: '10px',
    },
    gridItem: {
      width: '100%',
    },
    logo: {
      marginTop: '10px',
    },
    login: {
      textAlignLast: 'right',
      marginTop: '4px',
      float: 'right',
      fontSize: '14px',
      fontFamily: "'Raleway', 'Open Sans', sans-serif",
      color: '#5F6163 !important',
    },
    nav: {
      float: 'right',
      textAlignLast: 'right',
      marginTop: '30px',
      font: 'Arial, Helvetica',
      fontSize: '14px',
      fontWeight: '700',
      textTransform: 'uppercase',
    },
    navItem: {
      color: '#432F87 !important',
      font: 'Arial, Helvetica',
    },
    navItemLink: {
      color: '#432F87',
      textDecorationLine: 'none',
    },
    navItemText: {
      color: '#432F87 !important',
      font: 'Arial, Helvetica',
      textDecorationLine: 'none',
    },
    currentPage: {
      color: '#432F87 !important',
      fontWeight: '700',
      font: '13px Arial, Helvetica',
    },
    btn: {
      'border': 'none',
      'borderRadius': '4px',
      'WebkitBorderRadius': '4px',
      'MozBorderRadius': '4px',
      'verticalAlign': 'middle',
      'fontSize': 'smaller',
      'background': '#0095c8',
      'color': '#fff',
      'height': '1.3rem',
      'cursor': 'pointer',
      '&:hover': {
        color: '#fff',
        background: '#54575a',
      },
    },
    menu: {
      '&:before': {
        content: 'none!important',
      },
    },
    menuScreen: {
      '@media (max-width: 600px)': {
        maxWidth: '92%',
      },
    },
    searchList: {
      width: '245px',
    },
    searchListItem: {
      width: '100%',
      padding: '5px 10px',
      boxSizing: 'border-box',
    },
    searchListItemInput: {
      border: 'none',
      borderRadius: '4px',
      WebkitBorderRadius: '4px',
      MozBorderRadius: '4px',
      marginRight: '5px',
      height: '1.25rem',
    },
  }));

  const classes = useStyles();

  return (
    <Container>
      <div className={classes.navigation}>
        <Grid container>
          <Grid item xs={12} sm={5}>
            <ButtonBase
              className={classes.logo}
              href='https://www.amig.com'
              disableRipple>
              <img
                // className={classes.logo}
                alt='American Modern Logo'
                src='https://www.amig.com/amig-wp/wp-content/themes/amig2015/assets/images/logo2015.png'
              />
            </ButtonBase>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Grid container direction='column' spacing={3}>
              <Grid item xs>
                <div className={classes.login}>
                  <Link href='https://www.amig.com/customer/make-a-payment/?utm_source=make_payment&utm_medium=top_menu_site&utm_campaign=homepage_updates_nov2019'>
                    <span style={{ fontWeight: 'bold' }}>Make a Payment</span>
                  </Link>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <Link
                    href='https://my.doculivery.com/External/AMIG/Login.aspx'
                    style={{
                      fontWeight: 'bold',
                      color: '#5F6163',
                      textDecorationLine: 'none',
                    }}>
                    <span style={{ fontWeight: 'bold', color: '#5F6163' }}>
                      Online Services Login
                    </span>
                  </Link>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <Link
                    href='http://agents.amig.com/'
                    style={{
                      fontWeight: 'bold',
                      color: '#5F6163',
                      textDecorationLine: 'none',
                    }}>
                    <span style={{ fontWeight: 'bold', color: '#5F6163' }}>
                      Just for Agents
                    </span>
                  </Link>
                </div>
              </Grid>
              <Grid item xs>
                <div className={'animenu'}>
                  <input type='checkbox' id='button' />
                  <label className={classes.menu} htmlFor='button'>
                    Menu
                  </label>
                  <ul className={classes.menuScreen}>
                    <li className={classes.navItem}>
                      <Link
                        href='https://www.amig.com/'
                        style={{
                          textDecorationLine: 'none',
                        }}>
                        <span className={classes.navItemText}>Home</span>
                      </Link>
                    </li>
                    <li className={'seperator'}>
                      <span
                        style={{ paddingRight: '.25rem' }}
                        className={classes.navItemText}>
                        /
                      </span>
                    </li>
                    <li className={classes.navItem}>
                      <Link
                        style={{
                          textDecorationLine: 'none',
                        }}
                        href='https://www.amig.com/customer/'>
                        <span className={classes.navItemText}>Customer</span>
                      </Link>
                      <ul>
                        <li className={classes.navItem}>
                          <Link
                            style={{
                              textDecorationLine: 'none',
                            }}
                            href='https://www.amig.com/customer/make-a-payment/'>
                            <span className={classes.navItemText}>
                              Make a payment
                            </span>
                          </Link>
                        </li>
                        <li className={classes.navItem}>
                          <Link
                            style={{
                              textDecorationLine: 'none',
                            }}
                            href='https://www.amig.com/customer/file-a-claim/'>
                            <span className={classes.navItemText}>
                              File a claim
                            </span>
                          </Link>
                        </li>
                        <li className={classes.navItem}>
                          <Link
                            style={{
                              textDecorationLine: 'none',
                            }}
                            href='https://www.amig.com/customer/change-your-policy/'>
                            <span className={classes.navItemText}>
                              Change your policy
                            </span>
                          </Link>
                        </li>
                        <li className={classes.navItem}>
                          <Link
                            style={{
                              textDecorationLine: 'none',
                            }}
                            href='https://www.amig.com/customer/manage-your-account/'>
                            <span className={classes.navItemText}>
                              Manage your account
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className={'seperator'}>
                      <span
                        style={{ paddingRight: '.25rem' }}
                        className={classes.navItemText}>
                        /
                      </span>
                    </li>
                    <li className={classes.currentPage}>
                      <Link
                        style={{
                          textDecorationLine: 'none',
                        }}
                        href=''>
                        <span className={classes.navItemText}>{location}</span>
                      </Link>
                    </li>
                    <li
                      className={classes.navItem}
                      style={{ padding: '0px 8px' }}>
                      <img
                        style={{ verticalAlign: 'top' }}
                        src='https://www.amig.com/amig-wp/wp-content/themes/amig2015/assets/images/search-icon.png'
                        alt='Search American Modern Insurance Group'
                        className={'seperator'}
                      />
                      <ul style={{ width: '18em' }}>
                        <li className={classes.searchListItem}>
                          <form
                            method='get'
                            id='searchform'
                            action='https://www.amig.com/'>
                            <div>
                              <label className={'seperator'}>Search:</label>
                              <input
                                className={classes.searchListItemInput}
                                type={'text'}
                                size={'18'}
                                id={'s'}
                                name={'s'}
                                ref={searchInput}></input>
                              <label className={'seperator'}>
                                Search Button:
                              </label>
                              <input
                                type={'submit'}
                                id={'searchsubmit'}
                                value={'Search'}
                                className={classes.btn}
                                name={'sb'}></input>
                            </div>
                          </form>
                        </li>
                      </ul>
                    </li>
                    <li
                      className={classes.navItem}
                      style={{ padding: '0px 8px' }}>
                      <img
                        style={{ verticalAlign: 'top' }}
                        src='https://www.amig.com/amig-wp/wp-content/themes/amig2015/assets/images/menu-icon.png'
                        alt='American Modern Insurance Group'
                        className={'seperator'}
                      />
                      <ul>
                        <li className={classes.navItem}>
                          <Link
                            style={{
                              textDecorationLine: 'none',
                            }}
                            href='https://www.amig.com/customer/'>
                            <span className={classes.navItemText}>
                              Customer Center
                            </span>
                          </Link>
                        </li>
                        <li className={classes.navItem}>
                          <Link
                            style={{
                              textDecorationLine: 'none',
                            }}
                            href='https://www.amig.com/insurance/'>
                            <span className={classes.navItemText}>
                              Insurance Products
                            </span>
                          </Link>
                        </li>
                        <li className={classes.navItem}>
                          <Link
                            style={{
                              textDecorationLine: 'none',
                            }}
                            href='http://agents.amig.com'
                            target='_blank'
                            rel='noopener noreferrer'>
                            <span className={classes.navItemText}>
                              I'm an Agent
                            </span>
                          </Link>
                        </li>
                        <li className={classes.navItem}>
                          <Link
                            style={{
                              textDecorationLine: 'none',
                            }}
                            href='https://www.amig.com/company/'>
                            <span className={classes.navItemText}>
                              About Us
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default Navigation;
