import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { SubFormType } from '../Utilities/SubFormType';
import { AMIGForm } from '../Utilities/AMIGForm';
import { driver } from '../ScreenObjects/Recreational/driver';
import { getPathValue, setReduxValues } from '../Utilities/AMIGHandleForm';
import { StepTypes, ComponentTitle, DRIVING_OPTIONS, FIELD_TYPE } from '../../../../actions/types';
import { useLoading } from '../../../../hooks/useLoading';
import { LoadingPlaceholder } from '../Utilities/LoadingPlaceholder';

export const DriverWithRedux = ({
  claim,
  stepType,
  parentRedux,
  isInitValues,
  formik,
  allFields,
  setAllFields,
  setIsDriverFinished,
}) => {

  const buildFields = driver => {
    const firstName = getPathValue('claim.namedInsured.firstName', claim);
    const lastName = getPathValue('claim.namedInsured.lastName', claim);
    const phoneNumber = getPathValue('claim.namedInsured.contactInfo.phone', claim);
    let formatted = '', primaryPhoneType = '';

    if (phoneNumber?.length && phoneNumber[0].number.length === 10) {
      const areaCode = phoneNumber[0].number.slice(0, 3);
      const centralOfficeCode = phoneNumber[0].number.slice(3, 6);
      const number = phoneNumber[0].number.slice(6);
      formatted = `${areaCode}-${centralOfficeCode}-${number}`;
      primaryPhoneType = phoneNumber[0].type;
    }

    let newField = {
      name: 'populateDriver',
      label: `${firstName} ${lastName} ${formatted}`,
      type: FIELD_TYPE.LABEL,
      isOneColumn: true,
      toHide: true,
      driver: {
        firstName,
        lastName,
        subtype: 'Person',
        primaryPhoneType,
        cellNumber: formatted
      },
      dependencyFieldToShow: { name: 'driving', value: DRIVING_OPTIONS.DRIVINGINSURED },
    };
    const firsField = driver[0];
    
    return [firsField, newField, ...driver.slice(1, driver.length)];
  }

  const fields = buildFields(driver);
  const {isLoading, setIsLoading} = useLoading();
  
  const initValues = (list, formik) => list.forEach( field => {
    const initialValue = field.name === 'driverRule' ? true: field.initialValue;
    formik.setFieldValue(field.name, initialValue, false);
  });

  useEffect(() => {
    try {
      initValues(driver, formik);
    } catch(error) {
      console.error("Error",error);
    }
  },[isInitValues]);

  useEffect(() => {
    try {
      setAllFields({...allFields, driver: fields});
      setIsDriverFinished(true);
      setReduxValues(formik, parentRedux, fields);
      setIsLoading(false);
    } catch(error) {
      console.error("Error",error);
    }
  },[]);

  if(isLoading) return <LoadingPlaceholder />;
  
  return (
    <SubFormType title={ComponentTitle.DRIVER} >
      <AMIGForm stepType={stepType} fields={fields} formik={formik} />
    </SubFormType>
  );
};

DriverWithRedux.defaultProps = {
  stepType: StepTypes.DRIVER,
};

const mapStateToProps = (state) => ({
  claim: state.claim,
});

export const Driver = connect( mapStateToProps )(DriverWithRedux);
