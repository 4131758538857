import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as messages from './ClaimDetail.messages';
import CustomFormatterUtil from '../../common/CustomFormatterUtil';
import useStyles from './TrackClaimComponent.styles';

const ClaimDetailComponent = ({
  claimDetailsData = {},
}) => {
  const classes = useStyles();
  let claimMessage;

  const getCompletedProgress = useCallback((status) => {
    if (!!status) {
      switch (status.toLowerCase()) {
        case 'received':
            claimMessage = messages.claimReceivedText;
            return 1;
        case 'assigned':
            claimMessage = messages.claimAssignedText;
            return 2;
        case 'under review':
            claimMessage = messages.claimUnderReviewText;
            return 3;
        case 'loss inspection scheduled':
            claimMessage = messages.claimLossInspectionScheduledText;
            return 4;
        case 'waiting on inspection report':
            claimMessage = messages.claimInspectionReportText;
            return 5;
        case 'inspection report received':
            claimMessage = messages.claimInspectionReportReceivedText;
            return 6;
        case 'paid and open':
            claimMessage = messages.claimPaidAndOpenText;
            return 7;
        case 'paid and closed':
            claimMessage = messages.claimPaidAndClosedText;
            return 8;
        case 'closed':
            claimMessage = messages.claimClosedText;
            return 9;
        default:
              return 0;
      }
    } else {
        return 0;
    }
  });

  return (
  <Box>
    <Grid  container direction="column" className={classes.statusCard}>
      <Typography variant="h3" className={classes.headerTitle}>
        Claim progress #{claimDetailsData.claimNumber}
      </Typography>
      <Grid item container direction="row">
        <Grid item container>
          <Grid container direction="column">
            <Grid item className="claim-content">
              <Grid container direction="column">
                <Grid item ></Grid>
                <LinearProgress
                  className={classes.progressBar}
                  variant="determinate"
                    value={(getCompletedProgress(claimDetailsData.derivedClaimStatus) * 100 / 9)}
                >
                  <div style={{ transform: "translateX(-75%)", height: '32px', borderRadius: '2px', backgroundColor: '#31769b' }} />
                </LinearProgress>
              </Grid>
              <Typography variant="h6" className={classes.cardSubHead}>
                Status
              </Typography>
              <Typography variant="h3" className={classes.statusHeaderText}>
                {CustomFormatterUtil.formatText(claimDetailsData.derivedClaimStatus)}
              </Typography>
              <Typography variant="subtitle1" className={classes.subText}>
                {claimMessage}
              </Typography>
              <Grid container direction="row" className={classes.cardSection}>
                <Grid className={classes.cardRow} item xs={4}>
                  <Grid container direction="column" >
                    <label htmlFor="claimDateOfLoss" className={classes.cardLabel}>
                      <span>Loss date</span>
                    </label>
                  </Grid>
                  <Grid container direction="column" >
                    <div data-read-only="true" className={classes.tableFont}>{CustomFormatterUtil.formatDateForTable(claimDetailsData.lossDate)}</div>
                  </Grid>
                </Grid>
                <Grid item xs={4} className={classes.cardRow}>
                  <Grid container direction="column" >
                    <label htmlFor="claimCauseOfLoss" className={classes.cardLabel}>
                      <span>Cause of loss</span>
                    </label>
                  </Grid>
                  <Grid container direction="column" >
                    <div data-read-only="true" className={classes.tableFont}>{claimDetailsData.lossCauseDescription}</div>
                  </Grid>
                </Grid>
                <Grid item xs={4} className={classes.cardRow}>
                  <Grid container direction="column" >
                    <label htmlFor="claimLocation" className={classes.cardLabel}>
                      <span>Address</span>
                    </label>
                  </Grid>
                  <Grid container direction="column" >
                    { claimDetailsData.isLeasingDesk ? (
                        <div data-read-only="true" className={classes.tableFont}>-</div>
                      ) : (
                        <div data-read-only="true" className={classes.tableFont}>Log in to see</div>      
                      )
                    } 
                  </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  </Box>
  );
};

const mapStateToProps = (state) => ({
});

ClaimDetailComponent.propTypes = {
};

export default connect(mapStateToProps, { })(
  ClaimDetailComponent
);
