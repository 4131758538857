import { EMPTY_VALUE, FIELD_TYPE, anyOneInjuredOpt, PLACEHOLDER_SELECT_VALUE } from "../../../../../actions/types";
import * as Yup from 'yup';

export const injured = [
  {
    name: 'anyOneInjured',
    initialValue: PLACEHOLDER_SELECT_VALUE,
    label: 'Was anyone injured?',
    placeholder: 'Select one',
    type: FIELD_TYPE.SELECT,
    options: anyOneInjuredOpt,
    isOneColumn: true,
    keyPathToSaveValue: 'claim.eventDetails.anyOneInjured',
    yup: Yup.string().when('injuredRule', {
      is: true,
      then: schema => schema.test(
        'anyOneInjuredTest',
        'Provide a value',
        value => value !== PLACEHOLDER_SELECT_VALUE,
      ),
    }),
  },
  {
    name: 'manyInjured',
    initialValue: EMPTY_VALUE.NUMBER,
    label: 'How many people were injured?',
    type: FIELD_TYPE.NUMBER,
    inputProps: { min: 0,}, 
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: { 
      name: 'anyOneInjured', 
      value: 'yes' 
    },
    keyPathToSaveValue: 'claim.eventDetails.injuredPartiesCount',
    yup: Yup.number().when('anyOneInjured', {
      is: value => value === 'yes',
      then: schema => schema.min(1).nullable().required('Provide a value'),
    }),
  },
  {
    name: 'policyholder',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Are any the policyholder?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [
      {
        name: 'manyInjured', 
        min: 0,
      },
      {
        name: 'anyOneInjured', 
        value: 'yes',
      },
    ],
    keyPathToSaveValue: 'claim.eventDetails.isPolicyHolderInjured',
    yup: Yup.mixed().when('manyInjured', {
      is: value => value > EMPTY_VALUE.NUMBER,
      then: schema => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'parties',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Do you have any contact information for the injured parties?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [
      {
        name: 'manyInjured', 
        min: 0,
      },
      {
        name: 'anyOneInjured', 
        value: 'yes',
      },
    ],
    keyPathToSaveValue: 'claim.eventDetails.hasInjuredPartiesContact',
    yup: Yup.mixed().when('manyInjured', {
      is: value => value > EMPTY_VALUE.NUMBER,
      then: schema => schema.required('Select one').nullable(),
    }),
  },
  { 
    name: 'injuredRule',
    initialValue: false,
    type: FIELD_TYPE.EMPTY,
  },
];