import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PolicyInfoComponent from './PolicyInfoComponent';
import PolicyLossTsComponent from './PolicyLossTsComponent';
import PolicySearchDialog from '../dialogs/PolicySearchDialog';

import { Formik } from 'formik';
import { policyLossTsSchema } from '../../validation/claimsSchemas';
import { sendEventLogRequest } from '../../actions/eventActions';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import {
  getPolicySummary,
  getLegacyPolicy,
  getGWPolicy,
} from '../../actions/policyActions';
import { showLoading } from '../../actions/loading';
import { updateClaim, getClaimsSubmission } from '../../actions/claimActions';
import { deleteAttachment } from '../../actions/attachments';
import { CompanyNumbers } from '../../actions/types';
import {
  updateCompletedSteps,
  updateStepContentIndex,
} from '../../actions/stepperActions';
import { GenericDialog } from '../dialogs/GenericDialog';
import { globalClasses } from '../ui/customClasses';
import { useTheme } from '@material-ui/styles';

const PolicyComponent = ({
  claim,
  policy,
  loading,
  handleNext,
  getPolicySummary,
  getLegacyPolicy,
  getGWPolicy,
  updateClaim,
  showLoading,
}) => {
  const { breakpoints } = useTheme();
  const tabletBreakpoint = breakpoints.up(600);

  const useStyles = makeStyles((theme) => ({
    backButton: {
      marginRight: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      [tabletBreakpoint]: {
        justifyContent: 'flex-end',
      },
      paddingLeft: 0,
      paddingRight: 0,
    },
    btn: {
      width: '200px'
    },
  }));
  const globalCSS = globalClasses();
  const history = useHistory();
  const { logId } = claim;
  const { isLoading } = loading;
  const [policySearchDetails, setPolicySearchDetails] = useState({
    policyNumber: claim.policyNumber,
    lossDate: claim.lossDate,
  });
  const [validatedPolicies, setValidatedPolicies] = useState([]);
  const [showPolicySearchDialog, setShowPolicySearchDialog] = useState(false);
  const [showPolicySearchLoading, setShowPolicySearchLoading] = useState(false);
  const [showNoPolicyNumberDialog, setShowNoPolicyNumberDialog] = useState(
    false
  );

  const [showPolicyNotFound,setShowPolicyNotFound] = useState(false);
  const [policyDetails,setPolicyDetails] = useState({});

  useEffect(() => {
    var event = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Policy Search/Verification'}",
    };
    sendEventLogRequest(event);
  }, []);

  const handleClosePolicySearchDialog = () => {
    setShowPolicySearchDialog(false);
    showLoading(false);
  };

  const handleCloseNoPolicyNumberDialog = () => {
    setShowNoPolicyNumberDialog(false);
  };

  // Gets called when No Policy Number dialog appears and user selects 'Continue'
  const handleNoPolicyNumberDialogSubmit = () => {
    const newClaim = { ...claim, lossDate: policySearchDetails.lossDate };
    sessionStorage.setItem('notValidated', true);
    setShowNoPolicyNumberDialog(false);
    handleNext();
    localStorage.setItem('continueClaimId', claim.logId);
    updateClaim({ ...newClaim });
  };

  const addValidatedPolicy = (policyNumber) => {
    setValidatedPolicies((policies) => [...policies, policyNumber]);
  };

  const handlePolicyValidation = async ({ firstName, lastName, zipcode }) => {
    setShowPolicySearchLoading(true);

    const {
      policyNumber,
      companyNumber = null,
      lossDate,
    } = policySearchDetails;

    try {
      if (
        companyNumber &&
        !CompanyNumbers.some((element) => element === companyNumber)
      ) {
        throw 'Invalid Company Number ...';
      }

      var res = await getPolicySummary(
        policyNumber,
        firstName,
        lastName,
        zipcode
      );

      if (companyNumber && companyNumber !== res.data.company.number) {
        throw 'Company number mismatch ...';
      }

      setShowPolicySearchLoading(false);
      setShowPolicySearchDialog(false);
      showLoading(false);

      const summary = res.data;
      if (validatedPolicies.includes(policyNumber)) {
        validatedPolicies.map((p) => {
          if (p.policyNumber === summary.policyNumber) {
            return summary;
          }
          return p;
        });
      } else {
        setValidatedPolicies((policies) => [
          ...policies,
          { ...summary, lossDate },
        ]);
      }

      await retrievePolicy(summary, lossDate);
    } catch (error) {
      console.error("handlePolicyValidation error ",error);
      setShowPolicyNotFound(true);
      setPolicyDetails({
          policyNumber,
          lossDate,
          firstName,
          lastName,
          zipcode,
        });
    } finally {
      setShowPolicySearchLoading(false);
      setShowPolicySearchDialog(false);
      showLoading(false);
    }
  };

  const retrievePolicy = async (
    policySummary,
    lossDate,
    isNewClaim = true
  ) => {
    showLoading(true);
    sessionStorage.setItem('notValidated', false);
    
    const {
      agent: {contactHolderId},
      policyNumber,
      targetSystem,
      lob: { productCode },
      company: { number },
      policyRetrieveToken: { access_token },
    } = policySummary;

    var res;
    try {
      if (targetSystem === 'IC' || targetSystem === 'HUON') {
        res = await getLegacyPolicy(
          policyNumber,
          lossDate,
          contactHolderId,
          number,
          productCode,
          targetSystem,
          access_token,
          isNewClaim
        );
      } else {
        res = await getGWPolicy(
          policyNumber,
          lossDate,
          productCode,
          access_token,
          isNewClaim
        );
      }
      showLoading(false);
      return res;
    } catch (error) {
      throw error;
    }
  };

  const handleAuthenticatedUser = () => {
    handleNext();
    //localStorage.setItem('continueClaimId', claim.logId);
    const claimCopy = { ...claim };
    //claimCopy.reportingParty.reporter = '';
    updateClaim({ ...claimCopy });
  };

  const continueAsGuest = async (policyDetails) => {
    const {
      policyNumber,
      firstName,
      lastName,
      postalCode,
      lossDate,
    } = policyDetails;
    
    sessionStorage.setItem('notValidated', true);
    localStorage.setItem('continueClaimId', claim.logId);
    updateClaim({
      ...claim,
      policyNumber,
      lossDate,
      reportingParty: {
        ...claim.reportingParty,
        firstName,
        lastName,
        address: { ...claim.reportingParty.address, postalCode },
      }
    });
  };

  // Retrieves the policy, shows the policy search or continue as guest dialog
  const onSubmit = async (values, formikBag) => {
    const policyNumber = values.policyNumber.length > 10 ? values.policyNumber.slice(3) : values.policyNumber;
    const companyNumber = values.policyNumber.length > 10 ? values.policyNumber.slice(0, 3) : null;
    const lossDateTime = moment(values.lossDate).format();

    
    setPolicySearchDetails({
      policyNumber,
      companyNumber,
      lossDate: lossDateTime,
    });

    formikBag.setFieldValue('policyNumber', policyNumber);

    if (values.policyNumber !== '') {
      const summary = validatedPolicies.find(
        (element) => element.policyNumber === values.policyNumber
      );

      if (!summary) {
        setShowPolicySearchDialog(true);
      }

      if (summary && summary.lossDate !== lossDateTime) {
        retrievePolicy(summary, lossDateTime);
      }
    } else {
      setShowNoPolicyNumberDialog(true);
    }
  };

  const classes = useStyles();

  const handlePolicyNotFoundClose = () => {
    setShowPolicyNotFound(false);
  };

  const handlePolicyNotFoundSubmit = () => {
    handleNext();
    setShowPolicyNotFound(false);
    continueAsGuest(policyDetails);
    history.push("/claims");
  };

  return (
    <>
      <Formik
        initialValues={{
          lossDate: claim.lossDate,
          policyNumber: claim.policyNumber,
          requiresValidation: true,
          noPolicyNumber: claim.noPolicyNumber,
        }}
        validationSchema={policyLossTsSchema}
        onSubmit={onSubmit}>
        <>
          <PolicyLossTsComponent
            isLoading={isLoading}
            handleNext={handleNext}
            addValidatedPolicy={addValidatedPolicy}
          />
          <Dialog
            maxWidth='xs'
            open={showNoPolicyNumberDialog}
            onClose={handleCloseNoPolicyNumberDialog}>
            <DialogTitle id='form-dialog-title'>
              Policy Number Check
            </DialogTitle>
            <DialogContent>
              <Box pb={3}>
                <Grid container spacing={2} direction='column'>
                  <Grid item xs>
                    <Box pb={3}>
                      <Typography gutterBottom>
                        Are you sure you wish to continue without the policy
                        number? We can still process your claim without one, but
                        providing a policy number will help expedite your
                        request.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>

            <Box p={1}>
              <DialogActions>
                <Button className={globalCSS.AMIGBigButton} onClick={handleCloseNoPolicyNumberDialog}>
                  CANCEL
                </Button>
                <Button
                  onClick={handleNoPolicyNumberDialogSubmit}
                  className={globalCSS.AMIGBigButton}
                  to='/claims'
                  component={Link}
                  variant='contained'
                  color='primary'>
                  Continue
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
            <GenericDialog 
              showDialog={showPolicyNotFound} 
              title="Policy Not Found"
              maxWidth='xs'
              handleClose={handlePolicyNotFoundClose}
              handleSubmit={handlePolicyNotFoundSubmit} >
                <Box pb={3}>
                  <Grid container spacing={2} direction='column'>
                    <Grid item xs>
                      <Box pb={3}>
                        <Typography gutterBottom>
                        We are sorry but we were unable to locate the 
                        policy with that information.
                        We can still process your claim without one, 
                        we will just need a little more information.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
            </GenericDialog>
        </>
      </Formik>

      {policy.policyNumber !== '' && !isLoading && <PolicyInfoComponent handleContinue={handleAuthenticatedUser} />}
      <Box p={5} className={classes.buttons}>
        {policy.policyNumber === '' && claim.policyNumber === '' && (
          <Button
            className={globalCSS.AMIGBigButton} 
            form='policyLoss'
            type='submit'
            variant='contained'
            color='primary'
            disabled={isLoading}>
            Continue
          </Button>
        )}
        {policy.policyNumber !== '' && (
          <>
            <Button
              className={[classes.backButton, globalCSS.AMIGBigButton]}
              form='policyLoss'
              type='submit'
              disabled={isLoading}>
              Resubmit
            </Button>
            <Button
              variant='contained'
              color='primary'
              className={[classes.btn, globalCSS.AMIGBigButton]}
              onClick={handleAuthenticatedUser}>
              Continue
            </Button>
          </>
        )}
        {policy.policyNumber === '' && claim.policyNumber !== '' && (
          <Button
            variant='contained'
            color='primary'
            className={[classes.btn, globalCSS.AMIGBigButton]}
            onClick={() => {
              handleNext();
              localStorage.setItem('continueClaimId', claim.logId);
              updateClaim({ ...claim });
            }}>
            Continue
          </Button>
        )}
      </Box>
      <PolicySearchDialog
        contentText='Please provide the following details to validate your account:'
        errorText='There was a problem validating your policy. Please try again. If the problem persists, call 1-800-375-2075.'
        showDialog={showPolicySearchDialog}
        handleClose={handleClosePolicySearchDialog}
        handleOnSubmit={handlePolicyValidation}
        showLoading={showPolicySearchLoading}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  claim: state.claim,
  policy: state.policy,
  loading: state.loading,
});

PolicyComponent.propTypes = {
  loading: PropTypes.object.isRequired,
  policy: PropTypes.object.isRequired,
  getPolicySummary: PropTypes.func.isRequired,
  getLegacyPolicy: PropTypes.func.isRequired,
  getGWPolicy: PropTypes.func.isRequired,
  showLoading: PropTypes.func.isRequired,
  updateClaim: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  getClaimsSubmission: PropTypes.func.isRequired,
  updateCompletedSteps: PropTypes.func.isRequired,
  updateStepContentIndex: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getPolicySummary,
  getLegacyPolicy,
  getGWPolicy,
  showLoading,
  updateClaim,
  getClaimsSubmission,
  deleteAttachment,
  updateCompletedSteps,
  updateStepContentIndex,
})(PolicyComponent);
