import React, { Fragment, useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  navButtons: {
    textAlign: 'center',
  },
}));

const PDFViewer = ({ src }) => {
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js`;
  }, []);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = (props) => {
    setNumPages(props.numPages);
  };

  const classes = useStyles();

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid container item xs={4} justify='center'>
          <IconButton
            onClick={() => setPageNumber(pageNumber - 1)}
            disabled={pageNumber === 1}>
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>
        <Grid container item xs={4} justify='center'>
          <Typography>
            {pageNumber}/{numPages}
          </Typography>
        </Grid>
        <Grid container item xs={4} justify='center'>
          <IconButton
            className={classes.navButtons}
            onClick={() => setPageNumber(pageNumber + 1)}
            disabled={pageNumber === numPages}>
            <NavigateNextIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Document file={src} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
    </Fragment>
  );
};

export default PDFViewer;
