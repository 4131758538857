import {
  EMPTY_VALUE,
  PLACEHOLDER_SELECT_VALUE,
  MAX_LENGTH,
  DEPENDENCY_OPTIONS,
  causeOfDamage,
  weather,
  vandalism,
  water,
  FIELD_TYPE,
} from '../../../../../actions/types';
import * as Yup from 'yup';

export const caused = [
  {
    label: 'Please pick the best that applies',
    name: 'cause',
    initialValue: PLACEHOLDER_SELECT_VALUE,
    placeholder: 'Cause of damage',
    type: FIELD_TYPE.SELECT,
    InputProps: { style: { marginTop: '32px' } },
    options: causeOfDamage,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: { name: 'ruleDamaged', value: false },
    keyPathToSaveValue: 'claim.lossCause',
    yup: Yup.string().when('ruleDamaged', {
      is: false,
      then: Yup.string().test(
        'causeTest',
        'Provide a value',
        (value) => value !== PLACEHOLDER_SELECT_VALUE
      ),
    }),
  },
  {
    name: 'source',
    initialValue: PLACEHOLDER_SELECT_VALUE,
    placeholder: 'Source of damage',
    type: FIELD_TYPE.SELECT,
    toHide: true,
    dependencyFieldToShow: {
      name: 'cause',
      value: [
        PLACEHOLDER_SELECT_VALUE,
        DEPENDENCY_OPTIONS.FIRE,
        DEPENDENCY_OPTIONS.OTHER,
      ],
    },
    dependencyField: 'cause',
    options: (value) => {
      return (
        (value === DEPENDENCY_OPTIONS.WEATHER && weather) ||
        (value === DEPENDENCY_OPTIONS.VANDALISM && vandalism) ||
        (value === DEPENDENCY_OPTIONS.WATER && water) ||
        []
      );
    },
    isOneColumn: true,
    keyPathToSaveValue: 'claim.lossCause',
    yup: Yup.string().when('cause', {
      is: (value) =>
        value === DEPENDENCY_OPTIONS.WEATHER ||
        value === DEPENDENCY_OPTIONS.VANDALISM ||
        value === DEPENDENCY_OPTIONS.WATER,
      then: Yup.string().test(
        'sourceTest',
        'Provide a value',
        (value) => value !== PLACEHOLDER_SELECT_VALUE
      ),
    }),
  },
  {
    name: 'detail',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Tell us what happened',
    placeholder: 'Describe property damage',
    type: FIELD_TYPE.TEXT_AREA,
    isCountCharacters: true,
    inputProps: { maxLength: MAX_LENGTH },
    keyPathToSaveValue: 'claim.description',
    yup: Yup.string().required('Provide a value'),
  },
  {
    name: 'policeOrFireQuestion',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Were the police or fire department involved?',
    type: FIELD_TYPE.YES_NO,
    keyPathToSaveValue: 'claim.eventDetails.policeOrFireQuestion',
    yup: Yup.boolean().required('Select one').nullable(),
  },
  {
    name: 'departmentName',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Department name',
    type: FIELD_TYPE.TEXT,
    toHide: true,
    dependencyFieldToShow: { name: 'policeOrFireQuestion', value: true },
    keyPathToSaveValue: 'claim.officials[0].reportingAgency',
    yup: Yup.string().when('policeOrFireQuestion', {
      is: true,
      then: (schema) => schema.required('Provide a value'),
    }),
  },
  {
    name: 'ruleDamaged',
    initialValue: false,
    type: FIELD_TYPE.EMPTY,
    keyPath: 'damaged.ruleDamaged',
  },
];
