import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import {
  faClipboardList,
  faHouseDamage,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { sendEventLogRequest } from '../../actions/eventActions';
import PropTypes from 'prop-types';
import { PolicyTypes, CustomReferrers } from '../../actions/types';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import { updateClaim } from '../../actions/claimActions';
import moment from 'moment';

const homeLogo = require('../../img/2-Story2_Circle_Purple.png');
const autoLogo = require('../../img/Collector_Car_Circle_Green.png');
const rentersLogo = require('../../img/Apartment_Circle_Blue.png');

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  cardMedia: {
    margin: '1rem 0',
    width: '40%',
  },
  cardActions: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-end',
  },
  btn: {
    'color': '#fff',
    'backgroundColor': '#007bff',
    'borderColor': '#007bff',
    'marginBottom': '1rem',
    '&:hover': {
      color: '#fff',
      background: '#0056b3',
    },
  },
  listItem: {
    padding: '0.25rem 1.25rem',
  },
  verticalTimeLine: {
    maxWidth: 'unset',
  },
});

const ClaimsLandingPage = ({ claim, updateClaim }) => {
  const { deviceType } = getDeviceTypeInfo();
  const isNotValidated = sessionStorage.getItem('notValidated');
  const qs = useLocation().search;

  
  // handle the tracking of the claims button tracking
  const handleClick = (type) => {
    
    const policyType = type.toLowerCase();
    let logId = uuidv4();

    // Check local storage for a session that matches the type
    // const sessions = JSON.parse(localStorage.getItem('sessions')) ?? [];
    // const sessionIndex = sessions.findIndex(s => s.type === policyType);
    // if (sessionIndex === -1) {

    //   // create session and store it in local storage
    //   sessions.push({
    //     id: logId,
    //     type: policyType,
    //     expires: moment().add(12, 'hours'),
    //     referrer: 'direct'
    //   });
    //   localStorage.setItem('sessions', JSON.stringify(sessions));
    // } else {
    //   // if a session matching the type already exists, then use its logId
    //   logId = sessions[sessionIndex].id;
    // }

    const clonedClaim = { ...claim, logId, type: policyType };
    localStorage.setItem('policyType', type.toLowerCase());
    updateClaim({ ...clonedClaim }, false);    
    var event = {
      logId,
      event: 'referrer',
      eventBody: "{'referrer':'direct'}",
    };

    if (qs) {
      const location = qs.split('?')[1].split('=')[1];

      CustomReferrers.forEach((cr) => {
        if (location === cr.ref) {
          event.eventBody = "{'referrer':'" + cr.display + "'}";
        }
      });
    }
    
    var event2 = {
      logId,
      event: 'deviceType',
      eventBody: `{'deviceType':'${deviceType}'}`,
    };

    var event3 = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'" + type + "'}",
    };
    
    sendEventLogRequest(event);
    sendEventLogRequest(event2);
    sendEventLogRequest(event3);
  };

  

  const classes = useStyles();

  const notValidated = sessionStorage.getItem('notValidated');

  return notValidated ? (
    <Box my={5}>
      <Grid container spacing={2} justify='center'>
        <Grid
          container
          item
          spacing={2}
          style={{ background: '#ededed', padding: '1.5rem' }}>
          <Grid item xs={12}>
            <Typography variant='h1' align='center' color='primary' paragraph>
              What type of policy do you have?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                component='img'
                image={rentersLogo}
                alt='Renters Banner'
              />
              <CardContent className={classes.cardContent}>
                <Typography variant='h5' align='center' gutterBottom>
                  Renters
                </Typography>
                <Typography variant='body1' align='center'>
                  If you{' '}
                  <b>
                    <i>rent or lease your residence</i>
                  </b>{' '}
                  and have experienced a loss, click here to start your renters
                  claim.
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button
                  component={Link}
                  to={isNotValidated ? '/policy' : '/claim-welcome'}
                  variant='contained'
                  color='primary'
                  className={classes.btn}
                  onClick={() => handleClick(isNotValidated ? PolicyTypes.NOT_AUTHENTICATED_RENTERS : PolicyTypes.RENTERS)}>
                  Start a Claim
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                component='img'
                image={autoLogo}
                alt='Recreational Banner'
              />
              <CardContent className={classes.cardContent}>
                <Typography variant='h5' align='center' gutterBottom>
                  Recreational
                </Typography>
                <Typography variant='body1' align='center'>
                  Has your{' '}
                  <b>
                    <i>
                      collector vehicle, watercraft, or other motorized vehicle
                    </i>
                  </b>{' '}
                  been damaged or involved in an accident? Click here to start
                  your recreational claim.
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button
                  data-test-id='start-a-recreational-claim'
                  onClick={() => handleClick(isNotValidated ? PolicyTypes.NOT_AUTHENTICATED_RECREATIONAL : PolicyTypes.RECREATIONAL)}
                  component={Link}
                  to={isNotValidated ? '/policy' : '/claim-welcome'}
                  variant='contained'
                  color='primary'
                  className={classes.btn}>
                  Start a Claim
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                component='img'
                image={homeLogo}
                alt='Residential Banner'
              />
              <CardContent className={classes.cardContent}>
                <Typography variant='h5' align='center' gutterBottom>
                  Residential
                </Typography>
                <Typography variant='body1' align='center'>
                  Have you experienced a loss at{' '}
                  <b>
                    <i>a home or property you own</i>
                  </b>
                  ? Click here to start your residential claim.
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button
                  onClick={() => handleClick(isNotValidated ? PolicyTypes.NOT_AUTHENTICATED_RESIDENTIAL : PolicyTypes.RESIDENTIAL)}
                  component={Link}
                  to={isNotValidated ? '/policy' : '/claim-welcome'}
                  variant='contained'
                  color='primary'
                  className={classes.btn}>
                  Start a Claim
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box my={3}>
            <Typography
              align='center'
              variant='h1'
              color='primary'
              gutterBottom>
              Understanding the claims process
            </Typography>
            <Divider style={{ marginBottom: '1rem' }} />
          </Box>
        </Grid>

        <Box mt={2}>
          <Grid item xs={12} style={{ background: '#ededed' }}>
            <VerticalTimeline className={classes.verticalTimeLine}>
              <VerticalTimelineElement
                contentStyle={{ borderTop: 'solid #0095c8' }}
                iconStyle={{ background: '#0095c8', color: '#fff' }}
                icon={
                  <FontAwesomeIcon
                    icon={faClipboardList}
                    style={{ width: '0.3em' }}
                    size='5x'
                  />
                }>
                <Typography variant='h5'>Notify us of your loss</Typography>
                <Divider />
                <Box pt={2}>
                  <Typography variant={'body2'}>
                    Complete the online claims process or if you{' '}
                    <b>need help, call us at 1-800-375-2075.</b> Please have
                    this information ready:
                  </Typography>
                  <List dense={true}>
                    <ListItem className={classes.listItem}>
                      <ListItemText>Your Policy Number</ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.listItem}>
                      <ListItemText>Your Contact Information</ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.listItem}>
                      <ListItemText>
                        Date and approximate time of the loss
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.listItem}>
                      <ListItemText>
                        A description of what was damaged/lost, or who may have
                        been hurt
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.listItem}>
                      <ListItemText>
                        Any police or fire department report (if available) and
                        other documentation
                      </ListItemText>
                    </ListItem>
                    <Divider />
                    <ListItem className={classes.listItem}>
                      <ListItemText>
                        Your opinion on the need for temporary repairs or
                        temporary living arrangements
                      </ListItemText>
                    </ListItem>
                  </List>
                </Box>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className='vertical-timeline-element--work'
                contentStyle={{ borderTop: 'solid #5faa45' }}
                iconStyle={{ background: '#5faa45', color: '#fff' }}
                icon={
                  <FontAwesomeIcon
                    icon={faHouseDamage}
                    style={{ width: '0.3em' }}
                    size='5x'
                  />
                }>
                <Typography variant='h5'>
                  A claims adjuster works with you
                </Typography>
                <Divider />
                <Box py={2}>
                  <Typography variant={'body2'} gutterBottom>
                    One of our adjusters will contact you to assess the damage
                    and confirm your coverage. This may be by email or text,
                    over the phone, with an on-site visit, or through our new
                    remote access app (watch the video). The adjuster will be
                    your main contact until the claim is closed.
                  </Typography>
                </Box>
                <iframe
                  title='American Modern Insurance Claims Technology'
                  width='100%'
                  height='100%'
                  src='https://www.youtube.com/embed/GiHLx0flEs8'
                  frameBorder='0'
                  allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen></iframe>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                contentStyle={{ borderTop: 'solid #330072' }}
                iconStyle={{ background: '#330072', color: '#fff' }}
                icon={
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    style={{ width: '0.3em' }}
                    size='5x'
                  />
                }>
                <Typography variant='h5'>We settle your claim</Typography>
                <Divider />
                <Box py={2}>
                  <Typography variant={'body2'} gutterBottom>
                    Following the coverage terms of your policy, a repair or
                    replacement estimate for a covered loss will be created and
                    shared with you. Your adjuster will discuss how the claim
                    will be paid, and what actions we will take and what you can
                    do yourself.
                  </Typography>
                </Box>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </Grid>
        </Box>
      </Grid>
    </Box>
  ) : (<Redirect to='/' />)
};

const mapStateToProps = (state) => ({
  claim: state.claim,
});

ClaimsLandingPage.propTypes = {
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updateClaim })(ClaimsLandingPage);
