import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useFormikContext } from 'formik';

const AddressValidationDialog = ({
  showDialog,
  handleClose,
  handleOnSubmit,
  title,
  contentText,
  promptAddress,
  claim,
}) => {
  const { setFieldValue } = useFormikContext();

  const useStyles = makeStyles((theme) => ({
    errorMessage: {
      fontSize: '1.25rem',
      color: 'red',
    },
  }));
  const classes = useStyles();

  const handleSubmit = () => {
    if (promptAddress !== ' ') {
      setFieldValue('addressLine1', promptAddress.addressLine1);
      setFieldValue('addressLine2', promptAddress.addressLine2);
      setFieldValue('city', promptAddress.city);
      setFieldValue('state', promptAddress.state);
      setFieldValue('postalCode', promptAddress.postalCode);
    }
    handleOnSubmit();
  };

  return (
    <Dialog maxWidth='xs' open={showDialog} onClose={handleClose}>
      <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        <Box pb={3}>
          <Grid container spacing={2} direction='column'>
            <Grid item xs>
              {promptAddress !== ' ' ? (
                <>
                  <Box pb={3}>
                    <Typography gutterBottom>
                      Your address entry didn't match our records. Does the
                      following address work:
                    </Typography>
                  </Box>
                  <Typography>
                    {promptAddress.addressLine1},{' '}
                    {promptAddress.addressLine2 !== ''
                      ? promptAddress.addressLine2 + ', '
                      : null}
                    {promptAddress.city}, {promptAddress.state},{' '}
                    {promptAddress.postalCode}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography className={classes.errorMessage}>
                    The address provided is invalid. Please verify the address
                    and resubmit.
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <Box p={1}>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          <Button onClick={handleSubmit} variant='contained' color='primary'>
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddressValidationDialog;
