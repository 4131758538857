import React from 'react';
import { useField } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker } from '@material-ui/pickers';
import FormHelperText from '@material-ui/core/FormHelperText';

const FormikDatePicker = ({ ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DatePicker
        error={meta.touched && meta.error !== undefined}
        fullWidth
        {...field}
        {...props}
      />
    </MuiPickersUtilsProvider>
    {meta.touched && meta.error && (
        <FormHelperText style={{ color: '#f44336' }}>
          {meta.error}
        </FormHelperText>
      )}
    </>
    )
}

export default FormikDatePicker
