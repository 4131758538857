import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { otherSchema } from '../../validation/claimsSchemas';
import { TextField } from 'formik-material-ui';
import { sendEventLogRequest } from '../../actions/eventActions';
import { updateClaim } from '../../actions/claimActions';
import { getContainerOffset } from '../../actions/layoutActions';
import PropTypes from 'prop-types';
import ComponentToggle from '../common/ComponentToggle';
import { states } from '../../actions/types';
import { ClaimTypes } from '../../actions/types';
import OffsetGrid from '../layout/OffsetGrid';
import AddressValidationDialog from '../dialogs/AddressValidationDialog';
import { validateAddress } from '../../actions/policyActions';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import ErrorFocus from '../common/ErrorFocus';
import appSettings from '../../appSettings';
import AddressComponent from '../common/AddressComponent';
import FormikPhoneField from '../common/FormikPhoneField';

const OtherComponent = ({
  claim,
  policy,
  activeStep,
  handleNext,
  handleBack,
  updateClaim,
  validateAddress,
}) => {
  const { logId } = claim;
  const { deviceType } = getDeviceTypeInfo();
  const VALIDATE_ADDRESS = appSettings.VALIDATE_ADDRESSES === 'true';

  const initialValues = {
    type: ClaimTypes.OTHER,
    incidentDesc: claim.lossDetails.incidents[0].incidentDesc,
    injuredPerson: {
      isHospitalized:
        claim.lossDetails.incidents[0].injuredPerson.isHospitalized,
      isPolicyholder:
        claim.lossDetails.incidents[0].injuredPerson.isPolicyholder,
      name: claim.lossDetails.incidents[0].injuredPerson.name,
      phone: claim.lossDetails.incidents[0].injuredPerson.phone,
      email: claim.lossDetails.incidents[0].injuredPerson.email,
      addressLine1: claim.lossDetails.incidents[0].injuredPerson.addressLine1,
      addressLine2: claim.lossDetails.incidents[0].injuredPerson.addressLine2,
      city: claim.lossDetails.incidents[0].injuredPerson.city,
      state: claim.lossDetails.incidents[0].injuredPerson.state,
      postalCode: claim.lossDetails.incidents[0].injuredPerson.postalCode,
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    var event = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Other Incident'}",
    };

    sendEventLogRequest(event);
  }, []);

  const useStyles = makeStyles((theme) => ({
    card: {
      height: '100%',
      padding: '1rem',
    },
    cardTitle: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    cardActions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cardMedia: {
      width: '13%',
      alignSelf: 'center',
    },
    btn: {
      color: '#fff',
      backgroundColor: '#007bff',
      borderColor: '#007bff',
      '&:hover': {
        color: '#fff',
        background: '#0056b3',
      },
    },
    input: {
      backgroundColor: '#f5f5f7',
    },
    inputSelectLabel: {
      marginLeft: '1rem',
      '&:mui-focused={true}': {
        marginTop: '0.5rem',
      },
    },
    selectOption: {
      padding: '0.25rem',
      marginLeft: '0.25rem',
    },
    validationMessage: {
      fontSize: '0.75rem',
      color: '#f44336',
      marginTop: '3px',
    },
  }));

  const classes = useStyles();

  const [showAddressValidationDialog, setShowAddressValidationDialog] =
    useState(false);

  const [promptAddress, setPromptAddress] = useState(null);

  const handleCloseAddressValidationDialog = () => {
    setPromptAddress(null);
    setShowAddressValidationDialog(false);
  };

  const handleSubmit = async (values) => {
    let valRes = { isValid: true };
    if (values) {
      {
        VALIDATE_ADDRESS &&
          (valRes = await validateAddress(values.injuredPerson));
      }
      if (!valRes.isValid) {
        setPromptAddress(valRes.address === null ? ' ' : valRes.address);
        setShowAddressValidationDialog(true);
      } else {
        const newClaim = { ...claim };
        newClaim.lossDetails.incidents[0] = values;
        updateClaim({ ...newClaim });
        handleNext();
      }
    }
  };

  const InjuryExposure = (deviceType) => {
    const { errors, touched, handleBlur, setFieldValue, values } =
      useFormikContext();

    return (
      <Box pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Field
              label='Name of Injured Party'
              name='injuredPerson.name'
              component={TextField}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box pr={1}>
              <Field
                label='Email'
                name='injuredPerson.email'
                component={TextField}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormikPhoneField name='injuredPerson.phone' disabled={false} />
          </Grid>
          <AddressComponent prefix='injuredPerson' />
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <OffsetGrid offset={`${getContainerOffset(deviceType)}`}>
        <Typography variant='h1' align='center' color='primary'>
          {/* Injury Details */}
          Tell us what happened
        </Typography>
        <Typography variant={'subtitle1'} align={'center'}>
          Please provide a description of the loss and how it happened. (ex.
          unemployment, liability, etc.) Indicate if anyone was injured, and if
          so, please provide contact information for the affected person.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={otherSchema}
          onSubmit={handleSubmit}>
          {({ values, errors, touched, setFieldValue }) => (
            <div className={classes.form}>
              <Form id='injury-form'>
                <Box my={2}>
                  <Field
                    name='incidentDesc'
                    component={TextField}
                    placeholder='Please provide a description your the loss'
                    variant='outlined'
                    multiline
                    rows={4}
                    fullWidth
                  />
                </Box>

                <Field
                  name='injuredPerson.isHospitalized'
                  component={ComponentToggle}
                  title='Was anyone injured?'
                  displayOnSelected={true}
                  handleButtonChange={(value) => {
                    setFieldValue('injuredPerson.isHospitalized', value);
                    !value &&
                      setFieldValue('injuredPerson.isPolicyholder', null);
                  }}
                />

                {values.injuredPerson.isHospitalized && (
                  <Field
                    name='injuredPerson.isPolicyholder'
                    component={ComponentToggle}
                    title='Was the injured person the policyholder?'
                    displayOnSelected={false}
                    handleButtonChange={(value) =>
                      setFieldValue('injuredPerson.isPolicyholder', value)
                    }>
                    <InjuryExposure />
                  </Field>
                )}
                <ErrorFocus />
              </Form>
              {promptAddress && (
                <AddressValidationDialog
                  showDialog={showAddressValidationDialog}
                  handleClose={handleCloseAddressValidationDialog}
                  handleOnSubmit={() => {
                    if (promptAddress !== ' ') {
                      const newClaim = { ...claim };
                      newClaim.lossDetails.incidents[0].injuredPerson.addressLine1 =
                        promptAddress.addressLine1;
                      newClaim.lossDetails.incidents[0].injuredPerson.addressLine2 =
                        promptAddress.addressLine2;
                      newClaim.lossDetails.incidents[0].injuredPerson.city =
                        promptAddress.city;
                      newClaim.lossDetails.incidents[0].injuredPerson.state =
                        promptAddress.state;
                      newClaim.lossDetails.incidents[0].injuredPerson.postalCode =
                        promptAddress.postalCode;
                      updateClaim({ ...newClaim });
                      setFieldValue(
                        'injuredPerson.addressLine1',
                        promptAddress.addressLine1
                      );
                      setFieldValue(
                        'injuredPerson.addressLine2',
                        promptAddress.addressLine2
                      );
                      setFieldValue('injuredPerson.city', promptAddress.city);
                      setFieldValue('injuredPerson.state', promptAddress.state);
                      setFieldValue(
                        'injuredPerson.postalCode',
                        promptAddress.postalCode
                      );
                    }
                    setPromptAddress(null);
                    setShowAddressValidationDialog(false);
                  }}
                  title='Validate Loss Location Address'
                  promptAddress={promptAddress}
                />
              )}
            </div>
          )}
        </Formik>
      </OffsetGrid>

      <Box p={5} style={{ textAlign: 'center' }}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.backButton}>
          Back
        </Button>
        <Button
          type='submit'
          form='injury-form'
          variant='contained'
          color='primary'>
          Next
        </Button>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
  policy: state.policy,
});

OtherComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { validateAddress, updateClaim })(
  OtherComponent
);
