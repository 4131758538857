import { EMPTY_VALUE, FIELD_TYPE, phoneRegExp, states, zipCodeExp, IncidentOwnerTemplate } from "../../../../../actions/types";
import * as Yup from 'yup';

export const damage = [
    { 
      label: 'Belongings',
      name: 'BELONGINGS', 
      initialValue: EMPTY_VALUE.CHECKBOX, 
      type: FIELD_TYPE.CHECKBOX,
      tooltip: 'Instances where a building or structure was not damaged, just the personal items, things you own.', 
      yup: Yup.boolean(),
    },
    { 
      label: 'Damage to apartment',
      name: 'DAMAGE_TO_APT', 
      initialValue: EMPTY_VALUE.CHECKBOX, 
      type: FIELD_TYPE.CHECKBOX,
      tooltip: 'Insured is being held responsible for damages caused to the apartment unit.',

      yup: Yup.boolean(),
    },
    { 
      label: 'Unemployment',
      name: 'UNEMPLOYMENT', 
      initialValue: EMPTY_VALUE.CHECKBOX, 
      type: FIELD_TYPE.CHECKBOX,
      tooltip: 'Insured has experienced job loss.',  
      yup: Yup.boolean(),
    },
    { 
      label: 'Someone was injured', 
      name: 'SOMEONE_INJURED', 
      initialValue: EMPTY_VALUE.CHECKBOX, 
      type: FIELD_TYPE.CHECKBOX,
      tooltip: 'No damage to the property itself, a claim needs to be filed for an injury.', 
      keyPathToSaveValue: 'claim.eventDetails.isInjuredParties',
      yup: Yup.boolean(),
    },
    { 
      label: 'No damage/ my power was out or I had to evacuate', 
      name: 'NO_DAMAGE', 
      initialValue: EMPTY_VALUE.CHECKBOX,
      type: FIELD_TYPE.CHECKBOX,
      tooltip: 'If there is no physical damage to the home, but you need to submit a claim for additional living expenses because of evacuation, loss of food from power outage or other related event.', 
      yup: Yup.boolean(),
    },
    {
      name: 'information',
      initialValue: EMPTY_VALUE.POS_NEG,
      label: 'Do you have the contact information for the owner?',
      type: FIELD_TYPE.YES_NO,
      toHide: true,
      isOneColumn: true,
      dependencyFieldToShow: { name: 'DAMAGE_TO_APT', value: true },
      keyPathToSaveValue: 'claim.eventDetails.hasOwnerContact',
      yup: Yup.mixed().when('DAMAGE_TO_APT', {
        is: true,
        then: schema => schema.required('Select one').nullable(),
      }),
    },
    {
      name: 'firstName',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'First Name',
      type: FIELD_TYPE.TEXT,
      col: 4,
      toHide: true,
      dependencyFieldToShow: [{name: 'DAMAGE_TO_APT', value: true},{name: 'information', value: true}],
      keyPathToSaveValue: {
        dependencyFieldName: null,
        templateObj: IncidentOwnerTemplate,
        arrayPathName: 'claim.contacts',
        elementPathName: 'firstName',
      },
      yup: Yup.string().when('information', {
        is: true,
        then: schema => schema.required('Provide a first name').min(2, 'Too Short!').max(50, 'Too Long!'),
      }),
    },
    {
      name: 'lastName',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'Last Name',
      type: FIELD_TYPE.TEXT,
      col: 4,
      toHide: true,
      dependencyFieldToShow: [{name: 'DAMAGE_TO_APT', value: true},{name: 'information', value: true}],
      keyPathToSaveValue: {
        dependencyFieldName: null,
        templateObj: IncidentOwnerTemplate,
        arrayPathName: 'claim.contacts',
        elementPathName: 'lastName',
      },
      yup: Yup.string().when('information', {
        is: true,
        then: schema => schema.required('Provide a value').min(2, 'Too Short!').max(50, 'Too Long!'),
      }),
    },
    {
      name: 'phone',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'Phone Number',
      type: FIELD_TYPE.TEL,
      col: 4,
      toHide: true,
      dependencyFieldToShow: [{name: 'DAMAGE_TO_APT', value: true},{name: 'information', value: true}],
      keyPathToSaveValue: {
        dependencyFieldName: null,
        templateObj: IncidentOwnerTemplate,
        arrayPathName: 'claim.contacts',
        elementPathName: 'cellNumber',
      },
      yup: Yup.string().when('information', {
        is: true,
        then: schema => schema.required('Provide a phone').matches(phoneRegExp, 'Phone number is not valid, Format: 123-456-7890.'),
      }),
      inputProps: { maxLength: 12 },
    },
    {
      name: 'addressLine1',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'Address 1',
      type: FIELD_TYPE.TEXT,
      toHide: true,
      col: 6,
      dependencyFieldToShow: [{name: 'DAMAGE_TO_APT', value: true},{name: 'information', value: true}],
      keyPathToSaveValue: {
        dependencyFieldName: null,
        templateObj: IncidentOwnerTemplate,
        arrayPathName: 'claim.contacts',
        elementPathName: 'primaryAddress.addressLine1',
      },
      yup: Yup.string().when('information', {
        is: true,
        then: schema => schema.required('Provide an address'),
      })
    },
    {
      name: 'addressLine2',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'Address 2',
      type: FIELD_TYPE.TEXT,
      toHide: true,
      col: 6,
      dependencyFieldToShow: [{name: 'DAMAGE_TO_APT', value: true},{name: 'information', value: true}],
      keyPathToSaveValue: {
        dependencyFieldName: null,
        templateObj: IncidentOwnerTemplate,
        arrayPathName: 'claim.contacts',
        elementPathName: 'primaryAddress.addressLine2',
      },
    },
    {
      name: 'city',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'City',
      type: FIELD_TYPE.TEXT,
      toHide: true,
      col: 4,
      dependencyFieldToShow: [{name: 'DAMAGE_TO_APT', value: true},{name: 'information', value: true}],
      keyPathToSaveValue: {
        dependencyFieldName: null,
        templateObj: IncidentOwnerTemplate,
        arrayPathName: 'claim.contacts',
        elementPathName: 'primaryAddress.city',
      },
      yup: Yup.string().when('information', {
        is: true,
        then: schema => schema.required('Provide a value'),
      })
    },
    {
      name: 'state',
      initialValue: EMPTY_VALUE.SELECT,
      label: 'State',
      type: FIELD_TYPE.SELECT,
      options: states.map( option => {
        return { name: option.title , value: option.abbreviation.toUpperCase() };
      }),
      toHide: true,
      col: 4,
      dependencyFieldToShow: [{name: 'DAMAGE_TO_APT', value: true},{name: 'information', value: true}],
      keyPathToSaveValue: {
        dependencyFieldName: null,
        templateObj: IncidentOwnerTemplate,
        arrayPathName: 'claim.contacts',
        elementPathName: 'primaryAddress.state',
      },
      yup: Yup.string().when('information', {
        is: true,
        then: schema => schema.required('Provide a value'),
      }),
    },
    {
      name: 'postalCode',
      initialValue: EMPTY_VALUE.INPUT,
      label: 'Zip',
      type: FIELD_TYPE.TEXT,
      toHide: true,
      col: 4,
      dependencyFieldToShow: [{name: 'DAMAGE_TO_APT', value: true},{name: 'information', value: true}],
      keyPathToSaveValue: {
        dependencyFieldName: null,
        templateObj: IncidentOwnerTemplate,
        arrayPathName: 'claim.contacts',
        elementPathName: 'primaryAddress.postalCode',
      },
      yup: Yup.string().when('information', {
        is: true,
        then: schema => schema.required('Provide a value').matches(zipCodeExp, 'Provide a valid Zip Code'),
      }),
    }
  ];
  