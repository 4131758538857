import * as Yup from 'yup';
import {
  EMPTY_VALUE,
  PLACEHOLDER_SELECT_VALUE,
  CAUSE_OPTIONS,
  SOURCE_OPTIONS,
  MAX_LENGTH,
  causeOfDamageVehicle,
  whatsMissing,
  MAX_LENGTH_20,
  vehicleAccident,
  FIELD_TYPE,
} from '../../../../../actions/types';

export const damaged = [
  {
    name: 'cause',
    label: 'Please pick the best that applies',
    initialValue: PLACEHOLDER_SELECT_VALUE,
    placeholder: 'Cause of damage',
    type: FIELD_TYPE.SELECT,
    InputProps: { style: { marginTop: '32px' } },
    options: causeOfDamageVehicle,
    isOneColumn: true,
    keyPathToSaveValue: 'claim.lossCause',
    yup: Yup.string().test(
      'causeTest',
      'Provide a value',
      (value) => value !== PLACEHOLDER_SELECT_VALUE
    ),
  },
  {
    name: 'source',
    initialValue: PLACEHOLDER_SELECT_VALUE,
    placeholder: 'Source of damage',
    type: FIELD_TYPE.SELECT,
    toHide: true,
    dependencyFieldToShow: {
      name: 'cause',
      value: [
        PLACEHOLDER_SELECT_VALUE,
        CAUSE_OPTIONS.VEHICLEVANDALIZED,
        CAUSE_OPTIONS.VEHICLESTOLEN,
        CAUSE_OPTIONS.VEHICLEROADSIDE,
      ],
    },
    dependencyField: 'cause',
    options: (value) => {
      return (value === CAUSE_OPTIONS.VEHICLEACCIDENT && vehicleAccident) || [];
    },
    isOneColumn: true,
    keyPathToSaveValue: 'claim.lossSource',
    yup: Yup.string().when('cause', {
      is: (value) => value === CAUSE_OPTIONS.VEHICLEACCIDENT,
      then: Yup.string().test(
        'sourceTest',
        'Provide a value',
        (value) => value !== PLACEHOLDER_SELECT_VALUE
      ),
    }),
  },
  {
    name: 'vehicleNumInvol',
    initialValue: EMPTY_VALUE.NUMBER,
    label: 'How many other vehicles were involved?',
    type: FIELD_TYPE.NUMBER,
    inputProps: { min: 0 },
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [
      {
        name: 'cause',
        value: CAUSE_OPTIONS.VEHICLEACCIDENT,
      },
      {
        name: 'source',
        value: [SOURCE_OPTIONS.ANOTHERVEHICLE]
      },
    ],
    keyPathToSaveValue: 'claim.eventDetails.vehicleIncidentCount',
    yup: Yup.number().when('source', {
      is: (value) =>
        value === SOURCE_OPTIONS.ANOTHERVEHICLE,
      then: (schema) => schema.min(1).nullable().required('Provide a value'),
    }),
  },
  {
    name: 'accidentDamaged',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Were any of the other vehicles damaged?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [
      {
        name: 'cause',
        value: CAUSE_OPTIONS.VEHICLEACCIDENT,
      },
      {
        name: 'source',
        value: [SOURCE_OPTIONS.ANOTHERVEHICLE],
      },
      {
        name: 'vehicleNumInvol',
        min: 0,
      },
    ],
    keyPathToSaveValue: 'claim.eventDetails.otherVehiclesDamaged',
    yup: Yup.mixed().when('vehicleNumInvol', {
      is: (value) => value > EMPTY_VALUE.NUMBER,
      then: (schema) => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'accidentInformation',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Do you have the other vehicle information?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [
      {
        name: 'cause',
        value: CAUSE_OPTIONS.VEHICLEACCIDENT,
      },
      {
        name: 'source',
        value: [
          SOURCE_OPTIONS.ANOTHERVEHICLE
        ],
      },
      {
        name: 'accidentDamaged',
        value: true,
      },
      {
        name: 'vehicleNumInvol',
        min: 0,
      },
    ],
    keyPathToSaveValue: 'claim.eventDetails.isVehicleParties',
    yup: Yup.mixed().when('accidentDamaged', {
      is: true,
      then: (schema) => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'rock',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Is damage limited to the windshield?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [
      { name: 'cause', value: CAUSE_OPTIONS.VEHICLEACCIDENT },
      { name: 'source', value: [SOURCE_OPTIONS.WEATHER, SOURCE_OPTIONS.ROCK] },
    ],
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.windshieldDamageOnly',
    yup: Yup.mixed().when('source', {
      is: (value) =>
        value === SOURCE_OPTIONS.WEATHER || value === SOURCE_OPTIONS.ROCK,
      then: (schema) => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'vandalized',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Is there any damage to the vehicle?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: {
      name: 'cause',
      value: CAUSE_OPTIONS.VEHICLEVANDALIZED,
    },
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.vandalized',
    yup: Yup.mixed().when('cause', {
      is: (value) => value === CAUSE_OPTIONS.VEHICLEVANDALIZED,
      then: (schema) => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'missing',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Is anything missing from the vehicle?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: {
      name: 'cause',
      value: CAUSE_OPTIONS.VEHICLEVANDALIZED,
    },
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.missing',
    yup: Yup.mixed().when('cause', {
      is: (value) => value === CAUSE_OPTIONS.VEHICLEVANDALIZED,
      then: (schema) => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'whatIsMissing',
    initialValue: PLACEHOLDER_SELECT_VALUE,
    placeholder: 'What is missing?',
    type: FIELD_TYPE.SELECT,
    options: whatsMissing,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [
      { name: 'cause', value: CAUSE_OPTIONS.VEHICLEVANDALIZED },
      { name: 'missing', value: true },
    ],
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.whatIsMissing',
    yup: Yup.mixed().when('missing', {
      is: (value) => value === true,
      then: Yup.string().test(
        'sourceTest',
        'Provide a value',
        (value) => value !== PLACEHOLDER_SELECT_VALUE
      ),
    }),
  },
  {
    name: 'beenRecovered',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Has the vehicle been recovered?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: {
      name: 'cause',
      value: CAUSE_OPTIONS.VEHICLESTOLEN,
    },
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.beenRecovered',
    yup: Yup.mixed().when('cause', {
      is: (value) => value === CAUSE_OPTIONS.VEHICLESTOLEN,
      then: (schema) => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'vehicleMissing',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'How long has the vehicle been missing?',
    placeholder: 'Describe',
    type: FIELD_TYPE.TEXT_AREA,
    isCountCharacters: true,
    maxLength: MAX_LENGTH_20,
    inputProps: { maxLength: MAX_LENGTH_20 },
    toHide: true,
    dependencyFieldToShow: [
      { name: 'cause', value: CAUSE_OPTIONS.VEHICLESTOLEN },
      { name: 'beenRecovered', value: false },
    ],
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.missingHowLong',
    yup: Yup.mixed().when('beenRecovered', {
      is: (value) => value === false,
      then: (schema) => schema.required('Provide a value'),
    }),
  },
  {
    name: 'towCompleted',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Has the tow already been completed?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: {
      name: 'cause',
      value: CAUSE_OPTIONS.VEHICLEROADSIDE,
    },
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.towCompleted',
    yup: Yup.mixed().when('cause', {
      is: (value) => value === CAUSE_OPTIONS.VEHICLEROADSIDE,
      then: (schema) => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'towAmig',
    value:
      'If you need roadside assistance, please contact (866) 877-9095 or at <a target="_blank" rel="noreferrer" href="https://americanmodern.rsahelp.com/" className={classes.link}>https://americanmodern.rsahelp.com</a>',
    type: FIELD_TYPE.INFORMATION,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [
      { name: 'cause', value: CAUSE_OPTIONS.VEHICLEROADSIDE },
      { name: 'towCompleted', value: false },
    ],
  },
  {
    name: 'towPay',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Did you pay for the tow out of pocket? ',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [
      { name: 'cause', value: CAUSE_OPTIONS.VEHICLEROADSIDE },
      { name: 'towCompleted', value: true },
    ],
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.towAmount',
    yup: Yup.mixed().when('towCompleted', {
      is: (value) => value === true,
      then: (schema) => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'howMuch',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'How much was it?',
    type: FIELD_TYPE.CURRENCY,
    currency: '$',
    toHide: true,
    isOneColumn: true,
    dependencyFieldToShow: [
      { name: 'cause', value: CAUSE_OPTIONS.VEHICLEROADSIDE },
      { name: 'towCompleted', value: true },
      { name: 'towPay', value: true },
    ],
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.towAmountPaid',
    yup: Yup.string().when('towPay', {
      is: true,
      then: (schema) => schema.required('Provide a value'),
    }),
  },
  {
    name: 'detail',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Tell us what happened',
    placeholder: 'Describe property damage',
    type: FIELD_TYPE.TEXT_AREA,
    isCountCharacters: true,
    helperText: `${MAX_LENGTH} characters remaining`,
    inputProps: { maxLength: MAX_LENGTH },
    keyPathToSaveValue: 'claim.description',
    yup: Yup.string().required('Provide a value'),
  },
  {
    name: 'policeOrFireInvolved',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Were the police or fire department involved?',
    type: FIELD_TYPE.YES_NO,
    keyPathToSaveValue: 'claim.eventDetails.policeOrFireInvolved',
    yup: Yup.boolean().required('Select one').nullable(),
  },
  {
    name: 'departmentName',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Department name',
    type: FIELD_TYPE.TEXT,
    toHide: true,
    dependencyFieldToShow: {name:'policeOrFireInvolved', value: true},
    keyPathToSaveValue: 'claim.officials[0].reportingAgency',
    yup: Yup.string().when('policeOrFireInvolved', {
      is: true,
      then: (schema) => schema.required('Provide a value'),
    }),
  },
];
