import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';

export const LoadingComponent = () => {
  
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justify='center'
      style={{ minHeight: '100vh' }}>
      <>
        <Typography variant='h6'>Authentication in progress...</Typography>
        <CircularProgress />
      </>
    </Grid>
  );
};
