import React from "react";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Typography } from "@material-ui/core";

export const SubFormTypeWithRedux = ({
  title,
  summary,
  children
}) => {

  const { palette } = useTheme();

  const useStyles = makeStyles((theme) => ({
    formTitle: {
      color: palette.text.secondary,
      fontSize: '32px',
      fontWeight: 'bold',
    },
    summayFormTitle: {
      color: palette.text.secondary,
      fontSize: '32px',
      fontWeight: 'bold',
      textAlign: 'center',
      },
  }));

  const classes = useStyles();

  return (
    <Grid container >
      <Grid item xs={12} style={{marginBottom: '30px'}} >
        <Typography 
          variant="h2" 
          className={summary.isLastStept ? classes.summayFormTitle: classes.formTitle}>
          {title}
        </Typography>
      </Grid>
      {children} 
    </Grid>
  );
}

SubFormTypeWithRedux.defaultProps = {
  title: '',
};

const mapStateToProps = (state) => ({
  summary: state.summary,
});

export const SubFormType = connect(mapStateToProps)(SubFormTypeWithRedux);