import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormType } from '../Utilities/FormType';
import { AMIGForm } from '../Utilities/AMIGForm';
import { lossLocation } from '../ScreenObjects/Residential/lossLocation';
import {
  setReduxValues,
  useAMIGFormik,
  getPathValue,
  setPathValue,
  shallowCopy,
} from '../Utilities/AMIGHandleForm';
import {
  StepTypes,
  ComponentTitle,
  EMPTY_VALUE,
  FIELD_TYPE,
} from '../../../../actions/types';
import { summaryActions } from '../../../../actions/summary';
import { newClaimActions } from '../../../../actions/newClaim';
import { useLoading } from '../../../../hooks/useLoading';
import { LoadingPlaceholder } from '../Utilities/LoadingPlaceholder';
import * as Yup from 'yup';
import { updateClaim } from '../../../../actions/claimActions';

const { updateDisabled } = summaryActions;
const { updateLocationRes } = newClaimActions;

export const LossLocationWithRedux = ({
  summary,
  claim,
  newClaim,
  policy,
  updateDisabled,
  updateLocationRes,
  updateClaim,
  stepType,
  handleNext,
}) => {
  const buildFields = (lossLocation) => {
    const riskUnits = getPathValue('policy.riskUnits', policy);
    let properties = [];

    if (riskUnits?.length) {
      if (riskUnits.length === 1) {
        properties = [
          {
            label: riskUnits[0].description,
            name: 'vehicle',
            type: FIELD_TYPE.LABEL,
            isOneColumn: true,
            policyLocation: riskUnits[0].policyLocation,
          },
        ];
      } else {
        properties = riskUnits.map((property, index) => {
          return {
            label: property.description,
            name: `vehicle${index}`,
            initialValue: EMPTY_VALUE.CHECKBOX,
            type: FIELD_TYPE.CHECKBOX,
            tooltip: '',
            isOneColumn: true,
            policyLocation: property.policyLocation,
            yup: Yup.boolean(),
          };
        });
      }
    }

    return [...properties, ...lossLocation];
  };

  const fields = buildFields(lossLocation);
  const { isLoading, setIsLoading } = useLoading();

  const handleSubmit = () => {
    updateLocationRes(formik.values);

    let copyClaim = shallowCopy(claim);
    if (formik.values['lossHappened']) {
      const lossLocation = fields.find((field) => {
        return (
          field.name === 'vehicle' ||
          (field.type === FIELD_TYPE.CHECKBOX && formik.values[field.name])
        );
      });

      copyClaim.lossLocation = {
        addressLine1: lossLocation.policyLocation.AddressLine1,
        addressLine2: '',
        city: lossLocation.policyLocation.City,
        state: lossLocation.policyLocation.State,
        postalCode: lossLocation.policyLocation.PostalCode,
        Country: lossLocation.policyLocation.Country,
      };
    } else {
      copyClaim = setPathValue(formik, fields, claim);
    }

    const selectedValues = Object.keys(formik.values).filter((i) =>
      i.includes('vehicle')
    );

    const policyLocations = fields.filter((f) =>
      f.hasOwnProperty('policyLocation')
    );
    selectedValues.forEach((sv) => {
      const pl = policyLocations.find((location) => location.name === sv);
      const {
        AddressLine1,
        AddressLine2,
        City,
        Country,
        County,
        PostalCode,
        State,
      } = pl.policyLocation;
      copyClaim.eventDetails.residentialIncidents.push({
        addressLine1: AddressLine1,
        addressLine2: AddressLine2,
        city: City,
        state: State,
        postalCode: PostalCode,
      });
    });

    updateClaim({ ...copyClaim, locationRes: formik.values });
    if (!summary.isLastStept) handleNext();
    else updateDisabled(stepType, true);
  };

  const formik = useAMIGFormik(fields, handleSubmit);

  useEffect(() => {
    try {
      setReduxValues(formik, newClaim.locationRes, fields);
      setIsLoading(false);
    } catch (error) {
      console.error('Error', error);
    }
  }, []);

  if (isLoading) return <LoadingPlaceholder />;

  return (
    <FormType
      title={ComponentTitle.LOSSLOCATION}
      subtitle='File a claim'
      buttonLabel={summary.isLastStept ? 'Save' : 'Continue'}
      isDisable={summary.isStepDisabled(summary.disable, stepType)}
      handleSubmit={formik.handleSubmit}>
      <AMIGForm stepType={stepType} fields={fields} formik={formik} />
    </FormType>
  );
};

LossLocationWithRedux.defaultProps = {
  stepType: StepTypes.LOSS_LOCATION_RES,
  handleNext: () => {},
};

const mapStateToProps = (state) => ({
  summary: state.summary,
  claim: state.claim,
  newClaim: state.newClaim,
  policy: state.policy,
});

export const LossLocationResidential = connect(mapStateToProps, {
  updateDisabled,
  updateLocationRes,
  updateClaim,
})(LossLocationWithRedux);
