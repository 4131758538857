import React from 'react';
import { useField } from 'formik';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const FormikSelect = ({ children, label, ...props }) => {
  const [field, meta] = useField(props);
  const { variant } = props;

  return (
   
      <FormControl
        variant={variant}
        fullWidth
        error={meta.touched && meta.error !== undefined}>
        <InputLabel id={`${props.id}-select-label`}>{label}</InputLabel>
        <Select
          labelId={`${props.id}-select-label`}
          // id='demo-simple-select-outlined'
          label={label}
          {...field}
          {...props}>
          {children}
        </Select>
        {meta.touched && meta.error && (
          <FormHelperText style={{ color: '#f44336' }}>
            {meta.error}
          </FormHelperText>
        )}
      </FormControl>
  );
};

export default FormikSelect;
