import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const OffsetGrid = ({containerSpacing = 2, offset, children}) => {
    
    const useStyles = makeStyles({
        root: {
          flexGrow: 1,
        }
      });
      
      const classes = useStyles();

    return (
        <Grid container spacing={containerSpacing} className={classes.root} justify='center'>
            <Grid item xs={12 - offset}>
                {children}
            </Grid>
        </Grid>
    )
}

export default OffsetGrid
