import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { sendEventLogRequest } from '../../actions/eventActions';
import PropTypes from 'prop-types';
import ClaimInfoComponent from './ClaimInfoComponent';
import ClaimSubmitConfirmation from './ClaimSubmitConfirmation';
import { submitClaim } from '../../actions/claimActions';
import { sendClaimSummaryEmail } from '../../actions/emailActions';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import OffsetGrid from '../layout/OffsetGrid';
import { updateClaim, getIP } from '../../actions/claimActions';
import { getContainerOffset } from '../../actions/layoutActions';
import { ClaimStatus } from '../../actions/types';
import { showLoading } from '../../actions/loading';
import moment from 'moment';

const ClaimSummaryComponent = ({
  claim,
  activeStep,
  handleBack,
  updateClaim,
  submitClaim,
  getIP,
  showLoading,
  networkMonitor,
}) => {
  const [submitted, setSubmitted] = React.useState(false);
  const { logId } = claim;
  const { deviceType } = getDeviceTypeInfo();

  useEffect(() => {
    if (networkMonitor.failedNetworkCalls > 0) {
      handleBack();
    }
  }, [networkMonitor.failedNetworkCalls]);

  useEffect(() => {
    window.scrollTo(0, 0);
    var event = {
      logId: logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Verify and Submit'}",
    };

    sendEventLogRequest(event);
  }, []);

  const handleClaimSubmit = async () => {
    showLoading(true);
    const newClaim = { ...claim };
    newClaim.showStepper = false;
    newClaim.submittedOn = moment(new Date()).format('MM/DD/YYYY HH:mm:ss');

    try {
      if (!claim.namedInsured.firstName) {
        throw 'Claim submission failed';
      }

      const response = await submitClaim(newClaim);
      newClaim.status = ClaimStatus.API_SUBMISSION;
      newClaim.claimNumber = response.data.claimNumber;
    } catch (error) {
      newClaim.status = ClaimStatus.EMAIL_SUBMISSION;

      let toEmail = null;
      if (
        newClaim.reportingParty.contactInfo.email &&
        newClaim.namedInsured.contactInfo.emailAddress1
      ) {
        let emailArr = [];
        emailArr.push(`${newClaim.reportingParty.contactInfo.emailAddress1}`);
        emailArr.push(`${newClaim.namedInsured.contactInfo.emailAddress1}`);
        toEmail = emailArr;
      } else if (
        newClaim.reportingParty.contactInfo.emailAddress1 &&
        !newClaim.namedInsured.contactInfo.emailAddress1
      ) {
        toEmail = newClaim.reportingParty.contactInfo.emailAddress1;
      } else if (
        newClaim.namedInsured.contactInfo.emailAddress1 &&
        !newClaim.reportingParty.contactInfo.emailAddress1
      ) {
        toEmail = newClaim.namedInsured.contactInfo.emailAddress1;
      }

      const isNamedInsured = newClaim.reportingParty.reporter !== 'Other';

      const { firstName } = isNamedInsured
        ? newClaim.namedInsured
        : newClaim.reportingParty;

      let reporter = newClaim.reportingParty.reporter;
      {
        reporter === 'Other' &&
          (reporter = `${newClaim.reportingParty.firstName} ${newClaim.reportingParty.lastName}`);
      }

      const {
        logId,
        confirmationId,
        policyNumber,
        lossDate,
        type,
        lossLocation: { addressLine1, addressLine2, city, state, postalCode },
      } = newClaim;

      sendClaimSummaryEmail(
        logId,
        confirmationId,
        {
          policyNumber: policyNumber,
          firstName: firstName,
          reporter: reporter,
          dateOfLoss: lossDate,
          lossLocation: `${addressLine1} ${addressLine2} ${city} ${state} ${postalCode}`,
          email: toEmail,
        },
        type
      );
    } finally {
      getIP();
      updateClaim({ ...newClaim });
      showLoading(false);
      setSubmitted(true);
    }
  };

  return (
    <>
      {submitted ? (
        <ClaimSubmitConfirmation claim={claim} />
      ) : (
        <>
          <OffsetGrid offset={`${getContainerOffset(deviceType)}`}>
            <Typography
              variant='h1'
              align='center'
              color='primary'
              gutterBottom>
              Last step, click Submit after you review your claim information
            </Typography>
            <Typography variant='subtitle1' align='center' gutterBottom>
              After you click the Submit button, we will assign a claims
              adjuster to work with you on all of the details of your loss.
            </Typography>
          </OffsetGrid>

          <Box p={2}>
            <ClaimInfoComponent claim={claim} />
          </Box>
          <Box p={5} style={{ textAlign: 'center' }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              onClick={handleClaimSubmit}>
              Submit
            </Button>
          </Box>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
  networkMonitor: state.networkMonitor,
});

ClaimSummaryComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
  submitClaim: PropTypes.func.isRequired,
  showLoading: PropTypes.func.isRequired,
  networkMonitor: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, {
  updateClaim,
  submitClaim,
  showLoading,
  getIP,
})(ClaimSummaryComponent);
