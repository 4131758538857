import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Image from 'material-ui-image';

const AttachmentViewDialog = ({ file, show, handleClose }) => {
  const { name = '', data } = file;

  return (
    <>
      {data && (
        <Dialog
          open={show}
          onClose={handleClose}
          aria-labelledby='attachment-dialog-title'>
          <DialogTitle id='attachment-dialog-title'>{name}</DialogTitle>
          <DialogContent>
            <Image src={data} alt='Selected document view' />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default AttachmentViewDialog;
