import { NETWORK_CALL_SUCCEEDED, NETWORK_CALL_FAILED } from '../actions/types';

const initialState = {
  failedNetworkCalls: 0,
  lastAttemptedRequest: null
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case NETWORK_CALL_SUCCEEDED:
      return { ...state, failedNetworkCalls: 0, lastAttemptedRequest: null };
    case NETWORK_CALL_FAILED:
      return { ...state, failedNetworkCalls: (state.failedNetworkCalls + 1), lastAttemptedRequest: payload };
    default:
      return state;
  }
};
