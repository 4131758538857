import axios from 'axios';
import appSettings from '../appSettings';
import {
  HttpMethods,
  NETWORK_CALL_FAILED,
  NETWORK_CALL_SUCCEEDED,
  UPDATE_CLAIM,
  IPS
} from './types';
import store from '../store';
import { msalInstance } from '../index';
import { loginRequest } from '../auth/authConfig';
import * as messages from '../components/pages/trackClaim/ClaimDetail.messages';

export const updateClaim = (claim, doPersist = true) => async (dispatch) => {
  dispatch({
    type: UPDATE_CLAIM,
    payload: claim,
  });

  if (doPersist) {
    const { logId } = claim;
    dispatch(updateClaimsTracking(logId, claim));
  }
};

export const updateClaimsTracking = (id, body) => async (dispatch) => {

  const { completed } = store.getState().stepper;
  const { type } = body;
  const url = `${appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL}/claim/tracking/submissions/${id}?type=${type}`;
  const reqBody = {
      ...body,
      id,
      completedSteps: completed,
    },
    options = { headers: { 'Content-Type': 'application/json' } };
  try {
    await axios.put(url, reqBody, options);
    dispatch({
      type: NETWORK_CALL_SUCCEEDED
    });
  } catch (error) {

    dispatch({
      type: NETWORK_CALL_FAILED,
      payload: {
        method: HttpMethods.PUT,
        url,
        body: reqBody,
        options,
      },
    });
  }
};

export const getClaimsTrackingSubmission = (id, claimType) => async (
  dispatch
) => {
  const type = claimType ? claimType : localStorage.getItem('policyType');
  const url = `${appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL}/claim/tracking/submissions/${id}?type=${type}`;
  try {
    const response = await axios.get(url);

    dispatch({
      type: UPDATE_CLAIM,
      payload: response.data,
    });

    return response;
  } catch (error) {

    dispatch({
      type: NETWORK_CALL_FAILED,
      payload: {
        method: HttpMethods.GET,
        url,
      },
    });
  }
};

export const getClaimsSubmission = (id) => async (dispatch) => {
  
  const type = localStorage.getItem('policyType');
  const url = `${appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL}/claim/tracking/submissions/${id}?type=${type}`;
  try {
    const res = await axios.get(url);

    if (res.status === 404) {
      localStorage.removeItem('policyType');
    }

    return res;
  } catch (error) {
    dispatch({
      type: NETWORK_CALL_FAILED,
      payload: {
        method: HttpMethods.GET,
        url,
      },
    });
  }
};

export const getSubmissionSummaries = async (type, params) => {
  let data = [];
  let queryStr = null;
  let contToken = null;
  if (params) {
    for (const prop in params) {
      if (prop === 'continuationToken') {
        contToken = params[prop];
      } else {
        !queryStr
          ? (queryStr = `&${prop}=${params[prop]}`)
          : (queryStr += `&${prop}=${params[prop]}`);
      }
    }
  }

  try {
    const tokenResponse = await msalInstance.acquireTokenSilent({
      scopes: loginRequest.scopes,
      account: msalInstance.getActiveAccount,
    });

    const { accessToken } = tokenResponse;

    let options = null;

    contToken
      ? (options = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'x-ms-continuation': contToken,
          },
        })
      : (options = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
    let response = '';

    queryStr
      ? (response = await axios.get(
          `${appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL}/claim/tracking/submission/summaries?type=${type}${queryStr}`,
          options
        ))
      : (response = await axios.get(
          `${appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL}/claim/tracking/submission/summaries?type=${type}`,
          options
        ));

    data = {
      continuationToken: response.headers['x-ms-continuation'],
      data: response.data.summaries,
    };
  } catch (error) {
    console.log(error);
  }
  return data;
};

export const submitClaim = (claim) => async (dispatch) => {
  const url = `${appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL}/claims?type=${claim.type}`;
  const options = { headers: { 'Content-Type': 'application/json' } };
  try {
    const res = await axios.post(url, claim, options);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_CLAIM,
        payload: { ...claim, claimNumber: res.data.claimNumber },
      });
    }
    return res;
  } catch (error) {
    throw error;
  }
};

export const getIP = () => async (dispatch) => {
    const url = `https://ipapi.co/json/`;
    const res = await axios.get(url);
    if (res.status === 200) {
      return res.data;
    } else {
      throw 'Retrieve Error';
    }
  };

export const getClaimsByPolicyNumber = (params) => async (dispatch) => {
  let queryStr = null;
  if (params) {
    for (const prop in params) {
      !queryStr
        ? (queryStr = `&${prop}=${params[prop]}`)
        : (queryStr += `&${prop}=${params[prop]}`);
    }   
  }
  const currentDate = new Date();
  const previousYearDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());
  const url = `${appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL}/claims/v1/claim/search?lossDateStart=${previousYearDate.toISOString()}&lossDateEnd=${currentDate.toISOString()}${queryStr}`;
  
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    let errorMessage = error?.response?.data?.message;
    let errorStatus = error?.response?.status;
   if(errorMessage==='noclaims'|| errorMessage === 'not found' || errorMessage === 'invalid' || errorStatus===401|| errorStatus===400||errorStatus===500) {
      error.message = messages.policyNotFound;
    } 
    else {
      error.message = messages.apiFailed;
    }
    throw error;
  }
};

export const getClaimByClaimNumber = (params) => async (dispatch) => {
  
  let queryStr = null;
  if (params) {
    for (const prop in params) {
      !queryStr
        ? (queryStr = `&${prop}=${params[prop]}`)
        : (queryStr += `&${prop}=${params[prop]}`);
    }   
  }
  const currentDate = new Date();
  const previousYearDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());
  const url = `${appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL}/claims/v1/claim?lossDateStart=${previousYearDate.toISOString()}${queryStr}`;  
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    let errorMessage = error?.response?.data?.message;
    let errorStatus = error?.response?.status;
    if(errorMessage==='No Claim entity found'){
      error.message = messages.claimNotFound;
    }
    else if(errorMessage === 'not found' || errorMessage === 'invalid'|| errorStatus===401|| errorStatus===400||errorStatus===500) {
      error.message = messages.claimNotFound;
    } else {
      error.message = messages.apiFailed;
    }
    throw error;
  }
};
