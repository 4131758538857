import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import {UPDATE_CLAIM} from './actions/types';
import { MsalProvider } from '@azure/msal-react';

// Material-UI Components
import { ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';

// Components
import theme from './components/ui/Theme';
import Alerts from './components/common/Alerts';
import Loading from './components/common/Loading';
import Navigation from './components/layout/Navigation';
import Footer from './components/layout/Footer';
import ClaimsLandingPage from './components/pages/ClaimsLandingPage';
import Claim from './components/pages/Claim';
import WelcomePage from './components/pages/WelcomePage';
import ClaimSummaryFnolComponent from './components/pages/ClaimSummaryFnolComponent';
import ClaimSearchFnolComponent from './components/pages/ClaimSearchFnolComponent';
import TrackClaimComponent from './components/pages/trackClaim/TrackClaimComponent';
import RedirectRoute from './routing/RedirectRoute';
import ErrorDialog from './components/dialogs/ErrorDialog';
import { makeStyles } from '@material-ui/core/styles';
import LandingPage from './components/pages/claim/LandingPage';
import PolicyPage from './components/pages/claim/PolicyPage';
import { claimLossInspectionScheduledText } from './components/pages/trackClaim/ClaimDetail.messages';

const AMIGThemeProvider = ({ children, theme }) => {
  const useGlobalStyles = makeStyles({
    '@global': {
      body: {
        margin: 0,
        fontFamily: 'Arial, sans-serif'
      },
    }
  });

  useGlobalStyles();
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

function App({ pca, ...props }) {

  const urlParams = new URLSearchParams(window.location.search);
  const refParam = urlParams.get('ref');
  
  if (refParam && refParam === 'an') {
    
    const claim = store.getState().claim;
    
    store.dispatch({
      type: UPDATE_CLAIM,
      payload: {...claim, contactMethod: 'Phone'}
    })
  }

  let isNoHeadFoot = false;
  if (
    //props.location.pathname === '/claims-fnol-search' ||
    //props.location.pathname === '/claims-fnol' ||
    props.location.pathname === '/track-claim'
  ) {
    isNoHeadFoot = true;
  }
  return (
    <Provider store={store}>
      <AMIGThemeProvider theme={responsiveFontSizes(theme)}>
        {isNoHeadFoot ? '' : <Navigation />}
        <Alerts />
        <Loading />
        <ErrorDialog />
        {/* <Route exact path='/' component={ClaimsLandingPage} /> */}
        <Route exact path='/' component={LandingPage} />
        <Route exact path='/policy' component={PolicyPage} />
        <Route exact path='/claims' component={ClaimsLandingPage} />
        <Switch>
          <RedirectRoute exact path='/claim' component={Claim} />
          <RedirectRoute exact path='/claim-welcome' component={WelcomePage} />
        </Switch> 
        {/* <Route
          exact
          path='/claims-fnol-search'
          render={(props) => (
            <MsalProvider instance={pca}>
              <ClaimSearchFnolComponent {...props} authRequired={true} />
            </MsalProvider>
          )}
        /> */}
        {/* <Route
          exact
          path='/claims-fnol'
          render={(props) => (
            <MsalProvider instance={pca}>
              <ClaimSummaryFnolComponent {...props} authRequired={true} />
            </MsalProvider>
          )}
        /> */}
        <Route
          exact
          path='/track-claim'
          render={(props) => (
              <TrackClaimComponent {...props} authRequired={false} />
          )}
        />
        {isNoHeadFoot ? '' : <Footer />}
      </AMIGThemeProvider>
    </Provider>
  );
}

export default withRouter(App);
