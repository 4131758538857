import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import 'react-vertical-timeline-component/style.min.css';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
  cardActions: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardMedia: {
    width: '90px',
    margin: '0 auto 1rem',
  },
  container: {
    maxWidth: '1095px'
  },
  paragraph: {
    fontFamily: ['Raleway', 'sans-serif'].join(','),
    fontSize: '1rem',
    marginBottom: '1.5rem',
  },
  link: {
    color: '#000'
  }
});

const LandingPage = () => {

  useEffect(() => {
    localStorage.removeItem('continueClaimId');
    localStorage.removeItem('policyType');
  }, [])

  const classes = useStyles();

  const homeLogo = require('../../../img/2-Story2_Circle_Purple.png');
  const autoLogo = require('../../../img/Collector_Car_Circle_Green.png');
  const rentersLogo = require('../../../img/Apartment_Circle_Blue.png');

  return (
    <Box p={4} bgcolor="text.disabled" style={{background: '#ededed'}}>
      <Container  className={classes.container}>
        <Box p={4} boxShadow={3} borderRadius={5} bgcolor="background.paper">
          <Grid container>
            <Grid item xs={7}>
                <Typography
                    variant='subtitle1'
                    align='left'>
                    File a Claim
                </Typography>
                <Typography
                    variant='body1'
                    className={classes.paragraph}
                    component='p'
                    align='left'>
                     Thank you for protecting your property with American Modern. We are here to help during this stressful time.
                </Typography>
                <Typography
                    variant='body1'
                    className={classes.paragraph}
                    component='p'
                    align='left'>
                     Do you have a Collector Vehicle and <strong>need roadside assistance? <br/> Call (866) 877-9095 or at <a href="https://americanmodern.rsahelp.com/" className={classes.link}>https://americanmodern.rsahelp.com</a> </strong>
                </Typography>
                <Typography
                    variant='body1'
                    className={classes.paragraph}
                    component='p'
                    align='left'>
                    Has your home been damaged and you <strong>need temporary housing? <br/> Call (800) 375-2075 for assistance.</strong>
                </Typography>
                <Typography
                    variant='body1'
                    className={classes.paragraph}
                    component='p'
                    align='left'>
                   Click continue to file a claim now or call (800) 375-2075 to speak with a representative.
                </Typography>
            </Grid>
            <Grid item xs={5} >
              <Box justifyContent="center" alignItems="center">
                <CardMedia
                  className={classes.cardMedia}
                  component='img'
                  image={homeLogo}
                  alt='Renters Banner'
                />
                <CardMedia
                  className={classes.cardMedia}
                  component='img'
                  image={autoLogo}
                  alt='Renters Banner'
                />
                <CardMedia
                  className={classes.cardMedia}
                  component='img'
                  image={rentersLogo}
                  alt='Renters Banner'
                />
                <CardActions className={classes.cardActions}>
                  <Button 
                    id='continue'
                    data-test-id='welcome-page-continue'
                    component={Link}
                    variant="contained" 
                    color="primary" 
                    style={{width: 'clamp(1px, 100%, 300px)'}} 
                    to='/policy'>
                      Continue
                  </Button>
                </CardActions>
                </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPage;
