import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { useField } from 'formik';

const FormikTextArea = ({
  placeholder,
  maxLength = 0,
  rows = 2,
  label,
  ...props
}) => {
  const [field, meta] = useField(props);
  const { variant, fullWidth, name, value, onChange } = props;


  const getHelperText = (value) => {
    let color = '';
    if (value.length >= maxLength * 9 * 0.1) {
      color = '#f44336';
    } else {
      color = 'textSecondary';
    }
    return (
      <Typography style={{ color: { color } }}>
        remaining characters: {maxLength - field.value.length}
      </Typography>
    );
  };

  return (
    <>
      <TextField
        error={meta.touched && meta.error !== undefined}
        placeholder={placeholder}
        multiline
        rows={rows}
        inputProps={{
          maxLength,
        }}
        helperText={
          field.value.length <= maxLength * 9 * 0.1 ? (
            <span>remaining characters: {maxLength - field.value.length}</span>
          ) : (
            <span style={{ color: 'red' }}>
              remaining characters: {maxLength - field.value.length}
            </span>
          )
        }
        variant={variant}
        fullWidth={fullWidth}
        {...field}
        {...props}
      />
      {meta.touched && meta.error && (
        <FormHelperText style={{ color: '#f44336' }}>
          {meta.error}
        </FormHelperText>
      )}
    </>
  );
};

export default FormikTextArea;
