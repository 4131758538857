import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

export const LoadingPlaceholder = ({ children }) => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff'
    }
  }));

  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      {children ?? <CircularProgress color='inherit' />}
    </Backdrop>
  )
}
