import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { waterDamageSchema } from '../../validation/claimsSchemas';
import { TextField, Select } from 'formik-material-ui';
import { sendEventLogRequest } from '../../actions/eventActions';
import { updateClaim } from '../../actions/claimActions';
import { getContainerOffset } from '../../actions/layoutActions';
import PropTypes from 'prop-types';
import ComponentToggle from '../common/ComponentToggle';
import MenuItem from '@material-ui/core/MenuItem';
import { ClaimTypes } from '../../actions/types';
import OffsetGrid from '../layout/OffsetGrid';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import ErrorFocus from '../common/ErrorFocus';
import { WaterDamageSources } from '../../actions/types';
import FormikTextArea from '../common/FormikTextArea';

const WaterDamageComponent = ({
  claim,
  activeStep,
  handleNext,
  handleBack,
  updateClaim,
}) => {
  const { logId } = claim;
  const { deviceType } = getDeviceTypeInfo();

  const initialValues = {
    type: ClaimTypes.WATER_DAMAGE,
    incidentDesc: claim.lossDetails.incidents[0].incidentDesc ?? '',
    waterSource: claim.lossDetails.incidents[0].waterSource,
    isMitigated: claim.lossDetails.incidents[0].isMitigated,
    mitigationDesc: claim.lossDetails.incidents[0].mitigationDesc,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    var event = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Water Damage Incident'}",
    };

    sendEventLogRequest(event);
  }, []);

  const useStyles = makeStyles((theme) => ({
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    validationMessage: {
      fontSize: '0.75rem',
      color: '#f44336',
      marginTop: '3px',
    },
  }));

  const classes = useStyles();

  return (
    <>
      <OffsetGrid offset={`${getContainerOffset(deviceType)}`}>
        <Typography variant='h1' align='center' color='primary'>
          Tell us what happened
        </Typography>
        <Typography variant={'subtitle1'} align={'center'}>
          Please indicate the source of the water and describe if/how the damage
          was stopped.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={waterDamageSchema}
          onSubmit={(values) => {
            if (values) {
              const newClaim = { ...claim };
              newClaim.lossDetails.incidents[0] = values;
              updateClaim({ ...newClaim });
            }

            handleNext();
          }}>
          {({ errors, touched, setFieldValue }) => (
            <Box mt={2}>
              <Form id='water-damage-form'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box mb={3}>
                      <FormikTextArea
                        name='incidentDesc'
                        placeholder='Please describe how the water damage was discovered'
                        maxLength={250}
                        rows={4}
                        variant='outlined'
                        multiline
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      errors={
                        errors &&
                        errors.waterSource &&
                        touched &&
                        touched.waterSource
                      }
                      fullWidth>
                      <InputLabel id='water-source-label'>
                        Water damage source
                      </InputLabel>
                      <Field
                        labelId='water-source-label'
                        name='waterSource'
                        component={Select}>
                        {WaterDamageSources.map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Field>
                      <div className={classes.validationError}>
                        <ErrorMessage name='waterSource'>
                          {(msg) => (
                            <Typography className={classes.validationMessage}>
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </div>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name='isMitigated'
                      component={ComponentToggle}
                      title='Has the source of the water damage been repaired?'
                      displayOnSelected={true}
                      handleButtonChange={(value) =>
                        setFieldValue('isMitigated', value)
                      }>
                      {/* <Field
                          className={classes.textField}
                          name='mitigationDesc'
                          component={TextField}
                          placeholder='Please describe how the water damage was stopped'
                          variant='outlined'
                          multiline
                          fullWidth
                          margin='normal'
                          rows={2}
                        /> */}
                      <FormikTextArea
                        name='mitigationDesc'
                        placeholder='Please describe how the water damage was stopped'
                        maxLength={250}
                        rows={4}
                        variant='outlined'
                        multiline
                        fullWidth
                        margin='normal'
                      />
                    </Field>
                  </Grid>
                </Grid>
                <ErrorFocus />
              </Form>
            </Box>
          )}
        </Formik>
      </OffsetGrid>

      <Box p={5} style={{ textAlign: 'center' }}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.backButton}>
          Back
        </Button>
        <Button
          type='submit'
          form='water-damage-form'
          variant='contained'
          color='primary'>
          Next
        </Button>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
});

WaterDamageComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { updateClaim })(WaterDamageComponent);
