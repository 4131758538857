import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormType } from '../Utilities/FormType';
import { AMIGForm } from '../Utilities/AMIGForm';
import { lossLocationNotPolicy } from '../ScreenObjects/Common/lossLocationNotPolicy';
import { setPathValue, setReduxValues, useAMIGFormik } from '../Utilities/AMIGHandleForm';
import { StepTypes, ComponentTitle } from '../../../../actions/types';
import { summaryActions } from "../../../../actions/summary";
import { newClaimActions } from "../../../../actions/newClaim";
import { useLoading } from '../../../../hooks/useLoading';
import { LoadingPlaceholder } from '../Utilities/LoadingPlaceholder';
import { updateClaim } from '../../../../actions/claimActions';

const { updateDisabled } = summaryActions;
const { updateLocationNotPolicy } = newClaimActions;

export const LossLocationNotPolicyWithRedux = ({
  summary,
  claim,
  newClaim,
  updateDisabled, 
  updateLocationNotPolicy,
  updateClaim,
  stepType, 
  handleNext 
}) => {

  const fields = lossLocationNotPolicy;
  const {isLoading, setIsLoading} = useLoading();

  const handleSubmit = () => {
    updateLocationNotPolicy(formik.values);
    const copyClaim = setPathValue(formik, fields, claim);
    //updateClaim({...copyClaim, lossDetails: {...copyClaim.lossDetails, location: formik.values} });
    updateClaim({...copyClaim, lossLocation: {...copyClaim.lossLocation, ...formik.values }})
    if(!summary.isLastStept) handleNext();
    else updateDisabled(stepType, true);
  };
  
  const formik = useAMIGFormik(fields, handleSubmit);
    
  useEffect(() => {
    try {
        setReduxValues(formik, newClaim.notPolicy, fields);
        setIsLoading(false);
    } catch(error) {
      console.error("Error",error);
    }
  },[]);

  if(isLoading) return <LoadingPlaceholder />;

  return (
    <FormType
      title={ComponentTitle.LOSSLOCATIONNOTPOLICY}
      subtitle='File a claim'
      buttonLabel={summary.isLastStept ? "Save" : "Continue"}
      isDisable={summary.isStepDisabled(summary.disable, stepType)}
      handleSubmit={formik.handleSubmit}>
      <AMIGForm stepType={stepType} fields={fields} formik={formik} />
    </FormType>
  );
};

LossLocationNotPolicyWithRedux.defaultProps = {
  stepType: StepTypes.LOSS_LOCATION_NOT_POLICY,
  handleNext: () => {},
};

const mapStateToProps = (state) => ({
  summary: state.summary,
  claim: state.claim,
  newClaim: state.newClaim,
});

export const LossLocationNotPolicy = connect(mapStateToProps, { updateDisabled, updateLocationNotPolicy, updateClaim })(LossLocationNotPolicyWithRedux);
