import React from "react";
import { connect } from 'react-redux';
import { Caused } from "../Common/Caused";
import { Reporter } from "../Common/Reporter";
import { Summary } from "../Common/Summary";
import { DamagedResidential } from "./Damaged";
import { LossLocationResidential } from "./LossLocation";
import { StepTypes, ComponentTitle } from '../../../../actions/types';
import { LossLocationNotPolicy } from "../Common/LossLocationNotPolicy";
import { Injured } from "../Common/Injured";

export const LastSteptResidentialWithRedux = ({ claim, handleNext, newClaim }) => {

  const handleSubmit = () => {
    
  };
  
  return (
    <Summary>
        {claim.type !== "notvalidatedresidential" && <LossLocationResidential title={ComponentTitle.LOSSLOCATION} stepType={StepTypes.LOSS_LOCATION_RES} />}
        {claim.type === "notvalidatedresidential" && <LossLocationNotPolicy title={ComponentTitle.LOSSLOCATIONNOTPOLICY} stepType={StepTypes.LOSS_LOCATION_NOT_POLICY} />}
        {newClaim.injured.manyInjured > 0 && <Injured title={ComponentTitle.INJURED} stepType={StepTypes.INJURED} /> }
        <DamagedResidential title={ComponentTitle.DAMAGED} stepType={StepTypes.DAMAGED_RES} />
        <Caused title={ComponentTitle.CAUSED} stepType={StepTypes.CAUSED} />
        <Reporter title={ComponentTitle.REPORTER} stepType={StepTypes.REPORTER} />
    </Summary>
  );
};

LastSteptResidentialWithRedux.defaultProps = {
  handleNext: () => {}, 
};

const mapStateToProps = (state) => ({
  claim: state.claim,
  newClaim: state.newClaim,
});

export const LastSteptResidential = connect(mapStateToProps, {})(LastSteptResidentialWithRedux);