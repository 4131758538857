import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage } from 'formik';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0, 1, 0),
  },
}));

const ComponentToggle = (props) => {
  const [selected, setSelected] = useState(props.field.value);
  const { deviceType } = getDeviceTypeInfo();

  const {
    title = '',
    positiveTitle = 'Yes',
    negativeTitle = 'No',
    displayOnSelected = null,
  } = props;

  const buttons = [
    <ToggleButton size={'small'} key={1} value={true}>
      {positiveTitle}
    </ToggleButton>,
    <ToggleButton size={'small'} key={2} value={false}>
      {negativeTitle}
    </ToggleButton>,
  ];

  const onHandleButtonChange = (event, selected) => {
    setSelected(selected);
    props.handleButtonChange(selected);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container alignItems='baseline'>
        
          <Grid item xs={12} sm={10}>
            <Typography color={'textSecondary'}>{title}​</Typography>
          </Grid>
          <Grid item xs={12} sm={2} align='right'>
            <ToggleButtonGroup
              value={selected}
              exclusive
              onChange={onHandleButtonChange}>
              {buttons}
            </ToggleButtonGroup>
          </Grid>
        
      </Grid>
      <ErrorMessage name={props.field.name}>
        {(msg) => (
          <Typography
            align='right'
            style={{
              fontSize: '0.75rem',
              color: '#f44336',
              marginTop: '3px',
            }}>
            {msg}
          </Typography>
        )}
      </ErrorMessage>
      {selected === displayOnSelected && props.children}
    </div>
  );
};

export default ComponentToggle;
