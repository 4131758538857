import React from 'react';
import { useField } from 'formik';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DateTimePicker } from '@material-ui/pickers';

import FormHelperText from '@material-ui/core/FormHelperText';

const FormikDateTimePicker = ({ ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DateTimePicker
        error={meta.touched && meta.error !== undefined}
        fullWidth
        {...field}
        {...props}
        leftArrowButtonProps={{ "data-test-id": "loss-datetime-picker-left-arrow" }}
      />
    </MuiPickersUtilsProvider>
    {meta.touched && meta.error && (
        <FormHelperText style={{ color: '#f44336' }}>
          {meta.error}
        </FormHelperText>
      )}
    </>
  );
};

export default FormikDateTimePicker;
