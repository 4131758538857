import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Card, CardContent, CardHeader, IconButton, } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

const AMIGEnabledFieldsWithRedux = ({ 
  stepType, 
  title,
  handleClick, 
  summary, 
  children }) =>{

    const { palette } = useTheme();

    const useStyles = makeStyles((theme) => ({
      title: {
        "& .MuiTypography-h5": {
          fontSize: '32px',
          color: '#000000',
          textAlign: 'center',
          fontWeight: 'bold',
        },
      },
      shadowContainer: {
        margin: '1rem',
      },
    }));

    const classes = useStyles();
    
    return (
      <Card className={classes.shadowContainer} >
        <CardHeader className={classes.title} title={title} action={ summary.isStepDisabled(summary.disable, stepType) &&
          <IconButton onClick={handleClick}>
            <Edit fontSize={'small'} />
          </IconButton>} />
        <CardContent>
          {children}
        </CardContent>
      </Card>
    );
};

AMIGEnabledFieldsWithRedux.defaultProps = {
  stepType: '',
  title: '',
  handleClick: () => {},
};

const mapStateToProps = (state) => ({
  summary: state.summary,
});

export const AMIGEnabledFields = connect(mapStateToProps)(AMIGEnabledFieldsWithRedux);
