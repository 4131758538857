import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CHECKBOX_ERROR_VALUE, FIELD_TYPE } from '../../../../actions/types';

export const getInicialValues = (fields) =>
  fields.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.initialValue }), {});

export const setReduxValues = (formik, reduxState, fields, obj, isDependency) =>
  fields.forEach((field) => {
    const name = field.name;
    const reduxValue = reduxState[name];
    const formikValue = formik.values[name];
    let value = null;

    if (isDependency) {
      if (obj && field?.keyPath && typeof field.keyPath !== 'string') {
        if (reduxValue !== field.initialValue) value = reduxValue;
        else {
          const dependencyField = field.keyPath.dependencies.find(
            (element) =>
              formik.values[field.keyPath.dependencyFieldName] ===
              element.fieldValue
          );
          if (dependencyField)
            value = getPathValue(dependencyField.keyPath, obj);
          else value = field.initialValue;
        }
      }
    } else {
      if (reduxValue !== formikValue) value = reduxValue;
      else if (obj && field?.keyPath && typeof field.keyPath === 'string') {
        const keyPathValue = getPathValue(field.keyPath, obj);
        if (keyPathValue) value = keyPathValue;
      }
    }

    if (value !== null) formik.setFieldValue(name, value, false);
  });

export const getPathValue = (keyPath, obj) => {
  const objs = keyPath.split('.');
  objs.shift();
  return objs.reduce((currentObj, indexObj) => currentObj[indexObj], obj);
};

const getPathObject = (keyPath, obj) => {
  // Create an array containing elements of the keyPath.arrayPathName
  // eg., ['claim', 'contacts]
  const keys = keyPath.split('.');
  // Remove the first element e.g. ['contacts']
  keys.shift();
  // currentObj = claim
  // indexObj = 'contacts'
  return keys.reduce((currentObj, indexObj) => {
    return currentObj[indexObj];
  }, obj);
};

export const shallowCopy = (obj) => {
  const elementString = JSON.stringify(obj);
  return JSON.parse(elementString);
};

const getPathToSaveValue = (formik, field, obj, isInit) => {
  const keyPath = field.keyPathToSaveValue;

  if (typeof keyPath === 'object') {
    // Get the path to the array e.g., claim.contacts
    const arrayPathName = keyPath.arrayPathName;

    if (arrayPathName) {
      // This will return claim.contacts array
      const newObj = getPathObject(arrayPathName, obj);
      // Usually the number of items to push to the array
      const length = formik.values[keyPath.dependencyFieldName] ?? 1;
      // e.g., firstName
      const fieldName = field.name;
      // e.g., primaryAddress.addressLine1
      const elementPathName = keyPath.elementPathName;
      // e.g., accidentMake_0 or firstName
      // e.g., 12 or -1
      const index_ = fieldName.lastIndexOf('_');
      // e.g., 0
      const index =
        index_ >= 0 ? fieldName.substring(index_ + 1, fieldName.length) : 0;

      if(isInit){
        // e.g., claim.contacts is empty - length = 0
        if(newObj.length < length && newObj.length > 1){
          newObj.length = 1;
        }
      } else {
        if(newObj.length < length){
          for(let index=1; index < length; index++){
            const element = shallowCopy(keyPath.templateObj);
            newObj.push(element);
          }
        } else if (newObj.length > length) {
          newObj.length = length;
        }
      }
      // e.g., claim.contacts[0].primaryAddress.addressLine1
      return `${arrayPathName}[${index}].${elementPathName}`;
    }
  }

  return keyPath;
};

const getValue = (formik, field, isInit) => {
  if (isInit) return field.initialValue;

  return formik.values[field.name];
};

export const setPathValue = (formik, fields, obj, isInit) => {
  //
  const copyObj = shallowCopy(obj);
  fields.forEach((field) => {
    try {
      if (field.keyPathToSaveValue) {
        // Would return e.g., claim.contacts[0].primaryAddress.addressLine1
        const keyPath = getPathToSaveValue(formik, field, copyObj, isInit);
        const value = getValue(formik, field, isInit); // returns the value of the field e.g., 123 Main St.
        const template = field.keyPathToSaveValue.templateObj;
        mappingObject(keyPath, copyObj, value, template);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  });
  return copyObj;
};

// This is finding and updating the object at
const mappingObject = (keyPath, obj, value, templateObj) => {
  //e.g., ['claim', 'contacts[0]', 'primaryAddress', 'addressLine1']
  const keys = keyPath.split('.');
  //e.g., ['contacts[0]', 'primaryAddress', 'addressLine1']
  keys.shift();

  //e.g., ['contacts[0]', 'primaryAddress']
  // 1 - Result [{firstName, lastName ... primaryAddress: {}}]
  // 2 - Result { addressLine1, addressLine2, city, state ... }
  const newObj = keys.slice(0, -1).reduce((currentObj, indexObj) => {
    //1 - currentObj = claim
    //1 - indexObj = contacts[0]
    //2 - currentObj = object at claim.contacts[0] or templateObj if the former is undefined
    //2 - indexObj = primaryAddress1
    if (indexObj.indexOf('[') >= 1) {
      //e.g., 0
      const index = indexObj.substring(
        indexObj.indexOf('[') + 1,
        indexObj.indexOf(']')
      );
      // e.g., contacts
      const element = indexObj.substring(0, indexObj.indexOf('['));
      // Check if the currentObj[element][index] exists
      // e.g., the object at claim['contacts'][0] or the template object
      // Just check if the currentObj contains the same obj
      // if not push a template object
      if (!currentObj[element][index]) {
        currentObj[element][index] = templateObj;
      }
      return currentObj[element][index];
    }
    // object at claim.contacts[0] or templateObj if the former is undefined
    return currentObj[indexObj];
  }, obj);
  newObj[keys[keys.length - 1]] = value;
};

export const useAMIGFormik = (fields = [], handleSubmit = () => {}) =>
  useFormik({
    initialValues: getInicialValues(fields),
    validationSchema: () => {
      const genericFormFields = fields.reduce(
        (acc, cur) =>
          cur?.yup && cur?.type !== 'checkbox'
            ? { ...acc, [cur.name]: cur.yup }
            : acc,
        {}
      );

      const checkboxFields = fields.reduce(
        (acc, cur) =>
          cur.yup && cur.type === 'checkbox'
            ? { ...acc, [cur.name]: cur.yup }
            : acc,
        {}
      );

      const checkBoxes = Object.keys(checkboxFields);

      const schemaCheckboxFields = Yup.object(checkboxFields).test(
        CHECKBOX_ERROR_VALUE,
        (schemaFields) =>
          checkBoxes.length === 0 ||
          checkBoxes.some((checkbox) => schemaFields[checkbox]) ||
          new Yup.ValidationError(
            'Please check at least one',
            null,
            CHECKBOX_ERROR_VALUE
          )
      );

      return Yup.object(genericFormFields).concat(schemaCheckboxFields);
    },
    onSubmit: handleSubmit,
  });
