import moment from 'moment';
import {
  ContactTypes,
  GET_GW_POLICY,
  GET_LEGACY_POLICY,
  PhoneTypes,
  PolicyTypes,
  vesselType,
} from '../actions/types';
import { toTitleCase } from '../utils/StringUtils';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  policyNumber: '',
  type: '',
  status: '',
  effectiveDate: '',
  expirationDate: '',
  productCode: '',
  contacts: [],
  coverages: [],
  policyLocation: {
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      postalCode: '',
      state: '',
    },
    occupancy: '',
    coverages: [],
  },
  riskUnits: [],
};

const getDescriptionResRiskUnit = (
  ru,
  policyLocation,
  namedInsured,
  effectiveDate,
  expirationDate
) =>
  `${policyLocation.AddressLine1} ${policyLocation.City}, ${policyLocation.State}  ${
    policyLocation.PostalCode
  }
  Named Insured ${toTitleCase(namedInsured.FirstName)} ${toTitleCase(
    namedInsured.LastName
  )}
  Effective Time Period  ${moment(effectiveDate).format(
    'MM/DD/YYYY'
  )} to ${moment(expirationDate).format('MM/DD/YYYY')}`;

const getDescriptionRiskUnit = (
  ru,
  isVisselType,
  namedInsured,
  effectiveDate,
  expirationDate
) =>
  `${ru.Description} ${ isVisselType ? 'HIN ':'VIN'} ${ru.Vehicle.Vin} 
Named Insured ${toTitleCase(namedInsured.FirstName)} ${toTitleCase(
    namedInsured.LastName
  )}
Effective Time Period  ${moment(effectiveDate).format(
    'MM/DD/YYYY'
  )} to ${moment(expirationDate).format('MM/DD/YYYY')}`;

const getRecRiskUnit = (ru, isVisselType, namedInsured, effectiveDate, expirationDate) => {
  const { Year, Make, Model, Vin } = ru.Vehicle;
  const recRiskUnit = {
    id: uuidv4(),
    make: Make,
    model: Model ?? 'Unknown',
    year: Year,
    vin: Vin,
    description: getDescriptionRiskUnit(
      ru,
      isVisselType,
      namedInsured,
      effectiveDate,
      expirationDate
    ),
    isOnPolicy: true,
    isSelected: false,
    estimateReceived: null,
    estimatedRepairCost: '',
  };

  return recRiskUnit;
};

const getResidentialRiskUnit = (
  ru,
  policyLocation,
  namedInsured,
  effectiveDate,
  expirationDate
) => {
  const { YearBuilt, type } = ru;

  const residentialRiskUnit = {
    id: uuidv4(),
    YearBuilt: YearBuilt,
    type: type,
    description: getDescriptionResRiskUnit(
      ru,
      policyLocation,
      namedInsured,
      effectiveDate,
      expirationDate
    ),
    policyLocation, 
    isOnPolicy: true,
    isSelected: false,
    estimateReceived: null,
    estimatedRepairCost: '',
  };

  return residentialRiskUnit;
};

const getCCPolicyLocationById = (id, ccPolicyLocation) => {
  let policyLocation;
  if (Array.isArray(ccPolicyLocation)) {
    policyLocation = ccPolicyLocation.find((element) => element.ID === id);
  } else {
    policyLocation = id === ccPolicyLocation.ID ? ccPolicyLocation : undefined;
  }

  return policyLocation;
};

const getAddressById = (id, ccAddress) => {
  let address;
  if (Array.isArray(ccAddress)) {
    address = ccAddress.find((element) => element.ID === id);
  } else {
    address = id === ccAddress.ID ? ccAddress : undefined;
  }

  return address;
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LEGACY_POLICY:
      const {
        RetrievePolicyResponse: {
          PolicyNumber,
          PolicyType,
          PolicyStatus,
          PolicyEffectiveDate,
          PolicyExpirationDate,
          ProductCode,
          PackagedPolicyDetails,
          LocationRU,
          Insureds,
          ProducerInfo,
          SubProducerInfo,
          VehicleRU,
        },
      } = payload;

      const legacyContactArr = [
        {
          contactType: ContactTypes.POLICY_HOLDER,
          firstName: Insureds.FirstName,
          lastName: Insureds.LastName,
          company: null,
          address: {
            addressLine1: Insureds.PrimaryAddress.AddressLine1,
            addressLine2: Insureds.PrimaryAddress.Description,
            city: Insureds.PrimaryAddress.City,
            state: Insureds.PrimaryAddress.State,
            postalCode: Insureds.PrimaryAddress.PostalCode,
          },
          contactInfo: {
            email: '',
            phone: [
              {
                type: PhoneTypes.HOME,
                number:
                  Insureds.HomePhone !== '0000000000' ? Insureds.HomePhone : '',
              },
              {
                type: PhoneTypes.WORK,
                number:
                  Insureds.BusinessPhone !== '0000000000'
                    ? Insureds.BusinessPhone
                    : '',
              },
              { type: PhoneTypes.MOBILE, number: '' },
            ],
          },
        },
        {
          contactType: ContactTypes.PRODUCER,
          company: ProducerInfo.ContactInfo.Name,
          address: {
            addressLine1: ProducerInfo.ContactInfo.PrimaryAddress.AddressLine1,
            addressLine2: ProducerInfo.ContactInfo.PrimaryAddress.Description,
            city: ProducerInfo.ContactInfo.PrimaryAddress.City,
            state: ProducerInfo.ContactInfo.PrimaryAddress.State,
            postalCode: ProducerInfo.ContactInfo.PrimaryAddress.PostalCode,
          },
          contactInfo: {
            email: ProducerInfo.ContactInfo.EmailAddress1,
            phone: [
              {
                type: PhoneTypes.WORK,
                number:
                  ProducerInfo.ContactInfo.BusinessPhone !== '0000000000'
                    ? ProducerInfo.ContactInfo.BusinessPhone
                    : '',
              },
              { type: PhoneTypes.MOBILE, number: '' },
            ],
          },
        },
      ];

      SubProducerInfo &&
        legacyContactArr.push({
          contactType: ContactTypes.SUBPRODUCER,
          company: SubProducerInfo.ContactInfo.Name,
          address: {
            addressLine1: SubProducerInfo.ContactInfo.PrimaryAddress.AddressLine1,
            addressLine2: SubProducerInfo.ContactInfo.PrimaryAddress.Description,
            city: SubProducerInfo.ContactInfo.PrimaryAddress.City,
            state: SubProducerInfo.ContactInfo.PrimaryAddress.State,
            postalCode: SubProducerInfo.ContactInfo.PrimaryAddress.PostalCode,
          },
          contactInfo: {
            email: SubProducerInfo.ContactInfo.EmailAddress1,
            phone: [
              {
                type: PhoneTypes.WORK,
                number:
                  SubProducerInfo.ContactInfo.BusinessPhone !== '0000000000'
                    ? SubProducerInfo.ContactInfo.BusinessPhone
                    : '',
              },
              { type: PhoneTypes.MOBILE, number: '' },
            ],
          },
        });

      let coverages = [];

      if (PackagedPolicyDetails) {
        coverages = PackagedPolicyDetails.map((p) => {
          let cov;
          p.ItemDescription !== 'DEDUCTIBLE'
            ? (cov = {
                itemDescription: p.ItemDescription,
                covDescription: p.CovDescription,
                exposureLimit: p.LiabAmount,
              })
            : (cov = {
                itemDescription: p.ItemDescription,
                covDescription: p.CovDescription,
                exposureLimit: '$0',
                deductible: p.LiabAmount,
              });
          return cov;
        });
      }

      let legacyRiskLocation;
      if (LocationRU) {
        legacyRiskLocation = {
          address: {
            ...state.policyLocation.address,
            ...{
              addressLine1: LocationRU.Address.AddressLine1,
              city: LocationRU.Address.City,
              postalCode: LocationRU.Address.PostalCode,
              state: LocationRU.Address.State,
            },
          },
          occupancy: LocationRU.LocationBasedRisks.Occupancy,
        };
      }
      if (VehicleRU) {
        legacyRiskLocation = {
          address: {
            ...state.policyLocation.address,
            ...{
              addressLine1: Insureds.PrimaryAddress.AddressLine1,
              city: Insureds.PrimaryAddress.City,
              postalCode: Insureds.PrimaryAddress.PostalCode,
              state: Insureds.PrimaryAddress.State,
            },
          },
          occupancy: '',
        };
      }

      let legacyRiskUnits = [];
      if (VehicleRU) {
        Array.isArray(VehicleRU)
          ? (legacyRiskUnits = VehicleRU.map((ru) => {
              const { VehicleYear, Model, Manufacturer, Vin, Style } =
                ru.Vehicle;
              return {
                id: uuidv4(),
                make: Manufacturer,
                model: Model ?? 'Unknown',
                year: VehicleYear,
                vin: Vin,
                style: Style,
                isOnPolicy: true,
                isSelected: false,
                estimateReceived: null,
                estimatedRepairCost: '',
              };
            }))
          : (legacyRiskUnits = [
              {
                id: uuidv4(),
                make: VehicleRU.Vehicle.Manufacturer,
                model: VehicleRU?.Vehicle?.Model ?? 'Unknown',
                year: VehicleRU.Vehicle.VehicleYear,
                vin: VehicleRU.Vehicle.Vin,
                style: VehicleRU.Vehicle.Style,
                isOnPolicy: true,
                isSelected: false,
                estimateReceived: null,
                estimatedRepairCost: '',
              },
            ]);
      }

      return {
        ...state,
        policyNumber: PolicyNumber,
        type: localStorage.getItem('policyType') && '',
        status: PolicyStatus,
        effectiveDate: PolicyEffectiveDate,
        expirationDate: PolicyExpirationDate,
        productCode: ProductCode,
        contacts: legacyContactArr,
        coverages,
        policyLocation: legacyRiskLocation,
        riskUnits: legacyRiskUnits,
      };
    case GET_GW_POLICY:
      const { Envelope } = payload.retrievePolicyResponse['return'];

      const { CCAddress, CCContact, CCPolicyLocation, CCPolicy } = Envelope;
      const { Status } = CCPolicy;
      const gwPolicyNumber = CCPolicy.PolicyNumber;
      const namedInsuredId = CCPolicy.Insured;
      const isVisselType = CCPolicy.PolicyType.toLowerCase() === vesselType.boat || 
                              CCPolicy.PolicyType.toLowerCase() === vesselType.yacht;
      const policyType = localStorage.getItem('policyType');

      let namedInsured;
      if (Array.isArray(CCContact)) {
        namedInsured = CCContact.find(
          (element) => element.ID === namedInsuredId
        );
      } else if (
        CCContact.hasOwnProperty('ID') &&
        CCContact.ID === namedInsuredId
      ) {
        namedInsured = CCContact;
      }

      const namedInsuredAddressId = namedInsured.PrimaryAddress;
      let namedInsuredAddress;
      if (Array.isArray(CCAddress)) {
        namedInsuredAddress = CCAddress.find(
          (element) => element.ID === namedInsuredAddressId
        );
      } else if (
        CCAddress.hasOwnProperty('ID') &&
        CCAddress.ID === namedInsuredAddressId
      ) {
        namedInsuredAddress = CCAddress;
      }

      const gwContactArr = [
        {
          contactType: ContactTypes.POLICY_HOLDER,
          firstName: toTitleCase(namedInsured.FirstName),
          lastName: toTitleCase(namedInsured.LastName),
          company: null,
          address: {
            addressLine1: namedInsuredAddress.AddressLine1,
            addressLine2: '',
            city: namedInsuredAddress.City,
            state: namedInsuredAddress.State,
            postalCode: namedInsuredAddress.PostalCode,
          },
          contactInfo: {
            primaryPhoneType: namedInsured.PrimaryPhone,
            email: namedInsured.hasOwnProperty('EmailAddress1')
              ? namedInsured.EmailAddress1
              : '',
            phone: [
              {
                type: PhoneTypes.HOME,
                number: namedInsured.hasOwnProperty('HomePhone')
                  ? namedInsured.HomePhone
                  : '',
              },
              {
                type: PhoneTypes.WORK,
                number: namedInsured.hasOwnProperty('WorkPhone')
                  ? namedInsured.WorkPhone
                  : '',
              },
              {
                type: PhoneTypes.MOBILE,
                number: namedInsured.hasOwnProperty('CellPhone')
                  ? namedInsured.CellPhone
                  : '',
              },
            ],
          },
        },
      ];

      //const policyLocationId = getPolicyLocationAddress(CCPolicyLocation);
      
      
      const contactId = CCPolicy.Insured;
      let contact;
      if (Array.isArray(CCContact)) {
        contact = CCContact.find((element) => element.ID = contactId)
      } else {
        contact = CCContact;
      }
      
      let policyLocationId = contact.PrimaryAddress;
      let policyLocationAddress;
      if (Array.isArray(CCAddress)) {
        policyLocationAddress = CCAddress.find(
          (element) => element.ID === policyLocationId
        );
      } else if (
        CCAddress.hasOwnProperty('ID') &&
        CCAddress.ID === policyLocationId
      ) {
        policyLocationAddress = CCAddress;
      }

      const { EffectiveDate, ExpirationDate, RiskUnits } = CCPolicy;

      let gwRiskUnits = [];
      if (policyType === PolicyTypes.RESIDENTIAL.toLowerCase()) {
        // This isnt needed at this time, but may be needed in the future...
        if (Array.isArray(RiskUnits)) {
          gwRiskUnits = RiskUnits.map((ru) => {
            
            const policyLocation = getCCPolicyLocationById(ru.PolicyLocation, CCPolicyLocation);
            const address = getAddressById(policyLocation.Address, CCAddress);

            return getResidentialRiskUnit(
              ru,
              address,
              namedInsured,
              EffectiveDate,
              ExpirationDate
            );
          })
        } else {
            const policyLocation = getCCPolicyLocationById(RiskUnits.PolicyLocation, CCPolicyLocation);
            const address = getAddressById(policyLocation.Address, CCAddress);
            const riskUnit = getResidentialRiskUnit(
              RiskUnits,
              address,
              namedInsured,
              EffectiveDate,
              ExpirationDate
            );
            gwRiskUnits.push(riskUnit);
        }
      }
      if (policyType === PolicyTypes.RECREATIONAL.toLowerCase()) {
        
        gwRiskUnits = Array.isArray(RiskUnits)
          ? RiskUnits.map((ru) => {
              return getRecRiskUnit(
                ru,
                isVisselType,
                namedInsured,
                EffectiveDate,
                ExpirationDate
              );
            })
          : [
              getRecRiskUnit(
                RiskUnits,
                isVisselType,
                namedInsured,
                EffectiveDate,
                ExpirationDate
              ),
            ];
      }

      return {
        ...state,
        policyNumber: gwPolicyNumber,
        status: Status,
        isVisselType: isVisselType,
        effectiveDate: moment(EffectiveDate).format('MM/DD/YYYY'),
        expirationDate: moment(ExpirationDate).format('MM/DD/YYYY'),
        productCode: '',
        contacts: gwContactArr,
        coverages: [],
        policyLocation: {
          address: {
            ...policyLocationAddress,
            ...{
              addressLine1: policyLocationAddress.AddressLine1,
              city: policyLocationAddress.City,
              postalCode: policyLocationAddress.PostalCode,
              state: policyLocationAddress.State,
            },
          },
          occupancy: policyLocationAddress.AddressType,
        },
        riskUnits: gwRiskUnits,
      };

    default:
      return state;
  }
};
