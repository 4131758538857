import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import 'react-vertical-timeline-component/style.min.css';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { sendEventLogRequest } from '../../actions/eventActions';
import { getContainerOffset } from '../../actions/layoutActions';
import { uploadAttachment } from '../../actions/attachments';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Thumbnails from '../common/Thumbnails';
import CustomDialog from '../dialogs/CustomDialog';
import PDFViewer from '../common/PDFViewer';
import { v4 as uuidv4 } from 'uuid';
import OffsetGrid from '../layout/OffsetGrid';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { FileUploadStatus } from '../../actions/types';

const useStyles = makeStyles({
  input: {
    backgroundColor: '#f5f5f7',
  },
  thumbnailContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginTop: '1rem',
    justifyContent: 'center',
  },
  img: {
    display: 'block',
    maxWidth: '800px',
    maxHeight: '800px',
    width: 'auto',
    height: 'auto',
  },
});

const UploadDocumentsComponent = ({ claim }) => {
  const { logId } = claim;
  const { deviceType } = getDeviceTypeInfo();
  const maxLength = 36;

  useEffect(() => {
    window.scrollTo(0, 0);
    var event = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Upload Documents'}",
    };

    sendEventLogRequest(event);
  }, []);

  useEffect(() => {
    return () => {
      filesToRevoke.forEach((file) => {
        URL.revokeObjectURL(file);
      });
    };
  }, []);

  const [attachments, setAttachments] = useState([]);
  const [filesToRevoke, setFilesToRevoke] = useState([]);
  const [selectedAttachment, setSelectedAttachment] = useState({ name: '' });
  const [showImageView, setShowImageView] = useState(false);
  const [showPdfView, setShowPdfView] = useState(false);
  const [showFileSizeAlert, setShowFileSizeAlert] = useState(false);
  const [fileError, setFileError] = useState('');
  const [removeCloseIcon, setRemoveCloseIcon] = useState(false);
  const [isUploadingAttachments, setIsUploadingAttachments] = useState(false);

  useEffect(() => {
    if (showFileSizeAlert) {
      setTimeout(() => {
        setShowFileSizeAlert(false);
      }, 7000);
    }
  }, [showFileSizeAlert]);

  function nameLengthValidator(file) {
    if (file.name.length > maxLength) {
      return {
        code: "name-too-large",
        message: `File name with extension cannot exceed ${maxLength} characters`
      };
    }
  
    return null
  }

  const handleCloseAttachmentViewDialog = () => {
    setShowImageView(false);
  };

  const handleSubmit = async (e) => {
    setRemoveCloseIcon(true);
    setIsUploadingAttachments(true);

    const attachmentUploadResults = await Promise.all(
      attachments
        .filter(
          (attachment) => attachment.uploadStatus !== FileUploadStatus.SUCCESS
        )
        .map((attachment) => uploadAttachment(claim.claimNumber, attachment))
    );

    const clonedAttachments = [...attachments];

    attachmentUploadResults.forEach((result) => {
      const index = clonedAttachments.findIndex(
        (item) => item.id === result.id
      );
      if (index !== -1) {
        clonedAttachments[index].uploadStatus = result.status;
      }
    });
    setAttachments(clonedAttachments);
    setRemoveCloseIcon(false);
    setIsUploadingAttachments(false);
  };

  const classes = useStyles();

  return (
    <>
      {/* <OffsetGrid offset={`${getContainerOffset(deviceType)}`}> */}
        <Typography variant='h1' align='center' color='primary' gutterBottom>
          Upload documents and photos
        </Typography>
        <Typography gutterBottom variant='subtitle1' align='center'>
          This can include police and fire reports, photos you took of the
          damage, repair estimates from a contractor, and receipts for your
          expenses. If you discover any additional damages or have anything else to add, such as photos or other documents at a later time, please notify your adjuster.
        </Typography>
        <Box mt={3}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <div className={classes.thumbnailContainer}>
                <Thumbnails
                  attachments={attachments}
                  removeCloseIcon={removeCloseIcon}
                  onClearImage={(e) => {
                    const attachmentId = e.currentTarget.id;
                    const file = attachments.find(
                      (attachment) => attachment.id === attachmentId
                    );
                    const filteredAttachments = attachments.filter(
                      (attachment) => attachment.id !== attachmentId
                    );
                    setAttachments(filteredAttachments);
                    // file && deleteAttachment(logId, file.name);
                  }}
                  onClick={(e) => {
                    const file = attachments.find((f) => f.id === e.target.id);
                    setSelectedAttachment(file);
                    if (file.type === 'application/pdf') {
                      setShowPdfView(true);
                    } else {
                      setShowImageView(true);
                    }
                  }}
                />
              </div>
            </Grid>
            {showFileSizeAlert && (
              <Grid item xs={12}>
                <Alert
                  severity='error'
                  onClose={() => setShowFileSizeAlert(false)}
                  style={{ whiteSpace: 'pre-line' }}>
                  <AlertTitle>File Upload Error</AlertTitle>
                  {fileError}
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <Dropzone
                maxSize={50000000}
                multiple={true}
                accept={['.jpg', '.jpeg', '.png', '.pdf']}
                onDropAccepted={(files) => {
                  const filesWithPreview = files.map((file) => {
                    return {
                      id: uuidv4(),
                      file,
                      url: URL.createObjectURL(file),
                      uploadStatus: FileUploadStatus.PENDING,
                    };
                  });

                  setAttachments((prev) => [...prev, ...filesWithPreview]);

                  setFilesToRevoke((prev) => [...prev, ...files]);
                }}
                onDropRejected={(files) => {
                  let errorMessage = showFileSizeAlert ? fileError : '';
                  files.forEach((file) => {
                    file.errors.forEach((error) => {
                      const { code, message } = error;

                      if (code === 'file-too-large') {
                        errorMessage += `${file.file.name} exceeds 50 MB file size limit \r\n`;
                      } else {
                        errorMessage += `${message} \r\n`;
                      }
                    });
                  });

                  errorMessage !== '' && setFileError(errorMessage);
                  files.length > 0 && setShowFileSizeAlert(true);
                }}
                validator={nameLengthValidator}>
                {({ getRootProps, getInputProps }) => (
                  <Paper
                    {...getRootProps({ className: 'dropzone' })}
                    align='center'>
                    <Box p={5}>
                      <input
                        name='files'
                        className={classes.input}
                        {...getInputProps()}
                      />
                      <Typography varient={'body2'} color={'textSecondary'}>
                        Drag 'n' drop files, or click to select
                      </Typography>
                    </Box>
                  </Paper>
                )}
              </Dropzone>
            </Grid>
          </Grid>
        </Box>
      {/* </OffsetGrid> */}
      <Box pt={5} pb={5} style={{ textAlign: 'center' }}>
        {/* <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.backButton}>
          Back
        </Button>
        <Button
          style={{ margin: '5px' }}
          onClick={handleSubmit}
          variant='contained'>
          Skip
        </Button> */}
        <Button
          variant='contained'
          color='primary'
          onClick={handleSubmit}
          disabled={isUploadingAttachments}>
          {!isUploadingAttachments ? 'Upload' : 'Uploading...'}
        </Button>
      </Box>
      <CustomDialog
        title={selectedAttachment.name}
        maxWidth='xl'
        open={showImageView}
        handleClose={() => setShowImageView(false)}>
        {<img className={classes.img} src={selectedAttachment.url} />}
      </CustomDialog>
      {showPdfView && (
        <CustomDialog
          title={selectedAttachment.name}
          maxWidth='xl'
          open={showPdfView}
          handleClose={() => setShowPdfView(false)}>
          {<PDFViewer src={selectedAttachment.url} />}
        </CustomDialog>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
});

UploadDocumentsComponent.propTypes = {
  claim: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, null)(UploadDocumentsComponent);
