export function blobToBase64(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export const base64ToBlob = (base64) => {
  const raw = atob(base64);
  const rawLength = raw.length;
  const blobArray = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i++) {
    blobArray[i] = raw.charCodeAt(i);
  }

  const blob = new Blob([blobArray], { type: 'application/pdf' });

  return blob;
};
