import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { UPDATE_CLAIM, SUBMIT_CLAIM, RETRIEVE_ATTACHMENTS, UPLOAD_ATTACHMENT, DELETE_ATTACHMENT, PhoneTypes, ClaimStatus, DriverTemplate, InjuredTemplate } from '../actions/types';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  showStepper: true,
  claimNumber: '',
  logId: uuidv4(),
  confirmationId: '',
  contactMethod: 'Internet',
  contactSource: '',
  type: 'unknown',
  isVessel: false,
  policyNumber: '',
  namedInsured: {
    firstName: '',
    lastName: '',
    company: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
    },
    contactInfo: {
      primaryPhoneType: '',
      emailAddress1: '',
      phone: [{ type: PhoneTypes.MOBILE, number: '', mobileProvider: '', mobileProviderOther: '' }],
      confirmEmail: ''
    },
  },
  reportingParty: {
    reporter: 'Other',
    firstName: '',
    lastName: '',
    company: '',
    relationToInsured: '',
    address: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
    },
    contactInfo: {
      primaryPhoneType: '',
      email: '',
      phone: [{ type: PhoneTypes.MOBILE, number: '', mobileProvider: '', mobileProviderOther: '' }],
    },
    isOptedIn: null,
  },
  status: ClaimStatus.STARTED,
  submittedOn: null,
  submittedTimeZone: null,
  damageType: [],
  lossCause: null,
  lossSource: null,
  description: null,
  totalLoss: false,
  claimReporter: {
    firstName: '',
    lastName: '',
    allowEFTPayment: false,
    allowEmailText: false,
    subtype: 'Person',
    primaryAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        Country: 'US',
    },
    primaryPhoneType: '',
    isWorkPhoneProvided: null,
    workNumber: '',
    cellNumber: '',
    emailAddress1: '',
  },
  reportedByType: null,
  lossDate: null,
  lossLocation: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      Country: 'US',
  },
  eventDetails: {
    description: '',
    repairDone: true,
    vehicleIncidentCount: 0,
    isVehicleParties: false,
    vehicleIncidents: [],
    driverVehicleDetails: {
      vehicles: [],
      driver: DriverTemplate,
      missing: null,
      whatIsMissing: null,
      vandalized: null,
      windshieldDamageOnly: null,
      beenRecovered: null,
      missingHowLong: null,
      towCompleted: null,
      towAmount: null,
      towAmountPaid: null,
      driverRule: null
    },
    otherVehiclesDamaged: null,
    residentialIncidents: [],
    anyOneInjured: 'no',
    injuredPartiesCause: null,
    injuredPartiesCount: 0,
    isPolicyHolderInjured: null,
    isInjuredParties: false,
    injuredParties: [InjuredTemplate],
    policeOrFireInvolved: null
  },
  isVerifiedPolicy: null,
  officials: [
    {
      officialType: 'police',
      reportingAgency: ''
    },
  ],
  contacts: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CLAIM:
      const id = payload.logId ? payload.logId : state.logId;
      return {
        ...state,
        ...payload,
        confirmationId: `CS-${id.substring(31,36).toUpperCase()}`
      };
    case SUBMIT_CLAIM:
      return {
        ...state,
        claimNumber: payload.claimNumber
      }
    // case RETRIEVE_ATTACHMENTS:
    //   const { attachments } = payload;
    //   return {
    //     ...state,
    //     lossDetails: {...state.lossDetails, attachments }
    //   }
    case UPLOAD_ATTACHMENT:
      // const lossDetailsToUpdate = {...state.lossDetails};
      
      // const index = lossDetailsToUpdate.attachments.findIndex((attachment) => attachment.name === payload.name);
      // if (index !== -1) {
      //   lossDetailsToUpdate.attachments.splice(index, 1, payload);
      // } else {
      //   lossDetailsToUpdate.attachments.push(payload);
      // }
      return {
        ...state,
        // lossDetails: lossDetailsToUpdate
      }
    case DELETE_ATTACHMENT:
      //const attachmentsCopy = [...state.lossDetails.attachments];
      const attachmentsCopy = [...state.attachments];
      const attachmentToRemoveIndex = attachmentsCopy.findIndex((attachment) => attachment.name === payload);
      if (attachmentToRemoveIndex !== -1) {
        attachmentsCopy.splice(attachmentToRemoveIndex, 1);
      }
      return {
        ...state,
        attachments: attachmentsCopy
      }
    default:
      return state;
  }
};
