import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'react-vertical-timeline-component/style.min.css';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import { Form, useFormikContext, ErrorMessage } from 'formik';
import FormikDateTimePicker from '../common/FormikDateTimePicker';
import { showLoading } from '../../actions/loading';
import { setAlert } from '../../actions/alert';
import OffsetGrid from '../layout/OffsetGrid';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import InputAdornment from '@material-ui/core/InputAdornment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import { FormLabel } from "@material-ui/core";

import FormHelperText from '@material-ui/core/FormHelperText';
import InputMask from 'react-input-mask';
import MuiTextField from '@material-ui/core/TextField';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import ErrorFocus from '../common/ErrorFocus';

const PolicyLossTsComponent = ({ isLoading }) => {
  const [showWhereToFindPolicy, setShowWhereToFindPolicy] = useState(false);
  // const [showDateTooltip, setShowDateTooltip] = useState(false);
  const { deviceType } = getDeviceTypeInfo();

  const {
    setFieldValue,
    handleBlur,
    handleChange,
    values,
  } = useFormikContext();

  const PolicyImagesComponent = () => {
    const images = [
      {
        url: require('../../img/premiumNotice.jpg'),
        title: 'Insurance Premium Notice',
      },
      {
        url: require('../../img/FinalBillingInvoice.jpg'),
        title: 'Billing Invoice',
      },
      {
        url: require('../../img/paymentCoupon.jpg'),
        title: 'Payment Coupon',
      },
      {
        url: require('../../img/FinalCoupon.jpg'),
        title: 'Billing Coupon',
      },
    ];
    return (
      <>
        <Card
          elevation={3}
          style={{
            // width: '75%',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            padding: '0.5rem',
          }}>
          <CardContent align='center'>
            <Grid container spacing={1} orientation={'column'}>
              {images.map((obj) => {
                return (
                  <Grid
                    item
                    key={obj.title}
                    xs={12}
                    style={{ textAlign: 'center' }}>
                    <img
                      key={obj.title}
                      width={'100%'}
                      src={obj.url}
                      alt={obj.title}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  };

  const { palette } = useTheme();

  const useStyles = makeStyles((theme) => ({
    infoIcon: {
      color: 'black', 
      marginRight: '0.5em'
    },
    label: {
      marginBottom: '32px',
      display: 'block'
    },
    form: {
      margin: '0 5px',
    },
    title: {
      color: palette.text.secondary,
      fontSize: '32px',
      fontWeight: 'bold',
    },
  }));

  const classes = useStyles();

  const getContainerOffset = (deviceType) => {
    let padding = null;
    deviceType === 'Mobile' ? (padding = 0) : (padding = 5);
    return padding;
  };

  return (
    <>
      <OffsetGrid offset={`${getContainerOffset(deviceType)}`}>
        <Box mb={4}>
          <Typography
              variant='subtitle1'
              align='left'>
              File a Claim
          </Typography>
          <Typography variant="h2" className={ classes.title }>
            {/* Let’s retrieve your policy at the time of loss */}
            Let's start with your policy number & date of loss.
          </Typography>
          {/* <Typography gutterBottom variant='subtitle1' align='center'>
            Enter the date and time of the loss along with the policy number if
            you have it. If you don't know the exact time, enter the time you
            noticed the loss. If you don't know your policy number, click on the
            info icon below next to the text field for where to find it.
          </Typography> */}
        </Box>
        {!isLoading && (
          <Form id='policyLoss'>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormLabel className={classes.label}>Policy Number associated</FormLabel>
                <FormControl fullWidth>
                  <InputMask
                    id={'policyNumber'}
                    name={'policyNumber'}
                    mask='*************'
                    maskChar=''
                    value={values.policyNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={false}>
                    {(props) => (
                      <MuiTextField
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <Tooltip
                                disableFocusListener
                                interactive
                                arrow
                                title={
                                  'Your American Modern policy number uniquely identifies who you are and helps us find your policy coverages and details quickly. Your policy number can be found on your policy declarations page, on top of your bill, or on your proof of insurance card. Click here for some examples of where to find it.'
                                }>
                                <IconButton
                                  style={{ padding: '0' }}
                                  onClick={() => {
                                    setShowWhereToFindPolicy(true);
                                  }}>
                                  <HelpOutlineIcon
                                    className={classes.infoIcon}
                                    color={'disabled'}
                                    fontSize={'small'}
                                  />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                        placeholder='Policy Number'
                        {...props}
                      />
                    )}
                  </InputMask>
                </FormControl>
                <ErrorMessage name={'policyNumber'}>
                  {(msg) => (
                    <FormHelperText style={{ color: '#f44336' }}>
                      {msg}
                    </FormHelperText>
                  )}
                </ErrorMessage>
                <Dialog
                  open={showWhereToFindPolicy}
                  onClose={() => {
                    setShowWhereToFindPolicy(false);
                  }}
                  aria-labelledby='find-policy-numbers'
                  aria-describedby='show-where-to-find-policy-numbers'>
                  <DialogTitle>
                    <Grid container spacing={0}>
                      <Grid item xs={10}>
                        <Box align={'left'} style={{ verticalAlign: 'top' }}>
                          <Typography>Where to find Policy Number</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          align={'right'}
                          p={0}
                          style={{ verticalAlign: 'top' }}>
                          <IconButton
                            style={{ float: 'right', padding: '0' }}
                            aria-label='close-policy-modal'
                            onClick={() => {
                              setShowWhereToFindPolicy(false);
                            }}>
                            <CloseIcon fontSize={'small'} />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <PolicyImagesComponent />
                  </DialogContent>
                </Dialog>
              </Grid>
              <Grid item xs={12}>
                <FormLabel className={classes.label}>Date of incident</FormLabel>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <FormikDateTimePicker
                    data-test-id='lossDate-textfield'
                    name='lossDate'
                    placeholder='Select a Date'
                    disableFuture
                    onChange={(date) => setFieldValue('lossDate', date)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Tooltip
                            disableFocusListener
                            interactive
                            arrow
                            title={
                              'It’s important for us to know when the loss took place so we can accurately service your claim. Please provide us the date and time the loss took place, if you are unsure of the exact time please estimate or let us know when you first noticed the loss.'
                            }>
                            <CalendarTodayIcon
                              className={classes.infoIcon}
                              color={'disabled'}
                              fontSize={'small'}
                            />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <ErrorFocus />
          </Form>
        )}
      </OffsetGrid>
    </>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
  policy: state.policy,
});

PolicyLossTsComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  policy: PropTypes.object.isRequired,
  setAlert: PropTypes.func.isRequired,
  showLoading: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { setAlert, showLoading })(
  PolicyLossTsComponent
);
