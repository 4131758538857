import moment from "moment";

const hasData = (data) => {
    if (!(typeof (data) !== "undefined" && data !== null) || data === "") {
        return false;
    }
    return true;
};

const formatDateForTable = (date) => {
    return hasData(date) ? (moment.utc(date).format("M/D/YYYY")) : "-";
};

const formatPhoneNumber = (phoneNumber) => {
    if (hasData(phoneNumber)) {
        const regex = new RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);
        const results = regex.exec(phoneNumber.trim());
        if (results !== null && results.length > 8) {
            return `(${results[3]}) ${results[4]}-${results[5]}${typeof results[8] !== 'undefined' ? ` x${results[8]}` : ''}`;
        }
        return phoneNumber;
    }
    return '-';
};

const formatText = (text) => {
    return hasData(text) ? text.charAt(0).toUpperCase() + text.substring(1).toLowerCase() : "-";
};

export default {
    formatDateForTable: formatDateForTable,
    formatPhoneNumber: formatPhoneNumber,
    formatText: formatText
};
