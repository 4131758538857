import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';
import { recreationalDamageSchema } from '../../validation/claimsSchemas';
import { TextField } from 'formik-material-ui';
import { sendEventLogRequest } from '../../actions/eventActions';
import { updateClaim } from '../../actions/claimActions';
import { getContainerOffset } from '../../actions/layoutActions';
import ComponentToggle from '../common/ComponentToggle';
import OffsetGrid from '../layout/OffsetGrid';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import CurrencyField from '../common/CurrencyField';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import WitnessComponent from './recreational/WitnessComponent';
import OfficialsComponent from './OfficialsComponent';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
import FormikSelect from '../common/FormikSelect';
import MenuItem from '@material-ui/core/MenuItem';
import AddressComponent from '../common/AddressComponent';
import PhoneComponent from '../common/PhoneComponent';
import { PhoneTypes } from '../../actions/types';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box pt={1}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    margin: '1.5rem 0',
  },
  iconBtn: {
    paddingLeft: '0px',
  },
}));

const RecreationalDamageComponent = ({
  claim,
  activeStep,
  handleNext,
  handleBack,
  updateClaim,
}) => {
  const { logId } = claim;
  const { deviceType } = getDeviceTypeInfo();
  const [value, setValue] = useState(0);
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);

  const initialValues = { ...claim.lossDetails.incidents[0] };

  useEffect(() => {
    window.scrollTo(0, 0);
    var event = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Vehicle Damage Incident'}",
    };

    sendEventLogRequest(event);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const classes = useStyles();

  return (
    <OffsetGrid offset={getContainerOffset(deviceType)}>
      <Typography variant='h1' align='center' color='primary' gutterBottom>
        Tell us what happened
      </Typography>
      <Typography variant={'subtitle1'} align={'center'} gutterBottom>
        Please describe the damage to the vehicle(s). How did it happened?
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={recreationalDamageSchema}
        onSubmit={(values) => {
          if (values) {
            const valuesCopy = { ...values };
            if (!showAdditionalInfo) {
              valuesCopy.witnesses = [];
              valuesCopy.officials = [];
            }
            const newClaim = { ...claim };
            newClaim.lossDetails.incidents[0] = valuesCopy;
            updateClaim({ ...newClaim });
          }
          handleNext();
        }}>
        {({ values, handleSubmit, setFieldValue }) => (
          <Form id='vehicle-damage-form' onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  name='incidentDesc'
                  component={TextField}
                  placeholder='Please provide a description of the property damage'
                  variant='outlined'
                  multiline
                  rows={4}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormikSelect name='source' label='Source of the damage'>
                  <MenuItem value='fixedobjcoll'>
                    Collision with Fixed Object
                  </MenuItem>
                  <MenuItem value='singlevehiclecollision_ext'>
                    Collision - Single Vehicle or Vessel
                  </MenuItem>
                  <MenuItem value='animalinjurydamage_ext'>
                    Injury or Damage caused by Animal
                  </MenuItem>
                  <MenuItem value='glass_ext'>Glass</MenuItem>
                  <MenuItem value='theft_ext'>Theft</MenuItem>
                  <MenuItem value='vandalismmischief_ext'>Vandalism</MenuItem>
                </FormikSelect>
              </Grid>
              <FieldArray
                name='vehicles'
                render={(arrayHelpers) =>
                  values.vehicles.map((vehicle, index) => (
                    <Grid container item key={vehicle.id}>
                      <Grid item xs={12}>
                        <Box my={2}>
                          <Typography align='left' variant='h6' color='primary'>
                            {`${moment(vehicle.year).format('YYYY')} ${
                              vehicle.make
                            } ${vehicle?.model ?? 'Unknown'}`}
                          </Typography>
                          <Divider style={{ marginBottom: '1rem' }} />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name={`vehicles.${index}.estimateReceived`}
                          component={ComponentToggle}
                          title={'Have you received a repair estimate?'}
                          displayOnSelected={true}
                          handleButtonChange={(value) =>
                            setFieldValue(
                              `vehicles.${index}.estimateReceived`,
                              value
                            )
                          }
                        />
                      </Grid>
                      {vehicle.estimateReceived && (
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={12} sm={6}>
                              <CurrencyField
                                fullWidth
                                name={`vehicles.${index}.estimatedRepairCost`}
                                label='Total estimated repair value'
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {!vehicle.isOnPolicy && !vehicle.owner.isInsureds && (
                        <Grid item xs={12}>
                          <Field
                            name={`vehicles.${index}.hasDriverDetails`}
                            component={ComponentToggle}
                            title={`Can you provide the driver's information`}
                            displayOnSelected={true}
                            margin='dense'
                            handleButtonChange={(value) => {
                              setFieldValue(
                                `vehicles.${index}.hasDriverDetails`,
                                value
                              );
                              if (value) {
                                setFieldValue(`vehicles.${index}.driver`, {
                                  firstName: '',
                                  lastName: '',
                                  address: {
                                    addressLine1: '',
                                    addressLine2: '',
                                    city: '',
                                    state: '',
                                    postalCode: '',
                                  },
                                  contactInfo: {
                                    primaryPhoneType: '',
                                    phone: [],
                                  },
                                });
                              } else {
                                setFieldValue(`vehicles.${index}.driver`, null);
                              }
                            }}
                          />
                        </Grid>
                      )}
                      {vehicle.hasDriverDetails && (
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Field
                              label='First Name'
                              name={`vehicles.${index}.driver.firstName`}
                              component={TextField}
                              margin='dense'
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              label='Last Name'
                              component={TextField}
                              name={`vehicles.${index}.driver.lastName`}
                              margin='dense'
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <PhoneComponent
                              prefix={`vehicles.${index}.driver.contactInfo`}
                              contactInfo={vehicle.driver.contactInfo}
                              allowOptional={true}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AddressComponent
                              prefix={`vehicles.${index}.driver.address`}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  ))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Box my={2}>
                <Divider style={{ marginBottom: '1rem' }} />
              </Box>
            </Grid>
            {!showAdditionalInfo && (
              <Grid item xs={12}>
                <Box my={2}>
                  <Button
                    className={classes.iconBtn}
                    color={'primary'}
                    size={'small'}
                    onClick={() => setShowAdditionalInfo(!showAdditionalInfo)}>
                    <AddCircleOutlineIcon fontSize={'small'} /> &nbsp; Add
                    Additional Information
                  </Button>
                </Box>
              </Grid>
            )}
            {showAdditionalInfo && (
              <>
                <Grid container item spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      className={classes.iconBtn}
                      color={'primary'}
                      size={'small'}
                      onClick={() =>
                        setShowAdditionalInfo(!showAdditionalInfo)
                      }>
                      <RemoveCircleOutlineIcon fontSize={'small'} /> &nbsp;
                      Remove Additional Information
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.tabs}>
                  <AppBar position='static' color='default'>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor='primary'
                      textColor='primary'
                      variant='fullWidth'
                      aria-label='additional information tabs'>
                      <Tab label='Witnesses' {...a11yProps(0)} />
                      <Tab label='Officials' {...a11yProps(1)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0}>
                    <WitnessComponent />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <OfficialsComponent />
                  </TabPanel>
                </Grid>
              </>
            )}
          </Form>
        )}
      </Formik>
      <Box p={5} style={{ textAlign: 'center' }}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.backButton}>
          Back
        </Button>
        <Button
          type='submit'
          form='vehicle-damage-form'
          variant='contained'
          color='primary'>
          Next
        </Button>
      </Box>
    </OffsetGrid>
  );
};

const mapStateToProps = (state) => ({
  claim: state.claim,
});

RecreationalDamageComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updateClaim })(
  RecreationalDamageComponent
);
