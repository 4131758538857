import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import OffsetGrid from '../layout/OffsetGrid';
import ClaimInfoComponent from './ClaimInfoComponent';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import {
  getClaimsTrackingSubmission,
  getSubmissionSummaries,
} from '../../actions/claimActions';
import { retrieveAttachments } from '../../actions/attachments';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import AppMenu from '../layout/AppMenu';
import SubmissionTable from '../tables/SubmissionTable';
import { PolicyTypes } from '../../actions/types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// MSAL
import { MsalAuthenticationTemplate, useAccount, useMsal } from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../../auth/authConfig';
import { ErrorComponent } from '../ui/ErrorComponent';
import { LoadingComponent } from '../ui/LoadingComponent';


const ClaimSearchFnolComponent = ({
  claim,
  getSubmissionSummaries,
  getClaimsTrackingSubmission,
  retrieveAttachments,
  authRequired = true,
}) => {
  
  const qs = useLocation().search;
  let submissionId = null;
  qs.indexOf('?', 0) >= 0 && (submissionId = qs.split('?')[1].split('=')[1]);
  
  const { instance, accounts, inProgress } = useMsal();
  
  const authRequest = {
    ...loginRequest,
  };

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
    }
    }, [inProgress, accounts, instance])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}>
      <AppMenu user={accounts.length > 0 ? accounts[0].name : ''} />
      <OffsetGrid offset={2}>
        <Box pt={12} />
      </OffsetGrid>
      <Container style={{ background: '#ededed' }} align='center'>
        <Box p={3}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            centered>
            <Tab label='RENTERS' />
            <Tab label='RESIDENTIAL' />
            <Tab label='RECREATIONAL' />
          </Tabs>
          <Box px={1} pb={1}>
            <div hidden={tabValue !== 0}>
              <SubmissionTable
                submissionId={submissionId}
                claimType={PolicyTypes.RENTERS.toLowerCase()}
              />
            </div>
            <div hidden={tabValue !== 1}>
              <SubmissionTable
                submissionId={submissionId}
                claimType={PolicyTypes.RESIDENTIAL.toLowerCase()}
              />
            </div>
            <div hidden={tabValue !== 2}>
              <SubmissionTable
                submissionId={submissionId}
                claimType={PolicyTypes.RECREATIONAL.toLowerCase()}
              />
            </div>
          </Box>
        </Box>
      </Container>
    </MsalAuthenticationTemplate>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim
});

ClaimSearchFnolComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  retrieveAttachments: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, {
  getClaimsTrackingSubmission,
  retrieveAttachments,
  getSubmissionSummaries,
})(ClaimSearchFnolComponent);
