import { IPS } from '../actions/types';

const initialState = {
  ip:  '',
  version:  '',
  city:  '',
  region:  '',
  region_code:  '',
  country_code:  '',
  country_code_iso3:  '',
  country_name:  '',
  country_capital: '',
  country_tld:  '',
  continent_code:  '',
  in_eu: false,
  postal:  '',
  latitude: null,
  longitude: null,
  timezone:  '',
  utc_offset:  '',
  country_calling_code:  '',
  currency:  '',
  currency_name:  '',
  languages:  '',
  country_area: null,
  country_population: null,
  asn: '',
  org:  '',
  hostname:  ''
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
      case IPS:
      return {
        ...state
      };
    default:
      return state;
  }
};
