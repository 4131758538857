export const claimReceivedText = 'We have received your inspection report. Please allow 5 business days for us to review and contact you. Please feel free to reach out to your adjuster directly should you have additional questions. Please feel free to reach out to your adjuster directly should you have additional questions.';
export const claimAssignedText = 'Please allow 2-3 business days for a claim representative to contact you.';
export const claimUnderReviewText = 'Your Adjuster is in the process of reviewing your claim. Please feel free to reach out to your adjuster directly should you have additional questions.';
export const claimLossInspectionScheduledText = 'The inspector will contact you within 2 business days to schedule the appointment. Please feel free to reach out to your adjuster directly should you have additional questions.';
export const claimInspectionReportText = 'Your inspection has been performed; we await receipt of the report. Please feel free to reach out to your adjuster directly should you have additional questions.';
export const claimInspectionReportReceivedText = 'We have received your inspection report. Please allow 5 business days for us to review and contact you. Please feel free to reach out to your adjuster directly should you have additional questions.';
export const claimPaidAndOpenText = 'Your claim payment has been issued. Please allow 2-3 business days after the issued date for receipt. Please feel free to reach out to your adjuster directly should you have additional questions.';
export const claimPaidAndClosedText = 'Your claim payment has been issued and your file is closed. Please allow 7-10 business days after the issued date for receipt. Please feel free to reach out to your adjuster directly should you have additional questions.';
export const claimClosedText = 'Your claim has been closed. Please feel free to reach out to your adjuster directly should you have additional questions.';
export const claimNotFound = 'We are unable to find that claim. If you would like to chat with a representative, please contact customer service at (800) 375-2075 or contact your agent.';
export const policyNotFound = 'The information provided either does not match our records, or there are no claims present on the policy with a loss date within the past 12 months. Please review the information and reenter or try searching by claim number. ';
export const apiFailed = 'We have encountered an issue processing your request. Please try again later or contact our Customer Service team at (800) 375-2075 for further assistance.';