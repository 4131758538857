
import { UPDATE_STEP_CONTENT_INDEX, UPDATE_COMPLETED_STEPS } from './types';

export const updateStepContentIndex = (index) => dispatch => {
    dispatch({
        type: UPDATE_STEP_CONTENT_INDEX,
        payload: index
    })
}

export const updateCompletedSteps = (completed) => dispatch => {
    dispatch({
        type: UPDATE_COMPLETED_STEPS,
        payload: completed
    })
}