import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { lossLocationSchema } from '../../validation/claimsSchemas';
import { sendEventLogRequest } from '../../actions/eventActions';
import { updateClaim } from '../../actions/claimActions';
import { validateAddress } from '../../actions/policyActions';
import { getContainerOffset } from '../../actions/layoutActions';
import PropTypes from 'prop-types';
import OffsetGrid from '../layout/OffsetGrid';
import AddressValidationDialog from '../dialogs/AddressValidationDialog';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import appSettings from '../../appSettings';
import AddressComponent from '../common/AddressComponent';
import ErrorFocus from '../common/ErrorFocus';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    padding: '1rem',
  },
  cardTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardMedia: {
    width: '13%',
    alignSelf: 'center',
  },
  btn: {
    color: '#fff',
    backgroundColor: '#007bff',
    borderColor: '#007bff',
    '&:hover': {
      color: '#fff',
      background: '#0056b3',
    },
  },
  input: {
    backgroundColor: '#f5f5f7',
  },
  inputSelectLabel: {
    marginLeft: '1rem',
    '&:mui-focused={true}': {
      marginTop: '0.5rem',
    },
  },
  selectOption: {
    padding: '0.25rem',
    marginLeft: '0.25rem',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  validationMessage: {
    fontSize: '0.75rem',
    color: '#f44336',
    marginTop: '3px',
  },
  navigationButtons: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const LossLocationComponent = ({
  claim,
  activeStep,
  handleNext,
  handleBack,
  validateAddress,
  updateClaim,
}) => {
  const { logId } = claim;
  const { deviceType } = getDeviceTypeInfo();
  const VALIDATE_ADDRESS = appSettings.VALIDATE_ADDRESSES === 'true';

  useEffect(() => {
    window.scrollTo(0, 0);
    var event = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Loss Location'}",
    };

    sendEventLogRequest(event);
  }, []);

  const [showAddressValidationDialog, setShowAddressValidationDialog] =
    useState(false);

  const [promptAddress, setPromptAddress] = useState(null);

  const handleCloseAddressValidationDialog = () => {
    setPromptAddress(null);
    setShowAddressValidationDialog(false);
  };

  const handleSubmitAddressValidationDialog = () => {
    if (promptAddress !== ' ') {
      const newClaim = { ...claim, lossLocation: {...claim.lossLocation, ...promptAddress} };
      //newClaim.lossDetails.location = promptAddress;
      updateClaim({ ...newClaim });
    }
    setPromptAddress(null);
    setShowAddressValidationDialog(false);
  };

  const handleSubmit = async (values) => {
    let valRes = { isValid: true };

    if (values) {
      {
        VALIDATE_ADDRESS && (valRes = await validateAddress(values));
      }
      if (!valRes.isValid) {
        setPromptAddress(valRes.address === null ? ' ' : valRes.address);
        setShowAddressValidationDialog(true);
      } else {
        const newClaim = { ...claim, lossLocation: {...claim.lossLocation, ...values } };
        //newClaim.lossDetails.location = values;
        handleNext();
        updateClaim({ ...newClaim });
      }
    }
  };

  const classes = useStyles();

  const initialValues = {
    addressLine1: claim.lossLocation.addressLine1,
    addressLine2: claim.lossLocation.addressLine2,
    city: claim.lossLocation.city,
    state: claim.lossLocation.state,
    postalCode: claim.lossLocation.postalCode,
  };

  return (
    <>
      <OffsetGrid offset={`${getContainerOffset(deviceType)}`}>
        <Typography variant='h1' align='center' color='primary' gutterBottom>
          Now tell us where the loss happened
        </Typography>
        <Typography gutterBottom variant='subtitle1' align='center'>
          Enter the location of the incident. This may be the address of your
          home or a different location. If your loss took place away from your
          residence, we may need some additional information regarding the
          location.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={lossLocationSchema}
          onSubmit={handleSubmit}>
          {({ values, errors, touched, setFieldValue }) => (
            <>
              <Form id='loss-location-form'>
                <Grid container spacing={2}>
                  <AddressComponent />
                </Grid>
                <ErrorFocus />
              </Form>

              <Box p={5} className={classes.navigationButtons}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}>
                  Back
                </Button>
                <Button
                  type='submit'
                  form='loss-location-form'
                  variant='contained'
                  color='primary'>
                  Next
                </Button>
              </Box>
              {promptAddress && (
                <AddressValidationDialog
                  showDialog={showAddressValidationDialog}
                  handleClose={handleCloseAddressValidationDialog}
                  handleOnSubmit={handleSubmitAddressValidationDialog}
                  title='Validate Loss Location Address'
                  promptAddress={promptAddress}
                />
              )}
            </>
          )}
        </Formik>
      </OffsetGrid>
    </>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
});

LossLocationComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { validateAddress, updateClaim })(
  LossLocationComponent
);
