import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { fireDamageSchema } from '../../validation/claimsSchemas';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { TextField } from 'formik-material-ui';
import ComponentToggle from '../common/ComponentToggle';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { sendEventLogRequest } from '../../actions/eventActions';
import { updateClaim } from '../../actions/claimActions';
import { getContainerOffset } from '../../actions/layoutActions';
import { states } from '../../actions/types';
import Typography from '@material-ui/core/Typography';
import OffsetGrid from '../layout/OffsetGrid';
import { ClaimTypes } from '../../actions/types';
import AddressValidationDialog from '../dialogs/AddressValidationDialog';
import { validateAddress } from '../../actions/policyActions';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import ErrorFocus from '../common/ErrorFocus';
import appSettings from '../../appSettings';
import FormikTextArea from '../common/FormikTextArea';
import FormikAutocomplete from '../common/FormikAutocomplete';
import OfficialsComponent from './OfficialsComponent';
import AddressComponent from '../common/AddressComponent';

const FireDamageComponent = ({
  claim,
  activeStep,
  handleBack,
  handleNext,
  updateClaim,
  validateAddress,
}) => {
  const logId = claim.logId;
  const { deviceType } = getDeviceTypeInfo();
  const VALIDATE_ADDRESS = appSettings.VALIDATE_ADDRESSES === 'true';

  useEffect(() => {
    window.scrollTo(0, 0);
    var event = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Fire Damage Incident'}",
    };

    sendEventLogRequest(event);
  }, []);

  const initialValues = {
    type: ClaimTypes.FIRE_DAMAGE,
    fireSourceDesc: claim.lossDetails.incidents[0].fireSourceDesc ?? '',
    q1: {
      selected: claim.lossDetails.incidents[0].q1.selected,
      isHospitalized: claim.lossDetails.incidents[0].q1.isHospitalized,
      firstName: claim.lossDetails.incidents[0].q1.firstName,
      lastName: claim.lossDetails.incidents[0].q1.lastName,
      addressLine1: claim.lossDetails.incidents[0].q1.addressLine1,
      addressLine2: claim.lossDetails.incidents[0].q1.addressLine2,
      city: claim.lossDetails.incidents[0].q1.city,
      state: claim.lossDetails.incidents[0].q1.state,
      postalCode: claim.lossDetails.incidents[0].q1.postalCode,
    },
    q2: {
      selected: claim.lossDetails.incidents[0].q2.selected,
    },
    q3: {
      selected: claim.lossDetails.incidents[0].q3.selected,
    },
    q4: {
      selected: claim.lossDetails.incidents[0].q4.selected
    },
    officials: claim.lossDetails.incidents[0].q4.selected ?? []
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    textField: {
      width: '80%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    validationMessage: {
      fontSize: '0.75rem',
      color: '#f44336',
      marginTop: '3px',
      textAlign: 'left',
    },
  }));

  const classes = useStyles();

  const [
    showAddressValidationDialog,
    setShowAddressValidationDialog,
  ] = useState(false);

  const [promptAddress, setPromptAddress] = useState(null);

  const handleCloseAddressValidationDialog = () => {
    setPromptAddress(null);
    setShowAddressValidationDialog(false);
  };

  const InjuryExposure = () => {
    const { errors, touched, setFieldValue } = useFormikContext();

    return (
      
      <Grid container item spacing={2} style={{ marginBottom: '2rem' }}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name='q1.isHospitalized'
            component={ComponentToggle}
            title='Was the injured party hospitalized?'
            handleButtonChange={(value) =>
              setFieldValue('q1.isHospitalized', value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            label='First Name of Injured Party'
            name='q1.firstName'
            component={TextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            label='Last Name of Injured Party'
            name='q1.lastName'
            component={TextField}
            fullWidth
          />
        </Grid>
        <AddressComponent prefix='q1' />
      </Grid>
    );
  };

  const handleSubmit = async (values) => {
    let valRes = { isValid: true };
    
      if (values.q2.selected) {
        {
          VALIDATE_ADDRESS && (valRes = await validateAddress(values.q2));
        }
        if (!valRes.isValid) {
          setPromptAddress(valRes.address === null ? ' ' : valRes.address);
          setShowAddressValidationDialog(true);
        }
      } else {
        valRes = { isValid: true };
      }
      if (valRes.isValid) {
        const newClaim = { ...claim };
        newClaim.lossDetails.incidents[0] = values;
        updateClaim({ ...newClaim });
        handleNext();
      }

  };

  return (
    <>
      <OffsetGrid offset={`${getContainerOffset(deviceType)}`}>
        <Typography variant='h1' align='center' color='primary'>
          {/* Fire Damage Details */}
          Tell us what happened
        </Typography>
        <Typography variant={'subtitle1'} align={'center'}>
          How do you believe the fire started? How was it discovered?
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={fireDamageSchema}
          onSubmit={handleSubmit}>
          {({ setFieldValue }) => (
            <Box mt={2}>
              <Form id='fire-damage-form'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormikTextArea
                      name='fireSourceDesc'
                      placeholder='Please describe the source and how the fire was discovered'
                      maxLength={500}
                      rows={4}
                      variant='outlined'
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='q1.selected'
                    component={ComponentToggle}
                    title='Was anyone injured in the fire?'
                    displayOnSelected={true}
                    handleButtonChange={(value) =>
                      setFieldValue('q1.selected', value)
                    }>
                    <InjuryExposure />
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name='q2.selected'
                    component={ComponentToggle}
                    title='Did the fire cause smoke damage only?'
                    handleButtonChange={(value) =>
                      setFieldValue('q2.selected', value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='q3.selected'
                    component={ComponentToggle}
                    title='Is the home currently habitable?'
                    handleButtonChange={(value) =>
                      setFieldValue('q3.selected', value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='q4.selected'
                    component={ComponentToggle}
                    title='Is the home currently secure?'
                    handleButtonChange={(value) =>
                      setFieldValue('q4.selected', value)
                    }
                  />
                </Grid>
                
                <Grid item xs={12}>
                    <OfficialsComponent />
                </Grid>
                <ErrorFocus />
              </Form>

              {promptAddress && (
                <AddressValidationDialog
                  title='Validate Injured Person Address'
                  promptAddress={promptAddress}
                  showDialog={showAddressValidationDialog}
                  handleClose={handleCloseAddressValidationDialog}
                  // handleOnSubmit={handleSubmitAddressValidationDialog}
                  handleOnSubmit={() => {
                    if (promptAddress !== ' ') {
                      const newClaim = { ...claim };
                      newClaim.lossDetails.incidents[0].q2.addressLine1 =
                        promptAddress.addressLine1;
                      newClaim.lossDetails.incidents[0].q2.addressLine2 =
                        promptAddress.addressLine2;
                      newClaim.lossDetails.incidents[0].q2.city =
                        promptAddress.city;
                      newClaim.lossDetails.incidents[0].q2.state =
                        promptAddress.state;
                      newClaim.lossDetails.incidents[0].q2.postalCode =
                        promptAddress.postalCode;
                      updateClaim({ ...newClaim });
                      setFieldValue('q2.addressLine1', promptAddress.addressLine1);
                      setFieldValue('q2.addressLine2', promptAddress.addressLine2);
                      setFieldValue('q2.city', promptAddress.city);
                      setFieldValue('q2.state', promptAddress.state);
                      setFieldValue('q2.postalCode', promptAddress.postalCode);
                    }
                    setPromptAddress(null);
                    setShowAddressValidationDialog(false);
                  }}
                />
              )}
            </Box>
          )}
        </Formik>
      </OffsetGrid>

      <Box p={5} style={{ textAlign: 'center' }}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.backButton}>
          Back
        </Button>
        <Button
          type='submit'
          form='fire-damage-form'
          variant='contained'
          color='primary'>
          Next
        </Button>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
});

FireDamageComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { validateAddress, updateClaim })(
  FireDamageComponent
);
