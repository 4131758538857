import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { Formik, Form, Field } from 'formik';
import { vehicleInfoDialogSchema } from '../../validation/claimsSchemas';
import { TextField } from 'formik-material-ui';

import ComponentToggle from '../common/ComponentToggle';
import FormikDatePicker from '../common/FormikDatePicker';
import PhoneComponent from '../common/PhoneComponent';
import AddressComponent from '../common/AddressComponent';
import ErrorFocus from '../common/ErrorFocus';

const useStyles = makeStyles({
  noPadding: {
    padding: '0px 0px',
  },
});

const VehicleInfoDialog = ({ open, onClose, onSubmit, isEditing, data }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='vehicle-info-form-dialog-title'>
      <DialogTitle id='vehicle-info-form-dialog-title'>
        Vehicle Information
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={
            isEditing && data
              ? data
              : {
                  make: '',
                  model: '',
                  year: null,
                  vin: '',
                  owner: {
                    isInsureds: null,
                  },
                  isSelected: false,
                  estimateReceived: null,
                  estimatedRepairCost: '',
                }
          }
          validationSchema={vehicleInfoDialogSchema}
          onSubmit={(values) => {
            onSubmit(values);
          }}>
          {({ values, errors, handleSubmit, setFieldValue }) => (
            <Form id='vehicleInfoForm' onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    autoFocus
                    name='make'
                    component={TextField}
                    margin='dense'
                    label='Make'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='model'
                    component={TextField}
                    margin='dense'
                    label='Model'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormikDatePicker
                    name='year'
                    label='Year'
                    margin='dense'
                    disableFuture
                    views={['year']}
                    onChange={(date) =>
                      setFieldValue('year', moment(date).format('YYYY'))
                    }
                    value={values.year ? new Date(values.year, 0) : null}
                    autoOk
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Field
                    name='vin'
                    component={TextField}
                    margin='dense'
                    label='VIN'
                    placeholder='Optional'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name='owner.isInsureds'
                    component={ComponentToggle}
                    title='Are you the owner of this vehicle?'
                    displayOnSelected={true}
                    margin='dense'
                    handleButtonChange={(value) => {
                      setFieldValue('owner', { isInsureds: value });

                      if (!value) {
                        setFieldValue('owner', {
                          isInsureds: value,
                          firstName: '',
                          lastName: '',
                          address: {
                            addressLine1: '',
                            addressLine2: '',
                            city: '',
                            state: '',
                            postalCode: '',
                          },
                          contactInfo: {
                            primaryPhoneType: null,
                            phone: [],
                          },
                        });
                      }
                    }}
                  />
                </Grid>
                {values.owner.isInsureds === false && (
                  <>
                    <Box ml={1}>
                      <Grid item xs={12}>
                        <Typography variant='h6'>
                          Third-Party Owner Contact Information:
                        </Typography>
                      </Grid>
                    </Box>

                    <Grid item xs={12} sm={6}>
                      <Field
                        label='First Name'
                        name={`owner.firstName`}
                        component={TextField}
                        margin='dense'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        label='Last Name'
                        component={TextField}
                        name={`owner.lastName`}
                        margin='dense'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PhoneComponent
                        prefix='owner.contactInfo'
                        contactInfo={values.owner.contactInfo}
                        allowOptional={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AddressComponent prefix='owner.address' />
                    </Grid>
                    <ErrorFocus />
                  </>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
      <Box my={2}>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Cancel
          </Button>
          <Button type='submit' form='vehicleInfoForm' color='primary'>
            Submit
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default VehicleInfoDialog;
