import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { IconButton, Tooltip } from "@material-ui/core";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

export const AMIGTooltip = ({ 
  name, 
  tooltip, 
  ...rest
}) => {

  const { palette } = useTheme();

  const useStyles = makeStyles((theme) => ({
    svgIcon: {
      color: palette.primary.main,
      width: '12px',
      height: '12px',
    },
    iconButton: {
      padding: 0,
      verticalAlign: 'top',
    },
  }));

  const classes = useStyles();

  return (
    <Tooltip title={tooltip} {...rest} >
      <IconButton aria-label={name} className={classes.iconButton} >
        <HelpOutlineIcon className={classes.svgIcon} fontSize={'small'} />
      </IconButton>
    </Tooltip>
  );
}

AMIGTooltip.defaultProps = {
  name: '', 
  tooltip: '',
};
