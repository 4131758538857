import { PhoneTypes } from '../actions/types';

export const formatPhoneNumber = (phoneNumber) => {
  const cleanPhone = cleanPhoneNumber(phoneNumber);
  if (cleanPhone.match(/^\d{10}$/)) {
    return (
      cleanPhone.slice(0, 3) +
      '-' +
      cleanPhone.slice(3, 6) +
      '-' +
      cleanPhone.slice(6)
    );
  } else {
    return null;
  }
};

export const cleanPhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/\D[^\.]/g, '');
};

export const formatPhoneForDisplay = (phone = '') => {
  const { number = phone, type, mobileProvider, mobileProviderOther } = phone;
  let formatted = number;

  if (number.length === 10) {
    const areaCode = phone.number.slice(0, 3);
    const centralOfficeCode = phone.number.slice(3, 6);
    const number = phone.number.slice(6);
    formatted = `${areaCode}-${centralOfficeCode}-${number}`
  }

  if (
    type &&
    type === PhoneTypes.MOBILE &&
    mobileProvider &&
    mobileProvider !== 'Other'
  ) {
    formatted +=
      mobileProvider === 'ATT' ? ' (AT&T)' : ` (${mobileProvider})`;
  }
  if (
    type &&
    type === PhoneTypes.MOBILE &&
    mobileProviderOther &&
    mobileProviderOther !== ''
  ) {
    formatted += ` (Other: ${mobileProviderOther})`;
  }
  return formatted;
};
