import { StepTypes } from './types';

export const newClaimActions = {
  updateReporter:fields => dispatch => {
    dispatch({
      type: StepTypes.REPORTER,
      payload: fields,
    });
  },
  updateLocationNotPolicy:fields => dispatch => {
    dispatch({
      type: StepTypes.LOSS_LOCATION_NOT_POLICY,
      payload: fields,
    });
  },
  updateCaused:fields => dispatch => {
    dispatch({
      type: StepTypes.CAUSED,
      payload: fields,
    });
  },
  updateInjured:fields => dispatch => {
    dispatch({
      type: StepTypes.INJURED,
      payload: fields,
    });
  },
  updateDamagedRen:fields => dispatch => {
    dispatch({
      type: StepTypes.DAMAGED_REN,
      payload: fields,
    });
  },
  updateDamagedRec:fields => dispatch => {
    dispatch({
      type: StepTypes.DAMAGED_REC,
      payload: fields,
    });
  },
  updateDamagedRes:fields => dispatch => {
    dispatch({
      type: StepTypes.DAMAGED_RES,
      payload: fields,
    });
  },
  updateLocationRec:fields => dispatch => {
    dispatch({
      type: StepTypes.LOSS_LOCATION_REC,
      payload: fields,
    });
  },
  updateLocationRes:fields => dispatch => {
    dispatch({
      type: StepTypes.LOSS_LOCATION_RES,
      payload: fields,
    });
  }
};
