import { UPDATE_ACTIVE_STEP, UPDATE_STEP_CONTENT_INDEX, UPDATE_COMPLETED_STEPS } from '../actions/types';

const initialState = {
    activeStep: 0,
    stepContentIndex: 0,
    completed: {}
  };

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch (type) {
        case UPDATE_ACTIVE_STEP:
            return {
                ...state,
                activeStep: payload
            }
        case UPDATE_STEP_CONTENT_INDEX:
            return {...state, stepContentIndex: payload}
        case UPDATE_COMPLETED_STEPS:    
        return {
                ...state,
                completed: payload
            }
        default: 
            return state;
    }
}