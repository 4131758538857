import React, { forwardRef, useState, useEffect } from 'react';
import moment from 'moment';
import SubmissionTableDetail from './SubmissionTableDetail';
import {
  PolicyTypes,
  ClaimStatus,
  ClaimStatusLookup,
} from '../../actions/types';
import { getSubmissionSummaries } from '../../actions/claimActions';
import CircularProgress from '@material-ui/core/CircularProgress';

// Material-Table
import MaterialTable from 'material-table';

// Material UI Icons
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Warning from '@material-ui/icons/Warning';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const SubmissionTable = ({ submissionId, claimType }) => {
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => (
      <Check color={'primary'} {...props} ref={ref} />
    )),
    Clear: forwardRef((props, ref) => (
      <Clear color={'error'} {...props} ref={ref} />
    )),
    Delete: forwardRef((props, ref) => (
      <DeleteOutline color={'error'} {...props} ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => (
      <Edit color={'primary'} {...props} ref={ref} />
    )),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    LoadMore: forwardRef((props, ref) => (
      <CloudDownloadIcon {...props} ref={ref} />
    )),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    Refresh: forwardRef((props, ref) => <RefreshIcon {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Warning: forwardRef((props, ref) => (
      <Warning {...props} color='error' ref={ref} />
    )),
  };

  const [submissionSummariesData, setSubmissionSummariesData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMoreDisabled, setisLoadMoreDisabled] = useState(true);

  useEffect(() => {
    const getSubmissions = async (type, id = null) => {
      let res = '';
      if (id) {
        res = await getSubmissionSummaries(type, { confirmationId: id });
      } else {
        res = await getSubmissionSummaries(type);
      }

      setSubmissionSummariesData(res);
      setIsLoading(false);
      res.continuationToken && setisLoadMoreDisabled(false);
    };
    getSubmissions(claimType, submissionId);
  }, []);

  const handleGetSubmissions = async (type) => {
    setIsLoading(true);
    let res = '';
    if (type) {
      res = await getSubmissionSummaries(type);
    }
    setSubmissionSummariesData(res);
    setIsLoading(false);
    res.continuationToken
      ? setisLoadMoreDisabled(false)
      : setisLoadMoreDisabled(true);
  };

  const handleLoadMoreData = async (type) => {
    setIsLoading(true);
    let res = '';
    if (type) {
      res = await getSubmissionSummaries(type, {
        continuationToken: submissionSummariesData.continuationToken,
      });
    }
    setSubmissionSummariesData((prevState) => ({
      ...prevState,
      continuationToken: res.continuationToken,
      data: [...prevState.data, ...res.data],
    }));
    setIsLoading(false);
    res.continuationToken
      ? setisLoadMoreDisabled(false)
      : setisLoadMoreDisabled(true);
  };

  const handleUpdateSummary = (data) => {
    const submissionSummariesDataCopy = [...submissionSummariesData.data];
    const index = submissionSummariesDataCopy.findIndex(
      (summary) => summary.logId === data.logId
    );
    if (index !== -1) {
      submissionSummariesDataCopy.splice(index, 1, data);

      setSubmissionSummariesData((prev) => ({
        ...prev,
        data: [...submissionSummariesDataCopy],
      }));
    }
  };

  return (
    <>
      <MaterialTable
        icons={tableIcons}
        isLoading={isLoading}
        title='Claim Submissions'
        columns={[
          { title: 'Submission ID', field: 'logId', hidden: true },
          {
            title: 'ID',
            field: 'confirmationId',
            render: (rowData) =>
            rowData.claimNumber ? rowData.claimNumber : rowData.confirmationId
          },
          { title: 'Claim Type', field: 'type' },
          { title: 'Reporter', field: 'reporter' },
          { title: 'Loss Date/Time', field: 'lossDate' },
          { title: 'Loss Location', field: 'location' },
          { title: 'Policy Number', field: 'policyNumber' },
          {
            title: 'Status',
            field: 'status',
            lookup: ClaimStatusLookup,
          },
          {
            title: 'Last Updated',
            field: '_ts',
            render: (rowData) =>
              moment
                .unix(rowData._ts)
                .utcOffset('-0500')
                .format('MM/DD/YYYY HH:mm:ss'),
          },
          {
            title: 'Submitted Date/Time',
            field: '_ts',
            render: (rowData) =>
              rowData.status ===
              ClaimStatus.EMAIL_SUBMISSION || rowData.status === ClaimStatus.API_SUBMISSION
                ? moment
                    .unix(rowData._ts)
                    .utcOffset('-0500')
                    .format('MM/DD/YYYY HH:mm:ss')
                : 'N/A',
          },
        ]}
        data={submissionSummariesData.data}
        actions={[
          {
            icon: tableIcons.Refresh,
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: () => handleGetSubmissions(claimType),
          },
          {
            icon: tableIcons.LoadMore,
            tooltip: 'Load more data',
            isFreeAction: true,
            disabled: isLoadMoreDisabled,
            onClick: () => handleLoadMoreData(claimType),
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          loadingType: 'linear',
          pageSize: submissionSummariesData.length < 5 ? 5 : 10,
          search: true,
          searchText: submissionId ? submissionId : null,
          headerStyle: {
            padding: '0.5rem',
            background: '#ededed',
          },
          cellStyle: {
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '0.8rem',
            padding: '0.5rem',
          },
        }}
        detailPanel={(rowData) => (
          <SubmissionTableDetail
            submissionData={submissionSummariesData.data[rowData.tableData.id]}
            handleUpdateSummary={handleUpdateSummary}
          />
        )}
        onRowClick={(event, rowData, togglePanel) => {
          togglePanel();
        }}
      />
    </>
  );
};

export default SubmissionTable;
