export const formatAddressForDisplay = (address = {}) => {
    const { addressLine1, addressLine2, city, state, postalCode } = address;
  
    let formatted = '';
    if (addressLine1) formatted += addressLine1 + ' ';
    if (addressLine2) formatted += addressLine2 + ' ';
    if (addressLine1 || addressLine2) formatted += ', ';
    if (city) formatted += city + ', ';
    if (state) formatted += state + ' ';
    if (postalCode) formatted += postalCode;
  
    return formatted.length > 0 ? formatted : 'Not provided';
  };
  