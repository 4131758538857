import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Formik, Form, Field } from 'formik';
import { claimReporterSchema } from '../../validation/claimsSchemas';
import { TextField, Select } from 'formik-material-ui';
import { sendEventLogRequest } from '../../actions/eventActions';
import { updateClaim } from '../../actions/claimActions';
import { getContainerOffset } from '../../actions/layoutActions';
import PropTypes from 'prop-types';
import {
  relationshipToInsured,
  ContactTypes,
  PhoneTypes,
} from '../../actions/types';
import OffsetGrid from '../layout/OffsetGrid';
import ComponentToggle from '../common/ComponentToggle';
import FormikSelect from '../common/FormikSelect';
import AddressValidationDialog from '../dialogs/AddressValidationDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import ErrorFocus from '../common/ErrorFocus';
import appSettings from '../../appSettings';
import AddressComponent from '../common/AddressComponent';
import PhoneComponent from '../common/PhoneComponent';

const useStyles = makeStyles({
  card: {
    height: '100%',
    padding: '1rem',
  },
  cardTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cardMedia: {
    width: '13%',
    alignSelf: 'center',
  },
  btn: {
    color: '#fff',
    backgroundColor: '#007bff',
    borderColor: '#007bff',
    '&:hover': {
      color: '#fff',
      background: '#0056b3',
    },
  },
  input: {
    backgroundColor: '#f5f5f7',
  },
  inputSelectLabel: {
    marginLeft: '1rem',
    '&:mui-focused={true}': {
      marginTop: '0.5rem',
    },
  },
  selectOption: {
    padding: '0.25rem',
    marginLeft: '0.25rem',
  },
});

const ClaimReporterComponent = ({
  claim,
  policy,
  activeStep,
  handleBack,
  handleNext,
  updateClaim,
}) => {
  const { contacts } = policy;
  const { deviceType } = getDeviceTypeInfo();
  const logId = claim.logId;
  const VALIDATE_ADDRESS = appSettings.VALIDATE_ADDRESSES === 'true';

  const initialValues = {
    //validateOptIn: true,
    // (claim.reportingParty.reporter === 'Other' &&
    //   claim.namedInsured.firstName === '' && claim.reportingParty.relationToInsured === 'Claimant') ||
    // claim.reportingParty.reporter.toLowerCase() ===
    //   `${claim.namedInsured.firstName.toLowerCase()} ${claim.namedInsured.lastName.toLowerCase}`
    //   ? true
    //   : false,
    isAuth: claim.namedInsured.firstName ? true : false,
    reporter: claim.reportingParty.reporter,
    firstName: claim.reportingParty.firstName,
    lastName: claim.reportingParty.lastName,
    company: claim.reportingParty.company,
    relationToInsured: claim.reportingParty.relationToInsured,
    address: {
      addressLine1: claim.reportingParty.address.addressLine1,
      addressLine2: claim.reportingParty.address.addressLine2,
      city: claim.reportingParty.address.city,
      state: claim.reportingParty.address.state,
      postalCode: claim.reportingParty.address.postalCode,
    },
    contactInfo: { ...claim.reportingParty.contactInfo, email: claim.reportingParty.contactInfo.email.toLowerCase(), confirmEmail: '' },
    optInRequired: false,
    isOptedIn: claim.reportingParty.isOptedIn,
    // namedInsured: {
    //   firstName: claim.namedInsured.firstName,
    //   lastName: claim.namedInsured.lastName,
    //   address: {
    //     addressLine1: claim.namedInsured.address.addressLine1,
    //     addressLine2: claim.namedInsured.address.addressLine2,
    //     city: claim.namedInsured.address.city,
    //     state: claim.namedInsured.address.state,
    //     postalCode: claim.namedInsured.address.postalCode,
    //   },
    //   contactInfo: { ...claim.namedInsured.contactInfo, confirmEmail: '' },
    // },
  };

  const reporters = contacts.map((c) => {
    let reporter = {
      type: c.contactType,
      name: '',
    };
    if (c.contactType === ContactTypes.POLICY_HOLDER) {
      reporter.name = `${c.firstName} ${c.lastName}`;
    }
    if (
      c.contactType === ContactTypes.PRODUCER ||
      c.contactType === ContactTypes.SUBPRODUCER
    ) {
      reporter.name = c.company;
    }
    return reporter;
  });

  // Local State
  const [isMobileValidated, setIsMobileValidated] = useState(false);
  const [showNoMobileNumberDialog, setShowNoMobileNumberDialog] =
    useState(false);
  const [addressValidationResult, setAddressValidationResult] = useState({
    isValid: true,
  });
  const [showMailingAddress, setShowMailingAddress] = useState(false);
  const [showAddressValidationDialog, setShowAddressValidationDialog] =
    useState(false);

  const [promptAddress, setPromptAddress] = useState(null);

  // Hooks
  useEffect(() => {
    window.scrollTo(0, 0);
    var event = {
      logId: logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Reporting Party'}",
    };

    sendEventLogRequest(event);
  }, []);

  // Functions
  const checkOptIn = (isAuth = false, relationToInsured) => {
    if (
      relationToInsured === 'Self' ||
      (isAuth && relationToInsured === 'Claimant') ||
      !isAuth
    ) {
      return true;
    } else {
      return false;
    }
  };

  // const checkPolicyContact = (value) => {
  //   let isVisible = true;
  //   if (
  //     (value === 'Other' && claim.namedInsured.firstName === '') ||
  //     value === ''
  //   ) {
  //     isVisible = false;
  //   }
  //   return isVisible;
  // };

  // Handlers
  const handleCloseNoMobileNumberDialog = () => {
    setShowNoMobileNumberDialog(false);
    if (!addressValidationResult.isValid) {
      setPromptAddress(
        addressValidationResult.address === null
          ? ' '
          : addressValidationResult.address
      );
      setShowAddressValidationDialog(true);
    } else {
      handleNext();
    }
  };

  const handleCloseAddressValidationDialog = () => {
    setPromptAddress(null);
    setShowAddressValidationDialog(false);
    if (!isMobileValidated) {
      setShowNoMobileNumberDialog(true);
    } else {
      handleNext();
    }
  };

  const handleOnSubmit = async (values) => {
    const newClaim = { ...claim };
    const {
      firstName,
      lastName,
      company,
      relationToInsured,
      address,
      contactInfo,
      isOptedIn,
      reporter,
    } = values;
    const { addressLine1, addressLine2, city, state, postalCode } = address;
    if (relationToInsured !== 'Self') {
      newClaim.reportingParty.firstName = firstName;
      newClaim.reportingParty.lastName = lastName;
      newClaim.reportingParty.company = company;
      newClaim.reportingParty.contactInfo = contactInfo;
      if (addressLine1 && city && state && postalCode) {
        newClaim.reportingParty.address = address;
      }
    } else {
      if (addressLine1 && city && state && postalCode) {
        newClaim.namedInsured.address = address;
      }
      newClaim.namedInsured.contactInfo = contactInfo;
    }
    newClaim.reportingParty.reporter = reporter;
    newClaim.reportingParty.relationToInsured = relationToInsured;
    newClaim.reportingParty.isOptedIn = isOptedIn ?? false;

    updateClaim({ ...newClaim });

    handleNext();
  };

  const classes = useStyles();

  return (
    <>
      <OffsetGrid offset={`${getContainerOffset(deviceType)}`}>
        <Box mb={3}>
          <Typography variant='h1' align='center' color='primary' gutterBottom>
            {/* Who's Reporting the Claim */}
            We need your name and contact information, too
          </Typography>
          <Typography gutterBottom variant='subtitle1' align='center'>
            Please let us know who you are and provide your current contact
            information.
          </Typography>
        </Box>

        <Formik
          initialValues={initialValues}
          validationSchema={claimReporterSchema}
          onSubmit={handleOnSubmit}>
          {({ values, errors, setFieldValue}) => (
            <>
              <Form id='claim-reporter-form'>
                {values.isAuth && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormikSelect
                        id={'reporter-select'}
                        label={"Who's reporting the claim"}
                        //value={values.reporter}
                        onChange={(event) => {
                          setFieldValue('reporter', event.target.value);
                          if (
                            event.target.value.toLowerCase() ===
                            `${claim.namedInsured.firstName.toLowerCase()} ${claim.namedInsured.lastName.toLowerCase()}`
                          ) {
                            setFieldValue('relationToInsured', 'Self');
                            setShowMailingAddress(true);
                            setFieldValue(
                              'address',
                              claim.namedInsured.address
                            );
                            setFieldValue(
                              'contactInfo',
                              claim.namedInsured.contactInfo
                            );
                            setFieldValue('optInRequired', true);
                          } else {
                            setFieldValue('relationToInsured', '');
                            setFieldValue('optInRequired', false);
                            setShowMailingAddress(false);
                            setFieldValue('address', {
                              addressLine1: '',
                              addressLine2: '',
                              city: '',
                              state: '',
                              postalCode: '',
                            });
                            setFieldValue('contactInfo', {
                              primaryPhoneType: '',
                              email: '',
                              phone: [
                                {
                                  type: PhoneTypes.MOBILE,
                                  number: '',
                                  mobileProvider: '',
                                  mobileProviderOther: '',
                                },
                              ],
                            });
                          }
                        }}
                        name={'reporter'}>
                        {reporters.map((reporter, index) => (
                          <MenuItem key={index} value={reporter.name}>
                            {reporter.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={'Other'}>Other</MenuItem>
                      </FormikSelect>
                    </Grid>
                  </Grid>
                )}
                {values.reporter !== '' && (
                  <>
                    {values.relationToInsured !== 'Self' && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <FormikSelect
                              name='relationToInsured'
                              label='Select relationship to insured'
                              onChange={(event) => {
                                setFieldValue(
                                  'relationToInsured',
                                  event.target.value
                                );
                                if (
                                  (values.isAuth &&
                                    event.target.value === 'Claimant') ||
                                  !values.isAuth
                                ) {
                                  setFieldValue('optInRequired', true);
                                  setFieldValue('address.isRequired', true)
                                  setShowMailingAddress(true)
                                } else {
                                  setFieldValue('optInRequired', false);
                                  setFieldValue('address.isRequired', false)
                                  setShowMailingAddress(false);
                                  setFieldValue('address', {
                                    addressLine1: '',
                                    addressLine2: '',
                                    city: '',
                                    state: '',
                                    zipCode: '',
                                  });
                                }
                              }}>
                              {relationshipToInsured.map((relationship) => (
                                <MenuItem
                                  className={classes.selectOption}
                                  key={relationship}
                                  value={relationship}>
                                  {relationship}
                                </MenuItem>
                              ))}
                            </FormikSelect>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                    {values.relationToInsured === 'Self' && (
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            gutterBottom
                            variant='subtitle1'
                            align='center'>
                            Verify and/or update policyholder contact
                            information for updated correspondence regarding the
                            claim.
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    <Box mt={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography gutterBottom variant='h5' align='left'>
                            {values.relationToInsured !== 'Self'
                              ? 'Contact Information:'
                              : 'Policyholder Contact Information'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>

                    <Grid container spacing={2}>
                      {values.relationToInsured !== 'Self' && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <Field
                              label='First Name'
                              name='firstName'
                              component={TextField}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              label='Last Name'
                              name='lastName'
                              component={TextField}
                              fullWidth
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <Field
                            label='Email'
                            name='contactInfo.email'
                            component={TextField}
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <Field
                            error
                            label='Confirm Email'
                            name='contactInfo.confirmEmail'
                            component={TextField}
                            fullWidth
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <PhoneComponent
                          prefix='contactInfo'
                          contactInfo={values.contactInfo}
                        />
                      </Grid>
                    </Grid>
                    {showMailingAddress && (
                      <>
                      <Box mt={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography gutterBottom variant='h5' align='left'>
                            {values.relationToInsured === 'Self'
                              ? 'Policyholder Mailing Address'
                              : 'Mailing Address:'}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid container spacing={2}>
                      <AddressComponent
                        prefix='address'
                        readOnly={values.relationToInsured === 'Self'}
                      />
                    </Grid>
                      </>
                    )}
                  </>
                )}
                {values.optInRequired && (
                  <Box pt={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          name='isOptedIn'
                          component={ComponentToggle}
                          title={
                            'You have the option of receiving automated calls and/or text messages from us. Do we have your permission to send autodialed pre-recorded and artificial voice calls and/or text messages to the telephone number that you will provide?'
                          }
                          value={values.isOptedIn}
                          displayOnSelected={true}
                          handleButtonChange={(value) =>
                            setFieldValue('isOptedIn', value)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {/* )} */}
                <ErrorFocus />
              </Form>
              {promptAddress && (
                <AddressValidationDialog
                  showDialog={showAddressValidationDialog}
                  handleClose={handleCloseAddressValidationDialog}
                  // handleOnSubmit={handleSubmitAddressValidationDialog}
                  handleOnSubmit={() => {
                    if (promptAddress !== ' ') {
                      const newClaim = { ...claim };
                      if (claim.namedInsured.firstName === '') {
                        newClaim.reportingParty.address = promptAddress;
                        setFieldValue(
                          'address.addressLine1',
                          promptAddress.addressLine1
                        );
                        setFieldValue(
                          'address.addressLine2',
                          promptAddress.addressLine2
                        );
                        setFieldValue('address.city', promptAddress.city);
                        setFieldValue('address.state', promptAddress.state);
                        setFieldValue('address.postalCode', promptAddress.postalCode);
                      } else {
                        newClaim.namedInsured.address = promptAddress;
                        setFieldValue(
                          'namedInsured.address.addressLine1',
                          promptAddress.addressLine1
                        );
                        setFieldValue(
                          'namedInsured.address.addressLine2',
                          promptAddress.addressLine2
                        );
                        setFieldValue(
                          'namedInsured.address.city',
                          promptAddress.city
                        );
                        setFieldValue(
                          'namedInsured.address.state',
                          promptAddress.state
                        );
                        setFieldValue(
                          'namedInsured.address.postalCode',
                          promptAddress.postalCode
                        );
                      }
                      updateClaim({ ...newClaim });
                    }
                    setPromptAddress(null);
                    setShowAddressValidationDialog(false);
                  }}
                  title='Validate Address'
                  promptAddress={promptAddress}
                />
              )}
              <Dialog
                maxWidth='xs'
                open={showNoMobileNumberDialog}
                onClose={handleCloseNoMobileNumberDialog}>
                <DialogTitle id='form-dialog-title'>
                  Mobile Number Check
                </DialogTitle>
                <DialogContent>
                  <Box pb={3}>
                    <Grid container spacing={2} direction='column'>
                      <Grid item xs>
                        <Box pb={3}>
                          <Typography gutterbottom>
                            Are you sure you wish to continue without entering a
                            mobile phone number? Providing a mobile phone number
                            will improve your policy and claims communication
                            experience with American Modern.
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <Box p={1}>
                  <DialogActions>
                    <Button onClick={handleCloseNoMobileNumberDialog}>
                      CANCEL
                    </Button>
                    <Button
                      onClick={() => {
                        const newClaim = { ...claim };
                        if (values.namedInsured.firstName === '') {
                          newClaim.reportingParty.optInAuthorized =
                            values.optInAuthorized;
                          newClaim.reportingParty.firstName = values.firstName;
                          newClaim.reportingParty.lastName = values.lastName;
                          newClaim.reportingParty.company = values.company;
                          newClaim.reportingParty.relationToInsured =
                            values.relationToInsured;
                          newClaim.reportingParty.address = values.address;
                          newClaim.reportingParty.contactInfo =
                            values.contactInfo;
                          newClaim.reportingParty.isOptedIn = values.isOptedIn;
                        } else if (
                          values.namedInsured.firstName !== '' &&
                          values.reporter === 'Other'
                        ) {
                          newClaim.reportingParty.reporter = values.reporter;
                          newClaim.reportingParty.firstName = values.firstName;
                          newClaim.reportingParty.lastName = values.lastName;
                          newClaim.reportingParty.contactInfo =
                            values.contactInfo;
                          newClaim.reportingParty.relationToInsured =
                            values.relationToInsured;
                          newClaim.namedInsured = values.namedInsured;
                        } else {
                          newClaim.reportingParty.reporter = values.reporter;
                          newClaim.reportingParty.firstName = values.firstName;
                          newClaim.reportingParty.lastName = values.lastName;
                          newClaim.reportingParty.contactInfo =
                            values.contactInfo;
                          newClaim.namedInsured = values.namedInsured;
                          newClaim.reportingParty.isOptedIn = values.isOptedIn;
                        }
                        updateClaim({ ...newClaim });
                        setShowNoMobileNumberDialog(false);
                        handleNext();
                      }}
                      variant='contained'
                      color='primary'>
                      Continue
                    </Button>
                  </DialogActions>
                </Box>
              </Dialog>
            </>
          )}
        </Formik>
      </OffsetGrid>

      <Box p={5} style={{ textAlign: 'center' }}>
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button
          type='submit'
          form='claim-reporter-form'
          variant='contained'
          color='primary'>
          Next
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  claim: state.claim,
  policy: state.policy,
});

ClaimReporterComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updateClaim })(
  ClaimReporterComponent
);
