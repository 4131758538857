import moment from 'moment-timezone';

export const getYearFromDate = (value) => {
    const [month, date, year] = value.toLocaleString('en-US').split('/');
    return year;
}

export const formatDateForDisplay = (date) => {
    const format1 = "MM/DD/YYYY h:mm:ss A";
    const tzAbbr = moment.tz(moment.tz.guess()).format('z');
    return moment(date).format(format1)  + " " + "(" + tzAbbr + ")";
  }