import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  InputAdornment,
} from '@material-ui/core';
import { AMIGTogglePosNeg } from './AMIGTogglePosNeg';
import {
  PLACEHOLDER_SELECT_VALUE,
  CHECKBOX_ERROR_VALUE,
  MAX_LENGTH,
  FIELD_TYPE,
} from '../../../../actions/types';
import { AMIGCheckbox } from './AMIGCheckbox';
import { AMIGTooltip } from './AMIGTooltip';
import NumberFormatCustom from './AMIGNumberFormat';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import InputMask from 'react-input-mask';

const checkDependencyValue = (formik, dependencyFieldToShow, isList) => {
  const dependencyValue = dependencyFieldToShow?.value;
  const currentDependencyValue = formik.values[dependencyFieldToShow?.name];

  if (Array.isArray(dependencyValue))
    return dependencyValue.some((element) =>
      element.hasOwnProperty('min')
        ? currentDependencyValue >= element.min
        : element === currentDependencyValue
    );

  const isMin = dependencyFieldToShow.hasOwnProperty('min');

  if (isList)
    return isMin
      ? currentDependencyValue > dependencyFieldToShow.min
      : dependencyValue === currentDependencyValue;

  return isMin
    ? currentDependencyValue <= dependencyFieldToShow?.min
    : dependencyValue !== currentDependencyValue;
};

const isHidden = (formik, field) => {
  if (Array.isArray(field?.dependencyFieldToShow))
    return !field?.dependencyFieldToShow.every((field) =>
      checkDependencyValue(formik, field, true)
    );

  return checkDependencyValue(formik, field?.dependencyFieldToShow);
};

export const getShowedFields = (formik, fields) =>
  fields.reduce((acc, cur) => {
    if (cur.toHide && isHidden(formik, cur)) return acc;
    return [...acc, cur];
  }, []);

export const AMIGFormWithRedux = ({
  stepType,
  fields,
  isOneColumn,
  isDisable,
  formik,
  handleChange,
  summary,
}) => {
  const { palette } = useTheme();

  const useStyles = makeStyles((theme) => ({
    fieldMargin: {
      marginBottom: '8px',
    },
    question: {
      display: 'inline-flex',
      marginLeft: '8px',
      width: 'auto',
    },
    error: {
      color: palette.error.main,
    },
    checkboxLabel: {
      maxWidth: 'calc(100% - 20px)',
      whiteSpace: 'break-spaces',
      alignItems: 'flex-start',
    },
    disabledText: {
      fontFamily: ['Raleway', 'sans-serif'].join(','),
      fontSize: '16px',
      color: palette.text.disable,
    },
    information: {
      color: palette.text.primary,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
      '& a': {
        color: palette.text.primary,
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
  }));

  const classes = useStyles();

  const mergeFields = (object, dependencyFieldName, name, initialValue) => {
    const dependency = object[dependencyFieldName] || [];
    return [...dependency, { name, initialValue }];
  };

  const getDependencyFields = () =>
    fields.reduce((acc, cur) => {
      if (cur?.dependencyField || cur?.dependencyFieldToShow) {
        if (cur?.dependencyField)
          return {
            ...acc,
            [cur.dependencyField]: mergeFields(
              acc,
              cur.dependencyField.name,
              cur.name,
              cur.initialValue
            ),
          };
        if (cur?.dependencyFieldToShow) {
          if (Array.isArray(cur?.dependencyFieldToShow)) {
            const dependencyFields = cur?.dependencyFieldToShow.reduce(
              (dependencies, dependencyField) => ({
                ...dependencies,
                [dependencyField.name]: mergeFields(
                  acc,
                  dependencyField.name,
                  cur.name,
                  cur.initialValue
                ),
              }),
              {}
            );
            const newAcc = Object.assign(acc, dependencyFields);
            return newAcc;
          }
          return {
            ...acc,
            [cur.dependencyFieldToShow.name]: mergeFields(
              acc,
              cur.dependencyFieldToShow.name,
              cur.name,
              cur.initialValue
            ),
          };
        }
      }
      return acc;
    }, {});

  const dependencyFields = useMemo(() => getDependencyFields(), []);

  const isFieldDisabled = (field) =>
    summary.isStepDisabled(summary.disable, stepType) ||
    isDisable ||
    field?.isDisable;

  const isError = (field) =>
    formik.touched[field.name] && Boolean(formik.errors[field.name]);

  const getHelperText = (field) =>
    formik.touched[field.name] && formik.errors[field.name];

  const getValue = (name) => formik.values[name];

  const getArgs = (event, name, value) => {
    if (name) return { ...event, target: { ...event.target, name, value } };
    return event;
  };

  const handleChangeValue = (event, name, value) => {
    const args = getArgs(event, name, value);
    formik.handleChange(args);
    handleChange(args);
    const getDependencyFields = dependencyFields[args.target.name];
    if (getDependencyFields)
      getDependencyFields.forEach((field) =>
        formik.setFieldValue(field.name, field.initialValue)
      );
  };

  const getOptions = (field) => {
    if (typeof field.options === 'function')
      return field?.options(getValue(field?.dependencyField));

    return field?.options;
  };

  const fieldList = getShowedFields(formik, fields);

  const getFieldComponent = (field) => {
    if (field.type === FIELD_TYPE.CHECKBOX)
      return (
        <Grid>
          <FormControlLabel
            className={classes.checkboxLabel}
            control={
              <AMIGCheckbox
                name={field.name}
                checked={getValue(field.name)}
                handleChange={(event, value) =>
                  handleChangeValue(event, field.name, value)
                }
              />
            }
            label={field.label}
            disabled={isFieldDisabled(field)}
          />
          {field.tooltip && (
            <AMIGTooltip name={field.name} tooltip={field.tooltip} />
          )}
          <FormHelperText className={classes.error}>
            {formik.errors[CHECKBOX_ERROR_VALUE]}
          </FormHelperText>
        </Grid>
      );

    if (field.type === FIELD_TYPE.YES_NO)
      return (
        <AMIGTogglePosNeg
          className={classes.question}
          id={field.name}
          name={field.name}
          value={getValue(field.name)}
          isDisable={isFieldDisabled(field)}
          handleChange={(event, value) =>
            handleChangeValue(event, field.name, value)
          }
          helperText={getHelperText(field)}
        />
      );

    if (field.type === FIELD_TYPE.SELECT)
      return (
        <TextField
          className={classes.fieldMargin}
          id={field.name}
          name={field.name}
          value={getValue(field.name)}
          placeholder={field?.placeholder}
          InputProps={field?.InputProps}
          onChange={(event) => handleChangeValue(event)}
          onBlur={formik.handleBlur}
          error={isError(field)}
          helperText={getHelperText(field)}
          disabled={isFieldDisabled(field)}
          fullWidth
          select>
          {field.placeholder && (
            <MenuItem value={PLACEHOLDER_SELECT_VALUE} disabled>
              {' '}
              {field.placeholder}{' '}
            </MenuItem>
          )}
          {getOptions(field)?.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {' '}
              {option.name}{' '}
            </MenuItem>
          ))}
        </TextField>
      );

    if (field.type === FIELD_TYPE.TEXT_AREA)
      return (
        <TextField
          className={classes.fieldMargin}
          id={field.name}
          name={field.name}
          placeholder={field?.placeholder}
          value={getValue(field.name)}
          onChange={(event) => handleChangeValue(event)}
          onBlur={formik.handleBlur}
          error={isError(field)}
          helperText={
            getHelperText(field) ||
            (field.isCountCharacters &&
              `${
                (field.maxLength || MAX_LENGTH) - getValue(field.name)?.length
              } characters remaining`)
          }
          disabled={isFieldDisabled(field)}
          inputProps={field?.inputProps}
          rows={5}
          multiline
          fullWidth
        />
      );

    if (field.type === FIELD_TYPE.LABEL) return;

    if (field.type === FIELD_TYPE.INFORMATION)
      return (
        <span
          className={classes.information}
          dangerouslySetInnerHTML={{ __html: field.value }}
        />
      );

    if (field.type === FIELD_TYPE.CURRENCY)
      return (
        <TextField
          className={classes.fieldMargin}
          id={field.name}
          name={field.name}
          type={field.type}
          placeholder={field?.placeholder}
          value={field.value}
          onChange={(event) => handleChangeValue(event)}
          onBlur={formik.handleBlur}
          error={isError(field)}
          helperText={getHelperText(field)}
          disabled={isFieldDisabled(field)}
          fullWidth
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: (
              <InputAdornment position='start'>{field.currency}</InputAdornment>
            ),
          }}
        />
      );

    if (field.type === FIELD_TYPE.TEL) {
      return (
        <InputMask
          mask='999-999-9999'
          value={getValue(field.name)}
          onChange={(event) => handleChangeValue(event)}
          onBlur={formik.handleBlur}
          className={classes.fieldMargin}>
          {() => (
            <TextField
              id={field.name}
              name={field.name}
              className={classes.fieldMargin}
              type={field.type}
              error={isError(field)}
              disabled={isFieldDisabled(field)}
              fullWidth
            />
          )}
        </InputMask>
      );
    }

    return (
      <TextField
        className={classes.fieldMargin}
        id={field.name}
        name={field.name}
        type={field.type}
        inputProps={field?.inputProps}
        placeholder={field?.placeholder}
        value={getValue(field.name)}
        onChange={(event) => handleChangeValue(event)}
        InputProps={field?.InputProps}
        onBlur={formik.handleBlur}
        error={isError(field)}
        helperText={getHelperText(field)}
        disabled={isFieldDisabled(field)}
        fullWidth
      />
    );
  };

  const isFieldWithValue = (field) => {
    const value = formik.values[field.name];
    if (field.type === FIELD_TYPE.CHECKBOX) return value;
    if (field.type === FIELD_TYPE.YES_NO) return typeof value === 'boolean';
    return value !== field.initialValue;
  };

  const getFieldValue = (field) => {
    const value = formik.values[field.name];
    if (field.type === FIELD_TYPE.CHECKBOX) return ' ';
    if (field.type === FIELD_TYPE.YES_NO) return value ? 'Yes' : 'No';
    if (field.type === FIELD_TYPE.SELECT)
      return getOptions(field)?.find((option) => option.value === value)?.name;
    return value;
  };

  const getDataComponent = (field) => (
    <Typography className={classes.disabledText}>
      {getFieldValue(field)}
    </Typography>
  );

  const showLabel = (field) =>
    field?.type !== 'checkbox' ||
    (summary.isLastStept && isFieldDisabled(field));

  const { deviceType } = getDeviceTypeInfo();

  const getXS = (field) => {
    if (
      deviceType === 'Mobile' ||
      field.type === FIELD_TYPE.YES_NO ||
      field.type === FIELD_TYPE.TEXT_AREA ||
      field?.isOneColumn ||
      isOneColumn
    ) {
      return 12;
    }

    if (isDataComponent(field)) {
      if (field.type === FIELD_TYPE.CHECKBOX) return 12;

      return 6;
    }

    return field?.col || 6;
  };

  const isDataComponent = (field) =>
    summary.isLastStept && isFieldDisabled(field);

  const showField = (field) =>
    isEmpty(field) ||
    !isDataComponent(field) ||
    isFieldWithValue(field) ||
    field.type === FIELD_TYPE.LABEL;

  const isEmpty = (field) => field.type === FIELD_TYPE.EMPTY;

  return (
    <Grid container spacing={3} style={{ marginBottom: '30px' }}>
      {fieldList.map(
        (field, index) =>
          showField(field) && (
            <Grid item xs={getXS(field)} key={index}>
              {!isEmpty(field) && (
                <Typography variant='subtitle2'>
                  {showLabel(field) && (
                    <FormLabel style={{ whiteSpace: 'pre-line' }}>
                      {field?.label}
                    </FormLabel>
                  )}
                  {isDataComponent(field)
                    ? getDataComponent(field)
                    : getFieldComponent(field)}
                </Typography>
              )}
            </Grid>
          )
      )}
    </Grid>
  );
};

AMIGFormWithRedux.defaultProps = {
  stepIndex: null,
  fields: [],
  isOneColumn: false,
  isDisable: false,
  formik: null,
  handleChange: () => {},
};
const mapStateToProps = (state) => ({
  summary: state.summary,
});

export const AMIGForm = connect(mapStateToProps)(AMIGFormWithRedux);
