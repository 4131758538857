import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormType } from '../Utilities/FormType';
import { AMIGForm } from '../Utilities/AMIGForm';
import {
  setReduxValues,
  useAMIGFormik,
  getPathValue,
  shallowCopy,
} from '../Utilities/AMIGHandleForm';
import {
  StepTypes,
  ComponentTitle,
  EMPTY_VALUE,
  FIELD_TYPE,
} from '../../../../actions/types';
import { summaryActions } from '../../../../actions/summary';
import { newClaimActions } from '../../../../actions/newClaim';
import { useLoading } from '../../../../hooks/useLoading';
import { LoadingPlaceholder } from '../Utilities/LoadingPlaceholder';
import { updateClaim } from '../../../../actions/claimActions';
import * as Yup from 'yup';

const { updateDisabled } = summaryActions;
const { updateLocationRec } = newClaimActions;

export const LossLocationWithRedux = ({
  summary,
  claim,
  newClaim,
  policy,
  updateDisabled,
  updateLocationRec,
  updateClaim,
  stepType,
  handleNext,
}) => {
  const buildFields = () => {
    const vehicles = getPathValue('policy.riskUnits', policy);

    if (vehicles?.length)
      return vehicles.map((vehicle, index) => {
        return {
          name: `vehicle${index}`,
          initialValue: EMPTY_VALUE.CHECKBOX,
          label: vehicle.description,
          type: FIELD_TYPE.CHECKBOX,
          tooltip: '',
          isOneColumn: true,
          vehicle: {
            make: vehicle.make,
            model: vehicle?.model ?? 'Unknown',
            year: vehicle.year,
            VIN: vehicle.vin,
          },
          yup: Yup.boolean(),
        };
      });

    return [];
  };

  const fields = buildFields();
  const { isLoading, setIsLoading } = useLoading();

  const handleSubmit = () => {
    updateLocationRec(formik.values);
    let copyClaim = shallowCopy(claim);
    const selectedValues = Object.keys(formik.values);
    selectedValues.forEach((i) => {
      const vehicleField = fields.find(field => field.name === i);
      copyClaim.eventDetails.driverVehicleDetails.vehicles.push(vehicleField["vehicle"]);
    });

    updateClaim(copyClaim);

    if (!summary.isLastStept) handleNext();
    else updateDisabled(stepType, true);
  };

  const formik = useAMIGFormik(fields, handleSubmit);

  useEffect(() => {
    try {
      setReduxValues(formik, newClaim.locationRec, fields);
      setIsLoading(false);
    } catch (error) {
      console.error('Error', error);
    }
  }, []);

  if (isLoading) return <LoadingPlaceholder />;

  return (
    <FormType
      title={
        policy.isVisselType
          ? ComponentTitle.LOSSLOCATIONVESSEL
          : ComponentTitle.LOSSLOCATIONREC
      }
      subtitle='File a claim'
      buttonLabel={summary.isLastStept ? 'Save' : 'Continue'}
      isDisable={summary.isStepDisabled(summary.disable, stepType)}
      handleSubmit={formik.handleSubmit}>
      <AMIGForm stepType={stepType} fields={fields} formik={formik} />
    </FormType>
  );
};

LossLocationWithRedux.defaultProps = {
  stepType: StepTypes.LOSS_LOCATION_REC,
  handleNext: () => {},
};

const mapStateToProps = (state) => ({
  summary: state.summary,
  claim: state.claim,
  newClaim: state.newClaim,
  policy: state.policy,
});

export const LossLocationRecreational = connect(mapStateToProps, {
  updateDisabled,
  updateLocationRec,
  updateClaim,
})(LossLocationWithRedux);
