import React, { useEffect, useState }  from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Grid,
  Typography,
  FormControl,
  FormGroup,
  RadioGroup, 
  FormControlLabel, 
  Radio,
  TextField,
  Button,
  Tooltip
} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import CustomValidatorUtil from '../../common/CustomValidatorUtil';
import useStyles from './TrackClaimComponent.styles';

const ClaimSearchComponent = ({
  getClaimDetails,
  radioGroupChange,
  radioButtonSetting = 'claim',
  selectedAccountType,
}) => {
  const classes = useStyles();
  const loaderGif = require('../../../img/loader.gif');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    businessName: '',
    zipCode: '',
    searchNumber: '',
  });
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    businessName: '',
    zipCode: '',
    searchNumber: '',
  });
  const loaderStyle = {
    backgroundImage: isLoading ? `url(${loaderGif})` : 'none',
    backgroundPosition: '10px center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '1.5rem 1.5rem',
    paddingLeft: isLoading ? '2.5rem' : '',
  }

  useEffect(() => {
    if (selectedAccountType === 'individual') {
      setFormValues(prevState => {
        const { businessName, ...newState } = prevState;
        return newState;
      });
      setFormErrors(prevState => {
        const { businessName, ...newState } = prevState;
        return newState;
      });
    } else {
      setFormValues(prevState => {
        const { firstName, lastName, ...newState } = prevState;
        return newState;
      });
      setFormErrors(prevState => {
        const { firstName, lastName, ...newState } = prevState;
        return newState;
      });
    }
    // if the radio button changes from policy to claim, keep the values in the form except for searchNumber and reset the error message.
    setFormValues(prevState => ({
      ...prevState,
      searchNumber: ''
    }));  
    setFormErrors(prevState => ({
      ...prevState,
      searchNumber: ''
    }));  
  }, [selectedAccountType, radioButtonSetting]);

  useEffect(() => {
    const formValuesEmpty = Object.values(formValues).some(value => value === '');
    const isErrorValuesEmpty = Object.keys(formErrors).every((key) => formErrors[key] === '');
    setIsValid(isErrorValuesEmpty && !formValuesEmpty);
  }, [formErrors]);


  const validationCheck = (inputValidator, inputValue) => {
    switch (inputValidator) {
        case 'firstName':
            return CustomValidatorUtil.nameValidation(inputValue);
        case 'lastName':
            return CustomValidatorUtil.nameValidation(inputValue);
        case 'businessName':
            return CustomValidatorUtil.businessNameValidation(inputValue);
        case 'searchNumber':
            return CustomValidatorUtil.searchNumberValidation(inputValue, radioButtonSetting);
        case 'zipCode':
            return CustomValidatorUtil.zipCodeValidation(inputValue);
        default:
            break;
    }
};

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [id]: value,
    }));
  };

  const handleValidation = (event) => {
    const { id, value } = event.target;
    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [id]: validationCheck(id, value),
    }));
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = { ...formValues, searchNumber: formValues.searchNumber.toUpperCase() };
    if(radioButtonSetting==='policy'){
      formData.policyNumber=formValues.searchNumber;
    }
    await getClaimDetails(formData);
    setIsLoading(false);
  };

  return (
    <Grid container direction="column" className={classes.statusCard}>
      <Grid item>
        <Typography variant="body1" className={classes.subText}>
          You can search by claim or policy number.
        </Typography>
      </Grid>
      <Grid item container>
        <FormGroup>
          <Grid item container direction="row">
              <FormControl component="fieldset">
                <RadioGroup row name="searchType" className={classes.radioGroup} value={radioButtonSetting}>
                  <FormControlLabel value="claim" control={<Radio color="primary" />} label="Claim number" onChange={radioGroupChange}/>
                  <FormControlLabel value="policy" control={<Radio color="primary" />} label="Policy number" onChange={radioGroupChange}/>
                </RadioGroup>
              </FormControl>
          </Grid>
        </FormGroup>
      </Grid>
      <form className={classes.inputsForm} onSubmit={handleSubmit}>
        <Grid item container spacing={2}>
        {(selectedAccountType === 'individual') && ( 
          <>
            <Grid className={classes.inputFields} item xs={12} sm={6}>
              <Typography className={classes.inputHeading} variant="subtitle1" gutterBottom> First name insured </Typography>
              <TextField
                id="firstName"
                className={classes.inputBox}
                placeholder="Enter your name"
                variant="outlined"
                fullWidth
                value={formValues.firstName}
                onChange={handleInputChange}
                onBlur={handleValidation}
                error={formErrors.firstName !== ''}
                helperText={formErrors.firstName}
              />
            </Grid>
            <Grid className={classes.inputFields} item xs={12} sm={6}>
              <Typography className={classes.inputHeading} variant="subtitle1" gutterBottom> Last name insured </Typography>
              <TextField
                id="lastName"
                className={classes.inputBox}
                placeholder="Enter your last name"
                variant="outlined"
                fullWidth
                value={formValues.lastName}
                onChange={handleInputChange}
                onBlur={handleValidation}
                error={formErrors.lastName !== ''}
                helperText={formErrors.lastName}
              />
            </Grid>
          </>
          )}
          {(selectedAccountType === 'business') && ( 
            <>
              <Grid className={classes.businessField} item xs={12} sm={12}>
              <Typography className={classes.inputHeading} variant="subtitle1" gutterBottom> Named insured </Typography>
              <TextField
                id="businessName"
                className={classes.inputBox}
                placeholder="Enter business name"
                variant="outlined"
                fullWidth
                value={decodeURIComponent(formValues.businessName)}
                onChange={handleInputChange}
                onBlur={handleValidation}
                error={formErrors.businessName !== ''}
                helperText={formErrors.businessName}
              />
            </Grid>
          </>
          )}
          <Grid className={classes.inputFields}  item xs={12} sm={6}>
            <Box className={classes.toolTipWrapper}>
              <Typography className={classes.inputHeading} variant="subtitle1" gutterBottom> { radioButtonSetting === 'claim' ? "Coverage ZIP Code" : "Loss ZIP Code" } </Typography>
              <Tooltip className={classes.toolTip} title={ radioButtonSetting === 'claim' ? "The ZIP Code of your policy" : "The ZIP Code where the loss actually happened"} >
                <HelpIcon fontSize="small" style={{ marginLeft: 5 }} />
              </Tooltip>
            </Box>
            <TextField
              id="zipCode"
              className={classes.inputBox}
              placeholder={radioButtonSetting === 'claim' ? 'Enter your coverage ZIP Code ' : 'Enter your loss ZIP Code'}
              variant="outlined"
              fullWidth
              value={formValues.zipCode}
              onChange={handleInputChange}
              onBlur={handleValidation}
              error={formErrors.zipCode !== ''}
              helperText={formErrors.zipCode}
              />
          </Grid>
          <Grid className={classes.inputFields}  item xs={12} sm={6}>
            <Typography className={classes.inputHeading} variant="subtitle1" gutterBottom> {radioButtonSetting === 'claim' ? 'Claim number ' : 'Policy number'} </Typography>
            <TextField
              id="searchNumber"
              className={classes.inputBox}
              placeholder={radioButtonSetting === 'claim' ? 'Enter your claim number ' : 'Enter your policy number'}
              variant="outlined"
              fullWidth
              value={formValues.searchNumber}
              onChange={handleInputChange}
              onBlur={handleValidation}
              error={formErrors.searchNumber !== ''}
              helperText={formErrors.searchNumber}
              />
          </Grid>
          <Button className={isValid ? classes.amigButtonFilled : classes.amigButtonFilledDisabled} disabled={!isValid} style={loaderStyle} name="Track a claim search unauth" onClick={handleSubmit}>Search</Button>
        </Grid>
      </form>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  
});

ClaimSearchComponent.propTypes = {
};

export default connect(mapStateToProps, {  
})(ClaimSearchComponent);
