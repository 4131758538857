import { EMPTY_VALUE, FIELD_TYPE, states, zipCodeExp } from "../../../../../actions/types";
import * as Yup from 'yup';

export const lossLocationNotPolicy = [
  {
    name: 'addressLine1',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Address 1',
    type: FIELD_TYPE.TEXT,
    keyPathToSaveValue: 'claim.lossLocation.addressLine1',
    yup: Yup.string().required('Provide a value'),
  },
  {
    name: 'addressLine2',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Address 2',
    keyPathToSaveValue: 'claim.lossLocation.addressLine2',
    type: FIELD_TYPE.TEXT,
    keyPathToSaveValue: 'claim.lossLocation.addressLine2',
  },
  {
    name: 'city',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'City',
    type: FIELD_TYPE.TEXT,
    col: 4,
    keyPathToSaveValue: 'claim.lossLocation.city',
    yup: Yup.string().required('Provide a value'),
  },
  {
    name: 'state',
    initialValue: EMPTY_VALUE.SELECT,
    label: 'State',
    type: FIELD_TYPE.SELECT,
    col: 4,
    options: states.map( option => {
      return { name: option.title , value: option.abbreviation.toUpperCase() };
    }),
    keyPathToSaveValue: 'claim.lossLocation.state',
    yup: Yup.string().required('Provide a value'),
  },
  {
    name: 'postalCode',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Zip',
    type: FIELD_TYPE.TEXT,
    col: 4,
    keyPathToSaveValue: 'claim.lossLocation.postalCode',
    yup: Yup.string().matches(zipCodeExp, 'Provide a valid Zip Code').required('Provide a value'),
  },
];