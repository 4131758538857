import { makeStyles, unstable_createMuiStrictModeTheme } from '@material-ui/core/styles';

const cardDetail= {
  display: 'flex',
  flexWrap: 'wrap',
  minWidth: 300,
  width: '100%',
  borderRadius: '0.188rem',
  borderLeft: '0.375rem solid #31769b',
}

const statusCardStyle = {
  textAlign: 'left',
  padding: '2.5rem 2.875rem 3.5rem !important',
  borderRadius: '3px',
}

const headerStyle = {
  color: '#282828',
  marginBottom: '2rem',
  fontSize: '1.75rem',
  lineHeight: '2.25rem',
  fontFamily: '"Raleway", sans-serif',
  fontStyle: 'normal',
  fontWeight: '700',
  fontFeatureSettings: '"lnum"',
}

const headerStyleMobile = {
  color: '#282828',
  marginBottom: '2rem',
  fontSize: '1rem',
  fontFamily: '"Raleway", sans-serif',
  lineHeight: '1.5rem',
  fontStyle: 'normal',
  fontWeight: '700',
  fontFeatureSettings: '"lnum"',
}

const subTitle = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '1.5rem',
  lineHeight: '2rem',
  color: '#282828',
}

const subTextStyle = {
  color: '#535353',
  fontFamily: 'Arial',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.5rem'
}

const inputTextStyle = {
  fontFamily: 'Arial',
  fontStyle: 'normal',
  fontWeight:'400',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  color: '#7E7E7E',
}

const buttonTheme = {
  boxShadow: '0 0.063rem .125rem rgba(0, 0, 0, 0.06), 0px .063rem 0.188rem rgba(0, 0, 0, 0.1)',
  borderRadius: '.188rem',
  ...subTitle,
  fontSize: '1rem',
  lineHeight: '1.5rem',
  textAlign: 'center',
  border: '.125rem solid #31769b',
  padding: '.75rem 1.5rem',
  marginBottom: '1.5rem',
}

const breadCrumbText = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontFeatureSettings: '"lnum"',
}

const useStyles = makeStyles((theme) => ({
    root: {
      margin: '80px',
      [theme.breakpoints.down('md')]: {
        margin: '0px',
      },
    },

    trackClaimTitle: {
      ...headerStyle,
      fontSize: '2.5rem',
      lineHeight: '3.5rem',
      marginBottom: '0',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },
    },

    adjusterTitle: {
      ...headerStyle,
      fontSize: '2rem',
      lineHeight: '2.75rem',
      marginBottom: '0',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },
    },

    radioGroup: {
      margin: '1.25rem 0rem 1.25rem 0rem',
      '& .MuiFormControlLabel-label ': {
        color: '#000000',
      },
      '& .MuiRadio-root': {
        color: '#000000',
      },      '& .Mui-checked': {
        color: '#31769B',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        marginBottom: '0',
        '& .MuiFormControlLabel-root ': {
          paddingBottom:'1.5rem',
        },
      },
    },

    claimDetail: {
      ...cardDetail,

      display: 'block',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        flexBasis: '100%', 
        display: 'block', 
      },
    },

    adjusterCard: {
      ...cardDetail,
      borderLeft: '0.375rem solid #427D26',
    },

    container: {
      background: '#FFFFFF',
      margin: '2.5rem 0 2.5rem',
      boxShadow: '0 0.063rem .125rem rgba(0, 0, 0, 0.06), 0px .063rem 0.188rem rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.down('md')]: {
        margin: '1rem 0 2.5rem',
      },
    },

    sideCard: {
      ...cardDetail,
      borderLeft: '.125rem solid #D4D4D4',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        flexBasis: '100%', 
        display: 'block', 
        borderLeft: '0.375rem solid #31769b',
      },
    },

    sideCardTitle: {
      ...subTitle,
      [theme.breakpoints.down('md')]: {
        fontSize: '1rem',
        lineHeight: '1.5rem'  
      }
    },

    sideCardBorder: {
      padding: '0px 12px 12px !important',
      [theme.breakpoints.down('md')]: {
        padding: '1.5rem 0 0 .5rem !important', // overwriting xs3
        borderTop: '2px solid #EAEAEA',
      },
    },

    loginList: {
      padding: 0,
      textAlign: "center",
      listStyleType: "disc",
      display: "list-item",
      marginLeft: '1.563rem',
      '& li': {
        display:'inherit',
        padding: '0',
        '& div': {
          margin: '0',
        },
      },
    },

    searchButton: {
      ...buttonTheme,
      color:'#31769B',
      background: '#FFFFFF',
      textTransform: 'none',
      fontFamily: 'Arial',

      [theme.breakpoints.down('md')]: {
        width: '100%',      
        display:'none',
      },
    },

    amigButton:{
     ...buttonTheme,
      color:'#31769B',
      background: '#FFFFFF',
      textTransform: 'none',

      [theme.breakpoints.down('md')]: {
        width: '100%',      
      },
    },

    amigButtonFilled:{
      ...buttonTheme,
      background: '#31769B',
      margin: '.5rem .5rem 0 .5rem',
      color:'#FFFFFF',
      textTransform: 'none',
      '&:hover': {
        color: '#fff',
        background: '#3f94c3',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',      
      },
    },

    amigButtonFilledDisabled: {
      ...buttonTheme,
      color: '#FFFFFF !important', // overriding MUI disabled color
      background: '#ADC8D7',
      textTransform: 'none',
      margin: '.5rem .5rem 0 .5rem',
      border: 'none',
      [theme.breakpoints.down('md')]: {
        width: '100%',      
      },
    },

    createAccountLink: {
      fontFamily: 'Raleway',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      color: '#31769B',
      paddingBottom: '.75rem',
      [theme.breakpoints.down('md')]: {
        textAlign:'center',
        paddingBottom: '.75rem'
      },
    },

    documentDownloadText: {
      [theme.breakpoints.down('md')]: {
        textAlign:'center',
      },
    },

    progressBar:{
      height: '1rem',
      borderRadius: '2px',
      backgroundColor: '#f4f4f4',
      height: '2rem',
      borderRadius: '2px',
      '& .MuiLinearProgress-bar': {
        backgroundColor: '#31769b',
      },
    },

    headerTitle: {
      ...headerStyle,
      [theme.breakpoints.down('md')]: {
        ...headerStyleMobile,
        marginBottom: '1.5rem',
      },
    },

    policyNumber:{ 
      ...headerStyle,
      fontSize: '1rem', 
      lineHeight: '1.5rem',     
      marginBottom: '0',
      '& span': {
        color: '#7E7E7E',
        fontFamily: 'Arial',
      },
      display: 'inline-flex',
      justifyContent: 'space-between',
      '& div': {
        display:'flex',
        margin: '0 0 0 0.5rem',
        alignItems: 'center'
      },
      [theme.breakpoints.down('md')]: {
        '& span': {
          color: '#535353',
        },        
        display:'block',
        '& div': {
          margin: '0.5rem 0 0 0'
        }
      }
    },

    copyIcon: {
      paddingLeft: '0.375rem',
      cursor: 'pointer',
      height: '1.5rem',
      width: '1.5rem'
    },

    errorBox: {
      display:'flex',
      margin: '2.0rem 2.875rem 0rem',
      background: '#FDE7E7',
      [theme.breakpoints.down('md')]: {
        margin: '1.5rem 1.125rem 0rem',
      },
      '& img': {
        display: 'inline',
        margin: 0,
      },
    },

    cardSubHead:{ 
      ...headerStyle,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      margin: '1rem 0 0 0',
      marginBottom: '0',
    },

    cardSection:{
      marginTop: '2.5rem',
      marginBottom: '2rem',
      [theme.breakpoints.down('md')]: {
        display:'block',
        marginBottom: '0',
      },
    },

    cardRow:{
      [theme.breakpoints.down('md')]: {
        marginTop: '1.5rem',
      },
    },

    innerCard: {
      ...cardDetail,
      borderRadius: '0',
      borderLeft: 'none',
    },

    statusCard: {
      ...statusCardStyle,
      color: '#535353',
      margin: 'unset',
      width: 'unset',
      [theme.breakpoints.down('md')]: {
        padding: '1.5rem 1.125rem 1rem !important',
      },
    },

    headerText: {
      ...headerStyle,
      marginBottom: '0.5rem',
      fontSize:'1.75rem',
      lineHeight:'2.25rem',
      [theme.breakpoints.down('md')]: {
        fontSize:'1.25rem',
      },
    },

    adjusterHeaderText: {
      ...headerStyle,
      margin: '0.375rem 0rem .5rem 1.5rem',
      fontSize:'1.5rem',
      lineHeight:'2rem',
      [theme.breakpoints.down('md')]: {
        fontSize:'1.25rem',
        lineHeight:'1.75rem',
      },
    },

    statusHeaderText: {
      ...headerStyle,
      color: '#427d26',
      marginBottom: '0.5rem',
      fontSize:'1.75rem',
      lineHeight:'2.25rem',
      [theme.breakpoints.down('md')]: {
        fontSize:'1.25rem',
        lineHeight:'1.75rem',
      },
    },

    subText: {
      ...subTextStyle,
    },

    accountTypeSubText: {
      ...subTextStyle,
      marginBottom: '.5rem'
    },

    cardLabel:{
      ...headerStyle,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      margin: 'unset',
      marginBottom: '0.25rem'
    },

    accountTypeCard: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1.5rem',
      margin:'1.5rem 0 1.5rem 0',
      maxWidth:'554px',
      background: '#FFFFFF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      flex: 'none',
      order: '0',
      flexGrow: '0',  
      
      [theme.breakpoints.down('md')]: {
        maxWidth:'unset',
      },

      '& .MuiCardContent-root': {
        padding:'8px 16px'
      },

      '& .MuiTypography-h6': {
        fontFamily: '"Raleway", sans-serif',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize:'1rem',
        color: '#282828',
        lineHeight: '1.5rem'
      },
    },

    cardContent: {
      display: 'flex',
      alignItems: 'center',
    },
    
    arrowContainer: {
      marginLeft: 'auto',
    },

    tableFont: {
      ...subTextStyle,
      '& span': {
        font: 'inherit'
      },
    },

    adjusterSubTitle: {
      ...subTitle,
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      paddingBottom: '1rem',
      [theme.breakpoints.down('md')]: {
        display:'none',      
      },
    },

    agentDisplayNameWrapper: {
      display:'flex',
    },

    alertIcon: {
      paddingRight: '1.25rem',
      width: '1.5rem',
      height: '1.5rem',
    },

    adjusterIcon: {
      width: '3rem',
      height: '3rem',
    },

    agentContactPhoneWrapper: {
      display:'flex',
      margin: '1rem 0',
      height: '1.75rem',
      '& img': {
        margin: 0,
        width: '1.5rem',
        height: '1.5rem',
      },
    },

    phoneIcon: {
      display: 'inline',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
    },

    activePhoneIcon: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'inline'
      },
    },

    emailWrapper: {
      display:'flex',
      marginBottom: '1rem',
    },

    emailIcon: {
      width: '1.5rem',
      height: '1.5rem',
    },

    toolTipWrapper: {
      display:'flex',
    },

    agentContactLink:{     
      marginBottom:'.188rem',
      flex: 'none',
      order: '1',
      flexGrow: '1',
      boxShadow: 'none',
      textDecoration: 'none !important',
      
      '& span': {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },

      [theme.breakpoints.down('md')]: {
        '& span': {
          fontSize: '1rem',
          lineHeight: '1.5rem',
        },
      }      
    },

    agentMobileContactLink:{
      marginBottom:'.188rem',
      flex: 'none',
      order: '1',
      flexGrow: '1',
      boxShadow: 'none',
      textDecoration: 'none !important',
      pointerEvents: 'none',

      '& span': {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },

      [theme.breakpoints.down('md')]: {
        pointerEvents: 'unset',
        '& span': {
          fontSize: '1rem',
          lineHeight: '1.5rem'
        }
      },
    },

    adjusterPhoneNumber: {
      ...subTextStyle,
      fontWeight: 700,
      margin: '0 2rem',
      background: 'transparent', 
      [theme.breakpoints.down('md')]: {
        color: '#427d26', 
        '&:hover':{
          textDecoration: 'none',
          color: '#8eb17d',
        }
      },
    },

    inputsForm: {
      maxWidth: '37.5rem',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',    
      },
    },

    inputHeading: {
      ...subTextStyle,
      color: '#282828',
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: '700',
      marginBottom: '0.5rem',
      [theme.breakpoints.down('md')]: {
        fontSize: '0.75rem',
        lineHeight: '0.875rem',
      }
    },

    inputBox: {
      ...inputTextStyle,
      margin:'0 1rem 1.75rem 0',
      background:'#F4F4F4',
      '& .MuiInputBase-root': {
        border:'1px solid #d4d4d4',
      },
      '& .Mui-error': {
        background:'#ffffff',
        margin:'0',
      },
      '& .MuiOutlinedInput-input': {
        height: '48px',
        padding: '12px 16px',
        boxSizing: 'border-box'
      },
      '& .MuiInputLabel-outlined': {
        lineHeight: '14px'
      },
      [theme.breakpoints.down('md')]: {
        margin:'0 1rem 1rem 0',
        '& .MuiOutlinedInput-input': {
          padding: '14px 16px',
          fontSize: '0.875rem',
          lineHeight: '1.25rem'
        }
      }
    },

    inputFields: {
      maxWidth: '18.125rem',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        flexBasis: '100%',       
      },
    },

    businessField: {
      maxWidth: '37.188rem',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        flexBasis: '100%',       
      },
    },

    adjusterEmail: {
      margin: '0 2rem',   
      textDecoration: 'none',
      color: '#427d26',
      fontWeight: 700,
      '&:hover':{
        textDecoration: 'none',
        color: '#8eb17d',
      }
     },

  breadCrumb: {
    ...breadCrumbText,
    marginBottom: '1.5rem',
    color: '#282828',
    display: 'none',
    '& span': {
        color: '#535353',
        margin: '0 0.5rem'
      },      
      [theme.breakpoints.down('md')]: {        
        display: 'block'
      },
    },

    breadCrumbLink: {       
      color: '#31769B',
      textDecoration: 'none',
      '&:hover, &:active':{
        color: '#83adc3',
        textDecoration: 'none'
      }
    },
  }));
  
  export default useStyles;
