import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from '../../store';

import Alert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles';
import { REMOVE_ALERT } from '../../actions/types';

const Alerts = ({ alerts }) => {

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

  const classes = useStyles();

  return alerts !== null &&
  alerts.length > 0 &&
  alerts.map(alert => (
    <div className={classes.root}>
       <Alert key={alert.id} severity={alert.severity} onClose={() => store.dispatch({type: REMOVE_ALERT, payload: alert.id})} dismissible>
      {alert.msg}
    </Alert>
    </div>
  ));
}

Alert.propTypes = {
  alerts: PropTypes.array
};

const mapStateToProps = state => ({
  alerts: state.alerts
});

export default connect(mapStateToProps)(Alerts);