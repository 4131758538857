import React, { useState } from 'react';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import { Grid, Typography, Tooltip, Link } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import ClaimDetailComponent from './ClaimDetailComponent';
import ClaimSearchComponent from './ClaimSearchComponent';
import AdjusterComponent from './AdjusterComponent';
import SelectAccountTypeComponent from './SelectAccountTypeComponent';
import SideCardComponent from './SideCardComponent';
import { getClaimsByPolicyNumber, getClaimByClaimNumber } from '../../../actions/claimActions';
import { getPolicySummary } from '../../../actions/policyActions';
import useStyles from './TrackClaimComponent.styles';

const TrackClaimComponent = ({
  getClaimsByPolicyNumber,
  getClaimByClaimNumber
}) => {
  const [claimDetailsData, setClaimDetailsData] = useState(undefined);
  const [radioButtonSetting, setRadioButtonSetting] = useState('claim');
  const [selectedAccountType, setSelectedAccountType] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const classes = useStyles();
  const alertIcon = require('../../../img/alert_icon.png');
  const copyIcon = require('../../../img/copy_icon.png');

  const radioGroupChange = (event) => {
    const value = event.currentTarget.defaultValue;
    if(value === 'claim') { setRadioButtonSetting(value) }
    if(value === 'policy') { setRadioButtonSetting(value) }  
  }

  const handleCopyClick = () => {
    const policyNumber = claimDetailsData.policyNumber;
    navigator.clipboard.writeText(policyNumber);
    setCopySuccess(true);
  };

  const handleSelectAccountType = (accountType) => {
    setSelectedAccountType(accountType);
    document.getElementById('createAccountType').value = accountType;
  };

  const getClaimDetails = async (data) => {
    try {
      let response;
      data.searchType = radioButtonSetting;
      data.businessName=encodeURIComponent(data.businessName);
      if (radioButtonSetting === 'claim') {
        response = await getClaimByClaimNumber(data);
        data.searchNumber = response.policyNumber;
        const policyResponse = await getClaimsByPolicyNumber(data);
        response.policyType = policyResponse.policyType;
      } else {
        response = await getClaimsByPolicyNumber(data);
        if (response.claimNumber !== undefined) {
          data.searchNumber = response.claimNumber;
          const claimResponse = await getClaimByClaimNumber(data);
          const mergedResponse = { ...claimResponse, ...response };
          setErrorMessage(undefined);
          setClaimDetailsData(mergedResponse);
          return;
        } else {
          setErrorMessage(response);
          return;
        }
      }
      setErrorMessage(undefined);
      setClaimDetailsData(response);
    } catch (error) {
      setErrorMessage(error?.message);
    }
  };

  const handleReset = () => {
    setErrorMessage(undefined);
    setClaimDetailsData(undefined);
  };

  const handleAccountReset = () => {
    setErrorMessage(undefined);
    setSelectedAccountType(undefined);
    setClaimDetailsData(undefined);
  };

  return(
    <Box p={4} bgcolor="text.disabled" style={{background: '#F4F4F4'}}>
      <Box className={classes.root}> 
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
          <Box>
            {!!selectedAccountType &&
              <Typography className={classes.breadCrumb} variant="h5">
                <Link className={classes.breadCrumbLink} tabIndex={0} aria-disabled={false} onClick={handleAccountReset} rel="noreferrer">Home</Link><span>/</span>
                {!!claimDetailsData && (
                  <><Link className={classes.breadCrumbLink} tabIndex={0} aria-disabled={false} onClick={handleReset} rel="noreferrer">Track claims</Link><span>/</span>
                    Claim #{claimDetailsData.claimNumber}</>
                )}
                {!claimDetailsData && (
                  <>Track claims</>
                )}
              </Typography>}            
            <Typography className={classes.trackClaimTitle} variant="h1">Track claims</Typography>
            { !!claimDetailsData && ( 
            <>
            <Typography className={classes.headerText} variant="h3">{claimDetailsData.policyType}</Typography>
              <Typography className={classes.policyNumber} variant="h6">Policy number
                  <div><span>{claimDetailsData.policyNumber}</span>
                    <Tooltip title={copySuccess ? 'Copied!' : 'Copy policy number'} enterTouchDelay={0} arrow>
                      <img src={copyIcon} alt="" className={classes.copyIcon} onClick={handleCopyClick} />
                    </Tooltip>
                  </div>
              </Typography>
            </>
            )}
          </Box>
          { !!claimDetailsData && ( 
            <Button className={classes.searchButton} onClick={handleReset}>Search for a different claim</Button>
          )}
        </Box>
        <Grid container justify="space-between" className={classes.container}>
          <Grid item xs={8} className={classes.claimDetail}>  
            <Box>
              
              { errorMessage && (
                  <Box className={classes.errorBox} border={1} borderColor="error.main" p={2} mb={2}>
                    <img src={alertIcon} alt="" className={classes.alertIcon} />
                    <Typography>{errorMessage}</Typography>
                  </Box>          
              )}
              { (!!claimDetailsData) && ( 
                <ClaimDetailComponent claimDetailsData={claimDetailsData} />
              )}
              { (!claimDetailsData && !!selectedAccountType) && ( 
                <ClaimSearchComponent selectedAccountType={selectedAccountType} getClaimDetails={getClaimDetails} radioGroupChange={radioGroupChange} radioButtonSetting={radioButtonSetting}/>
              )}
              { (!selectedAccountType) && ( 
                <SelectAccountTypeComponent handleSelectAccountType={handleSelectAccountType}/>
              )}
            </Box>
          </Grid>
          <SideCardComponent isLeasingDesk={claimDetailsData?.isLeasingDesk}/>
        </Grid>
        { !!claimDetailsData && ( 
        <>
          <AdjusterComponent adjusterData={claimDetailsData.claimAdjuster} />
        </>
        )}
    </Box>
  </Box>
  );
};
const mapStateToProps = (state) => ({
});

TrackClaimComponent.propTypes = {
};
export default connect(mapStateToProps, {
  getClaimsByPolicyNumber,
  getClaimByClaimNumber,
  getPolicySummary
})(TrackClaimComponent);
