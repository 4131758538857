import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { ClaimTypes, ClaimSteps, WitnessLocations } from '../../actions/types';
import { updateStepContentIndex } from '../../actions/stepperActions';
import PropTypes from 'prop-types';
import Thumbnails from '../common/Thumbnails';
import AttachmentViewDialog from '../dialogs/AttachmentViewDialog';
import Link from '@material-ui/core/Link';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { capitalize } from '../../utils/StringUtils';
import { formatPhoneForDisplay } from '../../utils/PhoneNumberUtils';
import { formatAddressForDisplay } from '../../utils/AddressUtils';
import { useIsAuthenticated } from '@azure/msal-react';

const useStyles = makeStyles({
  card: {
    display: 'flex-start',
    flexDirection: 'column',
    alignItems: 'left',
    height: '100%',
    width: '70%',
    margin: '0px auto',
  },
  cardMobile: {
    display: 'flex-start',
    flexDirection: 'column',
    alignItems: 'left',
    height: '100%',
  },
  cardContent: {
    textAlign: 'left',
  },
  cardHeader: {
    padding: '1rem',
    fontSize: '1.2rem',
  },
  title: {
    fontSize: 14,
  },
  editIcon: {
    alignSelf: 'flex-end',
  },
});

const ClaimInfoComponent = ({
  claim,
  updateStepContentIndex,
  submissionId = null,
}) => {
  const isAuthenticated = useIsAuthenticated();
  const { deviceType } = getDeviceTypeInfo();
  const [] = useState(submissionId ? true : false);
  const [] = useState(submissionId ? true : false);

  const [selectedImage] = useState({ name: '' });
  const [showImageView, setShowImageView] = useState(false);
  const handleCloseAttachmentViewDialog = () => {
    URL.revokeObjectURL(selectedImage.preview);
    setShowImageView(false);
  };

  const classes = useStyles();

  const [, setExpanded] = useState(false);

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const ContactInfoComponent = () => {
    if (claim.namedInsured.firstName === '') {
      return (
        <Card
          className={
            deviceType === 'Mobile' ? classes.cardMobile : classes.card
          }>
          {!submissionId ? (
            <CardHeader
              title={'Claim Contact'}
              align='center'
              className={classes.cardHeader}
              action={
                <IconButton
                  size='small'
                  className={classes.editIcon}
                  onClick={() =>
                    updateStepContentIndex(ClaimSteps.CLAIM_REPORTER)
                  }>
                  <EditIcon fontSize='small' />
                </IconButton>
              }
            />
          ) : (
            <CardHeader
              title={'Claim Contact'}
              align='center'
              className={classes.cardHeader}
            />
          )}
          <CardContent className={classes.cardContent}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                {claim.policyNumber && (
                  <>
                    <Typography className={classes.title} color='textSecondary'>
                      Policy Number
                    </Typography>
                    <Typography gutterBottom>{claim.policyNumber}</Typography>
                  </>
                )}
                <Typography className={classes.title} color='textSecondary'>
                  Reporting Party
                </Typography>
                <Typography gutterBottom>
                  {claim.reportingParty.firstName}{' '}
                  {claim.reportingParty.lastName}
                </Typography>
                <Typography className={classes.title} color='textSecondary'>
                  Relation to Insured
                </Typography>
                <Typography gutterBottom>
                  {claim.reportingParty.relationToInsured}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography className={classes.title} color='textSecondary'>
                  Contact
                </Typography>
                <Typography gutterBottom>
                  {claim.reportingParty.contactInfo.email}
                </Typography>
                {claim.reportingParty.contactInfo.phone.map((item, idx) => {
                  return (
                    <>
                      {idx ===
                      claim.reportingParty.contactInfo.phone.length - 1 ? (
                        <Typography key={idx} gutterBottom>
                          {item.type}
                          {' : '}
                          {formatPhoneForDisplay(item)}
                        </Typography>
                      ) : (
                        <Typography>
                          {item.type}
                          {' : '}
                          {formatPhoneForDisplay(item)}
                        </Typography>
                      )}
                    </>
                  );
                })}
                <Typography className={classes.title} color='textSecondary'>
                  Mailing Address
                </Typography>
                <Typography gutterBottom>
                  {formatAddressForDisplay(claim.reportingParty.address)}
                </Typography>
                <Typography className={classes.title} color='textSecondary'>
                  Text/Email Opt-In
                </Typography>
                <Typography gutterBottom>
                  {claim.reportingParty.isOptedIn === true ? 'Yes' : 'No'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    } else {
      return (
        <Card
          className={
            deviceType === 'Mobile' ? classes.cardMobile : classes.card
          }>
          {!submissionId ? (
            <CardHeader
              title={'Claim Contact'}
              align='center'
              className={classes.cardHeader}
              action={
                <IconButton
                  size='small'
                  className={classes.editIcon}
                  onClick={() =>
                    updateStepContentIndex(ClaimSteps.CLAIM_REPORTER)
                  }>
                  <EditIcon fontSize='small' />
                </IconButton>
              }
            />
          ) : (
            <CardHeader
              title={'Claim Contact'}
              align='center'
              className={classes.cardHeader}
            />
          )}
          <CardContent className={classes.cardContent}>
            {claim.reportingParty.reporter === 'Other' && (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.title} color='textSecondary'>
                    Reporting Party
                  </Typography>
                  <Typography gutterBottom>
                    {claim.reportingParty.reporter === 'Other'
                      ? `${claim.reportingParty.firstName} ${claim.reportingParty.lastName}`
                      : `${claim.reporter}`}
                  </Typography>
                  <Typography className={classes.title} color='textSecondary'>
                    Relation to Insured
                  </Typography>
                  <Typography gutterBottom>
                    {claim.reportingParty.relationToInsured}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.title} color='textSecondary'>
                    Contact
                  </Typography>
                  <Typography gutterBottom>
                    {claim.reportingParty.contactInfo.email}
                  </Typography>
                  {claim.reportingParty.contactInfo.phone.map((item, idx) => {
                    return (
                      <>
                        {idx ===
                        claim.reportingParty.contactInfo.phone.length - 1 ? (
                          <Typography key={idx} gutterBottom>
                            {item.type}
                            {' : '}
                            {formatPhoneForDisplay(item)}{' '}
                          </Typography>
                        ) : (
                          <Typography key={idx}>
                            {item.type}
                            {' : '}
                            {formatPhoneForDisplay(item)}{' '}
                          </Typography>
                        )}
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12}>
                  <Box pb={2}>
                    <Divider />
                  </Box>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} alignContent={'left'}>
                {claim.reportingParty.reporter !== 'Other' && (
                  <>
                    <Typography className={classes.title} color='textSecondary'>
                      Reporting Party
                    </Typography>
                    <Typography gutterBottom>
                      {claim.reportingParty.reporter}
                    </Typography>
                  </>
                )}
                <Typography className={classes.title} color='textSecondary'>
                  Policy Number
                </Typography>
                <Typography gutterBottom>{claim.policyNumber}</Typography>
                <Typography className={classes.title} color='textSecondary'>
                  Named Insured
                </Typography>
                <Typography gutterBottom>
                  {claim.namedInsured.firstName} {claim.namedInsured.lastName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} alignContent={'left'}>
                <Typography className={classes.title} color='textSecondary'>
                  Contact
                </Typography>
                <Typography gutterBottom>
                  {claim.namedInsured.contactInfo.emailAddress1}
                </Typography>
                {claim.namedInsured.contactInfo.phone.map((item, idx) => {
                  return (
                    <>
                      {idx ===
                      claim.namedInsured.contactInfo.phone.length - 1 ? (
                        <Typography key={idx} gutterBottom>
                          {item.type}
                          {' : '}
                          {formatPhoneForDisplay(item)}
                        </Typography>
                      ) : (
                        <Typography key={idx}>
                          {item.type}
                          {' : '}
                          {formatPhoneForDisplay(item)}
                        </Typography>
                      )}
                    </>
                  );
                })}
                <Typography className={classes.title} color='textSecondary'>
                  Mailing Address
                </Typography>
                <Typography gutterBottom>
                  {claim.namedInsured.address.addressLine1}{' '}
                  {claim.namedInsured.address.addressLine2}
                  {', '}
                  {claim.namedInsured.address.city}
                  {', '}
                  {claim.namedInsured.address.state}{' '}
                  {claim.namedInsured.address.postalCode}
                </Typography>
                {claim.reportingParty.reporter ===
                  `${claim.namedInsured.firstName} ${claim.namedInsured.lastName}` && (
                  <>
                    <Typography className={classes.title} color='textSecondary'>
                      Text/Email Opt-In
                    </Typography>
                    <Typography gutterBottom>
                      {claim.reportingParty.isOptedIn === true ? 'Yes' : 'No'}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      );
    }
  };

  return (
    <>
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ContactInfoComponent />
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.cardMobile}>
              <CardHeader
                title={'Loss Details'}
                align='center'
                className={classes.cardHeader}
              />
              <CardContent style={{ textAlign: 'left' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1} direction={'column'}>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.title}
                          color='textSecondary'>
                          Loss Date/Time
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={11}>
                            <Typography>
                              {typeof claim.lossDate ===
                              'string'
                                ? claim.lossDate
                                : null}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            {!submissionId && (
                              <IconButton
                                size='small'
                                className={classes.editIcon}
                                onClick={() =>
                                  updateStepContentIndex(ClaimSteps.POLICY)
                                }>
                                <EditIcon fontSize='small' />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1} direction={'column'}>
                      <Grid item xs={12}>
                        <Typography
                          className={classes.title}
                          color='textSecondary'>
                          Loss Location
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={11}>
                            <Typography>
                              {formatAddressForDisplay(claim.lossLocation)}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}>
                            {!submissionId && (
                              <IconButton
                                size='small'
                                className={classes.editIcon}
                                onClick={() =>
                                  updateStepContentIndex(
                                    ClaimSteps.LOSS_LOCATION
                                  )
                                }>
                                <EditIcon fontSize='small' />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.title} color='textSecondary'>
                      Incidents
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {claim.lossDetails.incidents.map((value, index) => {
                      if (value.type === ClaimTypes.THEFT_VANDALISM) {
                        return (
                          <>
                            <ExpansionPanel
                              key={index}
                              onChange={handleExpanded({ index })}>
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={index}
                                id={index}>
                                <Typography className={classes.heading}>
                                  Theft/Vandalism
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Grid
                                  container
                                  spacing={1}
                                  direction={'column'}
                                  style={{ display: '-webkit-inline-box' }}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      padding: 0,
                                      textAlign: 'right',
                                    }}>
                                    {!submissionId && (
                                      <IconButton
                                        size='small'
                                        className={classes.editIcon}
                                        onClick={() =>
                                          updateStepContentIndex(
                                            ClaimSteps.LOSS_DETAILS
                                          )
                                        }>
                                        <EditIcon fontSize='small' />
                                      </IconButton>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color='textSecondary'>
                                      Description:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.incidentDesc}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color='textSecondary'>
                                      Incident:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {capitalize(value.source)}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color='textSecondary'>
                                      Number of Items Stolen/Damaged:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.numOfClaimItems}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color='textSecondary'>
                                      Estimated loss amount?
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>${value.totalLoss}</Typography>
                                  </Grid>
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </>
                        );
                      } else if (value.type === ClaimTypes.PROPERTY_DAMAGE) {
                        return (
                          <>
                            <ExpansionPanel
                              key={index}
                              onChange={handleExpanded({ index })}>
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={index}
                                id={index}>
                                <Typography className={classes.heading}>
                                  Property/Weather Damage
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Grid
                                  container
                                  spacing={1}
                                  direction={'column'}
                                  style={{ display: '-webkit-inline-box' }}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      padding: 0,
                                      textAlign: 'right',
                                    }}>
                                    {!submissionId && (
                                      <IconButton
                                        size='small'
                                        className={classes.editIcon}
                                        onClick={() =>
                                          updateStepContentIndex(
                                            ClaimSteps.LOSS_DETAILS
                                          )
                                        }>
                                        <EditIcon fontSize='small' />
                                      </IconButton>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Description:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.incidentDesc}
                                    </Typography>
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Source of Damage:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={3}>
                                    <Typography>{value.source}</Typography>
                                  </Grid>

                                  {value.source === 'Other' && (
                                    <>
                                      <Grid item xs={12} sm={6}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Source Description:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography>
                                          {value.sourceOtherDesc}
                                        </Typography>
                                      </Grid>
                                    </>
                                  )}
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Has the damage been repaired?
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.isRepaired === true ? 'Yes' : 'No'}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </>
                        );
                      } else if (value.type === ClaimTypes.FIRE_DAMAGE) {
                        return (
                          <>
                            <ExpansionPanel
                              onChange={handleExpanded({ index })}>
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={index}
                                id={index}>
                                <Typography className={classes.heading}>
                                  Fire Damage
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Grid
                                  container
                                  spacing={1}
                                  direction={'column'}
                                  style={{ display: '-webkit-inline-box' }}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      padding: 0,
                                      textAlign: 'right',
                                    }}>
                                    {!submissionId && (
                                      <IconButton
                                        size='small'
                                        className={classes.editIcon}
                                        onClick={() =>
                                          updateStepContentIndex(
                                            ClaimSteps.LOSS_DETAILS
                                          )
                                        }>
                                        <EditIcon fontSize='small' />
                                      </IconButton>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Fire Source Description:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.fireSourceDesc}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Was anyone injured in the fire?
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.q1.selected === true
                                        ? 'Yes'
                                        : 'No'}
                                    </Typography>
                                  </Grid>
                                  {value.q1.selected === true && (
                                    <>
                                      <Grid item xs={12} sm={6}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Was the injured person hospitalized?
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography>
                                          {value.q1.isHospitalized === true
                                            ? 'Yes'
                                            : 'No'}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          First Name of Injured Party:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography>
                                          {value.q1.firstName}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Last Name of Injured Party:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography>
                                          {value.q1.lastName}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Injured Party's Address:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography>
                                          {formatAddressForDisplay(value.q1)}
                                        </Typography>
                                      </Grid>
                                    </>
                                  )}
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Did the fire cause smoke damage only?
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.q2.selected === true
                                        ? 'Yes'
                                        : 'No'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Is the home currently habitable?
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.q3.selected === true
                                        ? 'Yes'
                                        : 'No'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Is the home currently secure?
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.q4.selected === true
                                        ? 'Yes'
                                        : 'No'}
                                    </Typography>
                                  </Grid>
                                  {value.officials.length > 0 && (
                                    <>
                                      <Grid item xs={12}>
                                        <Typography className={classes.heading}>
                                          Officials
                                        </Typography>
                                      </Grid>
                                      {value.officials.map(
                                        (official, index) => (
                                          <Grid
                                            key={index}
                                            container
                                            item
                                            spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                              <Typography
                                                className={classes.title}
                                                color={'textSecondary'}>
                                                Type:
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <Typography>
                                                {official.type}
                                              </Typography>
                                            </Grid>
                                            {official.reportingAgency && (
                                              <>
                                                <Grid item xs={12} sm={6}>
                                                  <Typography
                                                    className={classes.title}
                                                    color={'textSecondary'}>
                                                    Reporting Agency:
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                  <Typography>
                                                    {official.reportingAgency}
                                                  </Typography>
                                                </Grid>
                                              </>
                                            )}
                                            {official.reportNumber && (
                                              <>
                                                <Grid item xs={12} sm={6}>
                                                  <Typography
                                                    className={classes.title}
                                                    color={'textSecondary'}>
                                                    Report Number:
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                  <Typography>
                                                    {official.reportNumber}
                                                  </Typography>
                                                </Grid>
                                              </>
                                            )}
                                            {official.reportingOfficer !==
                                              '' && (
                                              <>
                                                <Grid item xs={12} sm={6}>
                                                  <Typography
                                                    className={classes.title}
                                                    color={'textSecondary'}>
                                                    Reporting Officer:
                                                  </Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                  <Typography>
                                                    {official.reportingOfficer}
                                                  </Typography>
                                                </Grid>
                                              </>
                                            )}
                                          </Grid>
                                        )
                                      )}
                                    </>
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </>
                        );
                      } else if (value.type === ClaimTypes.WATER_DAMAGE) {
                        return (
                          <>
                            <ExpansionPanel
                              onChange={handleExpanded({ index })}>
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={index}
                                id={index}>
                                <Typography className={classes.heading}>
                                  Water Damage
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Grid
                                  container
                                  spacing={1}
                                  style={{ display: '-webkit-inline-box' }}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      padding: 0,
                                      textAlign: 'right',
                                    }}>
                                    {!submissionId && (
                                      <IconButton
                                        size='small'
                                        className={classes.editIcon}
                                        onClick={() =>
                                          updateStepContentIndex(
                                            ClaimSteps.LOSS_DETAILS
                                          )
                                        }>
                                        <EditIcon fontSize='small' />
                                      </IconButton>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Description:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.incidentDesc}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Source of Damage:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>{value.waterSource}</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Has the damage been repaired?
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.isMitigated === true
                                        ? 'Yes'
                                        : 'No'}
                                    </Typography>
                                  </Grid>
                                  {value.isMitigated === true && (
                                    <>
                                      <Grid item xs={12} sm={6}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Mitigation Description:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography>
                                          {value.mitigationDesc}
                                        </Typography>
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </>
                        );
                      } else if (value.type === ClaimTypes.OTHER) {
                        return (
                          <>
                            <ExpansionPanel
                              onChange={handleExpanded({ index })}>
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={index}
                                id={index}>
                                <Typography className={classes.heading}>
                                  Other/Injury
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <Grid
                                  container
                                  spacing={1}
                                  style={{ display: '-webkit-inline-box' }}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{
                                      padding: 0,
                                      textAlign: 'right',
                                    }}>
                                    {!submissionId && (
                                      <IconButton
                                        size='small'
                                        className={classes.editIcon}
                                        onClick={() =>
                                          updateStepContentIndex(
                                            ClaimSteps.LOSS_DETAILS
                                          )
                                        }>
                                        <EditIcon fontSize='small' />
                                      </IconButton>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Description:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.incidentDesc}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography
                                      className={classes.title}
                                      color={'textSecondary'}>
                                      Was anyone injured?
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Typography>
                                      {value.injuredPerson.isHospitalized ===
                                      true
                                        ? 'Yes'
                                        : 'No'}
                                    </Typography>
                                  </Grid>
                                  {value.injuredPerson.isHospitalized ===
                                    true && (
                                    <>
                                      <Grid item xs={12} sm={6}>
                                        <Typography
                                          className={classes.title}
                                          color={'textSecondary'}>
                                          Was the injured person the
                                          policyholder?
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <Typography>
                                          {value.injuredPerson
                                            .isPolicyholder === true
                                            ? 'Yes'
                                            : 'No'}
                                        </Typography>
                                      </Grid>

                                      {value.injuredPerson.isPolicyholder ===
                                        false && (
                                        <>
                                          <Grid item xs={12} sm={6}>
                                            <Typography
                                              className={classes.title}
                                              color={'textSecondary'}>
                                              Injured Person's name:
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography>
                                              {value.injuredPerson.name}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography
                                              className={classes.title}
                                              color={'textSecondary'}>
                                              Injured Person's phone:
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography>
                                              {value.injuredPerson.phone}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography
                                              className={classes.title}
                                              color={'textSecondary'}>
                                              Injured Person's email:
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography>
                                              {value.injuredPerson.email}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography
                                              className={classes.title}
                                              color={'textSecondary'}>
                                              Injured Person's Address:
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography>
                                              {formatAddressForDisplay(value.injuredPerson)}
                                            </Typography>
                                          </Grid>
                                        </>
                                      )}
                                    </>
                                  )}
                                </Grid>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          </>
                        );
                      } else if (
                        value.type === ClaimTypes.RECREATIONAL_DAMAGE
                      ) {
                        return (
                          <ExpansionPanel onChange={handleExpanded({ index })}>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={index}
                              id={index}>
                              <Typography className={classes.heading}>
                                Vehicle Damage
                              </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Grid
                                container
                                spacing={1}
                                style={{
                                  display: '-webkit-inline-box',
                                }}>
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    padding: 0,
                                    textAlign: 'right',
                                  }}>
                                  {!submissionId && (
                                    <IconButton
                                      size='small'
                                      className={classes.editIcon}
                                      onClick={() =>
                                        updateStepContentIndex(
                                          ClaimSteps.LOSS_DETAILS
                                        )
                                      }>
                                      <EditIcon fontSize='small' />
                                    </IconButton>
                                  )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography
                                    className={classes.title}
                                    color={'textSecondary'}>
                                    Description:
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography>{value.incidentDesc}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography className={classes.heading}>
                                    Vehicles
                                  </Typography>
                                </Grid>
                                {value.vehicles.map((vehicle, index) => (
                                  <>
                                    <Grid item key={index} xs={12} sm={6}>
                                      <Typography
                                        className={classes.title}
                                        color={'textSecondary'}>
                                        {value.vehicles.length > 1
                                          ? `Vehicle (${index + 1}):`
                                          : 'Vehicle:'}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Typography>
                                        {moment(vehicle.year).format('YYYY')}{' '}
                                        {vehicle.make} {vehicle.model}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Typography
                                        className={classes.title}
                                        color={'textSecondary'}>
                                        VIN:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Typography>
                                        {vehicle.vin ? vehicle.vin : 'N/A'}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Typography
                                        className={classes.title}
                                        color={'textSecondary'}>
                                        Have you received a repair estimate for
                                        the:
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Typography>
                                        {vehicle.estimateReceived === true
                                          ? 'Yes'
                                          : 'No'}
                                      </Typography>
                                    </Grid>
                                    {vehicle.estimateReceived && (
                                      <>
                                        <Grid item xs={12} sm={6}>
                                          <Typography
                                            className={classes.title}
                                            color={'textSecondary'}>
                                            Estimated repair cost:
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Typography>
                                            ${vehicle.estimatedRepairCost}.00
                                          </Typography>
                                        </Grid>
                                      </>
                                    )}
                                    <Grid item xs={12} sm={6}>
                                      <Typography
                                        className={classes.title}
                                        color={'textSecondary'}>
                                        Are you the owner of the vehicle?
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Typography>
                                        {vehicle.isOnPolicy ||
                                        (vehicle.hasOwnProperty('owner') &&
                                          vehicle.owner.isInsureds === true)
                                          ? 'Yes'
                                          : 'No'}
                                      </Typography>
                                    </Grid>
                                    {vehicle.hasOwnProperty('owner') &&
                                      vehicle.owner.isInsureds === false && (
                                        <>
                                          <Grid item xs={12} sm={6}>
                                            <Typography
                                              className={classes.title}
                                              color={'textSecondary'}>
                                              Owner:
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography>
                                              {vehicle.owner.firstName}{' '}
                                              {vehicle.owner.lastName}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography
                                              className={classes.title}
                                              color={'textSecondary'}
                                              gutterBottom>
                                              Owner Contact:
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <>
                                              {vehicle.owner.contactInfo.phone.map(
                                                (phone, idx) => (
                                                  <Typography key={idx}>
                                                    {phone.type}:{' '}
                                                    {formatPhoneForDisplay(
                                                      phone
                                                    )}
                                                  </Typography>
                                                )
                                              )}
                                              <Typography
                                                className={classes.title}
                                                color={'textSecondary'}
                                                gutterBottom>
                                                Mailing Address
                                              </Typography>
                                              <Typography>
                                                {formatAddressForDisplay(
                                                  vehicle.owner.address
                                                )}
                                              </Typography>
                                            </>
                                          </Grid>
                                        </>
                                      )}
                                    {vehicle.hasOwnProperty('driver') &&
                                      vehicle.driver && (
                                        <>
                                          <Grid item xs={12} sm={6}>
                                            <Typography
                                              className={classes.title}
                                              color={'textSecondary'}>
                                              Driver:
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <Typography>
                                              {vehicle.driver.firstName}{' '}
                                              {vehicle.driver.lastName}
                                            </Typography>
                                          </Grid>
                                          {(vehicle.driver.address ||
                                            vehicle.driver.contactInfo
                                              .primaryPhoneType) && (
                                            <>
                                              <Grid item xs={12} sm={6}>
                                                <Typography
                                                  className={classes.title}
                                                  color={'textSecondary'}
                                                  gutterBottom>
                                                  Driver Contact:
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={12} sm={6}>
                                                <>
                                                  {vehicle.driver.contactInfo.phone.map(
                                                    (phone, idx) => (
                                                      <Typography key={idx}>
                                                        {phone.type}:{' '}
                                                        {formatPhoneForDisplay(
                                                          phone
                                                        )}
                                                      </Typography>
                                                    )
                                                  )}
                                                  {vehicle.driver.address && (
                                                    <>
                                                      <Typography
                                                        className={
                                                          classes.title
                                                        }
                                                        color={'textSecondary'}
                                                        gutterBottom>
                                                        Mailing Address
                                                      </Typography>
                                                      <Typography>
                                                        {formatAddressForDisplay(
                                                          vehicle.driver.address
                                                        )}
                                                      </Typography>
                                                    </>
                                                  )}
                                                </>
                                              </Grid>
                                            </>
                                          )}
                                        </>
                                      )}
                                  </>
                                ))}
                                {value.witnesses.length > 0 && (
                                  <>
                                    <Grid item xs={12}>
                                      <Typography className={classes.heading}>
                                        Witnesses
                                      </Typography>
                                    </Grid>
                                    {value.witnesses.map((witness, index) => (
                                      <Grid
                                        key={index}
                                        container
                                        item
                                        spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                          <Typography
                                            className={classes.title}
                                            color={'textSecondary'}>
                                            Name:
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Typography>
                                            {witness.firstName}{' '}
                                            {witness.lastName}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Typography
                                            className={classes.title}
                                            color={'textSecondary'}>
                                            Where was the witness?
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Typography>
                                            {WitnessLocations[witness.location]}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Typography
                                            className={classes.title}
                                            color={'textSecondary'}>
                                            Notes:
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Typography>
                                            {witness.perspective}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </>
                                )}
                                {value.officials.length > 0 && (
                                  <>
                                    <Grid item xs={12}>
                                      <Typography className={classes.heading}>
                                        Officials
                                      </Typography>
                                    </Grid>
                                    {value.officials.map((official, index) => (
                                      <Grid
                                        key={index}
                                        container
                                        item
                                        spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                          <Typography
                                            className={classes.title}
                                            color={'textSecondary'}>
                                            Type:
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                          <Typography>
                                            {official.type}
                                          </Typography>
                                        </Grid>
                                        {official.reportingAgency && (
                                          <>
                                            <Grid item xs={12} sm={6}>
                                              <Typography
                                                className={classes.title}
                                                color={'textSecondary'}>
                                                Reporting Agency:
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <Typography>
                                                {official.reportingAgency}
                                              </Typography>
                                            </Grid>
                                          </>
                                        )}
                                        {official.reportNumber && (
                                          <>
                                            <Grid item xs={12} sm={6}>
                                              <Typography
                                                className={classes.title}
                                                color={'textSecondary'}>
                                                Report Number:
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <Typography>
                                                {official.reportNumber}
                                              </Typography>
                                            </Grid>
                                          </>
                                        )}
                                        {official.reportingOfficer !== '' && (
                                          <>
                                            <Grid item xs={12} sm={6}>
                                              <Typography
                                                className={classes.title}
                                                color={'textSecondary'}>
                                                Reporting Officer:
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <Typography>
                                                {official.reportingOfficer}
                                              </Typography>
                                            </Grid>
                                          </>
                                        )}
                                        {official.phone !== '' && (
                                          <>
                                            <Grid item xs={12} sm={6}>
                                              <Typography
                                                className={classes.title}
                                                color={'textSecondary'}>
                                                Phone:
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                              <Typography>
                                                {formatPhoneForDisplay(
                                                  official.phone
                                                )}
                                              </Typography>
                                            </Grid>
                                          </>
                                        )}
                                      </Grid>
                                    ))}
                                  </>
                                )}
                              </Grid>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        );
                      }
                    })}
                  </Grid>
                  {/* <Grid item xs={12}>
                    <ExpansionPanel onChange={handleExpanded('attachments')}>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls='attachments'
                        id='attachments'>
                        <Typography className={classes.heading}>
                          Attachments
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Grid
                          container
                          spacing={1}
                          style={{ display: '-webkit-inline-box' }}>
                          <Grid
                            item
                            xs={12}
                            style={{
                              padding: 0,
                              textAlign: 'right',
                            }}>
                            {!submissionId && (
                              <IconButton
                                size='small'
                                className={classes.editIcon}
                                onClick={() =>
                                  updateStepContentIndex(
                                    ClaimSteps.UPLOAD_DOCUMENTS
                                  )
                                }>
                                <EditIcon fontSize='small' />
                              </IconButton>
                            )}
                          </Grid>
                          {claim.lossDetails.attachments.map((value, index) => {
                            return (
                              <>
                                <Grid item key={index} xs={12} sm={3}>
                                  <Grid
                                    container
                                    spacing={2}
                                    direction={'column'}>
                                    <Grid item xs={12} sm={3}>
                                      <Thumbnails
                                        files={[value]}
                                        removeCloseIcon={true}
                                      />
                                    </Grid>
                                    {isAuthenticated && (
                                      <Grid xs={12} sm={3} gutterBottom>
                                        <Box p={2}>
                                          <Typography
                                            style={{ textAlign: 'center' }}>
                                            <Link
                                              // component='button'
                                              href={value.url}
                                              download>
                                              Download
                                            </Link>
                                          </Typography>
                                        </Box>
                                      </Grid>
                                    )}
                                  </Grid>
                                </Grid>
                              </>
                            );
                          })}
                        </Grid>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid> */}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <AttachmentViewDialog
        file={selectedImage}
        show={showImageView}
        handleClose={handleCloseAttachmentViewDialog}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  stepper: state.stepper,
  claim: state.claim,
});

ClaimInfoComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  updateStepContentIndex: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updateStepContentIndex })(
  ClaimInfoComponent
);
