import { EMPTY_VALUE, states, zipCodeExp, PLACEHOLDER_SELECT_VALUE, FIELD_TYPE } from "../../../../../actions/types";
import * as Yup from 'yup';

export const lossLocation = [
  
  {
    name: 'lossHappened',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Is this where the loss happened?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    keyPathToSaveValue: 'claim.lossLocation.isPolicyLocation',
    yup: Yup.boolean().required('Select one').nullable(),
  },
  {
    name: 'addressLine1',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Address 1',
    type: FIELD_TYPE.TEXT,
    toHide: true,
    dependencyFieldToShow: { name: 'lossHappened', value: false },
    keyPathToSaveValue: 'claim.lossLocation.addressLine1',
    yup: Yup.string().when('lossHappened', {
      is: false,
      then: schema => schema.required('Provide a value'),
    }),
  },
  {
    name: 'addressLine2',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Address 2',
    type: FIELD_TYPE.TEXT,
    toHide: true,
    dependencyFieldToShow: { name: 'lossHappened', value: false },
    keyPathToSaveValue: 'claim.lossLocation.addressLine2',
  },
  {
    name: 'city',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'City',
    type: FIELD_TYPE.TEXT,
    col: 4,
    toHide: true,
    dependencyFieldToShow: { name: 'lossHappened', value: false },
    keyPathToSaveValue: 'claim.lossLocation.city',
    yup: Yup.string().when('lossHappened', {
      is: false,
      then: schema => schema.required('Provide a value'),
    }),
  },
  {
    name: 'state',
    initialValue: PLACEHOLDER_SELECT_VALUE,
    label: 'State',
    type: FIELD_TYPE.SELECT,
    col: 4,
    options: states.map( option => {
      return { name: option.title , value: option.abbreviation.toUpperCase() };
    }),
    toHide: true,
    dependencyFieldToShow: { name: 'lossHappened', value: false },
    keyPathToSaveValue: 'claim.lossLocation.state',
    yup: Yup.string().when('lossHappened', {
      is: false,
      then: schema => schema.required('Provide a value'),
    }),
  },
  {
    name: 'postalCode',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Zip',
    type: FIELD_TYPE.TEXT,
    col: 4,
    toHide: true,
    dependencyFieldToShow: { name: 'lossHappened', value: false },
    keyPathToSaveValue: 'claim.lossLocation.postalCode',
    yup: Yup.string().when('lossHappened', {
      is: false,
        then: schema => schema.matches(zipCodeExp, 'Provide a valid Zip Code').required('Provide a value'),
    }),
  },
];