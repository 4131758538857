import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useField } from 'formik';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString={true}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CurrencyField = ({ endAdornment, ...props }) => {
  const [field, meta] = useField(props);
  const { name } = props;
  return (
    <>
      <TextField
        id={`${name}-currency-label`}
        error={meta.touched && meta.error !== undefined}
        InputProps={{
          inputComponent: NumberFormatCustom,
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          endAdornment
        }}
        {...field}
        {...props}
      />
      {meta.touched && meta.error && (
        <FormHelperText style={{ color: '#f44336' }}>
          {meta.error}
        </FormHelperText>
      )}
    </>
  );
};

export default CurrencyField;
