import axios from 'axios';
import moment from 'moment';
import appSettings from '../appSettings';
import { UPDATE_CLAIM, GET_GW_POLICY, PhoneTypes, ProductCodes } from './types';
import { GET_LEGACY_POLICY } from './types';
import { toTitleCase } from '../utils/StringUtils';
import store from '../store';

export const validateAddress = (address) => async (dispatch) => {
  let isMatch = false;
  let isValidResponse = {
    isValid: false,
    address: null,
  };
  const payload = {
    lookupAddress: {
      address: {
        addressLine1: address.addressLine1.trim().toUpperCase(),
        addressLine2:
          address.addressLine2 === null
            ? ''
            : address.addressLine2.trim().toUpperCase(),
        city: address.city.trim().toUpperCase(),
        state: address.state.trim().toUpperCase(),
        postalCode: address.postalCode.trim(),
      },
    },
  };

  const url =
    appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL +
    '/rest/address/v1/lookupAddress';

  const res = await axios.post(url, payload);

  const returnAddress = res.data.lookupAddressResponse.return.address;
  const responseType = res.data.lookupAddressResponse.return.responseType;

  if (
    returnAddress.addressLine1.trim().toUpperCase() ===
      address.addressLine1.trim().toUpperCase() &&
    returnAddress.addressLine2.trim().toUpperCase() ===
      address.addressLine2.trim().toUpperCase() &&
    returnAddress.city.trim().toUpperCase() ===
      address.city.trim().toUpperCase() &&
    returnAddress.state.trim().toUpperCase() ===
      address.state.trim().toUpperCase() &&
    returnAddress.postalCode.trim() === address.postalCode.trim()
  ) {
    isMatch = true;
  }

  if (isMatch) {
    isValidResponse.isValid = true;
    isValidResponse.address = address;
  } else if (
    (responseType === 'VALID' && !isMatch) ||
    (responseType === 'INVALID' && returnAddress.addressLine1 !== '')
  ) {
    const newAddress = {
      addressLine1: returnAddress.addressLine1,
      addressLine2: returnAddress.addressLine2,
      city: returnAddress.city,
      state: returnAddress.state,
      postalCode: returnAddress.postalCode,
    };

    isValidResponse.address = newAddress;
  }

  return isValidResponse;
};

export const getPolicySummary =
  (policyNumber, firstName, lastName, zipCode) => async (dispatch) => {
    const url = `${appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL}/policy/${policyNumber}/summary?firstName=${firstName}&lastName=${lastName}&zipCode=${zipCode}`;

    const res = await axios.get(url);
    if (res.status === 200 && res.data.hasOwnProperty('policyNumber')) {
      return res;
    } else {
      throw 'Retrieve Policy Summary Error';
    }
  };

// getLegacyPolicy
export const getLegacyPolicy =
  (
    policyNumber,
    lossDate,
    contactHolderId,
    companyNumber,
    productCode,
    system,
    token,
    isNewClaim = true
  ) =>
  async (dispatch) => {
    const lossDT = moment(lossDate).format('MM/DD/YYYY HH:mm:ss');
    const url = `${appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL}/esb/policy/${policyNumber}?lossDate=${lossDT}&companyNumber=${companyNumber}&system=${system}&productCode=${productCode}`;

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (
      res.status === 200 &&
      res.data.RetrievePolicyResponse.hasOwnProperty('Status') &&
      res.data.RetrievePolicyResponse.Status === 'SUCCESS'
    ) {
      localStorage.setItem('policyType', ProductCodes[productCode]);

      dispatch({
        type: GET_LEGACY_POLICY,
        payload: res.data,
      });

      if (isNewClaim) {
        const { PolicyNumber, PolicyType, Insureds, LocationRU, VehicleRU } =
          res.data.RetrievePolicyResponse;

        let policyOrLossLocation;
        if (LocationRU) {
          policyOrLossLocation = {
            addressLine1: LocationRU.Address.AddressLine1.trim(),
            addressLine2: LocationRU.Address.Description
              ? LocationRU.Address.Description.trim()
              : '',
            city: LocationRU.Address.City.trim(),
            state: LocationRU.Address.State.trim(),
            postalCode: LocationRU.Address.PostalCode.trim(),
          };
        }
        if (VehicleRU) {
          policyOrLossLocation = {
            addressLine1: Insureds.PrimaryAddress.AddressLine1.trim(),
            addressLine2: '',
            city: Insureds.PrimaryAddress.City.trim(),
            state: Insureds.PrimaryAddress.State.trim(),
            postalCode: Insureds.PrimaryAddress.PostalCode.trim(),
          };
        }

        // build contactInfo.phone object
        let contactArr = [];
        if (Insureds.BusinessPhone && Insureds.BusinessPhone !== '0000000000') {
          contactArr.push({
            number: Insureds.BusinessPhone.trim(),
            type: PhoneTypes.WORK,
          });
        } else if (Insureds.HomePhone && Insureds.HomePhone !== '0000000000') {
          contactArr.push({
            number: Insureds.HomePhone.trim(),
            type: PhoneTypes.HOME,
          });
        } else {
          contactArr.push({
            number: '',
            type: PhoneTypes.MOBILE,
            mobileProvider: '',
          });
        }

        const claim = store.getState().claim;
        const claimCopy = { ...claim };
        claimCopy.type =
          contactHolderId === '048065' ? 'renters' : ProductCodes[productCode];
        claimCopy.policyNumber = PolicyNumber.trim();
        claimCopy.isVerifiedPolicy = true;
        claimCopy.namedInsured = {
          ...claim.namedInsured,
          firstName: toTitleCase(Insureds.FirstName.trim()),
          lastName: toTitleCase(Insureds.LastName.trim()),
          address: {
            addressLine1: Insureds.PrimaryAddress.AddressLine1.trim(),
            addressLine2: Insureds.PrimaryAddress.Description
              ? Insureds.PrimaryAddress.Description.trim()
              : '',
            city: Insureds.PrimaryAddress.City.trim(),
            state: Insureds.PrimaryAddress.State.trim(),
            postalCode: Insureds.PrimaryAddress.PostalCode.trim(),
          },
        };
        claimCopy.lossDate = lossDate;
        claimCopy.lossLocation = policyOrLossLocation;
        // claimCopy.lossDetails = {
        //   ...claim.lossDetails,
        //   lossDateTime,
        //   location: policyOrLossLocation,
        // };

        dispatch({
          type: UPDATE_CLAIM,
          payload: claimCopy,
        });
      }
      return res;
    } else {
      throw 'Legacy Policy Retrieve Error';
    }
  };

// getGWPolicy
export const getGWPolicy =
  (policyNumber, lossDate, productCode, token, isNewClaim = true) =>
  async (dispatch) => {
    const url = `${appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL}/gw/policy/${policyNumber}?lossDate=${lossDate}`;

    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const res = await axios.get(url, options);

    const { retrievePolicyResponse } = res.data;

    if (
      res.status === 200 &&
      res.data.retrievePolicyResponse.hasOwnProperty('return')
    ) {
      localStorage.setItem('policyType', ProductCodes[productCode]);

      dispatch({
        type: GET_GW_POLICY,
        payload: res.data,
      });

      if (isNewClaim) {
        const {
          return: { Envelope },
        } = retrievePolicyResponse;
        const { CCAddress, CCContact, CCPolicyLocation, CCPolicy } = Envelope;
        const { PolicyNumber } = CCPolicy;
        const namedInsuredId = CCPolicy.Insured;

        let namedInsured;
        if (Array.isArray(CCContact)) {
          namedInsured = CCContact.find(
            (element) => element.ID === namedInsuredId
          );
        } else if (
          CCContact.hasOwnProperty('ID') &&
          CCContact.ID === namedInsuredId
        ) {
          namedInsured = CCContact;
        }

        const namedInsuredAddressId = namedInsured.PrimaryAddress;
        let namedInsuredAddress;
        if (Array.isArray(CCAddress)) {
          namedInsuredAddress = CCAddress.find(
            (element) => element.ID === namedInsuredAddressId
          );
        } else if (
          CCAddress.hasOwnProperty('ID') &&
          CCAddress.ID === namedInsuredAddressId
        ) {
          namedInsuredAddress = CCAddress;
        }

        const contactArr = [];
        if (namedInsured.hasOwnProperty('HomePhone')) {
          contactArr.push({
            number: namedInsured.HomePhone.trim(),
            type: PhoneTypes.HOME,
          });
        }
        if (namedInsured.hasOwnProperty('WorkPhone')) {
          contactArr.push({
            number: namedInsured.WorkPhone.trim(),
            type: PhoneTypes.WORK,
          });
        }
        if (namedInsured.hasOwnProperty('CellPhone')) {
          contactArr.push({
            number: namedInsured.CellPhone.trim(),
            type: PhoneTypes.MOBILE,
            mobileProvider: '',
          });
        }

        const contactId = CCPolicy.Insured;
        let contact;
        if (Array.isArray(CCContact)) {
          contact = CCContact.find((element) => element.ID === contactId);
        } else {
          contact = CCContact;
        }

        const policyLocationId = contact.PrimaryAddress;
        let policyLocationAddress;
        if (Array.isArray(CCAddress)) {
          policyLocationAddress = CCAddress.find(
            (element) => element.ID === policyLocationId
          );
        } else if (
          CCAddress.hasOwnProperty('ID') &&
          CCAddress.ID === policyLocationId
        ) {
          policyLocationAddress = CCAddress;
        }

        const claim = store.getState().claim;
        const claimCopy = {
          ...claim,
          type: ProductCodes[productCode],
          policyNumber: PolicyNumber.trim(),
          lossDate,
          isVerifiedPolicy: true,
          namedInsured: {
            ...claim.namedInsured,
            firstName: toTitleCase(namedInsured.FirstName.trim()),
            lastName: toTitleCase(namedInsured.LastName.trim()),
            address: {
              addressLine1: namedInsuredAddress.AddressLine1.trim(),
              addressLine2: namedInsuredAddress.AddressLine2
                ? namedInsuredAddress.AddressLine2.trim()
                : '',
              city: namedInsuredAddress.City.trim(),
              state: namedInsuredAddress.State.trim(),
              postalCode: namedInsuredAddress.PostalCode.trim(),
            },
            contactInfo: {
              emailAddress1: namedInsured.EmailAddress1.toLowerCase() ?? '',
              primaryPhoneType: '',
              phone: contactArr,
            },
          },
          lossLocation: {
            ...claim.lossLocation,
            addressLine1: policyLocationAddress.AddressLine1.trim(),
            addressLine2: policyLocationAddress.AddressLine2
              ? policyLocationAddress.AddressLine2.trim()
              : '',
            city: policyLocationAddress.City.trim(),
            state: policyLocationAddress.State.trim(),
            postalCode: policyLocationAddress.PostalCode.trim(),
          },
        };

        //claimCopy.type = ProductCodes[productCode];
        //claimCopy.policyNumber = PolicyNumber.trim();
        //claimCopy.isVerifiedPolicy = true;
        // claimCopy.namedInsured = {
        //   ...claim.namedInsured,
        //   firstName: toTitleCase(namedInsured.FirstName.trim()),
        //   lastName: toTitleCase(namedInsured.LastName.trim()),
        //   address: {
        //     addressLine1: namedInsuredAddress.AddressLine1.trim(),
        //     addressLine2: namedInsuredAddress.AddressLine2
        //       ? namedInsuredAddress.AddressLine2.trim()
        //       : '',
        //     city: namedInsuredAddress.City.trim(),
        //     state: namedInsuredAddress.State.trim(),
        //     postalCode: namedInsuredAddress.PostalCode.trim(),
        //   },
        //   contactInfo: {
        //     emailAddress1: namedInsured.EmailAddress1.toLowerCase() ?? '',
        //     primaryPhoneType: '',
        //     phone: contactArr,
        //   },
        // };
        //claimCopy.lossDate = lossDateTime;
        // claimCopy.lossLocation = {
        //   addressLine1: policyLocationAddress.AddressLine1.trim(),
        //   addressLine2: policyLocationAddress.AddressLine2
        //     ? policyLocationAddress.AddressLine2.trim()
        //     : '',
        //   city: policyLocationAddress.City.trim(),
        //   state: policyLocationAddress.State.trim(),
        //   postalCode: policyLocationAddress.PostalCode.trim(),
        // }
        // claimCopy.lossDetails = {
        //   ...claim.lossDetails,
        //   lossDateTime,
        //   location: {
        //     addressLine1: policyLocationAddress.AddressLine1.trim(),
        //     addressLine2: policyLocationAddress.AddressLine2
        //       ? policyLocationAddress.AddressLine2.trim()
        //       : '',
        //     city: policyLocationAddress.City.trim(),
        //     state: policyLocationAddress.State.trim(),
        //     postalCode: policyLocationAddress.PostalCode.trim(),
        //   },
        // };

        dispatch({
          type: UPDATE_CLAIM,
          payload: claimCopy,
        });
      }
      return res;
    } else {
      throw 'GuideWire Policy Retrieve Error';
    }
  };
