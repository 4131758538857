import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

export const ErrorComponent = ({ error }) => {
  return (
    <Grid
      container
      spacing={0}
      direction='column'
      alignItems='center'
      justify='center'
      style={{ minHeight: '100vh' }}>
      <>
        <Typography variant='h6'>
          An Error Occurred: {error.errorCode}
        </Typography>
      </>
    </Grid>
  );
};
