import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FormType } from '../Utilities/FormType';
import { AMIGForm, getShowedFields } from '../Utilities/AMIGForm';
import { caused } from '../ScreenObjects/Common/caused';
import {
  setPathValue,
  setReduxValues,
  useAMIGFormik,
} from '../Utilities/AMIGHandleForm';
import {
  StepTypes,
  ComponentTitle,
  PolicyTypes,
  POLICY_HOLDER,
} from '../../../../actions/types';
import { summaryActions } from '../../../../actions/summary';
import { newClaimActions } from '../../../../actions/newClaim';
import { updateClaim } from '../../../../actions/claimActions';
import { useLoading } from '../../../../hooks/useLoading';
import { LoadingPlaceholder } from '../Utilities/LoadingPlaceholder';
import { isEmptyOrSpaces } from '../../../../utils/StringUtils';

const { updateDisabled } = summaryActions;
const { updateCaused } = newClaimActions;

const CausedWithRedux = ({
  summary,
  claim,
  newClaim,
  updateDisabled,
  updateCaused,
  updateClaim,
  stepType,
  handleNext,
}) => {
  const { palette } = useTheme();
  const useStyles = makeStyles((theme) => ({
    text: {
      color: palette.text.primary,
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '20px',
    },
  }));

  const classes = useStyles();
  const fields = caused;
  const { isLoading, setIsLoading } = useLoading();

  const handleSubmit = () => {
    updateCaused(formik.values);
    const showedFields = getShowedFields(formik, fields);
    const copyClaim = setPathValue(formik, showedFields, claim);
    updateClaim(copyClaim);

    if (!copyClaim.lossCause) {
      const { cause, source } = formik.values;
      copyClaim.lossCause = !isEmptyOrSpaces(source) ? source : cause;
      copyClaim.lossSource = !isEmptyOrSpaces(source) ? cause : null;
    }
    updateClaim(copyClaim);

    if (!summary.isLastStept) handleNext();
    else updateDisabled(stepType, true);
  };

  const formik = useAMIGFormik(fields, handleSubmit);

  const getDamageFieldsByType = (type) => {
    switch (type) {
      case PolicyTypes.RENTERS.toLowerCase():
      case PolicyTypes.NOT_AUTHENTICATED_RENTERS.toLowerCase():
        return newClaim.damagedRen;
      case PolicyTypes.RESIDENTIAL.toLowerCase():
      case PolicyTypes.NOT_AUTHENTICATED_RESIDENTIAL.toLowerCase():
        return newClaim.damagedRes;
      case PolicyTypes.RECREATIONAL.toLowerCase():
      case PolicyTypes.NOT_AUTHENTICATED_RECREATIONAL:
        return newClaim.damaged.Rec;
      default:
        return null;
    }
  };

  useEffect(() => {
    const damagedFieldValues = getDamageFieldsByType(claim.type);
    try {
      setReduxValues(formik, newClaim.caused, fields, damagedFieldValues);
      setIsLoading(false);
    } catch (error) {
      console.error('Error', error);
    }
  }, []);

  if (isLoading) return <LoadingPlaceholder />;

  return (
    <>
      <FormType
      title={ComponentTitle.CAUSED}
      subtitle='File a claim'
      buttonLabel={summary.isLastStept ? 'Save' : 'Continue'}
      isDisable={summary.isStepDisabled(summary.disable, stepType)}
      handleSubmit={formik.handleSubmit}>
      <AMIGForm stepType={stepType} fields={fields} formik={formik} />
      {!summary.isLastStept && formik.values.cause !== ' ' && (
        <Typography variant='subtitle2' className={classes.text}>
          You will have the ability to attach any documents at the end.
        </Typography>
      )}
    </FormType>
    </>
    
  );
};

CausedWithRedux.defaultProps = {
  stepType: StepTypes.CAUSED,
  handleNext: () => {},
};

const mapStateToProps = (state) => ({
  summary: state.summary,
  claim: state.claim,
  newClaim: state.newClaim,
});

export const Caused = connect(mapStateToProps, {
  updateDisabled,
  updateCaused,
  updateClaim,
})(CausedWithRedux);
