import React from 'react';
import { useFormikContext, Field, FieldArray } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import Box from '@material-ui/core/Box';
import { WitnessLocations } from '../../../actions/types';

const useStyles = makeStyles((theme) => ({
  witnessInfo: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconBtn: {
    marginTop: theme.spacing(1),
    paddingLeft: '0px',
  },
}));

const WitnessComponent = () => {
  const { values } = useFormikContext();

  const classes = useStyles();

  return (
    <FieldArray
      name='witnesses'
      render={(arrayHelpers) => (
        <>
          {values.witnesses &&
            values.witnesses.length > 0 &&
            values.witnesses.map((witness, index) => (
              <div key={index}>
                <Grid container spacing={2} className={classes.witnessInfo}>
                  <Grid item xs={12}>
                    <Typography display='inline'>{`Witness ${
                      index + 1
                    }`}</Typography>
                    <IconButton
                      key={`witness-${index}-delete-icon-button`}
                      size={'small'}
                      onClick={() => arrayHelpers.remove(index)}>
                      <ClearIcon
                        key={`witness-${index}-delete-icon`}
                        fontSize={'small'}
                        color={'secondary'}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name={`witnesses.${index}.firstName`}
                      label='First Name'
                      component={TextField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name={`witnesses.${index}.lastName`}
                      label='Last Name'
                      component={TextField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel id='witness-location-select-label'>
                        Where was the witness?
                      </InputLabel>
                      <Field
                        label='witness-location-select-label'
                        component={Select}
                        name={`witnesses.${index}.location`}>
                        <MenuItem value=''>
                          <em>None</em>
                        </MenuItem>
                        {Object.keys(WitnessLocations).map((key, index) => (
                          <MenuItem key={index} value={key}>
                            {WitnessLocations[key]}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Box my={3}>
                      <Field
                        className={classes.textField}
                        name={`witnesses.${index}.perspective`}
                        component={TextField}
                        placeholder='Please provide an optional description of the witnesses vantage'
                        variant='outlined'
                        multiline
                        rows={4}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                </Grid>
              </div>
            ))}
          <Grid item xs={12}>
            <Button
              className={classes.iconBtn}
              color={'primary'}
              size={'small'}
              onClick={() =>
                arrayHelpers.push({
                  firstName: '',
                  lastName: '',
                  location: '',
                  perspective: '',
                })
              }>
              <AddCircleOutlineIcon fontSize={'small'} /> &nbsp; Add a Witness
            </Button>
          </Grid>
        </>
      )}
    />
  );
};

export default WitnessComponent;
