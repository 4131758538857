import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Formik, Form, Field } from 'formik';
import { propertyDamageSchema } from '../../validation/claimsSchemas';
import { TextField } from 'formik-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import { sendEventLogRequest } from '../../actions/eventActions';
import { updateClaim } from '../../actions/claimActions';
import { getContainerOffset } from '../../actions/layoutActions';
import PropTypes from 'prop-types';
import ComponentToggle from '../common/ComponentToggle';
import { ClaimTypes, WeatherDamage } from '../../actions/types';
import OffsetGrid from '../layout/OffsetGrid';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import ErrorFocus from '../common/ErrorFocus';
import FormikSelect from '../common/FormikSelect';
import FormikTextArea from '../common/FormikTextArea';

const PropertyDamageComponent = ({
  claim,
  activeStep,
  handleNext,
  handleBack,
  updateClaim,
}) => {
  const select = WeatherDamage;
  const { deviceType } = getDeviceTypeInfo();
  const { logId } = claim;

  const initialValues = {
    type: ClaimTypes.PROPERTY_DAMAGE,
    incidentDesc: claim.lossDetails.incidents[0].incidentDesc,
    source: claim.lossDetails.incidents[0].source,
    sourceOtherDesc: claim.lossDetails.incidents[0].sourceOtherDesc,
    isRepaired: claim.lossDetails.incidents[0].isRepaired,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    var event = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Weather/Property Damage Incident'}",
    };

    sendEventLogRequest(event);
  }, []);

  const useStyles = makeStyles({
    card: {
      height: '100%',
      padding: '1rem',
    },
    cardTitle: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    cardActions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    cardMedia: {
      width: '13%',
      alignSelf: 'center',
    },
    btn: {
      'color': '#fff',
      'backgroundColor': '#007bff',
      'borderColor': '#007bff',
      '&:hover': {
        color: '#fff',
        background: '#0056b3',
      },
    },
    input: {
      backgroundColor: '#f5f5f7',
    },
    inputSelectLabel: {
      'marginLeft': '1rem',
      '&:mui-focused={true}': {
        marginTop: '0.5rem',
      },
    },
    selectOption: {
      padding: '0.25rem',
      marginLeft: '0.25rem',
    },
  });

  const classes = useStyles();

  return (
    <>
      <OffsetGrid offset={`${getContainerOffset(deviceType)}`}>
        <Typography variant='h1' align='center' color='primary'>
          Tell us what happened
        </Typography>
        <Typography variant={'subtitle1'} align={'center'}>
          Please describe the damage to the home. How did it happened?
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={propertyDamageSchema}
          onSubmit={(values) => {
            if (values) {
              const newClaim = { ...claim };
              newClaim.lossDetails.incidents[0] = values;
              updateClaim({ ...newClaim });
            }

            handleNext();
          }}>
          {({ values, setFieldValue }) => (
            <Box mt={2}>
              <Form id='property-damage-form'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* <Field
                      className={classes.textField}
                      name='incidentDesc'
                      component={TextField}
                      placeholder='Please provide a description of the property damage'
                      variant='outlined'
                      multiline
                      rows={4}
                      fullWidth
                    /> */}
                    <FormikTextArea
                      name='incidentDesc'
                      placeholder='Please provide a description of the property damage'
                      maxLength={1000}
                      rows={4}
                      variant='outlined'
                      multiline
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <FormikSelect name='source' label='Source of the damage'>
                        <MenuItem value='Weather - Lightning'>
                            Lightning
                        </MenuItem>
                        <MenuItem value='Weather - Hail'>Hail</MenuItem>
                        <MenuItem value={`Weather - Weight of Ice & Snow`}>
                          Weight from Snow/Ice
                        </MenuItem>
                        <MenuItem value='Weather - Wind'>Wind</MenuItem>
                        <MenuItem value='All Other'>Other</MenuItem>
                      </FormikSelect>
                    </FormControl>
                  </Grid>

                  {values.source === 'Other' && (
                    <Grid item xs={12}>
                      <Field
                        className={classes.textField}
                        name='sourceOtherDesc'
                        component={TextField}
                        placeholder='Please provide additional details about the source of the damage'
                        variant='outlined'
                        // multiline
                        // rows={1}
                        fullWidth
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Field
                      name='isRepaired'
                      component={ComponentToggle}
                      title='Has the damage been repaired?'
                      displayOnSelected={true}
                      handleButtonChange={(value) =>
                        setFieldValue('isRepaired', value)
                      }
                    />
                  </Grid>
                </Grid>

                <ErrorFocus />
              </Form>
            </Box>
          )}
        </Formik>
      </OffsetGrid>
      <Box p={5} style={{ textAlign: 'center' }}>
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.backButton}>
          Back
        </Button>
        <Button
          type='submit'
          form='property-damage-form'
          variant='contained'
          color='primary'>
          Next
        </Button>
      </Box>
    </>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
});

PropertyDamageComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  updateClaim: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { updateClaim })(
  PropertyDamageComponent
);
