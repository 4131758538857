import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import LinearProgress from '@material-ui/core/LinearProgress';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Check from '@material-ui/icons/Check';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import PolicyComponent from './PolicyComponent';
import LossLocationComponent from './LossLocationComponent';
import ClaimReporterComponent from './ClaimReporterComponent';
import UploadDocumentsComponent from './UploadDocumentsComponent';
import ClaimTypeComponent from './ClaimTypeComponent';
import RecreationalClaimTypeComponent from './RecreationalClaimTypeComponent';
import LossDetailsComponent from './LossDetailsComponent';
import ClaimSummaryComponent from './ClaimSummaryComponent';
import { ClaimSteps } from '../../actions/types';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentIcon from '@material-ui/icons/Assignment';
import IconButton from '@material-ui/core/IconButton';
import {
  updateCompletedSteps,
  updateStepContentIndex,
} from '../../actions/stepperActions';
import PolicyInfoComponent from './PolicyInfoComponent';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import { updateClaim } from '../../actions/claimActions';
import { PolicyTypes } from '../../actions/types';
import { sendEventLogRequest } from '../../actions/eventActions';
import { getContainerPadding } from '../../actions/layoutActions';
import { AMIGHelp } from './claim/Utilities/AMIGHelp';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  panel: {
    background: '#ededed',
  },
  fab: {
    float: 'right',
    bottom: '2.75rem',
  },
  fabPolicy: {
    float: 'left',
    bottom: '2.75rem',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const SimpleConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 12px)',
    right: 'calc(50% + 12px)',
  },
  active: {
    '& $line': {
      borderColor: '#0094ca',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#0094ca',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const simpleStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: '1.5rem',
    alignItems: 'center',
  },
  active: {
    color: '#0094ca',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#0094ca',
    zIndex: 1,
    fontSize: '1.25rem',
  },
});

function simpleStepIcon(props) {
  const classes = simpleStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

const Claim = ({
  claim,
  policy,
  stepper,
  updateCompletedSteps,
  updateStepContentIndex,
  updateClaim,
}) => {
  const { deviceType } = getDeviceTypeInfo();
  const { logId, type } = claim;

  useEffect(() => {
    var event = {
      logId,
      event: 'trackRoute',
      eventBody: "{'claimTypeClick':'Start Claim'}",
    };

    sendEventLogRequest(event);
  }, []);

  const { completed, stepContentIndex } = stepper;
  const activeStep = Object.keys(completed).length;
  const [policyDrawerState, setPolicyDrawerState] = useState(false);

  const getResponsiveStepper = (deviceType) => {
    let stepperContent = null;
    if (deviceType === 'Mobile') {
      return (
        <>
          <Container align={'center'}>
            <Box p={2}>
              <LinearProgress
                style={{ width: '80%' }}
                variant='determinate'
                value={((activeStep + 1) / getSteps().length) * 100}
              />
            </Box>
            <Typography color={'primary'}>
              {stepContentIndex + 1} / {getSteps().length}
            </Typography>
          </Container>
        </>
      );
    } else {
      return (
        <>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            //nonLinear
            style={{
              width: '85%',
              margin: '0px auto',
            }}
            connector={<SimpleConnector />}>
            {steps.map((label, index) => (
              <Step key={`step-${index}`}>
                <StepButton
                  key={`step-button-${index}`}
                  onClick={handleStep(index)}
                  completed={completed[index]}>
                  <StepLabel
                    key={`step-label-${index}`}
                    StepIconComponent={simpleStepIcon}>
                    <span style={{ fontSize: '0.75rem' }}>{label}</span>
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </>
      );
    }
    return null;
  };

  const togglePolicyDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setPolicyDrawerState(open);
  };

  function getSteps() {
    switch (claim.type) {
      case 'recreational':
        return [
          '1. Enter policy number',
          '2. Verify loss location',
          '3. Select vehicle',
          '4. Enter claim details',
          '5. Who’s reporting the claim?',
          '6. Finalize and submit',
        ];
      default:
        return [
          '1. Enter policy number',
          '2. Verify loss location',
          '3. Select claim type',
          '4. Enter claim details',
          '5. Who’s reporting the claim?',
          '6. Finalize and submit',
        ];
    }
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case ClaimSteps.POLICY:
        return (
          <PolicyComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case ClaimSteps.LOSS_LOCATION:
        return (
          <LossLocationComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case ClaimSteps.CLAIM_TYPE:
        return type === PolicyTypes.RECREATIONAL.toLowerCase() ? (
          <RecreationalClaimTypeComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        ) : (
          <ClaimTypeComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case ClaimSteps.LOSS_DETAILS:
        return (
          <LossDetailsComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      // case ClaimSteps.UPLOAD_DOCUMENTS:
      //   return (
      //     <UploadDocumentsComponent
      //       activeStep={activeStep}
      //       handleNext={handleNext}
      //       handleBack={handleBack}
      //     />
      //   );
      case ClaimSteps.CLAIM_REPORTER:
        return (
          <ClaimReporterComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case ClaimSteps.CLAIM_SUMMARY:
        return (
          <ClaimSummaryComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  }

  const steps = getSteps();

  const isStepComplete = (step) => {
    const res = completed.hasOwnProperty(step);
    return res;
  };

  const handleNext = () => {
    if (!isStepComplete(stepContentIndex)) {
      const newCompleted = completed;
      newCompleted[stepContentIndex] = true;
      updateCompletedSteps(newCompleted);
    }
    if (stepContentIndex + 1 < getSteps().length) {
      updateStepContentIndex(stepContentIndex + 1);
    }
  };

  const handleStep = (step) => () => {
    updateStepContentIndex(step);
  };

  const handleBack = () => {
    if (stepContentIndex - 1 > 0) {
      updateStepContentIndex(stepContentIndex - 1);
    } else {
      updateStepContentIndex(0);
    }
  };

  const handleReset = () => {
    updateCompletedSteps([]);
  };


  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container
        style={{
          background: '#ededed',
          padding: `${getContainerPadding(deviceType)}`,
        }}>
        <Box p={1}>
          <Paper elevation={1}>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {claim.showStepper && getResponsiveStepper(deviceType)}
                </Grid>
                <Grid item xs={12}>
                  <div>
                    {activeStep === steps.length ? (
                      <>
                        <Typography className={classes.instructions}>
                          All steps completed
                        </Typography>
                        <Button onClick={handleReset}>Reset</Button>
                      </>
                    ) : (
                      getStepContent(stepContentIndex)
                    )}
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <AMIGHelp classFab={classes.fab}/>
          {claim.namedInsured.firstName && (
            <>
              <Box px={1}>
                <Fab
                  className={classes.fabPolicy}
                  onClick={togglePolicyDrawer(true)}
                  color='primary'
                  aria-label='My Policy'
                  size='small'
                  variant='extended'>
                  <AssignmentIcon />
                  <Box px={1}>My Policy</Box>
                </Fab>
              </Box>
              <Drawer
                anchor={'top'}
                open={policyDrawerState}
                onClose={togglePolicyDrawer(false)}
                onOpen={togglePolicyDrawer(true)}>
                <Box className={classes.panel} height={'100%'} p={2} pb={5}>
                  <Grid container spacing={0}>
                    <Grid item xs>
                      <Box align={'left'}>
                        <Typography>Policy Information:</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs>
                      <Box align={'right'}>
                        <IconButton onClick={togglePolicyDrawer(false)}>
                          <CloseIcon fontSize={'small'} />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                  <PolicyInfoComponent policy={policy} />
                </Box>
              </Drawer>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim,
  policy: state.policy,
  stepper: state.stepper,
});

Claim.propTypes = {
  claim: PropTypes.object.isRequired,
  stepper: PropTypes.object.isRequired,
  updateCompletedSteps: PropTypes.func.isRequired,
  updateStepContentIndex: PropTypes.func.isRequired,
  updateClaim: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  updateCompletedSteps,
  updateStepContentIndex,
  updateClaim,
})(Claim);
