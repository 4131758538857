import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { globalClasses } from "../ui/customClasses";

export const GenericDialog = ({
  showDialog,
  title,
  maxWidth,
  handleClose,
  handleSubmit,
  children,
}) => {
  const globalCSS = globalClasses();

  return (
    <Dialog maxWidth={maxWidth} open={showDialog} >
      <DialogTitle id='dialog-title'>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <Box p={1}>
        <DialogActions>
          <Button className={globalCSS.AMIGBigButton} onClick={handleClose}>Cancel</Button>
          <Button className={globalCSS.AMIGBigButton} onClick={handleSubmit} variant='contained' color='primary'>
            Continue
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

GenericDialog.defaultProps = {
  showDialog: false,
  title: '',
  maxWidth: 'xl',
  handleClose: () => {},
  handleSubmit: () => {},
};
