import React from 'react';
import { connect } from 'react-redux';
import { Reporter } from '../Common/Reporter';
import { Summary } from '../Common/Summary';
import { DamagedRecreational } from './Damaged';
import { LossLocationRecreational } from './LossLocation';
import { LossLocationNotPolicy } from '../Common/LossLocationNotPolicy';
import { StepTypes, ComponentTitle } from '../../../../actions/types';
import { DamagedVessel } from './DamagedVessel';

export const LastSteptRecreationalWithRedux = ({
  claim,
  policy,
  handleNext,
}) => {
  const handleSubmit = () => {};

  return (
    <Summary>
      {claim.type !== 'notvalidatedrecreational' && (
        <LossLocationRecreational
          title={ComponentTitle.LOSSLOCATION}
          stepType={StepTypes.LOSS_LOCATION_REC}
        />
      )}
      <LossLocationNotPolicy
        title={ComponentTitle.LOSSLOCATIONNOTPOLICY}
        stepType={StepTypes.LOSS_LOCATION_NOT_POLICY}
      />
      {policy.isVisselType ? (
        <DamagedVessel
          title={ComponentTitle.DAMAGEDR}
          stepType={StepTypes.DAMAGED_REC}
        />
      ) : (
        <DamagedRecreational
          title={ComponentTitle.DAMAGEDR}
          stepType={StepTypes.DAMAGED_REC}
        />
      )}
      <Reporter title={ComponentTitle.REPORTER} stepType={StepTypes.REPORTER} />
    </Summary>
  );
};

LastSteptRecreationalWithRedux.defaultProps = {
  handleNext: () => {},
};

const mapStateToProps = (state) => ({
  claim: state.claim,
  policy: state.policy,
});

export const LastSteptRecreational = connect(
  mapStateToProps,
  {}
)(LastSteptRecreationalWithRedux);
