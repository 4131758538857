import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormType } from '../Utilities/FormType';
import { AMIGForm } from '../Utilities/AMIGForm';
import { injured } from '../ScreenObjects/Common/injured';
import { setPathValue, setReduxValues, useAMIGFormik } from '../Utilities/AMIGHandleForm';
import { StepTypes, ComponentTitle, EMPTY_VALUE, phoneRegExp, PLACEHOLDER_SELECT_VALUE, severeInjuries, FIELD_TYPE, states, InjuredTemplate } from '../../../../actions/types';
import { summaryActions } from "../../../../actions/summary";
import { updateClaim } from '../../../../actions/claimActions';
import { newClaimActions } from "../../../../actions/newClaim";
import { useLoading } from '../../../../hooks/useLoading';
import { LoadingPlaceholder } from '../Utilities/LoadingPlaceholder';
import * as Yup from 'yup';

const { updateDisabled } = summaryActions;
const { updateInjured } = newClaimActions;

export const InjuredWithRedux = ({
  summary, 
  claim,
  newClaim,
  updateClaim,
  updateDisabled, 
  updateInjured,
  stepType, 
  handleNext
}) => {
  const [fields, setFields] = useState();
  const [injuryFields,setInjuryFields] = useState([]);
  const [isInitValues, setIsInitValues] = useState(false);

  const getInjuryFields = ( injurySection, manyInjured ) => {
    const newList = [];
    for(let index = 0; index < manyInjured; index++){
      injurySection.forEach((field, indexField) => {
        if(manyInjured > 1 && !indexField) {
          newList.push({
            name: `injured'${indexField}`,
            label: `Injured ${index + 1}`,
            type: FIELD_TYPE.LABEL,
            isOneColumn:  true,
            toHide: true,
            dependencyFieldToShow: [
              {
                name: 'parties', 
                value: true,
              },
              {
                name: 'manyInjured', 
                min: 0,
              },
              {
                name: 'anyOneInjured', 
                value: 'yes',
              },
            ],
          });
        }
        newList.push({...field, name: `${field.name}_${index}`}); 
      });
    }
    
    return newList;
  };

  const buildFields = manyInjured => {
    if(manyInjured) {
      const partiesIndex = injured.findIndex( field => field.name === "parties");
      const injurySection = [
        {
          name: 'firstName',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'First Name',
          type: FIELD_TYPE.TEXT,
          col: 4,
          toHide: true,
          dependencyFieldToShow: [
            {
              name: 'parties', 
              value: true,
            },
            {
              name: 'manyInjured', 
              min: 0,
            },
            {
              name: 'anyOneInjured', 
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.firstName',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: schema => schema.required('Provide a first name').min(2, 'Too Short!').max(50, 'Too Long!'),
          }),
        },
        {
          name: 'lastName',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Last Name',
          type: FIELD_TYPE.TEXT,
          col: 4,
          toHide: true,
          dependencyFieldToShow: [
            {
              name: 'parties', 
              value: true,
            },
            {
              name: 'manyInjured', 
              min: 0,
            },
            {
              name: 'anyOneInjured', 
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.lastName',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: schema => schema.required('Provide a last name').min(2, 'Too Short!').max(50, 'Too Long!'),
          }),
        },
        {
          name: 'phone',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Phone Number',
          type: FIELD_TYPE.TEL,
          col: 4,
          toHide: true,
          inputProps: { maxLength: 12 },
          dependencyFieldToShow: [
            {
              name: 'parties', 
              value: true,
            },
            {
              name: 'manyInjured', 
              min: 0,
            },
            {
              name: 'anyOneInjured', 
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.cellNumber',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: schema => schema.required('Provide a phone number').matches(phoneRegExp, 'Phone number is not valid, Format: 123-456-7890.'),
          }),
        },
        {
          name: 'addressLine1',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Address 1',
          type: FIELD_TYPE.TEXT,
          col: 6,
          toHide: true,
          dependencyFieldToShow: [
            {
              name: 'parties', 
              value: true,
            },
            {
              name: 'manyInjured', 
              min: 0,
            },
            {
              name: 'anyOneInjured', 
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.primaryAddress.addressLine1',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: schema => schema.required('Provide a value'),
          }),
        },
        {
          name: 'addressLine2',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Address 2',
          type: FIELD_TYPE.TEXT,
          col: 6,
          toHide: true,
          dependencyFieldToShow: [
            {
              name: 'parties', 
              value: true,
            },
            {
              name: 'manyInjured', 
              min: 0,
            },
            {
              name: 'anyOneInjured', 
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.primaryAddress.addressLine2',
          }
        },
        {
          name: 'city',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'City',
          type: FIELD_TYPE.TEXT,
          col: 4,
          toHide: true,
          dependencyFieldToShow: [
            {
              name: 'parties', 
              value: true,
            },
            {
              name: 'manyInjured', 
              min: 0,
            },
            {
              name: 'anyOneInjured', 
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.primaryAddress.city',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: schema => schema.required('Provide a value'),
          }),
        },
        {
          name: 'state',
          initialValue: EMPTY_VALUE.SELECT,
          label: 'State',
          type: FIELD_TYPE.SELECT,
          options: states.map( option => {
            return { name: option.title , value: option.abbreviation.toUpperCase() };
          }),
          col: 4,
          toHide: true,
          dependencyFieldToShow: [
            {
              name: 'parties', 
              value: true,
            },
            {
              name: 'manyInjured', 
              min: 0,
            },
            {
              name: 'anyOneInjured', 
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.primaryAddress.state',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: schema => schema.required('Provide a value'),
          }),
        },
        {
          name: 'postalCode',
          initialValue: EMPTY_VALUE.INPUT,
          label: 'Zip',
          type: FIELD_TYPE.TEXT,
          col: 4,
          toHide: true,
          dependencyFieldToShow: [
            {
              name: 'parties', 
              value: true,
            },
            {
              name: 'manyInjured', 
              min: 0,
            },
            {
              name: 'anyOneInjured', 
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'injuredPerson.primaryAddress.postalCode',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: schema => schema.required('Provide a value'),
          }),
        },
        {
          name: 'severeInjuries',
          initialValue: PLACEHOLDER_SELECT_VALUE,
          label: 'How severe are the injuries?',
          placeholder: 'Select one',
          type: FIELD_TYPE.SELECT,
          options: severeInjuries,
          col: 6,
          toHide: true,
          dependencyFieldToShow: [
            {
              name: 'parties', 
              value: true,
            },
            {
              name: 'manyInjured', 
              min: 0,
            },
            {
              name: 'anyOneInjured', 
              value: 'yes',
            },
          ],
          keyPathToSaveValue: {
            dependencyFieldName: 'manyInjured',
            templateObj: InjuredTemplate,
            arrayPathName: 'claim.eventDetails.injuredParties',
            elementPathName: 'severity',
          },
          yup: Yup.string().when('parties', {
            is: true,
            then: Yup.string().test(
              'severeInjuriesTest',
              'Provide a value',
              value => value !== PLACEHOLDER_SELECT_VALUE,
            ),
          }),
        }
      ];
      const newInjuryFields = getInjuryFields(injurySection,manyInjured);
      setInjuryFields(newInjuryFields);
      return [...injured.slice(0, partiesIndex+1), ...newInjuryFields, ...injured.slice(partiesIndex+1)];
    }
    
    return injured;
  }

  const {isLoading, setIsLoading} = useLoading();

  const handleSubmit = () => {
    updateInjured(formik.values);
    const copyClaim = setPathValue(formik, fields, claim);
    updateClaim({...copyClaim});
    if(!summary.isLastStept) handleNext();
    else updateDisabled(stepType, true);
  };

  const initValues = list => list.forEach( field => formik.setFieldValue(field.name, field.initialValue, false));
  
  const handleInjuryRule = event => {
    const fieldName = 'parties';
    if(event.target.name === fieldName){
      if(event.target.value){ 
        const manyInjured = formik.values['manyInjured'];
        const newFields = buildFields(manyInjured);
        setFields(newFields);
        setIsInitValues(true);
      }
    }
  }

  const formik = useAMIGFormik(fields, handleSubmit);
    
  useEffect(() => {
    try {
      const newFields = buildFields(newClaim.injured['manyInjured']);
      setFields(newFields);
      setReduxValues(formik, newClaim.injured, newFields);
      setIsLoading(false);
    } catch(error) {
      console.error("Error",error);
    }
  },[]);

  useEffect(() => {
    try {
      if(isInitValues){
        initValues(injuryFields);
        setIsInitValues(false);
      }
    } catch(error) {
      console.error("Error",error);
    }
  },[injuryFields]);

  if(isLoading) return <LoadingPlaceholder />;

  return (
    <FormType
      title={ComponentTitle.INJURED}
      subtitle='File a claim'
      buttonLabel={summary.isLastStept ? "Save" : "Continue"}
      isDisable={summary.isStepDisabled(summary.disable, stepType)}
      handleSubmit={formik.handleSubmit}>
      <AMIGForm stepType={stepType} fields={fields} formik={formik} handleChange={event => handleInjuryRule(event)}/>
    </FormType>
  );
};

InjuredWithRedux.defaultProps = {
  stepType: StepTypes.INJURED,
  handleNext: () => { },
};

const mapStateToProps = (state) => ({
  summary: state.summary,
  claim: state.claim,
  newClaim: state.newClaim,
});

export const Injured = connect(mapStateToProps, { updateDisabled, updateInjured, updateClaim })(InjuredWithRedux);
