import React from 'react';
import { connect } from 'react-redux';
import { Box, Button, Grid, Typography, Link, List, ListItem, ListItemText } from "@material-ui/core";
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import useStyles from './TrackClaimComponent.styles';
import appSettings from '../../../appSettings';

const SideCardComponent = ({
  isLeasingDesk,
}) => {
  const { deviceType } = getDeviceTypeInfo();
  const classes = useStyles();

  return (
  <Grid className={classes.sideCard} item xs={4}>
    <Box>
      <Grid container spacing={3} className={classes.statusCard}>
        <Grid className={classes.sideCardBorder} item xs={12}>
        { isLeasingDesk ? (
            <Typography variant="h4" className={classes.sideCardTitle}>For more details</Typography>
          ) : (
            <Typography variant="h4" className={classes.sideCardTitle}>Log in to see even more</Typography>
          )
        }
        </Grid>
        <Grid item xs={12}>
          <Box>
            { isLeasingDesk ? (
              <Typography variant="body1">
                For more details regarding your claim, please contact Claims Customer Service at (800) 375-2075. 
              </Typography>          ) : (
              <>
                <Typography variant="body1">
                  When you log in, you get access to all the site features.
                </Typography>          
                <List className={classes.loginList}>
                  <ListItem>
                    <ListItemText className={classes.loginListItem} primary="Review all your claims in one spot" />
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.loginListItem} primary="See your claims payment history" />
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.loginListItem} primary="Upload and download claim documents*" />
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.loginListItem} primary="Download your declarations page" />
                  </ListItem>
                  <ListItem>
                    <ListItemText className={classes.loginListItem} primary="Download your ID card" />
                  </ListItem>
                </List>      
              </>
              )
            }            
          </Box>
        </Grid>
        { isLeasingDesk ? ( <></> ) : (
        <Grid item xs={12}>
          <Button className={classes.amigButton} href={appSettings.LOGIN_URL}>Log in to your account</Button> 
          <Link  tabIndex={0} aria-disabled={false} href={appSettings.CREATE_URL} target="_top" rel="noreferrer">
          <Typography className={classes.createAccountLink} variant="body1" >Or create an account</Typography>
            <i></i>
          </Link>
          <Typography className={classes.documentDownloadText} variant="body1">* Privileged documents are not available for download.</Typography>
        </Grid>
        )}
      </Grid>
    </Box>
  </Grid>
  );
};

const mapStateToProps = (state) => ({
});

SideCardComponent.propTypes = {
};

export default connect(mapStateToProps, { })(
  SideCardComponent
);
