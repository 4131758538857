import React from 'react';
import { connect } from 'react-redux';
import 'react-vertical-timeline-component/style.min.css';
import PropTypes from 'prop-types';
import WaterDamageComponent from './WaterDamageComponent';
import FireDamageComponent from './FireDamageComponent';
import TheftVandalismComponent from './TheftVandalismComponent';
import PropertyDamageComponent from './PropertyDamageComponent';
import RecreationalDamageComponent from './RecreationalDamageComponent';
import OtherComponent from './OtherComponent';
import { ClaimTypes } from '../../actions/types';

const LossDetailsComponent = ({
  claim,
  activeStep,
  handleNext,
  handleBack,
}) => {
  function getContent() {
    let response = null;
    claim.lossDetails.incidents.forEach((element) => {
      if (element.type === ClaimTypes.PROPERTY_DAMAGE) {
        response = (
          <PropertyDamageComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      } else if (element.type === ClaimTypes.THEFT_VANDALISM) {
        response = (
          <TheftVandalismComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      } else if (element.type === ClaimTypes.WATER_DAMAGE) {
        response = (
          <WaterDamageComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      } else if (element.type === ClaimTypes.FIRE_DAMAGE) {
        response = (
          <FireDamageComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      } else if (element.type === ClaimTypes.RECREATIONAL_DAMAGE) {
        response = (
          <RecreationalDamageComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      } else if (element.type === ClaimTypes.OTHER) {
        response = (
          <OtherComponent
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      }
    });
    return response;
  }

  return getContent();
};
const mapStateToProps = (state) => ({
  claim: state.claim,
});

LossDetailsComponent.propTypes = {
  claim: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, null)(LossDetailsComponent);
