import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import pdfIcon from '../../img/adobe-pdf-icon.png';
import Image from 'material-ui-image';
import { FileUploadStatus } from '../../actions/types';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    //  height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
  responsiveImg: {
    maxHeight: '160px',
    width: 'auto',
    height: 'auto',
  },
}));

const Thumbnails = ({
  attachments = [],
  onClick,
  onClearImage,
  removeCloseIcon = false,
}) => {
  
  const getSubtitle = (uploadStatus) => {
    switch (uploadStatus) {
      case FileUploadStatus.SUCCESS:
        return 'Uploaded';
      case FileUploadStatus.FAILED:
        return 'Failed';
      default:
        return ''
    }
  }
  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GridList cellHeight={'auto'} className={classes.gridList} cols={3}>
        {attachments.map((attachment) => (
          <GridListTile key={attachment.id}>
            <Image
              id={attachment.id}
              className={classes.responsiveImg}
              src={attachment.file.type === 'application/pdf' ? pdfIcon : attachment.url}
              alt='image thumbnail'
              onClick={onClick}
            />
            <GridListTileBar
              title={attachment.file.name}
              subtitle={getSubtitle(attachment.uploadStatus)}
              actionIcon={
                <>
                  {(attachment.uploadStatus === FileUploadStatus.PENDING || attachment.uploadStatus === FileUploadStatus.FAILED) && (
                    <IconButton
                      id={attachment.id}
                      name={attachment.file.name}
                      className={classes.icon}
                      onClick={onClearImage}>
                      <CancelIcon />
                    </IconButton>
                  )}
                </>
              }
              actionPosition='right'
              className={classes.titleBar}
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

export default Thumbnails;
