import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const RedirectRoute = ({
  component: Component,
  claim: { logId, type },
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (!logId || !type) {
        return <Redirect to='/' />;
      } else {
        return <Component {...props} />
      }
    }}
  />
);

RedirectRoute.propTypes = {
  claim: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  claim: state.claim,
});

export default connect(mapStateToProps)(RedirectRoute);
