import { createMuiTheme } from '@material-ui/core/styles';

const PALETTE = {
  primary: {
    main: '#31769B',
    light: '#ADC8D7'
  },
  text: {
    contrast: '#FFFFFF',
    primary: '#535353',
    secondary: '#282828',
    light: '#7E7E7E',
    disabled: '#2D2D2D'
  },
  error: {
    main: '#B00020',
  },
  neutral: {
    main: '#D4D4D4',
    light: '#F4F4F4',
  },
  placeholder: {
    main: '#7E7E7E',
  },
};

export default createMuiTheme({
  palette: {
    primary: {
      light: PALETTE.primary.light,
      main: PALETTE.primary.main,
      contrastText: PALETTE.text.contrast,
    },
    secondary: {
      main: PALETTE.primary.main,
    },
    error: {
      main: PALETTE.error.main,
    },
    text: {
      primary: PALETTE.text.primary,
      secondary: PALETTE.text.secondary,
      disabled: PALETTE.text.disabled,
    },
    neutral: {
      main: PALETTE.neutral.main,
      light: PALETTE.neutral.light,
    },
    placeholder: {
      main: PALETTE.placeholder.main,
    },
  },
  typography: {
    fontFamily: ['Arial', 'sans-serif'].join(','),
    h1: {
      fontFamily: ['Raleway', 'sans-serif'].join(','),
      fontSize: '3em',
      fontWeight: '300',
    },
    h2: { fontFamily: ['Raleway', 'sans-serif'].join(','), },
    h3: { fontFamily: ['Raleway', 'sans-serif'].join(','), },
    h4: { fontFamily: ['Raleway', 'sans-serif'].join(','), },
    h5: { fontFamily: ['Raleway', 'sans-serif'].join(','), },
    subtitle1: {
      fontSize: '1rem',
      fontFamily: ['Raleway', 'sans-serif'].join(','),
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    subtitle2: { fontFamily: ['Raleway', 'sans-serif'].join(','), },
    body1: { fontFamily: ['Arial', 'sans-serif'].join(','), },
    body2: { fontFamily: ['Arial', 'sans-serif'].join(','), },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.85em',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '2px',
        '&$disabled': {
          backgroundColor: PALETTE.primary.light,
          opacity: .7,
          color: PALETTE.text.contrast,
        }
      },
      contained: {
        '&$disabled': {
          backgroundColor: PALETTE.primary.light,
          opacity: .7,
          color: PALETTE.text.contrast,
        }
      },
    },
    MuiInput: {
      root: {
        border: `2px solid ${PALETTE.neutral.main}`,
        backgroundColor: PALETTE.neutral.light,
        borderRadius: '2px',
        boxShadow: 'none',
        fontSize: '16px',
        '&$disabled': {
          opacity: .7,
          color: PALETTE.text.disabled,
        },
        
      },
      colorSecondary: {
        border: 'none',
        borderBottom: `2px solid ${PALETTE.neutral.main}`,
        backgroundColor: 'transparent',
      }
    },
    MuiInputBase: {
      root: {
        "&::before": {
          display: 'none',
        },
        "&::after": {
          display: 'none',
        },
        "&.Mui-error": {
          borderColor: PALETTE.error.main,
        },
        '&$disabled': {
          opacity: .7,
          color: PALETTE.text.disabled,
        },
      },
      input: {
        padding: '12px 16px',
      },
      multiline: {
        border: `2px solid ${PALETTE.neutral.main}`,
        backgroundColor: PALETTE.neutral.light,
        borderRadius: '2px',
        boxShadow: 'none',
        fontSize: '16px',
        padding: '16px',
        "& > textarea::placeholder": {
          color: PALETTE.placeholder.main,
          opacity: 1,
        },
        '&$disabled': {
          opacity: .7,
          color: PALETTE.text.disabled,
        },
      },
    },
    MuiRadio:  {
      root: {
        color: PALETTE.neutral.main,
        '&$checked': {
          color:  PALETTE.primary.main,
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: '2px 9px',
        color: PALETTE.neutral.main,
        '&$checked': {
          color:  PALETTE.primary.main,
        },
      },
    },
    MuiToggleButton: {
      root: {
        '&$selected': {
          backgroundColor: PALETTE.neutral.main,
          color: PALETTE.text.contrast,
        },
        '&$disabled': {
          opacity: .7,
          color: PALETTE.text.disabled,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: PALETTE.text.primary,
        fontFamily: ['Raleway', 'sans-serif'].join(','),
        fontWeight: 'bold',
        fontSize: '16px',
      },
    },
    MuiStepLabel: {
      active: {
        fontWeight: 'bold !important'
      },
      iconContainer: {
        width: '100%'
      }
    }
  },
});
