import React from "react";
import { connect } from 'react-redux';
import { Caused } from "../Common/Caused";
import { Reporter } from "../Common/Reporter";
import { Summary } from "../Common/Summary";
import { Damaged } from "./Damaged";
import { StepTypes, ComponentTitle } from '../../../../actions/types';
import { LossLocationNotPolicy } from "../Common/LossLocationNotPolicy";
import { Injured } from "../Common/Injured";

export const LastSteptWithRedux = ({ claim, handleNext, newClaim }) => {
  const handleSubmit = () => {
    
  };
  
  return (
    <Summary>
        {claim.type === "notvalidatedrenters" && <LossLocationNotPolicy title={ComponentTitle.LOSSLOCATIONNOTPOLICY} stepType={StepTypes.LOSS_LOCATION_NOT_POLICY} />}
        <Damaged title={ComponentTitle.DAMAGED} stepType={StepTypes.DAMAGED_REN} />
        {newClaim.injured.manyInjured > 0 && <Injured title={ComponentTitle.INJURED} stepType={StepTypes.INJURED} /> }
        <Caused title={ComponentTitle.CAUSED} stepType={StepTypes.CAUSED} />
        <Reporter title={ComponentTitle.REPORTER} stepType={StepTypes.REPORTER} />
    </Summary>
  );
};

LastSteptWithRedux.defaultProps = {
  handleNext: () => {}, 
};

const mapStateToProps = (state) => ({
  claim: state.claim,
  newClaim: state.newClaim,
});

export const LastStept = connect(mapStateToProps, {})(LastSteptWithRedux);