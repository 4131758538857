import React from 'react';
import { useFormikContext, Field, FieldArray, ErrorMessage } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputMask from 'react-input-mask';
import MuiTextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  officialsInfo: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconBtn: {
    marginTop: theme.spacing(1),
    paddingLeft: '0px',
  },
}));

const OfficialsComponent = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormikContext();
  const officialTypes = [
    'None',
    'Ambulance',
    'Coroner',
    'Dept. of Transportation',
    'Police',
    'Security officer',
    'Sheriff',
    'State trooper',
    'Civil agency',
    'FEMA',
    'Fire',
    'Health department',
    'Regulatory agency',
    'Utility service provider',
    'Other',
  ];

  const classes = useStyles();

  return (
    <FieldArray
      name='officials'
      render={(arrayHelpers) => (
        <>
          {values.officials &&
            values.officials.length > 0 &&
            values.officials.map((official, index) => (
              <div key={index}>
                <Grid container spacing={2} className={classes.officialsInfo}>
                  <Grid item xs={12}>
                    <Typography display='inline' gutterBottom>{`Official ${
                      index + 1
                    }`}</Typography>
                    <IconButton
                      key={`official-${index}-delete-icon-button`}
                      size={'small'}
                      onClick={() => arrayHelpers.remove(index)}>
                      <ClearIcon
                        key={`official-${index}-delete-icon`}
                        fontSize={'small'}
                        color={'secondary'}
                      />
                    </IconButton>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id='official-type-select-label'>
                          Type
                        </InputLabel>
                        <Field
                          label='official-type-select-label'
                          component={Select}
                          name={`officials.${index}.type`}>
                          {officialTypes.map((type, index) => (
                            <MenuItem key={index} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      name={`officials.${index}.reportingAgency`}
                      label='Reporting Agency'
                      placeholder='Optional'
                      component={TextField}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Field
                      name={`officials.${index}.reportNumber`}
                      label='Report Number'
                      placeholder='Optional'
                      component={TextField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      name={`officials.${index}.reportingOfficer`}
                      label='Reporting Officer'
                      placeholder='Optional'
                      component={TextField}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputMask
                      name={`officials.${index}.phone`}
                      placeholder='Optional'
                      mask='999-999-9999'
                      maskChar=''
                      value={values.officials[index].phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={false}
                      fullWidth>
                      {(props) => <MuiTextField label='Phone' {...props} />}
                    </InputMask>
                  </Grid>
                </Grid>
              </div>
            ))}
          <Grid item xs={12}>
            <Button
              className={classes.iconBtn}
              color={'primary'}
              size={'small'}
              onClick={() =>
                arrayHelpers.push({
                  type: 'None',
                  reportingAgency: '',
                  reportingOfficer: '',
                  reportNumber: '',
                  phone: '',
                })
              }>
              <AddCircleOutlineIcon fontSize={'small'} /> &nbsp; Add an Official
            </Button>
          </Grid>
        </>
      )}
    />
  );
};

export default OfficialsComponent;
