import { SubjectTwoTone } from '@material-ui/icons';
import axios from 'axios';
import appSettings from '../appSettings';

export const sendClaimSummaryEmail = async (
  id,
  confirmationId,
  policyHolder,
  type
) => {
  try {
    const {
      policyNumber,
      firstName,
      reporter,
      email,
      dateOfLoss,
      lossLocation,
    } = policyHolder;
    const fnolEmails = appSettings.FNOL_TEAM_EMAILS.split(',').map(
      (element) => {
        return { email: element };
      }
    );

    let to;
    if (Array.isArray(email)) {
      to = email.map((element) => {
        return { email: element };
      });
    } else {
      to = [{ email }];
    }

    let subject = '';
    {
      policyNumber
        ? (subject = `American Modern Digital Claim Submission - Policy ${policyNumber}`)
        : (subject = 'American Modern Digital Claim Submission');
    }

    const body = JSON.stringify({
      htmlToPdf: {
        url: `${window.location.origin}/claims-fnol-search?id=${confirmationId}`,
        requiresAuth: false,
        width: '1200px',
        height: '1400px',
      },
      claim: {
        reporter: reporter,
        submissionId: id,
        dateOfLoss,
        lossLocation,
        type: type ?? localStorage.getItem('policyType'),
      },
      policyHolderEmail: {
        firstName: firstName,
        templateName: `claim-reporter-email-template`,
        to,
      },
      fnolTeamEmail: {
        templateName: `fnol-team-email-template`,
        fnolSummaryUrl: `${window.location.origin}/claims-fnol-search?id=${confirmationId}`,
        to: fnolEmails,
      },
      confirmationId: confirmationId,
      sender: {
        name: 'American Modern Insurance Group',
        email: 'noreply@amig.com',
      },
      subject: subject,
      attachments: [],
    });

    await axios.post(
      `${appSettings.DIGITAL_CLAIMS_PROXY_BASE_URL}/claims/email-summary?type=${type}`,
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
};
