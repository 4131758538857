import React, { useState } from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { Checkbox } from "@material-ui/core";

export const AMIGCheckbox = ({ 
  name, 
  label, 
  checked, 
  helperText, 
  handleChange, 
  ...rest
}) => {
  
  const { palette } = useTheme();

  const useStyles = makeStyles((theme) => ({
    checkbox: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      border: `1px solid ${palette.text.secondary}`,
      borderRadius: '50%',
      width: 16,
      height: 16,
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        borderColor: palette.primary.main,
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        opacity: .7,
      },
    },
    checkedIcon: {
      border: `1px solid ${palette.primary.main}`,
      '&:before': {
        display: 'block',
        width: 12,
        height: 12,
        backgroundColor: palette.primary.main,
        backgroundImage: 'none',
        content: "''",
        margin: '2px',
        borderRadius: '50%',
      },
    },
  }));

  const classes = useStyles();

  const [buttonChecked, setButtonChecked] = useState(checked);

  const handleCheckboxChange = (event, checked) => {

    handleChange(event, checked);
    setButtonChecked(checked);
  };

  return (
    <Checkbox 
      className={classes.checkbox}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}  
      name={name}
      label={label}
      checked={buttonChecked} 
      onChange={handleCheckboxChange}  
      {...rest} />
  );
};

AMIGCheckbox.defaultProps = {
  name: '',
  label: '',
  checked: false,
  helperText: '',
  handleChange: () => {},
};
