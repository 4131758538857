import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { ContactTypes } from '../../actions/types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GenericDialog } from '../dialogs/GenericDialog';

const { deviceType } = getDeviceTypeInfo();

const PolicyInfoComponent = ({ policy, handleContinue}) => {
  const {
    effectiveDate,
    expirationDate,
    contacts,
    policyLocation: { address },
  } = policy;

  const policyHolder = contacts.filter(
    (c) => c.contactType === ContactTypes.POLICY_HOLDER
  )[0];
  const { firstName, lastName } = policyHolder;

  const useStyles = makeStyles({
    card: {
      display: 'flex',
      flexDirection: 'column',
      // alignItems: 'center',
      height: '100%',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    headerText: {
      fontSize: '1.3rem',
      fontWeight: '500',
    },
    tableFont: {
      color: 'rgba(0, 0, 0, 0.54) !important',
    },
  });

  const [expanded, setExpanded] = useState(false);
  const [showPolicyInfoDialog,setShowPolicyInfoDialog] = useState(deviceType !== 'Mobile');

  const handlePolicyInfoClose = () => {
    setShowPolicyInfoDialog(false);
  };

  const handlePolicyInfoSubmit = () => {
    setShowPolicyInfoDialog(false);
    if(handleContinue) handleContinue();
  };
  
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const rows = policy.coverages.map((c, i) => {
    return {
      covDescription: c.covDescription,
      exposureLimit: c.exposureLimit,
      deductible: c.deductible,
    };
  });

  const classes = useStyles();

  return (
    <>
      {deviceType === 'Mobile' ? (
        <>
          <Box my={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent align='center'>
                    <Typography
                      className={classes.title}
                      color='textSecondary'
                      gutterBottom>
                      Named Insured
                    </Typography>
                    <Typography gutterBottom>
                      {firstName} {lastName}
                    </Typography>
                    <Typography
                      className={classes.title}
                      color='textSecondary'
                      gutterBottom>
                      Effective Time Period
                    </Typography>
                    <Typography gutterBottom>
                      {effectiveDate} to {expirationDate}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent align='center'>
                    <Typography
                      className={classes.title}
                      color='textSecondary'
                      gutterBottom>
                      Policy Location
                    </Typography>
                    <Typography>
                      {address.addressLine1}
                    </Typography>
                    <Typography>
                      {address.addressLine2}
                    </Typography>
                    <Typography>
                      {address.city}, {address.state} {address.postalCode}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box>
            {rows.length > 0 && rows.map((row, index) => (
              <ExpansionPanel key={index} onChange={handleChange({ index })}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={index}
                  id={index}>
                  <Typography>
                    {row.covDescription}
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <Typography className={classes.title} color={'textSecondary'}>Limit</Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography>
                        {row.exposureLimit}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={3}>
                      <Typography className={classes.title} color={'textSecondary'}>
                        Deductible
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Typography>
                        {row.deductible}
                      </Typography>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </Box>
        </>
      ) : (
        <GenericDialog 
        showDialog={showPolicyInfoDialog} 
        title="Policy info"
        handleClose={handlePolicyInfoClose}
        handleSubmit={handlePolicyInfoSubmit} >
          <Box m={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent align='center'>
                    <Typography
                      className={classes.title}
                      color='textSecondary'
                      gutterBottom>
                      Named Insured
                    </Typography>
                    <Typography variant='h5' component='h2'>
                      {firstName} {lastName}
                    </Typography>
                    <Typography
                      className={classes.title}
                      color='textSecondary'
                      gutterBottom>
                      Effective Time Period
                    </Typography>
                    <Typography variant='h5' component='h2'>
                      {effectiveDate} to {expirationDate}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent align='center'>
                    <Typography
                      className={classes.title}
                      color='textSecondary'
                      gutterBottom>
                      Policy Location
                    </Typography>
                    <Typography variant='h5' component='h2'>
                      {address.addressLine1}
                    </Typography>
                    <Typography variant='h5' component='h2'>
                      {address.addressLine2}
                    </Typography>
                    <Typography variant='h5' component='h2'>
                      {address.city}, {address.state} {address.postalCode}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          {rows.length > 0 && (
            <Box mx={3}>
            <TableContainer component={Paper}>
              <Table size={'small'}>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.headerText}>
                      Coverage
                    </TableCell>
                    <TableCell align='right'>
                      <Tooltip
                        disableFocusListener
                        interactive
                        arrow
                        title={
                          'This is the maximum amount of coverage you have for an individual loss. The limits vary by coverage and are based on your selections when the policy was purchased.'
                        }>
                        <Typography className={classes.headerText}>
                          Liability Limits
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align='right'>
                      <Tooltip
                        disableFocusListener
                        interactive
                        arrow
                        title={
                          'This is your share of the loss that was selected when the policy was purchased. This amount is subtracted from any amount paid on the loss.'
                        }>
                        <Typography className={classes.headerText}>
                          Deductible
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className={classes.tableFont}
                        component='th'
                        scope='row'>
                        {row.covDescription}
                      </TableCell>
                      <TableCell className={classes.tableFont} align='right'>
                        {row.exposureLimit}
                      </TableCell>
                      <TableCell className={classes.tableFont} align='right'>
                        {row.deductible}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          )}
        </GenericDialog>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  policy: state.policy,
});

export default connect(mapStateToProps, null)(PolicyInfoComponent);
