import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Link, Typography, Box } from '@material-ui/core';
import CustomFormatterUtil from '../../common/CustomFormatterUtil';
import useStyles from './TrackClaimComponent.styles';

const AdjusterComponent = ({
  adjusterData = {},
}) => {
  const classes = useStyles();
  const phoneIcon = require('../../../img/phone-icon.png');
  const activePhoneIcon = require('../../../img/phone-icon-green.png');
  const emailIcon = require('../../../img/email-icon.png');
  const adjusterIcon = require('../../../img/avatar_green.png');
  return (
  <Box>
    <Typography className={classes.adjusterTitle} variant="h2">Have questions?</Typography>
    <Box className={classes.container}>
      <Grid item xs={8} className={classes.adjusterCard}>  
        <Box>
          <Grid container direction="column" className={classes.statusCard}>
            <Typography variant="h3" className={classes.headerTitle}>
              Your adjuster
            </Typography>
              <Grid item container direction="row">
                <Grid item container>
                  <Grid container direction="column">
                    <Grid item>
                      <Box className={classes.agentDisplayNameWrapper}>
                      <img src={adjusterIcon} alt="" className={classes.adjusterIcon} />
                        <Typography variant="h3" className={classes.adjusterHeaderText}>
                          {adjusterData.displayName}
                        </Typography>
                      </Box>
                      <Typography variant="h5" className={classes.adjusterSubTitle}>
                        Call, text or email me
                      </Typography>
                      <Box className={classes.agentContactPhoneWrapper}>
                        <img src={phoneIcon} alt="" className={classes.phoneIcon} />
                        <img src={activePhoneIcon} alt="" className={classes.activePhoneIcon} />
                        <Link className={classes.agentMobileContactLink} component="a" href={"tel:+"+adjusterData.phoneNumber} variant="body1">
                          <Typography className={classes.adjusterPhoneNumber}  component="span">
                              {CustomFormatterUtil.formatPhoneNumber(adjusterData.phoneNumber)}
                          </Typography>
                        </Link>
                      </Box>
                      <Box className={classes.emailWrapper}>
                        <img src={emailIcon} alt="" className={classes.emailIcon} />
                        <Link className={classes.agentContactLink} component="a" href={"mailto:"+adjusterData.email} variant="body1">
                          <Typography className={classes.adjusterEmail} component="span">
                            {adjusterData.email}
                          </Typography>
                        </Link>
                      </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  </Box>
  );
};

const mapStateToProps = (state) => ({
});

AdjusterComponent.propTypes = {
};

export default connect(mapStateToProps, { })(
  AdjusterComponent
);
